import { clsx } from 'clsx';
import { ReactNode, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { getLastDashboardRoute } from '../../util/routes';

import { useAuth } from '../../contexts/auth';
import { useChatSupport } from 'contexts/chatSupport';
import Icon from 'components/common/Icon';
import Logo from '../common/Logo';
import LogoCollapsed from '../common/LogoCollapsed';
import Tooltip from '../common/Tooltip';
import UserBubble from '../common/UserBubble';

const NewLayout = ({
  canExpandSidebar = true,
  children,
  header = undefined,
}: {
  canExpandSidebar?: boolean;
  children: ReactNode;
  header?: ReactNode;
}): JSX.Element => {
  const [isSidebarCollapsed] = useState(!canExpandSidebar);

  return (
    <div className="relative flex flex-col h-full w-full font-inter text-sm text-gray-900 font-normal">
      <div
        className={clsx('fixed top-0 left-0 bg-gray-50', {
          'w-main-sidebar-collapsed': isSidebarCollapsed,
          'w-main-sidebar xl:w-main-sidebar-collapsed': !isSidebarCollapsed,
        })}
      >
        <Sidebar isCollapsed={isSidebarCollapsed} />
      </div>

      <div
        className={clsx('flex-grow h-px', {
          'ml-main-sidebar-collapsed': isSidebarCollapsed,
          'ml-main-sidebar xl:ml-main-sidebar-collapsed': !isSidebarCollapsed,
          'mt-14': header,
        })}
      >
        {header && (
          <div className="fixed top-0 h-14 w-full border-b border-gray-300 z-20">
            {header}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default NewLayout;

export const Sidebar = ({
  isCollapsed,
}: {
  isCollapsed: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onAuthChanged, user } = useAuth();
  const { showChat } = useChatSupport();

  return (
    <div
      className={clsx(
        'flex flex-col w-full h-screen p-6 border-r border-light-grey',
        {
          'pt-0 px-2': isCollapsed,
          'xl:px-2': !isCollapsed,
        },
      )}
    >
      <SidebarLogo isCollapsed={isCollapsed} />
      <div className="mt-4 flex flex-1 flex-col justify-between divide-light-grey text-dark-grey">
        <ul className="cursor-pointer">
          <SidebarLink
            icon={<Icon id="copy-06" />}
            isActive={location.pathname.startsWith('/dashboard')}
            isCollapsed={isCollapsed}
            label="Your Surveys"
            onClick={() => {
              navigate(getLastDashboardRoute());
            }}
          />
          <SidebarLink
            icon={<Icon id="users-01" />}
            isActive={location.pathname.startsWith('/team')}
            isCollapsed={isCollapsed}
            label="Your Team"
            onClick={() => {
              navigate('/team');
            }}
          />
        </ul>
        <ul className="mt-4 pt-4 cursor-pointer">
          <SidebarLink
            icon={<Icon id="help-circle" />}
            isCollapsed={isCollapsed}
            label="Help"
            onClick={() => {
              showChat();
            }}
          />
          <SidebarLink
            icon={<Icon id="log-out-01" />}
            isCollapsed={isCollapsed}
            label="Log Out"
            onClick={() => {
              onAuthChanged({ jwt: null });
            }}
          />
        </ul>
      </div>
      {user && (
        <div
          className="pt-3.5 pl-2 flex items-center text-sm cursor-pointer space-x-4"
          onClick={() => {
            navigate('/account');
          }}
        >
          <div className="shrink-0">
            <UserBubble user={user} withTooltip={false} />
          </div>

          <div
            className={clsx('flex flex-col leading-none', {
              hidden: isCollapsed,
              'inline xl:hidden': !isCollapsed,
            })}
          >
            {user.firstName} {user.lastName}
          </div>
        </div>
      )}
    </div>
  );
};

const SidebarLogo = ({
  isCollapsed,
}: {
  isCollapsed: boolean;
}): JSX.Element => {
  return (
    <div>
      <div
        className={clsx('flex items-center h-14 justify-center', {
          flex: isCollapsed,
          'hidden xl:flex': !isCollapsed,
        })}
      >
        <LogoCollapsed />
      </div>
      <div
        className={clsx('justify-center mb-8', {
          hidden: isCollapsed,
          'flex xl:hidden': !isCollapsed,
        })}
      >
        <div className="w-3/4">
          <Logo />
        </div>
      </div>
    </div>
  );
};

const SidebarLink = ({
  icon,
  isActive = false,
  isCollapsed,
  label,
  onClick,
  thirdPartyClassName = '',
  to,
}: {
  icon: ReactNode;
  isActive?: boolean;
  isCollapsed: boolean;
  label: string;
  onClick?: () => void;
  thirdPartyClassName?: string;
  to?: string;
}): JSX.Element => {
  const className = clsx(
    'flex items-center px-4 py-3.5 cursor-pointer',
    thirdPartyClassName,
    {
      'text-forest font-semibold': isActive,
      'hover:bg-dark-white font-medium': !isActive,
      'justify-center': isCollapsed,
    },
  );
  const linkContent = (
    <>
      <div className="w-4 h-4 flex items-center justify-center">{icon}</div>
      <span
        className={clsx('ml-4', {
          hidden: isCollapsed,
          'inline xl:hidden': !isCollapsed,
        })}
      >
        {label}
      </span>
    </>
  );

  const sidebarLink = to ? (
    <Link className={className} to={to}>
      {linkContent}
    </Link>
  ) : (
    <a className={className} onClick={onClick}>
      {linkContent}
    </a>
  );

  return isCollapsed ? (
    <Tooltip placement="right" trigger={sidebarLink}>
      {label}
    </Tooltip>
  ) : (
    sidebarLink
  );
};
