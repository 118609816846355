import { cloneDeep, every, isEmpty, reject, some } from 'lodash-es';
import { useState } from 'react';
import { FieldArray, Form, Formik, FormikErrors, useField } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { getJwtFromHeaders } from '../../util/auth';
import { getOrgStatus } from '../../util/users';
import { Organization } from '../../types/domainModels';
import {
  useChangeOrganization,
  useCreateOrganization,
  useOrderedOrganizations,
} from 'hooks/backend/organizations';
import { showErrorMessage } from '../../util/notifications';

import { useAuth } from '../../contexts/auth';
import { useHasRole } from '../../hooks/users';
import { useModal } from '../../hooks/modals';
import AddButton from '../common/forms/AddButton';
import Button from 'components/common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import ColoredBubble from 'components/common/ColoredBubble';
import Combobox, {
  ComboboxInput,
  ComboboxOptions,
} from 'components/common/Combobox';
import FormCheckbox from '../common/forms/FormCheckbox';
import FormGroup from '../common/forms/FormGroup';
import FormInput from '../common/forms/FormInput';
import Icon from 'components/common/Icon';
import Modal, { ModalHeader } from '../common/Modal';
import Popover from 'components/common/PopoverNew';
import XButton from '../common/forms/XButton';

interface CreateOrganizationFormData {
  organizationName: string;
  teamMembers: TeamMemberFormData[];
  useInvoice: boolean;
}

interface TeamMemberFormData {
  email: string;
  firstName: string;
  lastName: string;
}

function getEmptyTeamMember(): TeamMemberFormData {
  return {
    email: '',
    firstName: '',
    lastName: '',
  };
}

export function isTeamMemberEmpty(teamMember: TeamMemberFormData): boolean {
  return !teamMember.email && !teamMember.firstName && !teamMember.lastName;
}

function validateCreateOrganization(
  formData: CreateOrganizationFormData,
): FormikErrors<CreateOrganizationFormData> {
  const errors: FormikErrors<CreateOrganizationFormData> = {};

  if (!formData.organizationName) {
    errors.organizationName = 'Please provide a name.';
  }

  const teamMemberErrors: FormikErrors<TeamMemberFormData>[] = [];
  formData.teamMembers.forEach((teamMember) => {
    const errors: FormikErrors<TeamMemberFormData> = {};

    if (teamMember.email || teamMember.firstName || teamMember.lastName) {
      if (!teamMember.email) {
        errors.email = 'Required';
      }

      if (!teamMember.firstName) {
        errors.firstName = 'Required';
      }

      if (!teamMember.lastName) {
        errors.lastName = 'Required';
      }
    }

    teamMemberErrors.push(errors);
  });

  if (every(formData.teamMembers, isTeamMemberEmpty)) {
    errors.teamMembers = [
      { firstName: 'Please enter at least one team member.' },
    ];
  }

  if (some(teamMemberErrors, (errors) => !isEmpty(errors))) {
    errors.teamMembers = teamMemberErrors;
  }

  return errors;
}

const ChangeOrganization = () => {
  const isAdmin = useHasRole('admin');
  const queryClient = useQueryClient();
  const { onAuthChanged } = useAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {
    isOpen: isCreateOrgModalOpen,
    onCloseModal: onCloseCreateOrgModal,
    setIsOpen: setIsCreateOrgModalOpen,
  } = useModal();

  const { isPending: isChangingOrganization, mutate: changeOrganization } =
    useChangeOrganization({
      onError: (err) => {
        showErrorMessage(
          `Failed to change organization. Error: ${err.message}`,
        );
      },
      onSuccess: (data) => {
        onAuthChanged({
          jwt: getJwtFromHeaders(data.headers),
        });

        // Want to clear all our data so we're not showing data for the previous organization.
        queryClient.invalidateQueries();

        setIsCreateOrgModalOpen(false);
      },
    });

  const { organizations: organizationOptions } = useOrderedOrganizations();

  const { baseOrganizationId, currentOrganizationId, hasChangedOrg } =
    getOrgStatus();
  const currentOrg = organizationOptions.find(
    (org) => org.value === currentOrganizationId,
  );

  if (!currentOrg) {
    return null;
  }

  return (
    <div className="flex">
      <Popover
        open={isPopoverOpen}
        placement="bottom-start"
        setIsOpen={setIsPopoverOpen}
        trigger={
          <div className="flex items-center space-x-2">
            <ColoredBubble id={currentOrg.value} value={currentOrg.label} />
            <span>{currentOrg.label}</span>
            <div className="w-4 h-4">
              <Icon id="chevron-down" />
            </div>
            {isAdmin && hasChangedOrg && baseOrganizationId && (
              <Button
                hierarchy="secondary-gray"
                onClick={(event) => {
                  event.stopPropagation();

                  changeOrganization({
                    data: { organizationId: baseOrganizationId },
                  });
                }}
                size="xs"
                type="button"
              >
                See all
              </Button>
            )}
          </div>
        }
      >
        <Combobox
          onChange={(organization) => {
            if (organization) {
              setIsPopoverOpen(false);

              changeOrganization({
                data: { organizationId: organization.value },
              });
            }
          }}
          options={organizationOptions}
          value={currentOrg}
        >
          <div className="p-2">
            <ComboboxInput
              icon={
                <div className="text-gray-d-400">
                  <Icon id="search-lg" />
                </div>
              }
              iconPlacement="leading"
            />
          </div>

          <div className="max-h-60 w-96 max-w-full overflow-auto">
            <ComboboxOptions alwaysOpen />
          </div>
        </Combobox>

        <div className="flex p-2 border-t border-gray-d-200">
          <Button
            grow
            hierarchy="secondary-gray"
            icon={<Icon id="plus" />}
            iconPlacement="leading"
            onClick={() => {
              setIsCreateOrgModalOpen(true);
            }}
            size="sm"
            type="button"
          >
            Create New
          </Button>
        </div>
      </Popover>

      {isCreateOrgModalOpen && (
        <CreateOrganizationModal
          isChangingOrganization={isChangingOrganization}
          onChangeOrganization={(organizationId) => {
            changeOrganization({ data: { organizationId } });
          }}
          onCloseModal={onCloseCreateOrgModal}
        />
      )}
    </div>
  );
};

export default ChangeOrganization;

const CreateOrganizationModal = ({
  isChangingOrganization,
  onChangeOrganization,
  onCloseModal,
}: {
  isChangingOrganization: boolean;
  onChangeOrganization(organizationId: number): void;
  onCloseModal(): void;
}): JSX.Element => {
  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Create Organization
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <p className="text-dark-grey text-sm">
        Use the form below to create a new organization. After creation, you
        will be changed into the new organization.
      </p>
      <div className="mt-4">
        <CreateOrganizationForm
          isChangingOrganization={isChangingOrganization}
          onOrganizationCreated={(organization) => {
            onChangeOrganization(organization.id);
          }}
        />
      </div>
    </Modal>
  );
};

const CreateOrganizationForm = ({
  isChangingOrganization,
  onOrganizationCreated,
}: {
  isChangingOrganization: boolean;
  onOrganizationCreated(organization: Organization): void;
}): JSX.Element => {
  const isAdmin = useHasRole('admin');

  const { isPending: isCreatingOrganization, mutate: createOrganization } =
    useCreateOrganization({
      onError: (err) => {
        showErrorMessage(
          `Failed to create organization. Error: ${err.message}`,
        );
      },
      onSuccess: (data) => {
        onOrganizationCreated(data);
      },
    });

  return (
    <Formik<CreateOrganizationFormData>
      initialValues={{
        organizationName: '',
        teamMembers: [
          getEmptyTeamMember(),
          getEmptyTeamMember(),
          getEmptyTeamMember(),
        ],
        useInvoice: isAdmin,
      }}
      onSubmit={(formData) => {
        const data = cloneDeep(formData);

        // A user may not fill in every single row with team memmber information. This is a convenience
        // so we just ignore any rows they didn't fill in.
        data.teamMembers = reject(data.teamMembers, isTeamMemberEmpty);

        return createOrganization({
          data: {
            name: data.organizationName,
            useInvoice: isAdmin ? data.useInvoice : false,
            users: data.teamMembers,
          },
        });
      }}
      validate={validateCreateOrganization}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <CreateOrganizationFormFields
        isAdmin={isAdmin}
        isLoading={isCreatingOrganization || isChangingOrganization}
      />
    </Formik>
  );
};

const CreateOrganizationFormFields = ({
  isAdmin,
  isLoading,
}: {
  isAdmin: boolean;
  isLoading: boolean;
}): JSX.Element => {
  const [{ value: teamMembers }] =
    useField<CreateOrganizationFormData['teamMembers']>('teamMembers');

  return (
    <Form>
      <div className="space-y-4">
        <div className="flex space-x-6">
          <div className="w-1/2">
            <FormInput
              id="organizationName"
              label="Name"
              labelFor="organizationName"
              name="organizationName"
              size="md"
              type="text"
            />
          </div>
          {isAdmin && (
            <div className="mt-4">
              <FormCheckbox
                checkboxLabel="Can use invoice"
                name="useInvoice"
                tooltip="This allows the organization members to use an invoice instead of credit card payments when launching a new survey."
              />
            </div>
          )}
        </div>
        <FormGroup>
          <h3 className="mb-2 text-sm">Team Members</h3>
          <FieldArray
            name="teamMembers"
            render={(arrayHelpers) => {
              return (
                <>
                  <div className="space-y-4">
                    {teamMembers.map((_teamMember, index) => {
                      return (
                        <CreateOrganizationTeamMember
                          key={index}
                          index={index}
                          onClickRemove={() => {
                            if (teamMembers.length > 1) {
                              arrayHelpers.remove(index);
                            } else {
                              arrayHelpers.replace(index, getEmptyTeamMember());
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="flex mt-2">
                    <AddButton
                      label="Add"
                      onClick={() => {
                        arrayHelpers.push(getEmptyTeamMember());
                      }}
                    />
                  </div>
                </>
              );
            }}
          />
        </FormGroup>
      </div>
      <div className="flex justify-end mt-8">
        <ButtonLoading hierarchy="primary" isLoading={isLoading} size="md">
          Create Organization
        </ButtonLoading>
      </div>
    </Form>
  );
};

const CreateOrganizationTeamMember = ({
  index,
  onClickRemove,
}: {
  index: number;
  onClickRemove(): void;
}): JSX.Element => {
  const ids = {
    email: `email-${index}`,
    firstName: `firstName-${index}`,
    lastName: `lastName-${index}`,
  };

  return (
    <div className="flex w-full">
      <div className="flex-grow grid grid-cols-3 gap-4">
        <FormInput
          id={ids.firstName}
          label="First Name"
          labelFor={ids.firstName}
          name={`teamMembers.${index}.firstName`}
          size="md"
          type="text"
        />
        <FormInput
          id={ids.lastName}
          label="Last Name"
          labelFor={ids.lastName}
          name={`teamMembers.${index}.lastName`}
          size="md"
          type="text"
        />
        <FormInput
          id={ids.email}
          label="Email"
          labelFor={ids.email}
          name={`teamMembers.${index}.email`}
          size="md"
          type="text"
        />
      </div>
      <div className="mt-6 ml-4">
        <XButton onClick={onClickRemove} title="Remove" />
      </div>
    </div>
  );
};
