import { clsx } from 'clsx';
import { FocusEvent, useEffect, useState } from 'react';

import {
  addToDate,
  DATE_FORMATS,
  difference,
  formatDate,
  getDateFormat,
  isSame,
  MANUAL_ENTRY_DATE_FORMATS,
  parseToDate,
  startOf,
} from '../../../util/dates';

import Icon from '../Icon';
import Input from './Input';
import Popover from '../Popover';

const DAY_ABBRS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function formatDisplayValue(value: string): string {
  const foundDateFormat = getDateFormat(value, MANUAL_ENTRY_DATE_FORMATS);
  if (value && foundDateFormat) {
    return formatDate(value, { format: DATE_FORMATS.DEFAULT_DATE_FORMAT });
  }

  return value ?? '';
}

/**
 * Returns 6 weeks of days to display in the picker starting with the provided month.
 */
function getDisplayDays(currentMonth: Date): (Date | null)[] {
  const currentMonthDay = currentMonth.getUTCDay();
  const days: (Date | null)[] = [];

  // This goes from today's current weekday back to the previous Sunday since we want our
  // display days to always start on a Sunday (albeit with blank spots).
  for (let i = 0; i < currentMonthDay; i++) {
    days.push(null);
  }

  days.push(currentMonth);

  // This adds all the days in the current month.
  let nextDay = addToDate(currentMonth, { quantity: 1, units: 'days' });
  while (isSame(nextDay, currentMonth, 'months')) {
    days.push(nextDay);
    nextDay = addToDate(nextDay, { quantity: 1, units: 'days' });
  }

  return days;
}

export interface Props {
  name: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange(newValue: string): void;
  value: string;
}

const DateInput = ({ name, onBlur, onChange, value }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Responds to a change in the date.
   */
  function internalOnChange(value: string) {
    setIsOpen(false);

    onChange(value);
  }

  return (
    <Popover
      isOpen={isOpen}
      name={`date-input-${name}`}
      setIsOpen={setIsOpen}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <Input
            name={name}
            onBlur={(event) => {
              if (value) {
                onChange(
                  formatDate(value.trim(), {
                    format: DATE_FORMATS.API_DATE_FORMAT,
                  }),
                );
              }

              if (onBlur) {
                onBlur(event);
              }
            }}
            onChange={(event) => internalOnChange(event.target.value)}
            size="md"
            type="text"
            value={formatDisplayValue(value)}
          />
        </div>
      )}
    >
      <DatePicker onSelectDate={internalOnChange} value={value} />
    </Popover>
  );
};

export default DateInput;

const DatePicker = ({
  onSelectDate,
  value,
}: { onSelectDate(date: string): void } & Pick<
  Props,
  'value'
>): JSX.Element => {
  const [monthOffset, setMonthOffset] = useState(0);
  const [yearOffset, setYearOffset] = useState(0);

  const parseFormat = getDateFormat(value, MANUAL_ENTRY_DATE_FORMATS);
  const activeDate =
    value && parseFormat ? parseToDate(value, { parseFormat }) : null;

  const currentMonth = startOf(
    addToDate(
      addToDate(new Date(), { quantity: monthOffset, units: 'months' }),
      { quantity: yearOffset, units: 'years' },
    ),
    'months',
  );
  const displayDays = getDisplayDays(currentMonth);

  /**
   * Updates the date offsets from today's month/year to the provided date's month/year.
   */
  function setOffsetsToDate(date: Date) {
    const startOfDate = startOf(date, 'months');
    const todaysMonth = startOf(new Date(), 'months');
    const monthsDiff = difference(startOfDate, todaysMonth, 'months');
    const yearsDiff = difference(startOfDate, todaysMonth, 'years');

    if (Number.isNaN(monthsDiff) || Number.isNaN(yearsDiff)) {
      return;
    }

    setMonthOffset(monthsDiff - 12 * yearsDiff);
    setYearOffset(yearsDiff);
  }

  // If the user types in a date, we want to update the offsets so our date picker shows the current
  // month and year for the date the user typed in (but we only do this if we were able to parse the
  // date as a valid date).
  useEffect(() => {
    if (value && parseFormat) {
      setOffsetsToDate(parseToDate(value, { parseFormat }));
    }
  }, [parseFormat, value]);

  return (
    <div className="p-4" data-testid="date-picker">
      <div className="flex items-center justify-between mb-2">
        <div>
          {formatDate(currentMonth, {
            format: DATE_FORMATS.READABLE_MONTH_YEAR,
          })}
        </div>
        <div className="flex space-x-1">
          <div
            className="cursor-pointer"
            onClick={() => {
              setMonthOffset((monthOffset) => monthOffset - 1);
            }}
          >
            <div className="w-4 h-4">
              <Icon id="chevron-left" />
            </div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setMonthOffset((monthOffset) => monthOffset + 1);
            }}
          >
            <div className="w-4 h-4">
              <Icon id="chevron-right" />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-date-input justify-items-center">
        {DAY_ABBRS.map((abbr) => {
          return (
            <div
              key={abbr}
              className="text-dark-grey text-xs font-normal uppercase select-none"
            >
              {abbr}
            </div>
          );
        })}
        {displayDays.map((day, i) => {
          if (!day) {
            return <div key={i} className="h-10 w-10" />;
          }

          const isActive =
            (activeDate && isSame(day, activeDate, 'days')) ||
            (!activeDate && isSame(day, new Date(), 'days'));

          return (
            <div
              key={i}
              className="group flex items-center justify-center h-10 w-10 text-sm cursor-pointer"
              onClick={() => {
                onSelectDate(
                  formatDate(day, { format: DATE_FORMATS.API_DATE_FORMAT }),
                );
              }}
            >
              <div
                className={clsx(
                  'flex items-center justify-center h-8 w-8 rounded-full',
                  {
                    'bg-primary-d-600 text-white': isActive,
                    'group-hover:bg-light-grey': !isActive,
                  },
                )}
              >
                {formatDate(day, { format: DATE_FORMATS.SINGLE_DAY })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
