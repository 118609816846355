import { queryOptions } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';

import { fetchIncidenceTypes } from 'services/backend/incidenceTypes';

export const incidenceTypeQueries = {
  list: queryOptions({
    queryFn: () => fetchIncidenceTypes(),
    queryKey: ['incidenceTypes'],
    staleTime: minutesToMilliseconds(10),
  }),
};
