import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeMatrixOptions = ({
  disabled,
}: {
  disabled: boolean;
}): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel="Randomize Options"
        disabled={disabled}
        name="features.randomizeMatrixOptions"
      />
    </>
  );
};

export default RandomizeMatrixOptions;
