import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { TeamMember } from '../../types/domainModels';

export const FETCH_TEAM_MEMBERS = ({
  organizationId,
}: {
  organizationId: number;
}) => ({
  path: `/team/${organizationId}`,
  version: 'v1' as const,
});

export async function fetchTeamMembers({
  organizationId,
}: {
  organizationId: number;
}) {
  return (
    await axios.get<{ results: TeamMember[] }>(
      getAPIUrl(FETCH_TEAM_MEMBERS({ organizationId })),
    )
  ).data;
}

interface InviteTeamMember {
  data: {
    email: string;
    firstName: string;
    lastName: string;
  };
}

export const INVITE_TEAM_MEMBER = () => ({
  path: '/team/member',
  version: 'v1' as const,
});

export async function inviteTeamMember({ data }: InviteTeamMember) {
  return (await axios.post<''>(getAPIUrl(INVITE_TEAM_MEMBER()), data)).data;
}

interface RemoveTeamMember {
  organizationId: number;
  userId: number;
}

export const REMOVE_TEAM_MEMBER = ({
  organizationId,
  userId,
}: {
  organizationId: number;
  userId: number;
}) => ({
  path: `/teams/${organizationId}/member/${userId}`,
  version: 'v2' as const,
});

export async function removeTeamMember({
  organizationId,
  userId,
}: RemoveTeamMember) {
  return (
    await axios.delete(
      getAPIUrl(REMOVE_TEAM_MEMBER({ organizationId, userId })),
    )
  ).data;
}
