import { ReactNode } from 'react';

import IndexCard from 'components/common/IndexCard';

const TitledCard = ({
  children,
  header,
}: {
  children: ReactNode;
  header: ReactNode;
}) => {
  return (
    <IndexCard>
      {header}

      <div>{children}</div>
    </IndexCard>
  );
};

export default TitledCard;

export const TitledCardHeader = ({
  children,
  rightContent,
}: {
  children: string;
  rightContent?: ReactNode;
}) => {
  return (
    <div className="p-4 flex items-center justify-between border-b border-gray-400">
      <div className="text-xs font-semibold text-gray-600">{children}</div>

      {rightContent}
    </div>
  );
};
