import { ReactNode, useState } from 'react';

import {
  QuestionQuotaLogicalModifiers,
  QuestionWithResults,
  Survey,
  SurveyVariable,
  SurveyVariableQuotaType,
} from 'types/domainModels';

import { showErrorMessage, showSuccessMessage } from 'util/notifications';
import { useRemoveQuestionQuotas } from 'hooks/backend/questionQuotas';
import { useRemoveVariableQuotas } from 'hooks/backend/surveyVariableQuotas';

import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import Icon from 'components/common/Icon';
import ProgressBar from 'components/common/ProgressBar';
import ResultQuotaEditModal from 'components/common/ResultQuotaEditModal';
import ResultsVariableQuotasModal from 'components/surveyEdit/ResultsVariableQuotasModal';
import VerticalDotsButton from 'components/common/VerticalDotsButton';

export const QuotaProgress = ({
  firstColumnContent,
  numCompletes,
  numNeeded,
  type,
}: {
  firstColumnContent: ReactNode;
  numCompletes: number;
  numNeeded: number | null;
  type?: QuestionQuotaLogicalModifiers | SurveyVariableQuotaType;
}) => {
  const percentage = numNeeded ? Math.min(numCompletes / numNeeded, 1) : 0;

  return (
    <div className="grid grid-cols-3 gap-4">
      <div>{firstColumnContent}</div>
      <div className="flex justify-start items-start">
        {type === 'none' || numNeeded === null ? (
          '---'
        ) : (
          <div className="flex items-center">
            <ProgressBar percentage={percentage} />
            <span>
              {type === 'at_least' && numCompletes >= numNeeded
                ? numCompletes
                : `${numCompletes} / ${numNeeded}`}
            </span>
          </div>
        )}
      </div>
      {type && (
        <span>
          {type.replace('_', ' ').toUpperCase()}
          {type === 'at_least' ? ` ${numNeeded}` : ''}
        </span>
      )}
    </div>
  );
};

export const QuotasHeader = ({
  rightContent,
  subtitle,
  title,
}: {
  rightContent?: ReactNode;
  subtitle?: string;
  title: string;
}) => {
  return (
    <div className="flex justify-between items-start gap-4">
      <div>
        {subtitle && (
          <div className="text-xs text-gray-d-400 font-semibold">
            {subtitle}
          </div>
        )}
        <div className="font-semibold">{title}</div>
      </div>

      {rightContent}
    </div>
  );
};

export const QuotasProgressHeader = ({
  titleFirstColumn,
}: {
  titleFirstColumn: string;
}) => {
  return (
    <div className="grid grid-cols-3 gap-4 items-center text-gray-600">
      <div>{titleFirstColumn}</div>
      <div>Respondents</div>
      <div />
    </div>
  );
};

export const QuestionQuotasDropdown = ({
  numQuotas,
  question,
  questions,
  survey,
  waveIds,
}: {
  numQuotas: number;
  question: QuestionWithResults;
  questions: QuestionWithResults[];
  survey: Survey;
  waveIds: number[];
}) => {
  const [questionIdEditQuotas, setQuestionIdEditQuotas] = useState<
    number | undefined
  >(undefined);
  const questionEditQuotas = questions.find(
    (q) => q.id === questionIdEditQuotas,
  );

  const { mutate: removeQuestionQuotas } = useRemoveQuestionQuotas({
    onError: (err) => {
      showErrorMessage(
        `Failed to remove question quota. Error: ${err.message}`,
      );
    },
    onSuccess: () => {
      showSuccessMessage('Question quotas were removed successfully.');
    },
  });

  const onClickRemove =
    numQuotas === 0
      ? undefined
      : () => {
          removeQuestionQuotas({
            questionId: question.id,
            waveIds,
          });
        };

  return (
    <>
      <Dropdown
        button={
          <DropdownButton as="div">
            <VerticalDotsButton ariaLabel="Quota Options" />
          </DropdownButton>
        }
      >
        <DropdownItem
          as="button"
          icon={<Icon id="pencil" />}
          onClick={() => {
            setQuestionIdEditQuotas(question.id);
          }}
          type="button"
        >
          Edit Quota
        </DropdownItem>
        {onClickRemove && (
          <DropdownItem
            as="button"
            icon={<Icon id="trash" />}
            onClick={() => {
              onClickRemove();
            }}
            type="button"
          >
            Remove Quota
          </DropdownItem>
        )}
      </Dropdown>

      {questionEditQuotas && (
        <ResultQuotaEditModal
          onCloseModal={() => {
            setQuestionIdEditQuotas(undefined);
          }}
          question={questionEditQuotas}
          questions={questions}
          survey={survey}
        />
      )}
    </>
  );
};

export const Requirements = ({
  logic,
  requirements,
}: {
  logic: 'is' | 'isnt' | 'should' | 'shouldnt';
  requirements: string[];
}) => {
  if (requirements.length <= 1) {
    return (
      <span>
        {logic === 'isnt' ? `is not ` : ''}
        {requirements[0]}
      </span>
    );
  }

  let intro = 'is';
  if (logic === 'should') {
    intro = 'is either';
  } else if (logic === 'isnt' || logic === 'shouldnt') {
    intro = 'is not';
  }

  return (
    <div>
      {intro}
      <ul className="list-disc pl-4">
        {requirements.map((requirement, requirementIdx) => {
          return <li key={requirementIdx}>{requirement}</li>;
        })}
      </ul>
    </div>
  );
};

export const VariableQuotasDropdown = ({
  numQuotas,
  questions,
  survey,
  variable,
}: {
  numQuotas: number;
  questions: QuestionWithResults[];
  survey: Survey;
  variable: SurveyVariable;
}) => {
  const [variableEditQuotas, setVariableEditQuotas] = useState<
    SurveyVariable | undefined
  >(undefined);

  const { mutate: removeVariableQuotas } = useRemoveVariableQuotas({
    onError: (err) => {
      showErrorMessage(
        `Failed to remove variable quota. Error: ${err.message}`,
      );
    },
    onSuccess: () => {
      showSuccessMessage('The variable quota was successfully removed.');
    },
  });

  const onClickRemove =
    numQuotas === 0
      ? undefined
      : () => {
          removeVariableQuotas({ variableId: variable.id });
        };

  return (
    <>
      <Dropdown
        button={
          <DropdownButton as="div">
            <VerticalDotsButton ariaLabel="Quota Options" />
          </DropdownButton>
        }
      >
        <DropdownItem
          as="button"
          icon={<Icon id="pencil" />}
          onClick={() => {
            setVariableEditQuotas(variable);
          }}
          type="button"
        >
          Edit Quota
        </DropdownItem>
        {onClickRemove && (
          <DropdownItem
            as="button"
            icon={<Icon id="trash" />}
            onClick={() => {
              onClickRemove();
            }}
            type="button"
          >
            Remove Quota
          </DropdownItem>
        )}
      </Dropdown>

      {variableEditQuotas && (
        <ResultsVariableQuotasModal
          onCloseModal={() => {
            setVariableEditQuotas(undefined);
          }}
          questions={questions}
          survey={survey}
          variable={variableEditQuotas}
        />
      )}
    </>
  );
};
