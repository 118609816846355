import { clsx } from 'clsx';
import { ReactNode } from 'react';

import Icon from './Icon';

type Color = 'error' | 'gray';

const BACKGROUND_COLORS: Record<Color, string> = {
  error: 'text-error-d-700 border-error-d-300 bg-error-d-25',
  gray: 'text-gray-d-700 border-gray-d-300 bg-gray-d-25',
};

const Alert = ({
  children,
  color = 'error',
  supportingText,
}: {
  children: string | string[];
  color?: Color;
  supportingText?: ReactNode;
}) => {
  return (
    <div
      className={clsx(
        'relative p-4 flex gap-2 items-start rounded-xl border shadow-sm',
        BACKGROUND_COLORS[color],
      )}
    >
      <div className="absolute -translate-y-1/4">
        <IconWithRings color={color} />
      </div>

      <div className="text-sm space-y-1 pl-12">
        <p className="font-semibold">{children}</p>

        {supportingText}
      </div>
    </div>
  );
};

export default Alert;

const ICON_BORDER_COLORS = {
  error: 'border-error-d-600',
  gray: 'border-gray-d-600',
};
const ICON_TEXT_COLORS = {
  error: 'text-error-d-600',
  gray: 'text-gray-d-600',
};

const IconWithRings = ({ color }: { color: Color }) => {
  return (
    <div
      className={clsx(
        'relative flex items-center justify-center w-9 h-9',
        ICON_TEXT_COLORS[color],
      )}
    >
      {/* Outer ring */}
      <div
        className={clsx(
          'absolute inset-0 w-9 h-9 border opacity-10 rounded-full',
          ICON_BORDER_COLORS[color],
        )}
      />
      {/* Inner ring */}
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-7 h-7 border opacity-30 rounded-full',
          ICON_BORDER_COLORS[color],
        )}
      />

      <div className="w-5 h-5">
        <Icon id="alert-circle" />
      </div>
    </div>
  );
};
