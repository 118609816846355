import { Fragment } from 'react';

import {
  ConstraintConcepts,
  ConstraintOptions,
  ConstraintWithNumber as IConstraintWithNumber,
  ConstraintWithRange as IConstraintWithRange,
  ConstraintWithStatement as IConstraintWithStatement,
  DisplayLogicAndGroup as IDisplayLogicAndGroup,
  DisplayLogicOrGroups,
} from '../../types/forms';
import { getConceptTitle, isIdeaPresenterQuestion } from '../../util/questions';
import {
  isConstraintWithConcepts,
  isConstraintWithNumber,
  isConstraintWithRanges,
  isConstraintWithStatement,
} from '../../util/displayLogic';
import {
  getOptionTitle,
  getOptionTitleIndexFromSort,
} from '../../util/options';

import WordSeparator from '../common/WordSeparator';
import { castArray, flatten } from 'lodash-es';

const DisplayLogicSentence = ({
  displayLogicValues,
  isWithinMonadicLoop,
}: {
  displayLogicValues: DisplayLogicOrGroups;
  isWithinMonadicLoop: boolean;
}) => {
  return (
    <div className="text-sm">
      <p className="mb-2">Display this question if:</p>
      {displayLogicValues.map((group, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <WordSeparator word="or" />}
            <div className="relative ml-4 pl-4">
              <div className="absolute top-0 left-0 w-0.5 h-full bg-dark-grey" />
              {group.map((andGroup, index) => {
                return (
                  <Fragment key={index}>
                    <DisplayLogicAndGroup
                      andGroup={andGroup}
                      isWithinMonadicLoop={isWithinMonadicLoop}
                    />
                    {index !== group.length - 1 && <WordSeparator word="and" />}
                  </Fragment>
                );
              })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default DisplayLogicSentence;

const DisplayLogicAndGroup = ({
  andGroup,
  isWithinMonadicLoop,
}: {
  andGroup: IDisplayLogicAndGroup;
  isWithinMonadicLoop: boolean;
}) => {
  const { concept, constraints, modifier, question } = andGroup;

  return (
    <div>
      "{question?.label}" is{' '}
      <strong className="font-semibold">{modifier?.label}:</strong>
      <ul className="list-disc pl-8">
        {constraints.map((constraint, index) => {
          if (isConstraintWithRanges(constraint)) {
            return <ConstraintWithRange key={index} constraint={constraint} />;
          } else if (isConstraintWithStatement(constraint)) {
            return (
              <ConstraintWithStatement key={index} constraint={constraint} />
            );
          } else if (isConstraintWithNumber(constraint)) {
            return <ConstraintWithNumber key={index} constraint={constraint} />;
          } else if (isConstraintWithConcepts(constraint)) {
            return (
              <ConstraintWithConcepts key={index} constraint={constraint} />
            );
          }

          return <ConstraintWithOptions key={index} constraint={constraint} />;
        })}
      </ul>
      {!isIdeaPresenterQuestion(question?.value) && concept ? (
        <span>
          when {isWithinMonadicLoop ? 'current' : ''} concept seen is{' '}
          {concept.value?.description ?? 'any concept'}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

const ConstraintWithStatement = ({
  constraint,
}: {
  constraint: IConstraintWithStatement;
}): JSX.Element => {
  return (
    <li>
      {getOptionTitle({
        index: getOptionTitleIndexFromSort(constraint.statement?.value.sort),
        option: constraint.statement?.value,
      })}
      :{' '}
      {castArray(constraint.options)
        .map((option) => {
          return option.label;
        })
        .join(', ')}
    </li>
  );
};

const ConstraintWithConcepts = ({
  constraint,
}: {
  constraint: ConstraintConcepts;
}): JSX.Element => {
  return (
    <>
      {flatten(
        castArray(constraint.concepts).map((concept, index) => {
          return (
            <li key={index}>{getConceptTitle({ concept: concept.value })}</li>
          );
        }),
      )}
    </>
  );
};

const ConstraintWithOptions = ({
  constraint,
}: {
  constraint: ConstraintOptions;
}): JSX.Element => {
  return (
    <>
      {flatten(
        castArray(constraint.options).map((option, index) => {
          return (
            <li key={index}>
              {getOptionTitle({
                index: getOptionTitleIndexFromSort(option.value.sort),
                option: option.value,
              })}
            </li>
          );
        }),
      )}
    </>
  );
};

const ConstraintWithRange = ({
  constraint,
}: {
  constraint: IConstraintWithRange;
}): JSX.Element => {
  return (
    <li>
      {getOptionTitle({
        index: getOptionTitleIndexFromSort(constraint.option?.value.sort),
        option: constraint.option?.value,
      })}{' '}
      <span className="italic">with a value between</span>{' '}
      {constraint.range.start} and {constraint.range.end}
    </li>
  );
};

const ConstraintWithNumber = ({
  constraint,
}: {
  constraint: IConstraintWithNumber;
}): JSX.Element => {
  return (
    <li>
      {constraint.range.start} and {constraint.range.end}
    </li>
  );
};
