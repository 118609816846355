import { date, object } from 'yup';
import { useState } from 'react';
import { reduce, find } from 'lodash-es';
import { Form, Formik, useField } from 'formik';
import { useQuery } from '@tanstack/react-query';

import { DashboardFilters } from '../../types/internal';
import { getEmptyFilters } from '../../util/dashboard';
import { tagQueries } from 'hooks/backend/tags';
import { useOrderedTeamMembers } from '../../hooks/backend/team';

import Button from '../common/forms/Button';
import FormCheckbox from '../common/forms/FormCheckbox';
import FormDateInput from '../common/forms/FormDateInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Icon from 'components/common/Icon';
import Popover from '../common/Popover';

const FiltersSchema = object().shape({
  createdAt: object().shape({
    end: date().typeError('Please provide a valid date.'),
    start: date().typeError('Please provide a valid date.'),
  }),
});

const DashboardFiltersPopover = ({
  filters,
  onFiltersChanged,
}: {
  filters: DashboardFilters;
  onFiltersChanged(filters: DashboardFilters): void;
}): JSX.Element => {
  const [isFiltersPopoverOpen, setIsFiltersPopoverOpen] = useState(false);

  const numEnabledFilters = reduce(
    filters,
    (numEnabled, { enabled }) => {
      return enabled ? numEnabled + 1 : numEnabled;
    },
    0,
  );

  return (
    <Popover
      isOpen={isFiltersPopoverOpen}
      name="filter-dashboard"
      setIsOpen={setIsFiltersPopoverOpen}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="filter-funnel-01" />}
            iconPlacement="leading"
            size="sm"
          >
            <span>Filter</span>
            {numEnabledFilters > 0 && (
              <div className="flex items-center justify-center w-5 h-5 rounded-full bg-green text-white text-xs font-bold">
                {numEnabledFilters}
              </div>
            )}
          </Button>
        </div>
      )}
    >
      <DashboardFiltersBody
        appliedFilters={filters}
        onFiltersApplied={(filters) => {
          onFiltersChanged(filters);

          setIsFiltersPopoverOpen(false);
        }}
      />
    </Popover>
  );
};

export default DashboardFiltersPopover;

const DashboardFiltersBody = ({
  appliedFilters,
  onFiltersApplied,
}: {
  appliedFilters: DashboardFilters;
  onFiltersApplied(filters: DashboardFilters): void;
}): JSX.Element => {
  return (
    <Formik<DashboardFilters>
      initialValues={appliedFilters}
      onSubmit={(formData) => {
        const filtersToApply = { ...formData };

        if (
          filtersToApply.createdAt.enabled &&
          !filtersToApply.createdAt.end &&
          !filtersToApply.createdAt.start
        ) {
          filtersToApply.createdAt.enabled = false;
        }

        if (filtersToApply.tag.enabled && !filtersToApply.tag.value) {
          filtersToApply.tag.enabled = false;
        }

        if (filtersToApply.userId.enabled && !filtersToApply.userId.value) {
          filtersToApply.userId.enabled = false;
        }

        onFiltersApplied(filtersToApply);
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={FiltersSchema}
    >
      <Form>
        <div className="w-72 p-4">
          <div className="flex items-center space-x-2 text-sm">
            <div className="w-4 h-4">
              <Icon id="filter-funnel-01" />
            </div>
            <span>Filters</span>
          </div>
          <div className="mt-4 space-y-2">
            <DateCreatedFilter />
            <OwnerFilter />
            <TagFilter />
          </div>
          <div className="flex flex-row-reverse mt-8 gap-2">
            <Button hierarchy="primary" size="sm" type="submit">
              Done
            </Button>
            <Button
              hierarchy="secondary-gray"
              onClick={() => {
                onFiltersApplied(getEmptyFilters());
              }}
              size="sm"
              type="button"
            >
              Clear
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const DateCreatedFilter = (): JSX.Element => {
  const [{ value: enabled }] =
    useField<DashboardFilters['createdAt']['enabled']>('createdAt.enabled');

  return (
    <>
      <FormCheckbox checkboxLabel="Date Created" name="createdAt.enabled" />
      {enabled && (
        <div>
          <p className="text-xs">show surveys created between:</p>
          <div className="flex space-between mt-2 space-x-2">
            <div className="flex-grow-0 w-32">
              <FormDateInput name="createdAt.start" />
            </div>
            <span className="mt-2 text-xs">and</span>
            <div className="flex-grow-0 w-32">
              <FormDateInput name="createdAt.end" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const OwnerFilter = (): JSX.Element => {
  const [{ value: userId }, , userIdHelpers] =
    useField<DashboardFilters['userId']>('userId');

  // At some point, our owner options should not be based off the current list of users in the
  // organization because the user list can change over time. Instead, we should have back-end
  // support for fetching a list of users that have surveys in the organization.
  const { orderedTeamMembers } = useOrderedTeamMembers({
    enabled: userId.enabled,
  });
  const ownerOptions = orderedTeamMembers.map((user) => {
    return {
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    };
  });
  const selectedOwner =
    find(ownerOptions, ({ value }) => userId.value === value) ?? null;

  return (
    <>
      <FormCheckbox checkboxLabel="Owner" name="userId.enabled" />
      {userId.enabled && (
        <div>
          <p className="text-xs">show surveys created by:</p>
          <div className="w-full mt-1">
            <FormSearchSelectInput
              name="userId.value"
              onChange={(newOwner) => {
                userIdHelpers.setValue({
                  ...userId,
                  value: newOwner?.value ?? null,
                });
              }}
              options={ownerOptions}
              value={selectedOwner}
            />
          </div>
        </div>
      )}
    </>
  );
};

const TagFilter = (): JSX.Element => {
  const [{ value: tag }, , tagHelpers] =
    useField<DashboardFilters['tag']>('tag');

  // At some point, our tag options should not be based off the current list of tags for the
  // organization because the tag list can change over time. Instead, we should have back-end
  // support for fetching a list of tags associated to any of the organization's surveys.
  const { data } = useQuery({ ...tagQueries.list(), enabled: tag.enabled });
  const tagOptions = (data?.results ?? []).map((tag) => {
    return {
      label: tag.title,
      value: tag.id,
    };
  });
  const selectedTag =
    find(tagOptions, ({ value }) => tag.value === value) ?? null;

  return (
    <>
      <FormCheckbox checkboxLabel="Tag" name="tag.enabled" />
      {tag.enabled && (
        <div>
          <p className="text-xs">show surveys tagged:</p>
          <div className="w-full mt-1">
            <FormSearchSelectInput
              name="tag.value"
              onChange={(newTag) => {
                tagHelpers.setValue({
                  ...tag,
                  value: newTag?.value ?? null,
                });
              }}
              options={tagOptions}
              value={selectedTag}
            />
          </div>
        </div>
      )}
    </>
  );
};
