import { orderBy } from 'lodash-es';

import { SurveyWave } from 'types/domainModels';

export function getSurveyWaveOptions({ waves }: { waves: SurveyWave[] }) {
  const orderedWaves = orderBy(waves, 'waveValue', 'desc');
  const waveOptions = orderedWaves.map((wave) => {
    return { label: getSurveyWaveTitle(wave), value: wave.id };
  });

  return [...waveOptions, { label: 'All Waves', value: null }];
}

export function getSurveyWaveTitle(wave: SurveyWave) {
  return `Wave ${wave.waveValue}: ${wave.title}`;
}
