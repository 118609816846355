import { useField } from 'formik';

import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';

const DisplayOptionDescription = (): JSX.Element | null => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');

  if (optionType?.value !== 'image') {
    return null;
  }

  return (
    <>
      <FormCheckbox
        checkboxLabel="Display Option Description"
        name="features.displayOptionDescription"
        tooltip="Options with images can optionally have titles / descriptions. This feature enables displaying the entered title / description to the respondent for an option with an image."
      />
    </>
  );
};

export default DisplayOptionDescription;
