import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format as dateFnsFormat,
  formatDistanceToNow,
  isMatch,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';

export enum DATE_FORMATS {
  API_DATE_FORMAT = 'yyyy-MM-dd',
  DASHBOARD_CREATED = 'MM/dd/yyyy, h:mm a',
  DASHBOARD_FIELD_DATE = 'M/d',
  DATETIME = 'M/d/yyyy h:mm aaa',
  DEFAULT_DATE_FORMAT = 'M/d/yyyy',
  EXPORT_DATE = 'MM.dd.yy',
  EXPORTED_AT = 'yyyy-MM-dd HH:mm:ss',
  LINE_CHART = 'M/d h a',
  READABLE_FULL = 'MMMM do, yyyy',
  READABLE_MONTH_YEAR = 'MMMM yyyy',
  SINGLE_DAY = 'd',
  SURVEY_COMPLETE = 'MMM d, yyyy h:mm a',
  SURVEY_LAUNCHED_AT = 'MMM d, yyyy',
  TIME = 'h:mm:ss b',
}

type DateUnits = 'days' | 'months' | 'years';

/**
 * A list of date formats that we support in parsing text a user has entered.
 */
export const MANUAL_ENTRY_DATE_FORMATS = [
  DATE_FORMATS.API_DATE_FORMAT,
  DATE_FORMATS.DEFAULT_DATE_FORMAT,
];

export function addToDate(
  date: Date,
  { quantity, units }: { quantity: number; units: DateUnits },
): Date {
  if (units === 'days') {
    return addDays(date, quantity);
  } else if (units === 'months') {
    return addMonths(date, quantity);
  }

  return addYears(date, quantity);
}

export function difference(
  firstDate: Date,
  secondDate: Date,
  units: DateUnits,
): number {
  if (units === 'days') {
    return differenceInDays(firstDate, secondDate);
  } else if (units === 'months') {
    return differenceInMonths(firstDate, secondDate);
  }

  return differenceInYears(firstDate, secondDate);
}

export function formatDate(
  value: Date | number | string,
  { format, parseFormat }: { format: DATE_FORMATS; parseFormat?: DATE_FORMATS },
): string {
  const valueAsDate =
    typeof value === 'string' ? parseToDate(value, { parseFormat }) : value;

  // If we fail to parse the provided input, we can't try to format it.
  if (!isValid(valueAsDate)) {
    return typeof value === 'string' ? value : '';
  }

  return dateFnsFormat(valueAsDate, format);
}

export function formatHumanReadableDurationFromNow(
  value: Date | number | string,
  { parseFormat }: { parseFormat?: DATE_FORMATS } = {},
) {
  const valueAsDate =
    typeof value === 'string' ? parseToDate(value, { parseFormat }) : value;

  return formatDistanceToNow(valueAsDate, {
    addSuffix: true,
  });
}

/**
 * Returns the date format that matches the supplied value if one is found.
 */
export function getDateFormat(
  date: string | null,
  parseFormats: DATE_FORMATS[],
): DATE_FORMATS | null {
  if (!date) {
    return null;
  }

  for (let i = 0; i < parseFormats.length; i++) {
    if (isMatch(date, parseFormats[i])) {
      return parseFormats[i];
    }
  }

  return null;
}

export function isSame(
  firstDate: Date,
  secondDate: Date,
  units: DateUnits,
): boolean {
  if (units === 'days') {
    return isSameDay(firstDate, secondDate);
  } else if (units === 'months') {
    return isSameMonth(firstDate, secondDate);
  }

  return isSameYear(firstDate, secondDate);
}

/**
 * Parses the provided value to a Date. By default, this will try to parse the provided
 * value as an ISO date. If you provide a parseFormat, it will try to parse using that
 * format instead.
 */
export function parseToDate(
  value: string,
  { parseFormat = null }: { parseFormat?: DATE_FORMATS | null } = {},
): Date {
  return parseFormat ? parse(value, parseFormat, new Date()) : parseISO(value);
}

export function startOf(date: Date, units: DateUnits): Date {
  if (units === 'days') {
    return startOfDay(date);
  } else if (units === 'months') {
    return startOfMonth(date);
  }

  return startOfYear(date);
}
