import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useCloneSurveyInLucid } from 'hooks/backend/surveys';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Modal, { ModalHeader } from '../common/Modal';

const CloneSurveyInLucidModal = ({
  onCloseModal,
  surveyId,
}: {
  onCloseModal(): void;
  surveyId: number;
}): JSX.Element => {
  const { isPending: isCloningSurvey, mutate: cloneSurvey } =
    useCloneSurveyInLucid({
      onError: (err) => {
        showErrorMessage(
          `Failed to clone survey in Lucid. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('The survey was successfully cloned in Lucid.');

        onCloseModal();
      },
    });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Boost Sample Traffic (Clone)
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
    >
      <p>
        This action will create a clone of the current survey in Lucid. Are you
        sure you'd like to proceed?
      </p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isCloningSurvey}
          onClick={() => {
            cloneSurvey({ surveyId });
          }}
          size="lg"
          type="button"
        >
          Clone Survey
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CloneSurveyInLucidModal;
