import { cloneDeep } from 'lodash-es';
import { useField, FieldHelperProps } from 'formik';

import { getImageUrl } from '../../util/images';
import {
  getOtherQuestionReferencesForConcept,
  getVariableReferencesForConcept,
  IQuestionReferenceForOption,
  IVariableReference,
} from '../../util/questions';
import { OPTION_FEATURES, OptionFeatureConfig } from '../../util/options';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';

import { useModal } from '../../hooks/modals';
import Button from '../common/forms/Button';
import FileUploadWithCrop from '../common/forms/FileUploadWithCrop';
import FormInput from '../common/forms/FormInput';
import OptionFeatureLabel from './OptionFeatureLabel';
import OptionSettings from './OptionSettings';
import Tooltip from '../common/Tooltip';
import XButton from '../common/forms/XButton';
import PreventDeleteConceptModal from './PreventDeleteConceptModal';

const QuestionConcept = ({
  index,
  onClickRemove,
  question,
  questions,
  survey,
  variables,
}: {
  index: number;
  onClickRemove?: () => void;
  question: Question | undefined;
  questions: Question[];
  survey: Survey;
  variables: SurveyVariable[];
}): JSX.Element => {
  const fieldPrefix = `concepts.${index}`;
  const [{ value: concept }] = useField<QuestionFormOption>(`${fieldPrefix}`);

  const [{ value: optionFeatures }, , optionFeatureHelpers] = useField<
    QuestionFormOption['features']
  >(`${fieldPrefix}.features`);
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');

  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${fieldPrefix}.weight`,
  );
  const [{ value: isActive }, , activeHelper] = useField(
    `concepts.${index}.features.isActive`,
  );

  return (
    <div className="p-2">
      <div className="flex items-center w-full space-x-4">
        <OptionImage disabled={false} fieldPrefix={fieldPrefix} />

        <div className="flex-grow">
          <FormInput
            name={`${fieldPrefix}.value`}
            placeholder="Concept Name..."
            size="md"
            type="text"
          />

          <div className="space-x-2">
            {optionFeatures.displayLogic &&
              optionFeatures.displayLogic.enabled && (
                <OptionFeatureLabel
                  label="Display Logic"
                  onClickRemove={() => {
                    const newOptionFeatures = cloneDeep(optionFeatures);
                    newOptionFeatures.displayLogic.enabled = false;

                    optionFeatureHelpers.setValue(newOptionFeatures);
                  }}
                />
              )}
            {!isActive && <OptionFeatureLabel label="Inactive" />}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <OptionActions
            activeHelper={activeHelper}
            availableOptionFeatures={OPTION_FEATURES.filter(
              // This will result in an arrary of length 1
              (feature) => feature.apiName === 'isActive',
            )}
            conceptId={concept.id}
            index={index}
            namePrefix="concepts"
            onClickRemove={onClickRemove}
            question={question}
            questionType={questionType}
            questions={questions}
            survey={survey}
            variables={variables}
            weightHelper={weightHelper}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionConcept;

const OptionImage = ({
  disabled,
  fieldPrefix,
}: {
  disabled: boolean;
  fieldPrefix: string;
}): JSX.Element => {
  const [{ value: media }, , mediaHelpers] = useField<
    QuestionFormOption['image']
  >(`${fieldPrefix}.image`);

  const mediaUrl = typeof media === 'string' ? media : getImageUrl(media);

  const type = mediaUrl.substring(5, 10);

  return (
    <>
      <FileUploadWithCrop
        disabled={disabled}
        onDone={(file) => {
          mediaHelpers.setValue(file);
        }}
      >
        <Button disabled={disabled} hierarchy="secondary-gray" size="xs">
          Upload
        </Button>
      </FileUploadWithCrop>
      {mediaUrl && (
        <Tooltip
          showDelay={350}
          trigger={
            <div className="flex items-center justify-center w-8 h-8">
              {type === 'video' && (
                <video className="max-w-full max-h-full" src={mediaUrl} />
              )}
              {type !== 'video' && ( // Handles saved videos as well as images
                <img className="max-w-full max-h-full" src={mediaUrl} />
              )}
            </div>
          }
        >
          <div className="flex items-center justify-center w-64 h-64">
            {type === 'video' && (
              <video className="max-w-full max-h-full" src={mediaUrl} />
            )}
            {type !== 'video' && (
              <img className="max-w-full max-h-full" src={mediaUrl} />
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

const OptionActions = ({
  activeHelper,
  availableOptionFeatures,
  index,
  namePrefix,
  onClickRemove,
  conceptId,
  question,
  questions,
  questionType,
  survey,
  weightHelper,
  variables,
}: {
  activeHelper: FieldHelperProps<boolean>;
  availableOptionFeatures: OptionFeatureConfig[];
  index: number;
  namePrefix: 'concepts' | 'labels' | 'options';
  onClickRemove?: () => void;
  conceptId: number | null;
  question: Question | undefined;
  questions: Question[];
  questionType: QuestionFormData['questionType'];
  survey: Survey;
  weightHelper: FieldHelperProps<number | null>;
  variables: SurveyVariable[];
}): JSX.Element => {
  const {
    isOpen: isPreventDeleteModalOpen,
    onCloseModal,
    setIsOpen: setIsPreventDeleteModalOpen,
  } = useModal();

  return (
    <>
      <OptionSettings
        activeHelper={activeHelper}
        availableOptionFeatures={availableOptionFeatures}
        index={index}
        isWithinMonadicLoop={!!question?.monadicId}
        namePrefix={namePrefix}
        optionId={conceptId}
        question={question}
        questionType={questionType}
        questions={questions}
        setIsPreventDeleteModalOpen={setIsPreventDeleteModalOpen}
        survey={survey}
        variables={variables}
        weightHelper={weightHelper}
      />
      {onClickRemove && (
        <XButton
          onClick={() => {
            if (conceptId) {
              let variableReferences: IVariableReference[] = [];
              let questionReferences: IQuestionReferenceForOption[] = [];
              if (questions) {
                questionReferences = getOtherQuestionReferencesForConcept({
                  conceptId,
                  questions,
                  survey,
                });
              }
              if (variables) {
                variableReferences = getVariableReferencesForConcept({
                  conceptId,
                  variables,
                });
              }
              const hasOtherReferences =
                questionReferences.length > 0 || variableReferences.length > 0;

              if (hasOtherReferences) {
                setIsPreventDeleteModalOpen(true);

                return;
              }
            }

            onClickRemove();
          }}
          title="Remove"
        />
      )}

      {isPreventDeleteModalOpen && conceptId && (
        <PreventDeleteConceptModal
          conceptId={conceptId}
          onCloseModal={onCloseModal}
          questions={questions}
          survey={survey}
          variables={variables}
        />
      )}
    </>
  );
};
