import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react';
import { ComponentProps, ReactNode } from 'react';
import { Listbox as HeadlessUIListbox } from '@headlessui/react';

function Listbox<ValueType>({
  button,
  children,
  onChange,
  placement = 'bottom-end',
  value,
}: {
  button: ReactNode;
  children: ReactNode;
  onChange(newValue: ValueType | null): void;
  placement?: Placement;
  value: ValueType;
}) {
  const { refs, strategy, x, y } = useFloating({
    middleware: [offset({ mainAxis: 8 }), flip(), shift({ padding: 8 })],
    placement,
    whileElementsMounted: autoUpdate,
  });

  return (
    <HeadlessUIListbox
      as="div"
      className="relative flex"
      onChange={onChange}
      value={value}
    >
      <div ref={refs.setReference}>{button}</div>

      <HeadlessUIListbox.Options
        ref={refs.setFloating}
        className="z-40 rounded-lg bg-white border border-gray-d-200 shadow-lg py-1 px-1.5 min-w-[240px]"
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        {children}
      </HeadlessUIListbox.Options>
    </HeadlessUIListbox>
  );
}

export default Listbox;

export const ListboxButton = (
  props: ComponentProps<typeof HeadlessUIListbox.Button>,
) => {
  return <HeadlessUIListbox.Button {...props} />;
};

export const ListboxOption = (
  props: Omit<ComponentProps<typeof HeadlessUIListbox.Option>, 'className'>,
) => {
  return (
    <HeadlessUIListbox.Option
      {...props}
      className="cursor-default select-none block py-3 px-2.5 text-gray-d-700 text-sm ui-active:bg-gray-d-50 whitespace-nowrap rounded-md"
    />
  );
};
