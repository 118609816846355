import { useEffect, useState } from 'react';

import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Tooltip from '../common/Tooltip';

const CopyToClipboard = ({ text }: { text: string }) => {
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    let resetTimeout: number | null = null;

    if (hasCopied) {
      resetTimeout = window.setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }

    return () => {
      if (resetTimeout) {
        window.clearTimeout(resetTimeout);
      }
    };
  }, [hasCopied]);

  return (
    <IconBackground
      onClick={async () => {
        await window.navigator.clipboard.writeText(text);

        setHasCopied(true);
      }}
      title="Copy link"
    >
      <div className="w-4 h-4">
        {hasCopied ? (
          <Tooltip
            alwaysShow={true}
            trigger={
              <div className="w-4 h-4">
                <Icon id="check" />
              </div>
            }
          >
            Copied!
          </Tooltip>
        ) : (
          <Icon id="clipboard-download" />
        )}
      </div>
    </IconBackground>
  );
};

export default CopyToClipboard;
