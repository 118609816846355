import { ButtonHTMLAttributes } from 'react';

const BorderedButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...props}
      className="flex border border-gray-400 rounded-lg p-1 text-gray-900 bg-white"
    />
  );
};

export default BorderedButton;
