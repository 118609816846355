import { minutesToMilliseconds } from 'date-fns';
import { queryOptions } from '@tanstack/react-query';

import { getQuote } from 'services/backend/prices';

export const pricesQueries = {
  getQuote: (data: {
    incidence: number | null;
    questionCount: number | null;
    respondentsCount: number;
    services: string[];
  }) =>
    queryOptions({
      enabled:
        data.respondentsCount > 0 &&
        data.incidence !== null &&
        data.questionCount !== null,
      queryFn: () => {
        if (data.incidence === null || data.questionCount === null) {
          throw new Error('Incidence and question count must be provided');
        }

        return getQuote({
          data: {
            incidence: data.incidence,
            questionCount: data.questionCount,
            respondentsCount: data.respondentsCount,
            services: data.services,
          },
        });
      },
      queryKey: ['quotes', data],
      staleTime: minutesToMilliseconds(5),
    }),
};
