import { Audience, Question, Survey } from '../types/domainModels';

/**
 * Transforms the "question audiences" into a list of expectations that represent display
 * logic. This is easier to work with than the audiences. Eventually, I'd like to rework
 * all this display logic stuff to work how it does in our survey-response application.
 */
export function getDisplayLogicForQuestion({
  question,
}: {
  question: Question;
}) {
  return (
    question.questionAudiences?.flatMap((audience) => {
      return audience.audience.audienceSlices.flatMap((slice) => {
        return slice.audienceSliceCategories.flatMap(
          ({ audienceSliceCategoryAttributes, questionId }) => {
            return {
              expectations: audienceSliceCategoryAttributes.flatMap(
                ({ audienceAttribute }) => audienceAttribute,
              ),
              questionId,
            };
          },
        );
      });
    }) ?? []
  );
}

export function isNaturalFallout(survey: Survey): boolean {
  return survey.audienceId === 0;
}

export function questionAudiencesToAudiences(
  questionAudiences: { audience: Audience }[] | undefined,
): Audience[] {
  return (questionAudiences ?? []).map(({ audience }) => audience);
}
