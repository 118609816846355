import Icon from '../Icon';
import IconBackground from '../icons/IconBackground';

const XButton = ({
  disabled = false,
  onClick,
  title,
}: {
  disabled?: boolean;
  onClick(): void;
  title: string;
}): JSX.Element => {
  return (
    <IconBackground
      disabled={disabled}
      onClick={onClick}
      size="small"
      title={title}
    >
      <div className="w-3 h-3">
        <Icon id="x" />
      </div>
    </IconBackground>
  );
};

export default XButton;
