import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { Tag } from '../../types/domainModels';

export const ADD_TAG_TO_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}`,
  version: 'v1' as const,
});

export async function addTagToSurvey({
  data,
  surveyId,
}: {
  data: { projectId: number };
  surveyId: number;
}) {
  return (await axios.patch(getAPIUrl(ADD_TAG_TO_SURVEY({ surveyId })), data))
    .data;
}

export const CREATE_TAG = () => ({
  path: '/projects',
  version: 'v1' as const,
});

export async function createTag({
  data,
}: {
  data: { organizationId: number; title: string };
}) {
  return (await axios.post<Tag>(getAPIUrl(CREATE_TAG()), data)).data;
}

export const FETCH_TAGS = () => ({
  path: '/projects/tags',
  version: 'v1' as const,
});

export async function fetchTags({
  nameFilter = '',
  organizationId = undefined,
}: {
  nameFilter?: string;
  organizationId?: number;
} = {}) {
  return (
    await axios.get<{ results: Tag[] }>(getAPIUrl(FETCH_TAGS()), {
      params: {
        organizationId,
        title: nameFilter,
      },
    })
  ).data;
}

export const REMOVE_TAG_FROM_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}`,
  version: 'v1' as const,
});

export async function removeTagFromSurvey({ surveyId }: { surveyId: number }) {
  return (
    await axios.patch(getAPIUrl(REMOVE_TAG_FROM_SURVEY({ surveyId })), {
      projectId: null,
    })
  ).data;
}
