import { ErrorMessage, Form, Formik, FormikErrors, useField } from 'formik';

import { createTeamMemberOption } from '../../util/team';
import { DashboardSurvey, TeamMember } from '../../types/domainModels';
import { getOrgStatus } from '../../util/users';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useCreateSurveyTemplate } from 'hooks/backend/surveys';

import { useAuth } from '../../contexts/auth';
import { useHasRole } from '../../hooks/users';
import { useOrderedOrganizations } from '../../hooks/backend/organizations';
import { useOrderedTeamMembers } from '../../hooks/backend/team';
import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import InputFormik from 'components/common/forms/InputFormik';
import Modal, { ModalHeader } from '../common/Modal';
import TextareaFormik from 'components/common/forms/TextareaFormik';

interface CreateTemplateFormData {
  description: string;
  organization: ReactSelectValue<number> | null;
  owner: ReactSelectValue<TeamMember> | null;
  title: string;
}

function validateData(
  formData: CreateTemplateFormData,
  { isAdmin = false } = {},
): FormikErrors<CreateTemplateFormData> {
  const errors: FormikErrors<CreateTemplateFormData> = {};

  if (!formData.title) {
    errors.title = 'Please enter a title.';
  }

  if (isAdmin && !formData.organization) {
    errors.owner = 'Please select a template organization.';
  }

  if (isAdmin && !formData.owner) {
    errors.owner = 'Please select a template owner.';
  }

  return errors;
}

const CreateSurveyTemplateModal = ({
  onCloseModal,
  survey,
}: {
  onCloseModal(): void;
  survey: DashboardSurvey;
}): JSX.Element => {
  const { user } = useAuth();
  const isAdmin = useHasRole('admin');

  const { currentOrganizationId, hasChangedOrg } = getOrgStatus();

  const { isPending: isCreatingTemplate, mutateAsync: createTemplate } =
    useCreateSurveyTemplate({
      onError: (err) => {
        showErrorMessage(`Failed to create template. Error: ${err.message}`);
      },
      onSuccess: () => {
        onCloseModal();

        showSuccessMessage('Survey template created successfuly.');
      },
    });

  const { isLoadingOrgs, organizations: organizationOptions } =
    useOrderedOrganizations();
  const initialTemplateOrg = organizationOptions.find(({ value }) => {
    return value === currentOrganizationId;
  });

  const { orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId: currentOrganizationId,
  });
  const initialTemplateOwner = orderedTeamMembers.find(
    ({ id }) => id === user?.id,
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Create Survey Template
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <div className="mb-4 space-y-2 text-sm">
        <p>
          Use the form below to create a template from the existing survey:
          <br />
          <span className="font-semibold">{survey.title}</span>
        </p>
        <p>Templates can be used during new survey creation.</p>
      </div>

      <Formik<CreateTemplateFormData>
        enableReinitialize={true}
        initialValues={{
          description: '',
          organization: initialTemplateOrg ?? null,
          owner: initialTemplateOwner
            ? createTeamMemberOption(initialTemplateOwner)
            : null,
          title: '',
        }}
        onSubmit={(formData) => {
          return createTemplate({
            data: {
              description: formData.description,
              organizationId: isAdmin
                ? formData.organization?.value
                : undefined,
              ownerId: isAdmin ? formData.owner?.value.id : undefined,
              title: formData.title,
            },
            surveyId: survey.id,
          });
        }}
        validate={(formData) => {
          return validateData(formData, { isAdmin });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <CreateTemplateForm
          hasChangedOrg={hasChangedOrg}
          isAdmin={isAdmin}
          isCreatingTemplate={isCreatingTemplate}
          isLoadingOrgs={isLoadingOrgs}
          onClickCancel={onCloseModal}
          organizations={organizationOptions}
        />
      </Formik>
    </Modal>
  );
};

export default CreateSurveyTemplateModal;

const CreateTemplateForm = ({
  hasChangedOrg,
  isAdmin,
  isCreatingTemplate,
  isLoadingOrgs,
  onClickCancel,
  organizations,
}: {
  hasChangedOrg: boolean;
  isAdmin: boolean;
  isCreatingTemplate: boolean;
  isLoadingOrgs: boolean;
  onClickCancel(): void;
  organizations: ReactSelectValue<number>[];
}): JSX.Element => {
  const [{ value: organization }] =
    useField<CreateTemplateFormData['organization']>('organization');
  const [, , setOwnerHelpers] =
    useField<CreateTemplateFormData['owner']>('owner');

  const organizationId = organization?.value;

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });
  const teamMembers = orderedTeamMembers.map((teamMember) => {
    return createTeamMemberOption(teamMember);
  });

  return (
    <Form>
      <div className="space-y-4">
        <FormGroup>
          <FormLabel labelFor="title">Template Title</FormLabel>
          <InputFormik id="title" name="title" size="lg" type="text" />
          <FormFieldError error={<ErrorMessage name="title" />} />
        </FormGroup>
        <FormGroup>
          <FormLabel labelFor="description">Description</FormLabel>
          <TextareaFormik id="description" name="description" size="lg" />
        </FormGroup>

        {isAdmin && (
          <>
            <hr className="text-light-grey" />
            <FormSearchSelectInput
              inputId="organization"
              // If an admin has changed their organization, we don't want them to be selecting a different organization
              // for the template.
              isDisabled={hasChangedOrg || isLoadingOrgs}
              isLoading={isLoadingOrgs}
              label="Template Organization"
              labelFor="organization"
              name="organization"
              onChange={() => {
                // If an organization is changed, we load new options for the survey owner so we want to clear out
                // the existing one and make the user select an updated value.
                setOwnerHelpers.setValue(null);
              }}
              options={organizations}
            />
            <FormSearchSelectInput
              inputId="owner"
              isDisabled={isLoadingTeamMembers}
              isLoading={isLoadingTeamMembers}
              label="Template Owner"
              labelFor="owner"
              name="owner"
              options={teamMembers}
            />
          </>
        )}
      </div>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isCreatingTemplate}
          size="lg"
          type="submit"
        >
          Create Template
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickCancel}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
