import { SubmitHandler, useForm } from 'react-hook-form';
import { useRef } from 'react';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { SurveyWave } from 'types/domainModels';
import { useSaveWave } from 'hooks/backend/surveys';

import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import FormGroup from './forms/FormGroupNew';
import FormLabel from './forms/FormLabel';
import Input from './forms/Input';
import Modal, { ModalHeader } from './Modal';
import Textarea from './forms/Textarea';

type SaveWaveData = {
  description: string;
  target: string;
  title: string;
};

const SaveWaveModal = ({
  onCloseModal,
  onWaveSaved,
  surveyId,
  wave,
}: {
  onCloseModal(): void;
  onWaveSaved(): void;
  surveyId: number;
  wave?: SurveyWave;
}): JSX.Element => {
  const { isPending: isSavingWave, mutate: saveWave } = useSaveWave({
    onError: () => {
      showErrorMessage(
        `There was an error ${
          wave ? 'editing the wave details' : 'adding a new wave'
        }. Please contact Glass support.`,
      );
    },
    onSuccess: () => {
      if (wave) {
        showSuccessMessage('The wave details were edited successfully.');
      } else {
        showSuccessMessage('A new wave was created successfully.');
      }

      onWaveSaved();
    },
  });

  const { handleSubmit, register } = useForm<SaveWaveData>({
    defaultValues: {
      description: wave?.description,
      target: wave ? `${wave.target}` : '',
      title: wave?.title,
    },
  });

  const onSubmit: SubmitHandler<SaveWaveData> = (data) => {
    return wave
      ? saveWave({ data, waveId: wave.id })
      : saveWave({ data, surveyId });
  };

  const titleRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...titleFields } = register('title', { required: true });

  return (
    <Modal
      header={
        <ModalHeader
          onClickClose={onCloseModal}
          supportingText={
            wave
              ? undefined
              : 'Begin a new wave for this survey. New respondents will belong to the new wave.'
          }
        >
          {wave ? 'Update Wave Details' : 'Add a Wave to Survey'}
        </ModalHeader>
      }
      initialFocus={titleRef}
      onCloseModal={onCloseModal}
    >
      <form
        onSubmit={(event) => {
          // We have to prevent the default action and stop propagation here because the modal
          // is a child of a form in the React hierarchy. If we don't do this, the parent form
          // will be submitted as well. This isn't ideal and we probably need a new React parent-child
          // structure.
          event.preventDefault();
          event.stopPropagation();

          return handleSubmit(onSubmit)(event);
        }}
      >
        <div className="space-y-4">
          <FormGroup>
            <FormLabel labelFor="waveName">Wave Name</FormLabel>
            <Input
              {...titleFields}
              ref={(inputRef) => {
                ref(inputRef);
                titleRef.current = inputRef;
              }}
              id="waveName"
              size="lg"
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel labelFor="waveDescription">Description</FormLabel>
            <Textarea
              {...register('description')}
              id="waveDescription"
              size="lg"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel labelFor="waveParticipants">Wave Participants</FormLabel>
            <Input
              {...register('target', { required: true })}
              disabled={!!wave?.endedAt}
              id="waveParticipants"
              min={wave ? wave.completes : 1}
              size="lg"
              type="number"
            />
          </FormGroup>
        </div>

        <div className="mt-8 flex gap-3 flex-row-reverse">
          <ButtonLoading
            grow
            hierarchy="primary"
            isLoading={isSavingWave}
            size="lg"
            type="submit"
          >
            {wave ? 'Update' : 'Create'} Wave
          </ButtonLoading>
          <Button
            grow
            hierarchy="secondary-gray"
            onClick={onCloseModal}
            size="lg"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SaveWaveModal;
