import { AnchorHTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

const Tab = ({
  children,
  href,
  isActive,
  ...rest
}: AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: ReactNode;
  isActive: boolean;
}): JSX.Element => {
  const anchorClasses = clsx('block px-2 pb-2 border-b-2 cursor-pointer', {
    'border-green font-semibold': isActive,
    'border-transparent text-gray-600': !isActive,
  });

  if (href) {
    return (
      <Link {...rest} className={anchorClasses} to={href}>
        {children}
      </Link>
    );
  }

  return (
    <a {...rest} className={anchorClasses}>
      {children}
    </a>
  );
};

export default Tab;
