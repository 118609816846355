import { orderBy } from 'lodash-es';
import { INCIDENCE_TYPES } from '../constants/incidenceTypes';
import { IncidenceType, IncidenceTypeWithAverage } from '../types/domainModels';
import { ReactSelectValue } from '../types/forms';

export function findIncidenceType(
  incidence: number,
): IncidenceTypeWithAverage | null {
  return (
    INCIDENCE_TYPES.find(({ name }) => {
      const range = name.split('-');
      const end = Number(range[0]);
      const start = Number(range[1].split('%')[0]);

      return incidence >= start && incidence <= end;
    }) ?? null
  );
}

export function getIncidenceTypeOptions(
  incidenceTypes: IncidenceType[],
): ReactSelectValue<IncidenceType>[] {
  return orderBy(
    incidenceTypes,
    ({ name }) => {
      // An incidence type name looks like "100-75%" so the resulting split here will be
      // an array of ['100', '75%']. This ordering should eventually be moved to the back-end
      // because this is brittle.
      return Number(name.split('-')[0]);
    },
    ['desc'],
  ).map((incidenceType) => {
    return {
      label: incidenceType.name,
      value: incidenceType,
    };
  });
}
