import FormCheckbox from '../../common/forms/FormCheckbox';

const MultipleOptionSelections = (): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel="Multiple Option Selections"
        name="features.multipleOptionSelections"
      />
    </>
  );
};

export default MultipleOptionSelections;
