import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import { useTouchOutside } from '../../hooks/dom';

/**
 * This is by default an "uncontrolled" component but the `isOpen` and `setIsOpen` props can be
 * used to make it "controlled".
 */
const Popover = ({
  children,
  disabled = false,
  name,
  isOpen,
  setIsOpen,
  trigger,
}: {
  children: ReactNode;
  disabled?: boolean;
  name: string;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  trigger(opts: {
    ref: Dispatch<SetStateAction<HTMLDivElement | null>>;
    className: string;
    onClick(): void;
  }): ReactNode;
}): JSX.Element => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const showPopover = isOpen === undefined ? internalIsOpen : isOpen;
  const setShowPopover =
    setIsOpen === undefined ? setInternalIsOpen : setIsOpen;

  const popoverTriggerClassName = `popover-trigger-${name}`;
  const triggerProps = {
    ref: setReferenceElement,
    className: popoverTriggerClassName,
    onClick: () => {
      if (!disabled) {
        setShowPopover(!showPopover);
      }
    },
  };

  return (
    <>
      {trigger(triggerProps)}

      {showPopover && (
        <PopoverBody
          popoverTriggerClassName={popoverTriggerClassName}
          referenceElement={referenceElement}
          setShowPopover={setShowPopover}
        >
          {children}
        </PopoverBody>
      )}
    </>
  );
};

export default Popover;

const PopoverBody = ({
  children,
  popoverTriggerClassName,
  referenceElement,
  setShowPopover,
}: {
  children: ReactNode;
  referenceElement: HTMLElement | null;
  popoverTriggerClassName: string;
  setShowPopover:
    | Dispatch<SetStateAction<boolean>>
    | ((isOpen: boolean) => void);
}): JSX.Element => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-8, 0],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const closePopover = useCallback(() => {
    setShowPopover(false);
  }, [setShowPopover]);

  useTouchOutside({
    el: popperElement,
    enabled: true,
    // We don't consider a click on the trigger to be an outside click. The trigger is really
    // part of the popover.
    exceptionClassName: popoverTriggerClassName,
    onTouchOutside: closePopover,
  });

  return (
    <div
      ref={setPopperElement}
      className="max-w-xs border border-light-grey rounded shadow-md z-50"
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="rounded bg-white">{children}</div>
    </div>
  );
};
