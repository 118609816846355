import { SubmitHandler, useForm } from 'react-hook-form';
import { useRef } from 'react';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useUpdateParticipants } from 'hooks/backend/surveys';

import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import FormGroup from './forms/FormGroupNew';
import FormLabel from './forms/FormLabel';
import Input from './forms/Input';
import Modal, { ModalHeader } from './Modal';

const ParticipantsModal = ({
  onCloseModal,
  participants,
  surveyID,
}: {
  onCloseModal(): void;
  participants: number;
  surveyID: number;
}): JSX.Element => {
  const { isPending: isUpdatingParticipants, mutate: updateParticipants } =
    useUpdateParticipants({
      onError: (err: Error) => {
        showErrorMessage(
          `Failed to update participant count. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('Participants count updated!');
        onCloseModal();
      },
    });

  const { handleSubmit, register, watch } = useForm<{
    participants: number | '';
  }>({
    defaultValues: { participants: '' },
  });

  const onSubmit: SubmitHandler<{ participants: number | '' }> = (data) => {
    return updateParticipants({
      data: {
        participants: Number(data.participants),
      },
      surveyID,
    });
  };

  const newParticipants = watch('participants');

  const participantsRef = useRef<HTMLElement | null>(null);
  const { ref, ...participantsFields } = register('participants', {
    required: true,
  });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Add Participants</ModalHeader>
      }
      initialFocus={participantsRef}
      onCloseModal={onCloseModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel labelFor="additional-participants">
            Additional Participants
          </FormLabel>
          <Input
            {...participantsFields}
            ref={(inputRef) => {
              ref(inputRef);
              participantsRef.current = inputRef;
            }}
            id="additional-participants"
            min={0}
            size="lg"
            step={1}
            type="number"
          />
        </FormGroup>

        <div className="text-xs text-gray-600 leading-8 font-normal">
          Total participants after addition:{' '}
          {participants + Number(newParticipants)}
        </div>

        <div className="mt-8 flex gap-3 flex-row-reverse">
          <ButtonLoading
            grow
            hierarchy="primary"
            isLoading={isUpdatingParticipants}
            size="lg"
            type="submit"
          >
            Add Participants
          </ButtonLoading>
          <Button
            grow
            hierarchy="secondary-gray"
            onClick={onCloseModal}
            size="lg"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ParticipantsModal;
