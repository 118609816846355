import Tooltip from 'components/common/Tooltip';

const SurveyEditRowLeftSide = ({
  subtitle,
  title,
  tooltip,
}: {
  subtitle?: string;
  title: string;
  tooltip?: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex text-gray-900 font-medium">
        {title}

        {subtitle && (
          <div className="flex items-center space-x-1">
            <span className="ml-1 font-normal italic">{subtitle}</span>

            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyEditRowLeftSide;
