import { useCallback, useState } from 'react';

export function useModal(): {
  isOpen: boolean;
  onCloseModal: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const [isOpen, setIsOpen] = useState(false);

  const onCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    onCloseModal,
    setIsOpen,
  };
}
