import { ReactNode, useState } from 'react';

import { getOptionOptions, getQuestionOptions } from '../../util/formOptions';
import { isIdeaPresenterQuestion } from '../../util/questions';
import {
  Question,
  QuestionOption,
  QUESTION_TYPE,
} from '../../types/domainModels';

import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import Popover from '../common/Popover';

function formatResponse({
  option,
  question,
}: {
  option?: QuestionOption;
  question: Question;
}) {
  let optionPart = '';
  if (option) {
    if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
      optionPart = `/M${option.id}`;
    } else if (question.questionTypeId === QUESTION_TYPE.RANKING) {
      // Rankings use the sort because the user is saying: "I want to pipe whatever option was chosen 2nd." In other words,
      // the option to pipe is dynamic depending on what the user decided.
      optionPart = `/R${option.sort}`;
    } else if (question.questionTypeId === QUESTION_TYPE.SCALE) {
      optionPart = `/S${option.id}`;
    }
  }

  return `::(Q${question.id}${optionPart}/ANSWER)`;
}

const PipeResponsesPopover = ({
  isOpen,
  onResponseChosen,
  question,
  questions,
  setIsOpen,
}: {
  isOpen: boolean;
  onResponseChosen(response: string): void;
  question: Question | undefined;
  questions: Question[];
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((isOpen: boolean) => void);
}): JSX.Element => {
  return (
    <Popover
      isOpen={isOpen}
      name="pipe-responses"
      setIsOpen={setIsOpen}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <div className="text-xs flex justify-end">
            <span>+ Pipe Response</span>
          </div>
        </div>
      )}
    >
      <PipeResponsesPopoverBody
        onResponseChosen={onResponseChosen}
        question={question}
        questions={questions}
      />
    </Popover>
  );
};

export default PipeResponsesPopover;

const PipeResponsesPopoverBody = ({
  onResponseChosen,
  question,
  questions,
}: {
  onResponseChosen(response: string): void;
  question: Question | undefined;
  questions: Question[];
}): JSX.Element => {
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null,
  );

  let options: ReactNode[];
  if (selectedQuestion) {
    options = getOptionOptions({
      options: selectedQuestion.options,
    }).map((option, index) => {
      // For ranking questions, the piped answer is based on how the user ranked the options
      // and not which option they chose.
      const label =
        selectedQuestion.questionTypeId === QUESTION_TYPE.RANKING
          ? index + 1
          : option.label;

      return (
        <div
          key={option.value.id}
          className="px-4 py-2 hover:bg-light-grey text-xs cursor-pointer"
          onClick={() => {
            onResponseChosen(
              formatResponse({
                option: option.value,
                question: selectedQuestion,
              }),
            );
          }}
        >
          {label}
        </div>
      );
    });
  } else {
    options = getQuestionOptions({
      filterFn: (questionOption) => {
        const isAllowedQuestionType =
          questionOption.questionTypeId !== QUESTION_TYPE.GABOR_GRANGER &&
          !isIdeaPresenterQuestion(questionOption);

        return (
          isAllowedQuestionType &&
          (!question || questionOption.sort < question.sort)
        );
      },
      questions,
    }).map((question) => {
      return (
        <div
          key={question.value.id}
          className="px-4 py-2 hover:bg-light-grey text-xs cursor-pointer"
          onClick={() => {
            if (
              question.value.questionTypeId === QUESTION_TYPE.SCALE ||
              question.value.questionTypeId === QUESTION_TYPE.RANKING ||
              question.value.questionTypeId === QUESTION_TYPE.MATRIX
            ) {
              setSelectedQuestion(question.value);

              return;
            }

            onResponseChosen(formatResponse({ question: question.value }));
          }}
        >
          {question.label}
        </div>
      );
    });
  }

  return (
    <div className="w-64">
      <div className="p-2 pb-1 text-dark-grey text-xs">
        {selectedQuestion ? (
          <div className="flex items-center">
            <div className="w-4 h-4">
              <Hyperlink
                onClick={() => {
                  setSelectedQuestion(null);
                }}
              >
                <Icon id="chevron-left" />
              </Hyperlink>
            </div>
            <span>Choose option:</span>
          </div>
        ) : (
          'Choose question:'
        )}
      </div>
      <div className="max-h-52 overflow-auto">{options}</div>
    </div>
  );
};
