import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { UpdatePasswordSchema } from 'util/auth';
import { useOnboardingSetPassword } from 'hooks/backend/onboarding';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Card from '../common/Card';
import Dots from './Dots';
import FormGroup from 'components/common/forms/FormGroup';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormLabel from 'components/common/forms/FormLabel';
import Input from 'components/common/forms/Input';
import UnauthenticatedLayout from '../layout/UnauthenticatedLayout';

interface SetPasswordFormData {
  password: string;
  retypepassword: string;
}

const Password = (): JSX.Element => {
  const navigate = useNavigate();
  const { token: onboardingToken = '' } = useParams<{ token: string }>();

  const { isPending, mutate: setPassword } = useOnboardingSetPassword({
    onError: () => {
      showErrorMessage('Error creating your password.');
    },
    onSuccess: () => {
      showSuccessMessage(
        "You're ready to go! Please login with your newly created password.",
      );
      navigate('/login');
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<SetPasswordFormData>({
    defaultValues: { password: '', retypepassword: '' },
    resolver: yupResolver(UpdatePasswordSchema),
  });

  const onSubmit: SubmitHandler<SetPasswordFormData> = (data) => {
    return setPassword({ data, token: onboardingToken });
  };

  return (
    <UnauthenticatedLayout introText="Please choose a password using the form below.">
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <FormGroup>
              <FormLabel labelFor="password">New Password</FormLabel>
              <Input
                {...register('password')}
                id="password"
                size="lg"
                type="password"
              />
              {errors.password?.message && (
                <FormFieldError error={errors.password.message} />
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel labelFor="retypepassword">Confirm Password</FormLabel>
              <Input
                {...register('retypepassword')}
                id="retypepassword"
                size="lg"
                type="password"
              />
              {errors.retypepassword?.message && (
                <FormFieldError error={errors.retypepassword.message} />
              )}
            </FormGroup>
          </div>
          <div className="flex justify-end mt-6">
            <ButtonLoading
              grow
              hierarchy="primary"
              isLoading={isPending}
              size="lg"
              type="submit"
            >
              Set New Password
            </ButtonLoading>
          </div>
        </form>
      </Card>
      <div className="flex justify-center mt-4">
        <Dots step={2} total={2} />
      </div>
    </UnauthenticatedLayout>
  );
};

export default Password;
