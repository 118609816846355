import { useField } from 'formik';

import { getQuestionNumber } from '../../../util/questions';
import { getQuestionOptions } from '../../../util/formOptions';
import { Question } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormSearchSelectInput from '../../common/forms/FormSearchSelectInput';

const PipeConcept = ({
  question,
  questions,
  concepts,
}: {
  question: Question | undefined;
  questions: Question[];
  concepts?: QuestionFormOption[];
}): JSX.Element => {
  const [{ value: pipeConcept }] = useField<
    QuestionFormData['features']['pipeConcept']
  >('features.pipeConcept');

  const questionNumber = getQuestionNumber({ question, questions });
  const questionOptions = getQuestionOptions({
    filterFn: ({ concepts = [], id, sort }) => {
      return (
        id !== question?.id && concepts.length > 0 && sort < questionNumber
      );
    },
    questions,
  });

  // Piping a concept isn't relevant for the first question BUT the user may have dragged a later
  // question to be first in which case we want to show the pipe concept feature so they can
  // remove it / adjust as necessary.
  const isDisabledFirstQuestion = questionNumber < 2 && !pipeConcept.enabled;
  const isDisabledConcepts = concepts && concepts.length > 0;

  return (
    <>
      <FormCheckbox
        checkboxLabel="Pipe Concept"
        disabled={isDisabledFirstQuestion || isDisabledConcepts}
        name="features.pipeConcept.enabled"
        tooltip={
          <div>
            <p>
              This feature allows you to use a concept uploaded for a previous
              question as the concept for this question.
            </p>
            {isDisabledFirstQuestion && (
              <p className="mt-4 font-bold">
                Disabled: Cannot pipe a concept into the first question.
              </p>
            )}
            {isDisabledConcepts && (
              <p className="mt-4 font-bold">
                Disabled: Cannot pipe a concept into question with its own
                concepts.
              </p>
            )}
          </div>
        }
      />
      {pipeConcept.enabled && (
        <div className="flex p-2 space-x-4">
          <span className="text-xs">
            View concept
            <br />
            seen from
          </span>
          <div className="flex-grow">
            <FormSearchSelectInput
              name="features.pipeConcept.question"
              options={questionOptions}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PipeConcept;
