const DragIcon = (): JSX.Element => {
  return (
    <svg
      fill="currentColor"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="8" cy="4" r="2" />
        <circle cx="8" cy="12" r="2" />
        <circle cx="8" cy="20" r="2" />
      </g>
      <g>
        <circle cx="16" cy="4" r="2" />
        <circle cx="16" cy="12" r="2" />
        <circle cx="16" cy="20" r="2" />
      </g>
    </svg>
  );
};

export default DragIcon;
