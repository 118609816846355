import { clsx } from 'clsx';
import { ComponentProps, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';

import Icon from './Icon';

type ModalPosition = 'center' | 'full-screen' | 'top';
type ModalSize = 'auto' | 'sm' | 'md';

const Modal = ({
  children,
  header,
  initialFocus,
  onCloseModal,
  position = 'center',
  size = 'md',
}: {
  children: ReactNode;
  header?: ReactNode;
  initialFocus?: ComponentProps<typeof Dialog>['initialFocus'];
  onCloseModal(): void;
  position?: ModalPosition;
  size?: ModalSize;
}): JSX.Element => {
  return (
    <Dialog
      initialFocus={initialFocus}
      onClose={() => {
        onCloseModal();
      }}
      open={true}
    >
      <div
        aria-hidden="true"
        className="fixed inset-0 z-30 h-full w-full bg-black opacity-40"
        onClick={onCloseModal}
      />

      <div className="fixed inset-0 w-screen overflow-y-auto z-30">
        <div
          className={clsx('flex min-h-full justify-center px-4 py-10', {
            'items-center': position === 'center',
            'items-start': position === 'top',
            'items-stretch': position === 'full-screen',
          })}
        >
          <Dialog.Panel
            className={clsx('rounded-xl bg-white p-6 shadow-lg flex flex-col', {
              'w-[25rem]': size === 'sm',
              'w-160': size === 'md',
              'w-auto': size === 'auto',
            })}
          >
            {header && <div className="mb-5">{header}</div>}

            <div className="grow">{children}</div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;

export const ModalHeader = ({
  children,
  onClickClose,
  supportingText,
}: {
  children: ReactNode;
  onClickClose(): void;
  supportingText?: string;
}): JSX.Element => {
  return (
    <div className="space-y-1">
      <div className="flex justify-between">
        <Dialog.Title className="text-gray-d-900 font-semibold text-lg">
          {children}
        </Dialog.Title>

        <button aria-label="Close" onClick={onClickClose} type="button">
          <div className="h-6 w-6 text-gray-d-500">
            <Icon id="x" />
          </div>
        </button>
      </div>

      {supportingText && (
        <p className="text-gray-d-600 text-sm">{supportingText}</p>
      )}
    </div>
  );
};
