import { clsx } from 'clsx';
import { ReactNode } from 'react';

import SurveyEditRowLeftSide from './SurveyEditRowLeftSide';

const SurveyEditRow = ({
  children,
  isLastRow,
  layout = 'row',
  subtitle,
  title,
  tooltip,
}: {
  children: ReactNode;
  isLastRow?: boolean;
  layout?: 'column' | 'row';
  subtitle?: string;
  title: string;
  tooltip?: string;
}): JSX.Element => {
  return (
    <div
      className={clsx('flex p-6', {
        'space-x-5': layout === 'row',
        'flex-col': layout === 'column',
        'border-b border-gray-300': !isLastRow,
      })}
    >
      <SurveyEditRowLeftSide
        subtitle={subtitle}
        title={title}
        tooltip={tooltip}
      />
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default SurveyEditRow;
