import { useField } from 'formik';

import { clampNumberField } from '../../../util/forms';
import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';

type MultipleResponseFeature = QuestionFormData['features']['multipleResponse'];

const MultipleResponse = ({
  isMultipleResponseDisabled = false,
  tooltip,
}: {
  isMultipleResponseDisabled?: boolean;
  tooltip?: string;
}): JSX.Element => {
  const minFieldName = 'features.multipleResponse.min';
  const maxFieldName = 'features.multipleResponse.max';
  const [{ value: options }] = useField<QuestionFormData['options']>('options');
  const [{ value: multipleResponse }] = useField<boolean>(
    'features.multipleResponse.enabled',
  );

  const [, , minHelpers] =
    useField<MultipleResponseFeature['min']>(minFieldName);
  const [, , maxHelpers] =
    useField<MultipleResponseFeature['max']>(maxFieldName);

  return (
    <div className="flex items-center">
      <FormCheckbox
        checkboxLabel="Multiple Response"
        disabled={isMultipleResponseDisabled}
        name="features.multipleResponse.enabled"
        tooltip={`${
          tooltip ? `${tooltip} ` : ''
        }Multiple response allows the respondent to choose multiple options. You can optionally specify a min and / or a max number of options the respondent can choose. By default, the respondent will be able to choose a min of 1 and a max of the number of available options.`}
      />
      {multipleResponse && (
        <div className="flex items-center ml-4 space-x-4">
          <div className="flex items-center">
            <span className="text-sm">Min:</span>
            <div className="w-20 ml-2">
              <FormInput
                name={minFieldName}
                onChange={(event) => {
                  clampNumberField({
                    formHelpers: minHelpers,
                    lowerBound: 1,
                    upperBound: options.length,
                    value: event.target.value,
                  });
                }}
                size="md"
                type="number"
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-sm">Max:</span>
            <div className="w-20 ml-2">
              <FormInput
                name={maxFieldName}
                onChange={(event) => {
                  clampNumberField({
                    formHelpers: maxHelpers,
                    lowerBound: 1,
                    upperBound: options.length,
                    value: event.target.value,
                  });
                }}
                size="md"
                type="number"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleResponse;
