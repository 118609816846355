import { Form, Formik } from 'formik';
import { isEqual } from 'lodash-es';

import {
  apiDataToFormData,
  DemographicFormData,
  DemographicFormDataValidated,
  validateDemographicFormData,
} from '../../util/demographics';
import { getNestedErrorMessages } from 'util/forms';
import { Question, Survey } from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useSaveAudience } from 'hooks/backend/audience';
import { useSubmitValidation } from '../../hooks/forms';

import { BuildYourAudienceCard, DefineAudienceCard } from './Audience';
import Button from './forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import IndexCard from '../common/IndexCard';
import Modal, { ModalHeader } from './Modal';

const AudienceEditModal = ({
  onAudienceUpdated,
  onCloseModal,
  questions,
  survey,
}: {
  onAudienceUpdated(): void;
  onCloseModal(): void;
  questions: Question[];
  survey: Survey;
}): JSX.Element => {
  const demographicQuestions = questions.filter((q) => q.isDemographic);
  const initialValues = apiDataToFormData({ demographicQuestions, survey });

  const { isPending: isSavingAudience, mutate: saveAudience } = useSaveAudience(
    {
      initialValues,
      onError: (err: Error) => {
        showErrorMessage(
          `There was an error saving your audience. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('The survey audience was updated successfully.');
        onAudienceUpdated();
      },
      survey,
    },
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Edit Audience</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <Formik<DemographicFormData>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(formData) => {
          if (isEqual(initialValues, formData)) {
            onCloseModal();
          } else {
            saveAudience(formData as DemographicFormDataValidated);
          }
        }}
        validate={validateDemographicFormData}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form>
          <AudienceForm
            demographicQuestions={questions.filter((q) => q.isDemographic)}
            isSavingAudience={isSavingAudience}
            onClickCancel={onCloseModal}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default AudienceEditModal;

const AudienceForm = ({
  demographicQuestions,
  isSavingAudience,
  onClickCancel,
}: {
  demographicQuestions: Question[];
  isSavingAudience: boolean;
  onClickCancel(): void;
}): JSX.Element => {
  const { errors, onClickSubmit } = useSubmitValidation({
    isSaving: isSavingAudience,
  });

  return (
    <div>
      {errors ? (
        <div className="mb-8">
          <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
        </div>
      ) : null}

      <IndexCard>
        <DefineAudienceCard useInboundCensusOptions={false} />
        <BuildYourAudienceCard demographicQuestions={demographicQuestions} />
      </IndexCard>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isSavingAudience}
          onClick={onClickSubmit}
          size="lg"
          // This can't currently be a submit button since we handle the form submission
          // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
          type="button"
        >
          Save Audience
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickCancel}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
