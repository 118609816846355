export const SURVEY_STATUSES = {
  APPROVED: { name: 'approved' },
  COMPLETED: { id: 7, name: 'completed' },
  DRAFT: { id: 6, name: 'draft' },
  LIVE: { id: 1 },
} as const;

export const ELIGIBLE_STATUSES_FOR_CHANGE = {
  [SURVEY_STATUSES.APPROVED.name]: [
    SURVEY_STATUSES.COMPLETED,
    SURVEY_STATUSES.DRAFT,
  ],
  [SURVEY_STATUSES.COMPLETED.name]: [SURVEY_STATUSES.DRAFT],
  [SURVEY_STATUSES.DRAFT.name]: [SURVEY_STATUSES.COMPLETED],
};
