import { ReactNode } from 'react';

import Logo from '../common/Logo';

const UnauthenticatedLayout = ({
  children,
  introText,
}: {
  children?: ReactNode;
  introText: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col w-screen h-screen bg-sand">
      <div className="flex flex-col items-center m-auto space-y-6">
        <div className="flex flex-col items-center space-y-4 font-inter text-gray-900">
          <Logo />
          <p className="font-light">{introText}</p>
        </div>
        {children && <div className="w-unauth-card">{children}</div>}
      </div>
    </div>
  );
};

export default UnauthenticatedLayout;
