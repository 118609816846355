import UnauthenticatedLayout from 'components/layout/UnauthenticatedLayout';

export const EmailConfirmationSuccess = (): JSX.Element => {
  return (
    <UnauthenticatedLayout
      introText="You've confirmed your email successfully! Feel free to close this
    browser window."
    />
  );
};

export const EmailConfirmationNotFound = (): JSX.Element => {
  return (
    <UnauthenticatedLayout
      introText="We weren't able to find an account associated with your email. Please contact Glass support at support@useglass.com for further
    assistance."
    />
  );
};
