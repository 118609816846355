import { ComponentProps, ReactNode } from 'react';
import { useField } from 'formik';

import FormGroup from './FormGroup';
import Input from './Input';

const FormInput = ({
  label,
  labelFor,
  tooltip,
  ...props
}: Omit<ComponentProps<typeof Input>, 'name' | 'value'> & {
  label?: ReactNode;
  labelFor?: string;
  name: string;
  tooltip?: string;
}): JSX.Element => {
  const [field, meta] = useField({ name: props.name });
  const hasError = !!(meta.touched && meta.error);

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
      tooltip={tooltip}
    >
      <Input {...field} {...props} />
    </FormGroup>
  );
};

export default FormInput;
