import { assign, setup } from 'xstate';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';
import { useMachine } from '@xstate/react';

interface RouteBlocker {
  cancelBlock(): void;
  isBlocked: boolean;
  performRedirect(): void;
  shouldBlock(): void;
}

const RouteBlockerContext = createContext<RouteBlocker | undefined>(undefined);

const RouteBlockerProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();

  const [state, send] = useMachine(
    routeBlockerMachine.provide({
      actions: {
        redirect: ({ context }) => {
          if (context.blockedPath) {
            navigate(context.blockedPath);
          }
        },
        redirectImmediately: (_, event) => {
          navigate(event.blockedPath);
        },
      },
    }),
  );
  const isBlocked = state.matches('blocked');

  const blocker = unstable_useBlocker(state.matches('waitingToBlock'));

  useEffect(() => {
    if (blocker.state === 'blocked') {
      // I'm not entirely sure why, but it seems like a blocker will block a navigation even
      // if the path is the same as the current path. For our purposes, we only care about a
      // navigation to a different path.
      if (blocker.location.pathname !== window.location.pathname) {
        send({ blockedPath: blocker.location.pathname, type: 'BLOCKED' });
      }

      blocker.reset();
    }
  }, [blocker, send]);

  const cancelBlock = useCallback(() => {
    send({ type: 'CANCEL_BLOCK' });
  }, [send]);

  const performRedirect = useCallback(() => {
    send({ type: 'PERFORM_REDIRECT' });
  }, [send]);

  const shouldBlock = useCallback(() => {
    send({ type: 'SHOULD_BLOCK' });
  }, [send]);

  return (
    <RouteBlockerContext.Provider
      value={{
        cancelBlock,
        isBlocked,
        performRedirect,
        shouldBlock,
      }}
    >
      {children}
    </RouteBlockerContext.Provider>
  );
};

function useRouteBlocker() {
  const context = useContext(RouteBlockerContext);
  if (context === undefined) {
    throw new Error('useRouteBlocker must be used in an RouteBlockerProvider');
  }

  return context;
}

export { RouteBlockerProvider, useRouteBlocker };

const routeBlockerMachine = setup({
  types: {} as {
    context: { blockedPath: string };
    events:
      | { blockedPath: string; type: 'BLOCKED' }
      | { type: 'CANCEL_BLOCK' }
      | { type: 'PERFORM_REDIRECT' }
      | { type: 'RETURN_TO_WAITING' }
      | { type: 'SHOULD_BLOCK' };
  },

  actions: {
    clearBlockedPath: assign({
      blockedPath: '',
    }),
    redirect: () => {
      throw new Error('Unimplemented');
    },
    redirectImmediately: (_, params: { blockedPath: string }) => {
      throw new Error(`Unimplemented: ${params.blockedPath}`);
    },
    setBlockedPath: assign({
      blockedPath: (_, params: { blockedPath: string }) => params.blockedPath,
    }),
  },
}).createMachine({
  id: 'routeBlocker',
  context: { blockedPath: '' },
  initial: 'idle',
  states: {
    idle: {
      on: {
        // If we're in an idle state and our route blocker blocks something,
        // we don't actually want to block it since we only will do that if
        // we're intentionally in the "waitingToBlock" state.
        BLOCKED: {
          actions: [
            {
              type: 'redirectImmediately',
              params: ({ event }) => ({ blockedPath: event.blockedPath }),
            },
          ],
        },
        PERFORM_REDIRECT: {
          actions: [{ type: 'redirect' }, { type: 'clearBlockedPath' }],
        },
        SHOULD_BLOCK: { target: 'waitingToBlock' },
      },
    },
    waitingToBlock: {
      on: {
        BLOCKED: {
          actions: [
            {
              type: 'setBlockedPath',
              params: ({ event }) => ({ blockedPath: event.blockedPath }),
            },
          ],
          target: 'blocked',
        },
      },
    },
    blocked: {
      on: {
        PERFORM_REDIRECT: {
          actions: [{ type: 'redirect' }, { type: 'clearBlockedPath' }],
          target: 'idle',
        },
        SHOULD_BLOCK: { target: 'waitingToBlock' },
      },
    },
  },
  on: {
    CANCEL_BLOCK: { target: '.idle' },
  },
});
