import { useField, FieldHelperProps } from 'formik';

import { getEligibleQuestions } from '../../util/displayLogic';
import {
  getPreviousQuestions,
  getVariableReferencesForOption,
  getOtherQuestionReferencesForOption,
} from '../../util/questions';
import { getQuestionOptions } from '../../util/formOptions';
import { OptionFeatureConfig } from '../../util/options';
import {
  Question,
  QUESTION_TYPE,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';

import { useModal } from '../../hooks/modals';
import { QuestionFormData } from '../../types/forms';
import DisplayLogicEditModal from './DisplayLogicEditModal';
import FormCheckbox from '../common/forms/FormCheckbox';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Popover from '../common/Popover';
import Tooltip from '../common/Tooltip';

const OptionSettings = ({
  activeHelper,
  availableOptionFeatures,
  index,
  isWithinMonadicLoop,
  namePrefix = 'options',
  optionId,
  question,
  questions,
  questionType,
  setIsPreventDeleteModalOpen,
  survey,
  weightHelper,
  variables,
}: {
  activeHelper: FieldHelperProps<boolean>;
  availableOptionFeatures: OptionFeatureConfig[];
  index: number;
  isWithinMonadicLoop: boolean;
  namePrefix?: 'concepts' | 'labels' | 'options';
  optionId?: number | null;
  question: Question | undefined;
  questions: Question[];
  questionType: QuestionFormData['questionType'];
  setIsPreventDeleteModalOpen(value: boolean): void;
  survey: Survey;
  weightHelper: FieldHelperProps<number | null>;
  variables: SurveyVariable[];
}): JSX.Element => {
  const featuresFieldName = `${namePrefix}.${index}.features`;
  const [{ value: displayLogic }] = useField<boolean>(
    `${featuresFieldName}.displayLogic.enabled`,
  );

  const [, , preservationHelpers] = useField<boolean>(
    `${namePrefix}.${index}.preserved`,
  );

  const {
    isOpen: isDisplayLogicModalOpen,
    onCloseModal,
    setIsOpen: setIsDisplayLogicModalOpen,
  } = useModal();

  return (
    <>
      <Popover
        name={`${namePrefix}-settings-${index}`}
        trigger={(triggerProps) => (
          <div {...triggerProps}>
            <IconBackground size="small" title="Settings">
              <div className="w-3 h-3">
                <Icon id="settings-01" />
              </div>
            </IconBackground>
          </div>
        )}
      >
        <div className="w-44 p-4 space-y-2 text-sm">
          <FormCheckbox
            checkboxLabel={
              <div className="flex items-center">
                <span className="mr-2">Display Logic</span>
                {displayLogic && (
                  <IconBackground
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      setIsDisplayLogicModalOpen(true);
                    }}
                    title="Edit"
                  >
                    <div className="w-3 h-3">
                      <Icon id="pencil" />
                    </div>
                  </IconBackground>
                )}
              </div>
            }
            name={`${featuresFieldName}.displayLogic.enabled`}
            onChange={(value) => {
              if (value) {
                setIsDisplayLogicModalOpen(true);
              }
            }}
          />
          {questionType?.value === QUESTION_TYPE.MULTIPLE_CHOICE &&
            namePrefix !== 'concepts' && (
              <div className="flex">
                <FormCheckbox
                  checkboxLabel={
                    <div className="flex items-center">
                      <span className="mr-2">Use weight</span>
                    </div>
                  }
                  name={`${featuresFieldName}.useWeight`}
                  onChange={(value) => {
                    if (!value) {
                      weightHelper.setValue(null);
                    }
                  }}
                />
                <div className="ml-2 h-full">
                  <Tooltip>
                    Weights will be applied when calculating averages for the
                    question in crosstab exports.
                  </Tooltip>
                </div>
              </div>
            )}
          {questionType?.value === QUESTION_TYPE.IDEA_PRESENTER &&
            namePrefix === 'concepts' && (
              <>
                <div className="flex">
                  <FormCheckbox
                    checkboxLabel={
                      <div className="flex items-center">
                        <span className="mr-2">Preserve Concept</span>
                      </div>
                    }
                    name={`${namePrefix}.${index}.preserved`}
                    onChange={(value) => {
                      preservationHelpers.setValue(value);
                    }}
                  />
                  <div className="ml-2 h-full">
                    <Tooltip>
                      Ensure this concept will be seen by respondents.
                    </Tooltip>
                  </div>
                </div>
              </>
            )}

          {availableOptionFeatures.map(({ featureName, label }) => {
            return (
              <FormCheckbox
                key={featureName}
                checkboxLabel={label}
                name={`${featuresFieldName}.${featureName}`}
                onChange={(value) => {
                  if (optionId && !value && featureName === 'isActive') {
                    const questionReferences =
                      getOtherQuestionReferencesForOption({
                        optionId,
                        questions,
                        survey,
                      });
                    const variableReferences = getVariableReferencesForOption({
                      optionId,
                      variables,
                    });
                    const hasOtherReferences =
                      questionReferences.length > 0 ||
                      variableReferences.length > 0;

                    if (hasOtherReferences) {
                      setIsPreventDeleteModalOpen(true);
                      activeHelper.setValue(true);
                    }
                  }
                }}
              />
            );
          })}
        </div>
      </Popover>
      {isDisplayLogicModalOpen && (
        <DisplayLogicEditModal
          canAddOrGroups={namePrefix !== 'concepts'}
          isWithinMonadicLoop={isWithinMonadicLoop}
          namePrefix={`${featuresFieldName}.displayLogic`}
          onCloseModal={onCloseModal}
          questionOptions={getQuestionOptions({
            questions: getPreviousQuestions({
              isConcept: namePrefix === 'concepts',
              question,
              questions: getEligibleQuestions({ questions }),
            }),
          })}
          survey={survey}
          type={namePrefix === 'concepts' ? 'concept' : 'option'}
        />
      )}
    </>
  );
};

export default OptionSettings;
