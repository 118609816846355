import { useField } from 'formik';

import FormCheckbox from '../../common/forms/FormCheckbox';

const OpenMatrix = (): JSX.Element => {
  const [{ value: openMatrix }] = useField<boolean>(
    'features.openMatrix.enabled',
  );

  return (
    <div className="flex items-center">
      <FormCheckbox
        checkboxLabel="Open Matrix"
        name="features.openMatrix.enabled"
        tooltip="The default matrix type is a closed matrix in which the respondent first chooses a statement and then an option for that selected statement. This closed matrix display is extremely mobile friendly. Choosing an open matrix enables the more traditional matrix display."
      />
      {openMatrix && (
        <div className="flex items-center ml-6 space-x-6">
          <div className="flex items-center">
            <FormCheckbox
              checkboxLabel="Inverted"
              name="features.openMatrix.inverted"
              tooltip="By default, an open matrix displays its statements as the rows
              and the options as the columns. This feature switches that
              logic so statements are displayed as the columns and options
              are displayed as the rows."
            />
          </div>
          <div className="flex items-center">
            <FormCheckbox
              checkboxLabel="Scroll"
              name="features.openMatrix.scroll"
              tooltip='An open matrix displays the data in a row / column format.
              For some questions, there can be many columns which causes
              the matrix to be displayed in a "squished" format. This
              feature enables scrolling of the matrix columns so the
              respondent can click an arrow to go to the next set of
              statements / options and columns can be displayed with enough
              room.'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenMatrix;
