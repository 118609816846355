import { ReactNode } from 'react';

import Tooltip from '../Tooltip';

export interface Props {
  label: ReactNode;
  labelFor?: string;
  tooltip?: string | ReactNode;
}

const FormFieldLabel = ({ label, labelFor, tooltip }: Props): JSX.Element => {
  return (
    <label
      className="flex items-center text-dark-grey text-xs"
      htmlFor={labelFor}
    >
      {label}

      {tooltip && (
        <div className="ml-2">
          <Tooltip>{tooltip}</Tooltip>
        </div>
      )}
    </label>
  );
};

export default FormFieldLabel;
