import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { commentQueries } from 'hooks/backend/comments';
import { Question, SurveyVariable } from '../../types/domainModels';
import { questionBlockQueries } from 'hooks/backend/questionBlocks';
import { questionQueries, useReorderQuestions } from 'hooks/backend/questions';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep, SurveyFlowSteps } from '../../types/internal';
import { surveyFlowMachine } from './machines/surveyFlow';
import { surveyQueries } from 'hooks/backend/surveys';
import { useRouteBlocker } from 'contexts/routeBlocker';
import { variableQueries } from 'hooks/backend/surveyVariables';

import AudienceStep from './AudienceStep';
import CreateQuestionBlocks from './CreateQuestionBlocks';
import ErrorDisplay from '../common/ErrorDisplay';
import FixedHeaderAndCollapsedSidebar from 'components/layout/FixedHeaderAndCollapsedSidebar';
import { MenuName } from '../common/SurveyPageSidebarLayout';
import OverviewStep from './OverviewStep';
import QuestionsStep from './QuestionsStep';
import ReviewStep from './ReviewStep';
import { Sidebar } from '../layout/DefaultLayout';
import SurveyEditHeader from './SurveyEditHeader';
import SurveySidebar from './SurveySidebar';
import SurveyVariablesPage from './SurveyVariablesPage';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';

const SurveyEditPage = (): JSX.Element => {
  const {
    id: surveyId,
    resourceId,
    step: currentStep,
  } = useParams<{
    id: string;
    resourceId?: string;
    step: SurveyFlowStep;
  }>();

  if (!surveyId) {
    return (
      <ErrorDisplay message="Missing URL variables for editing a survey." />
    );
  }

  return (
    <SurveyEditPageLoaded
      currentStep={currentStep}
      resourceId={resourceId}
      surveyId={Number(surveyId)}
    />
  );
};

const SurveyEditPageLoaded = ({
  currentStep,
  resourceId,
  surveyId,
}: {
  currentStep?: SurveyFlowStep;
  resourceId?: string;
  surveyId: number;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const questionId =
    currentStep === SurveyFlowSteps.questions ? resourceId : undefined;
  const variableId =
    currentStep === SurveyFlowSteps.variables ? resourceId : undefined;

  const {
    data: survey,
    error: loadSurveyError,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useQuery(surveyQueries.survey({ surveyId }));

  const {
    data: questions = [],
    error: getQuestionsError,
    isLoadingError: hasLoadQuestionsError,
    isLoading: isLoadingQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const question = questions.find(({ id }) => `${id}` === questionId);

  const {
    data: variables = [],
    error: getVariablesError,
    isLoadingError: hasLoadVariablesError,
    isLoading: isLoadingVariables,
  } = useQuery({
    ...variableQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const variable = variables.find(({ id }) => `${id}` === variableId);

  const {
    data: questionBlocks = [],
    error: getQuestionBlocksError,
    isLoadingError: hasLoadQuestionBlocksError,
    isLoading: isLoadingQuestionBlocks,
  } = useQuery({
    ...questionBlockQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const { data: questionComments = [] } = useQuery(
    commentQueries.questions({ surveyId }),
  );
  const { data: surveyComments = [] } = useQuery(
    commentQueries.survey({ surveyId }),
  );
  const comments = [...questionComments, ...surveyComments];

  const { mutate: reorderQuestions } = useReorderQuestions({
    onError: (err) => {
      showErrorMessage(
        `There was an error adjusting the order of the questions. Error: ${err.message}`,
      );
    },
  });

  const { cancelBlock, isBlocked, performRedirect, shouldBlock } =
    useRouteBlocker();

  const [surveyFlowState, surveyFlowSend] = useMachine(
    surveyFlowMachine.provide({
      actions: {
        cancelBlock: () => {
          cancelBlock();
        },
        navigateToBlocked: () => {
          performRedirect();
        },
        navigateToNewQuestion: () => {
          navigate(`/campaign/edit/${surveyId}/questions`);
        },
        navigateToSpecificResource: (_, params) => {
          if (currentStep === SurveyFlowSteps.questions) {
            const resourceId = params.newResourceId
              ? `/${params.newResourceId}`
              : '';

            navigate(`/campaign/edit/${surveyId}/questions${resourceId}`);
          } else if (currentStep === SurveyFlowSteps.variables) {
            const resourceId = params.newResourceId
              ? `/${params.newResourceId}`
              : '';

            navigate(`/campaign/edit/${surveyId}/variables${resourceId}`);
          } else if (currentStep === SurveyFlowSteps.audience) {
            navigate(`/campaign/edit/${surveyId}/questions`);
          }
        },
        shouldBlock: () => {
          shouldBlock();
        },
        updateRouteForLaunched: () => {
          navigate(`/campaign/${surveyId}`);
        },
        updateRouteForNewVariable: () => {
          navigate(`/campaign/edit/${surveyId}/variables`);
        },
        updateRouteForNewBlock: () => {
          navigate(`/campaign/edit/${surveyId}/question-blocks`);
        },
        updateRouteForStep: (_, params) => {
          const surveyIdToUse = params.surveyId ?? surveyId;

          let questionIdPart = '';
          if (
            params.step === SurveyFlowSteps.questions &&
            questions.filter((q) => !q.isDemographic).length > 0
          ) {
            questionIdPart = `/${questions[0].id}`;
          }

          navigate(
            `/campaign/edit/${surveyIdToUse}/${params.step}${questionIdPart}`,
          );
        },
      },
      guards: {
        shouldNavigateAfterSave: (_, params) => {
          if (
            currentStep === SurveyFlowSteps.questions ||
            currentStep === SurveyFlowSteps.variables
          ) {
            return !params.existingResourceId;
          }

          return currentStep === SurveyFlowSteps.audience;
        },
      },
    }),
  );
  const { formDataToDuplicate } = surveyFlowState.context;
  const isShowingUnsavedChanges = surveyFlowState.matches('unsavedChanges');

  const onDiscardChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISCARD_CHANGES' });
  }, [surveyFlowSend]);

  const onDismissUnsavedChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISMISS_UNSAVED_CHANGES' });
  }, [surveyFlowSend]);

  const onFormDirtyChanged = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        surveyFlowSend({ type: 'FORM_DIRTY' });
      } else {
        surveyFlowSend({ type: 'FORM_NOT_DIRTY' });
      }
    },
    [surveyFlowSend],
  );

  const onHasFormError = useCallback(() => {
    surveyFlowSend({ type: 'HAS_FORM_ERROR' });
  }, [surveyFlowSend]);

  useEffect(() => {
    if (isBlocked) {
      surveyFlowSend({ type: 'BLOCKED_NAVIGATION' });
    }
  }, [isBlocked, surveyFlowSend]);

  const [openMenus, setOpenMenus] = useState<Record<MenuName, boolean>>({
    demos: true,
    questionBlocks: true,
    questions: true,
    variables: true,
  });

  function onMenuToggled(menuName: MenuName) {
    setOpenMenus((openMenus) => {
      return {
        ...openMenus,
        [menuName]: !openMenus[menuName],
      };
    });
  }

  if (hasLoadSurveyError) {
    return (
      <FixedHeaderAndCollapsedSidebar
        header={survey ? <SurveyEditHeader survey={survey} /> : null}
        sidebar={<Sidebar isCollapsed />}
      >
        <SurveyWithSidebar sidebar={null}>
          <ErrorDisplay
            message={`Failed to load survey. Error: ${loadSurveyError.message}`}
          />
        </SurveyWithSidebar>
      </FixedHeaderAndCollapsedSidebar>
    );
  }

  const demographicQuestions = questions.filter((q) => q.isDemographic);
  const sidebar = (
    <SurveySidebar
      comments={comments}
      demographicQuestions={demographicQuestions}
      isLoadingQuestionBlocks={isLoadingQuestionBlocks}
      isLoadingQuestions={isLoadingQuestions}
      isLoadingSurvey={isLoadingSurvey}
      onBlockCloned={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
        queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));
      }}
      onClickNewBlock={() => {
        surveyFlowSend({ type: 'enterNewBlock' });
      }}
      onClickNewQuestion={() => {
        surveyFlowSend({ type: 'enterNewQuestion' });
      }}
      onClickNewVariable={() => {
        surveyFlowSend({ type: 'enterNewVariable' });
      }}
      onMenuToggled={onMenuToggled}
      onQuestionsReordered={(newQuestions) => {
        queryClient.setQueryData(
          questionQueries.forSurvey({ surveyId }).queryKey,
          (oldData) => {
            if (!oldData) {
              return;
            }

            return [...demographicQuestions, ...newQuestions];
          },
        );

        reorderQuestions({ data: newQuestions, surveyId });
      }}
      onTemplateQuestionsAdded={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
      }}
      openMenus={openMenus}
      question={question}
      questionBlocks={questionBlocks}
      questions={questions?.filter((q) => !q.isDemographic)}
      survey={survey}
      variable={variable}
      variables={variables}
    />
  );

  return currentStep === SurveyFlowSteps.overview ? (
    <OverviewStep
      isLoadingSurvey={isLoadingSurvey}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasFormError}
      onOverviewDirtyChanged={onFormDirtyChanged}
      onOverviewSaved={() => {
        surveyFlowSend({ type: 'RESOURCE_SAVED' });
      }}
      onStepCompleted={(surveyId) => {
        surveyFlowSend({
          step: 'audience',
          surveyId,
          type: 'goToStep',
        });
      }}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
    />
  ) : currentStep === SurveyFlowSteps.audience ? (
    <AudienceStep
      demographicQuestions={demographicQuestions}
      isLoadingSurvey={isLoadingSurvey}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onAudienceSaved={() => {
        surveyFlowSend({ type: 'RESOURCE_SAVED' });
      }}
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDirtyChanged={onFormDirtyChanged}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasFormError}
      onStepCompleted={() => {
        surveyFlowSend({ step: 'questions', type: 'goToStep' });
      }}
      sidebar={sidebar}
      survey={survey}
    />
  ) : currentStep === SurveyFlowSteps.variables ? (
    <SurveyVariablesPage
      key={variable?.id || 'newVariable'}
      demographicQuestions={demographicQuestions}
      isLoadingDemographicQuestions={false}
      isLoadingQuestions={isLoadingQuestions}
      isLoadingSurvey={isLoadingSurvey}
      isLoadingVariables={isLoadingVariables}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      loadVariablesError={
        // Errors that occur after loading are handled on an individual basis (e.g. showing
        // an error toast or something else depending on the context).
        hasLoadVariablesError && getVariablesError instanceof Error
          ? getVariablesError
          : null
      }
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDirtyChanged={onFormDirtyChanged}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasFormError}
      onStepCompleted={() => {
        surveyFlowSend({
          step: 'review',
          type: 'goToStep',
        });
      }}
      onVariableDeleted={() => {
        const currentVariableIndex = variables.findIndex((variable) => {
          return `${variable.id}` === variableId;
        });

        let nextVariable: SurveyVariable | null = null;
        if (currentVariableIndex !== -1) {
          // Try to use the previous question for the next question to show but if there is no
          // previous question (e.g. the user deleted the first question), try to use the next
          // question.
          nextVariable =
            variables[currentVariableIndex - 1] ??
            variables[currentVariableIndex + 1] ??
            null;
        }

        surveyFlowSend({
          newResourceId: nextVariable?.id,
          type: 'RESOURCE_DELETED',
        });
      }}
      onVariableSaved={(data) => {
        surveyFlowSend({
          existingResourceId: variable?.id,
          newResourceId: data.id,
          type: 'RESOURCE_SAVED',
        });
      }}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
      surveyId={surveyId}
      variable={variable}
    />
  ) : currentStep === SurveyFlowSteps.questions ? (
    <QuestionsStep
      key={question?.id || 'newQuestion'}
      comments={comments}
      initialFormData={formDataToDuplicate}
      isLoadingQuestions={isLoadingQuestions}
      isLoadingSurvey={isLoadingSurvey}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      loadQuestionsError={
        // Errors that occur after loading are handled on an individual basis (e.g. showing
        // an error toast or something else depending on the context).
        hasLoadQuestionsError && getQuestionsError instanceof Error
          ? getQuestionsError
          : null
      }
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onDuplicate={(formData) => {
        surveyFlowSend({ formData, type: 'duplicateQuestion' });
      }}
      onHasError={onHasFormError}
      onQuestionDeleted={() => {
        const currentQuestionIndex = questions.findIndex((question) => {
          return `${question.id}` === questionId;
        });

        let nextQuestion: Question | null = null;
        if (currentQuestionIndex !== -1) {
          // Try to use the previous question for the next question to show but if there is no
          // previous question (e.g. the user deleted the first question), try to use the next
          // question.
          nextQuestion =
            questions[currentQuestionIndex - 1] ??
            questions[currentQuestionIndex + 1] ??
            null;
        }

        surveyFlowSend({
          newResourceId: nextQuestion?.id,
          type: 'RESOURCE_DELETED',
        });
      }}
      onQuestionDirtyChanged={onFormDirtyChanged}
      onQuestionSaved={(data) => {
        surveyFlowSend({
          existingResourceId: question?.id,
          newResourceId: data.id,
          type: 'RESOURCE_SAVED',
        });
      }}
      onStepCompleted={() => {
        surveyFlowSend({
          step: 'review',
          type: 'goToStep',
        });
      }}
      question={question}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
      surveyId={surveyId}
      variables={variables}
    />
  ) : currentStep === SurveyFlowSteps.questionBlocks ? (
    <CreateQuestionBlocks
      demographicQuestions={demographicQuestions}
      isLoadingDemographicQuestions={false}
      isLoadingQuestionBlocks={isLoadingQuestionBlocks}
      isLoadingQuestions={isLoadingQuestions}
      isLoadingSurvey={isLoadingSurvey}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      loadQuestionBlocksError={
        // Errors that occur after loading are handled on an individual basis (e.g. showing
        // an error toast or something else depending on the context).
        hasLoadQuestionBlocksError && getQuestionBlocksError instanceof Error
          ? getQuestionBlocksError
          : null
      }
      onBlockDirtyChanged={onFormDirtyChanged}
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasFormError}
      onQuestionBlockDeleted={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
        queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));
      }}
      onQuestionBlocksSaved={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
        queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));

        surveyFlowSend({ type: 'RESOURCE_SAVED' });
      }}
      onStepCompleted={() => {
        surveyFlowSend({
          step: 'review',
          type: 'goToStep',
        });
      }}
      questionBlocks={questionBlocks}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
      surveyId={surveyId}
    />
  ) : currentStep === SurveyFlowSteps.review ? (
    <ReviewStep
      isLoadingSurvey={isLoadingSurvey}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onClickStep={(step) => {
        surveyFlowSend({ step, type: 'goToStep' });
      }}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasFormError}
      onReviewDirtyChanged={onFormDirtyChanged}
      onStepCompleted={() => {
        surveyFlowSend({
          type: 'launched',
        });
      }}
      onSurveySaved={() => {
        surveyFlowSend({ type: 'RESOURCE_SAVED' });
      }}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
      surveyVariables={variables}
    />
  ) : (
    <Navigate to={`/campaign/edit/${surveyId}/overview`} />
  );
};

export default SurveyEditPage;
