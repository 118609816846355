import { useField } from 'formik';

import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';

const ViewConcept = (): JSX.Element | null => {
  const [{ value: concepts }] =
    useField<QuestionFormData['concepts']>('concepts');

  if (concepts.length === 0) {
    return null;
  }

  return (
    <>
      <FormCheckbox
        checkboxLabel="Require Viewing of Concept"
        name="features.viewConcept"
      />
    </>
  );
};

export default ViewConcept;
