import { FullStory } from '@fullstory/browser';
import * as Sentry from '@sentry/react';

import { UserV2 } from '../types/domainModels';

export function identifyUser({
  organizationId,
  user,
}: {
  organizationId: number | undefined;
  user: UserV2;
}): void {
  FullStory.identify(`${user.id}`, {
    displayName: `${user.firstName} ${user.lastName}`,
    email: user.email,
    organizationId,
  });

  Sentry.setUser({
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
    organizationId,
  });
}
