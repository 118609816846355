import { ComponentProps, forwardRef, ReactNode } from 'react';
import { useField } from 'formik';

import FormGroup from './FormGroup';
import Textarea from './Textarea';

const FormTextarea = forwardRef<
  HTMLTextAreaElement,
  Omit<ComponentProps<typeof Textarea>, 'name'> & {
    label?: ReactNode;
    labelFor?: string;
    name: string;
    tooltip?: string;
  }
>(function FormTextarea({ label, labelFor, tooltip, ...props }, ref) {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
      tooltip={tooltip}
    >
      <div>
        <Textarea ref={ref} {...field} {...props} />
      </div>
    </FormGroup>
  );
});

export default FormTextarea;
