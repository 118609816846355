import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { getEnvVar } from 'util/env';
import { useAuth } from './auth';

const FRONT_CHAT_SCRIPT_ID = 'front-chat';
const ChatSupportContext = createContext<{ showChat(): void } | undefined>(
  undefined,
);

// There's no @types NPM package for Front Chat, so we have to declare it ourselves.
declare global {
  interface Window {
    FrontChat?: <Action extends 'identity' | 'init' | 'show'>(
      action: Action,
      opts?: Action extends 'identity'
        ? { email: string; userHash: string }
        : Action extends 'init'
          ? {
              chatId: string;
              email: string;
              useDefaultLauncher: boolean;
              userHash: string;
            }
          : never,
    ) => void;
  }
}

const ChatSupportProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { user } = useAuth();

  // Loads the Front chat script when the user is logged in.
  useEffect(() => {
    if (
      !user ||
      document.getElementById(FRONT_CHAT_SCRIPT_ID) ||
      getEnvVar('NODE_ENV') === 'test'
    ) {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.id = FRONT_CHAT_SCRIPT_ID;
    script.onload = () => {
      console.log(import.meta.env.VITE_FRONT_CHAT_ID);
      window.FrontChat?.('init', {
        chatId: import.meta.env.VITE_FRONT_CHAT_ID,
        email: user.email,
        // We decided not to have the front chat show all the time by default since it
        // covers some of our content (like the comments section when building a question).
        useDefaultLauncher: false,
        userHash: user.hashFront,
      });
    };
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

    document.body.appendChild(script);
  }, [user]);

  // Identifies the user with Front when the user information changes.
  useEffect(() => {
    if (user) {
      window.FrontChat?.('identity', {
        email: user.email,
        userHash: user.hashFront,
      });
    }
  }, [user]);

  const showChat = useCallback(() => {
    window.FrontChat?.('show');
  }, []);

  return (
    <ChatSupportContext.Provider value={{ showChat }}>
      {children}
    </ChatSupportContext.Provider>
  );
};

function useChatSupport() {
  const context = useContext(ChatSupportContext);
  if (context === undefined) {
    throw new Error('useChatSupport must be used in an ChatSupportProvider');
  }

  return context;
}

export { ChatSupportProvider, useChatSupport };
