import { getDecodedJwt } from './auth';

export function getOrgStatus(): {
  baseOrganizationId: number | undefined;
  currentOrganizationId: number | undefined;
  hasChangedOrg: boolean;
} {
  const { baseOrganizationId, organizationId } = getDecodedJwt() || {};

  return {
    baseOrganizationId,
    currentOrganizationId: organizationId,
    hasChangedOrg: !!(
      baseOrganizationId && baseOrganizationId !== organizationId
    ),
  };
}
