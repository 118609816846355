const DIYIllustration = (): JSX.Element => {
  return (
    <svg
      viewBox="0 0 163 103"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path d="M0 101.482h162.332V.112H0z" id="diy-illustration-a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M44 90.5h75v-88H44z" fill="#FFF" />
        <path
          d="M44 90.5h76v-89H44v89zm1.074-1.073h73.852V2.573H45.074v86.854z"
          fill="#F5B3A5"
        />
        <path
          d="M56.891 26.622h50.218V12.378H56.89v14.244zM56 27.5h52v-16H56v16zM57 39.5h49v-1H57z"
          fill="#2E2E76"
        />
        <g transform="translate(0 .5)">
          <path
            d="M105.002 37.684c0 2.292-1.845 4.15-4.12 4.15-2.277 0-4.122-1.858-4.122-4.15 0-2.292 1.845-4.15 4.121-4.15 2.276 0 4.121 1.858 4.121 4.15"
            fill="#2A69EA"
          />
          <path
            d="M100.881 33.981c-2.027 0-3.676 1.661-3.676 3.703 0 2.04 1.65 3.702 3.676 3.702 2.027 0 3.676-1.661 3.676-3.702 0-2.042-1.649-3.703-3.676-3.703m0 8.301c-2.518 0-4.566-2.063-4.566-4.598 0-2.536 2.048-4.599 4.566-4.599s4.566 2.063 4.566 4.599c0 2.535-2.048 4.598-4.566 4.598"
            fill="#2E2E76"
          />
          <mask fill="#fff" id="diy-illustration-b">
            <use xlinkHref="#diy-illustration-a" />
          </mask>
          <path
            d="M56.862 50.842h49.136v-1.255H56.862z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M67.635 50.135c0 2.292-1.845 4.15-4.121 4.15-2.277 0-4.122-1.858-4.122-4.15 0-2.292 1.845-4.15 4.122-4.15 2.276 0 4.12 1.858 4.12 4.15"
            fill="#89EFD7"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M63.514 46.432c-2.027 0-3.677 1.661-3.677 3.703 0 2.04 1.65 3.702 3.677 3.702 2.026 0 3.675-1.661 3.675-3.702 0-2.042-1.649-3.703-3.675-3.703m0 8.301c-2.518 0-4.567-2.063-4.567-4.598 0-2.536 2.049-4.599 4.567-4.599 2.517 0 4.566 2.063 4.566 4.599 0 2.535-2.049 4.598-4.566 4.598M56.862 63.293h49.136v-1.255H56.862z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M90.796 62.585c0 2.293-1.845 4.15-4.121 4.15-2.276 0-4.121-1.857-4.121-4.15 0-2.292 1.845-4.15 4.12-4.15 2.277 0 4.122 1.858 4.122 4.15"
            fill="#F5B3A5"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M86.675 58.883c-2.027 0-3.676 1.661-3.676 3.702 0 2.042 1.649 3.703 3.676 3.703 2.026 0 3.676-1.661 3.676-3.703 0-2.04-1.65-3.702-3.676-3.702m0 8.301c-2.518 0-4.566-2.063-4.566-4.599 0-2.535 2.048-4.598 4.566-4.598 2.517 0 4.566 2.063 4.566 4.598 0 2.536-2.049 4.6-4.566 4.6M56.862 76.063h49.136v-1.255H56.862z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M74.429 75.356c0 2.292-1.845 4.15-4.121 4.15-2.276 0-4.121-1.858-4.121-4.15 0-2.292 1.845-4.15 4.121-4.15 2.276 0 4.121 1.858 4.121 4.15"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M70.308 71.654c-2.027 0-3.676 1.66-3.676 3.702 0 2.041 1.649 3.702 3.676 3.702s3.676-1.661 3.676-3.702c0-2.042-1.65-3.702-3.676-3.702m0 8.3c-2.518 0-4.566-2.063-4.566-4.598 0-2.536 2.048-4.599 4.566-4.599s4.566 2.063 4.566 4.599c0 2.535-2.048 4.598-4.566 4.598M61.433 17.677H82.93v-.897H61.433zM61.508 22.37h39.988v-.897H61.508zM8.891 84.525s6.013-3.33 20.896-.605c0 0 9.622 1.817 13.23 6.51l-5.112 8.327-31.87-5.3 2.856-8.932z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M48.312 47.432s7.366 4.24 5.712 7.268c-1.653 3.028-9.771-.455-9.771-.455l4.059-6.813z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M44.901 54.028c2.436.965 7.616 2.501 8.733.455 1.112-2.035-3.052-5.145-5.168-6.438l-3.565 5.983zm6.21 2.365c-2.866 0-6.455-1.487-7.033-1.735l-.47-.202 4.546-7.63.379.217c.793.457 7.694 4.554 5.881 7.873-.6 1.1-1.86 1.477-3.303 1.477z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M44.854 55.91s5.111 1.06 4.961 3.483c-.15 2.422-6.615 2.422-6.615 2.422l1.654-5.904z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M45.168 56.443l-1.377 4.913c2.328-.084 5.497-.652 5.58-1.991.094-1.518-2.706-2.544-4.203-2.922m-1.968 5.82h-.588l1.926-6.875.406.084c.562.116 5.485 1.217 5.315 3.949-.171 2.763-6.354 2.843-7.059 2.843"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M31.85 100.69l7.517-15.483L55.133 74.62a6.502 6.502 0 002.14-2.39l9.294-17.799c.352-.674-.03-1.508-.772-1.66-2.477-.51-8.026-.06-14.552 10.633l-4.134.681s-2.706-3.33 2.105-16.805l7.667.606s4.698 1.028 7.696-1.578c1.046-.908.742-2.619-.538-3.139-6.025-2.444-22.829-8.202-27.002 1.689 0 0-6.765-.303-9.32 6.207 0 0-4.962 2.27-6.766 7.57 0 0-4.81 13.777-5.562 19.228-.752 5.45-10.974 22.75-10.974 22.75l27.435.075z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M5.193 100.167l26.38.073L39.02 84.9l15.867-10.652a6.067 6.067 0 001.991-2.226l9.295-17.799a.705.705 0 00.013-.632.679.679 0 00-.48-.38c-1.168-.24-3.062-.25-5.455 1.07-2.917 1.608-5.82 4.757-8.629 9.359l-.106.174-4.589.757-.162-.2c-.117-.145-2.81-3.684 2.03-17.242l.115-.322 8.065.642c.045.01 4.521.946 7.312-1.479.366-.319.537-.8.457-1.286-.082-.5-.408-.91-.872-1.098-2.917-1.184-8.623-3.245-14.07-3.75-6.488-.604-10.645 1.146-12.356 5.198l-.12.286-.309-.014c-.263-.01-6.482-.202-8.887 5.925l-.066.168-.164.075c-.047.022-4.81 2.263-6.528 7.307-.049.141-4.805 13.8-5.542 19.144-.68 4.925-8.718 18.949-10.637 22.242zm26.935.971l-28.495-.078.399-.676c.102-.172 10.185-17.283 10.916-22.582.75-5.432 5.386-18.75 5.583-19.315 1.68-4.932 5.932-7.294 6.837-7.75 2.363-5.763 7.995-6.285 9.378-6.325 1.944-4.258 6.362-6.098 13.138-5.47 5.56.517 11.359 2.61 14.321 3.813a2.312 2.312 0 011.417 1.782 2.285 2.285 0 01-.754 2.112c-3.034 2.637-7.592 1.778-8.05 1.682l-7.3-.576c-3.847 10.921-2.598 14.938-2.178 15.84l3.631-.6c6.524-10.598 12.139-11.234 14.913-10.662.489.1.892.419 1.107.875a1.6 1.6 0 01-.03 1.433L57.666 72.44a6.97 6.97 0 01-2.286 2.554L39.714 85.512l-7.586 15.626z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M43.017 90.43s-8.72 6.207-20.596 3.785c-10.365-2.114-13.53-9.69-13.53-9.69L0 101.406h38.313l4.704-10.976z"
            fill="#2563F6"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M119.386 81.043s1.052-6.51 16.236-11.052c0 0 11.576-3.028 17.138 1.211l2.105 11.507-33.074 8.024-2.405-9.69z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M115.703 35.32s-7.367 4.24-5.713 7.268c1.654 3.028 9.772-.454 9.772-.454l-4.06-6.814z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M115.548 35.933c-2.115 1.293-6.28 4.403-5.168 6.439 1.118 2.048 6.298.51 8.733-.456l-3.565-5.983zm-2.645 8.348c-1.442 0-2.702-.376-3.303-1.477-1.812-3.319 5.09-7.416 5.882-7.873l.378-.217 4.546 7.63-.47.202c-.578.248-4.166 1.735-7.033 1.735z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M119.16 43.799s-5.11 1.06-4.96 3.482c.15 2.422 6.614 2.422 6.614 2.422L119.16 43.8z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M118.847 44.332c-1.497.379-4.297 1.406-4.203 2.921.083 1.34 3.252 1.907 5.579 1.991l-1.376-4.912zm2.555 5.82h-.588c-.704 0-6.887-.08-7.059-2.843-.17-2.732 4.754-3.833 5.316-3.95l.405-.083 1.926 6.876z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M129.458 100.69l-4.51-27.595-15.974-10.54a6.797 6.797 0 01-2.28-2.528l-8.988-17.211a1.548 1.548 0 011.225-2.261c2.735-.25 7.88.972 13.84 10.738l4.134.681s2.706-3.33-2.104-16.805l-7.667.606s-4.741 1.038-7.738-1.613c-1.018-.902-.728-2.572.53-3.083 5.98-2.433 22.866-8.25 27.052 1.668 0 0 6.765-.303 9.32 6.207 0 0 4.21 1.514 6.014 6.813 0 0 5.562 14.534 6.314 19.985.751 5.45 6.464 34.861 6.464 34.861l-25.632.076z"
            fill="#FFF"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M99.55 40.975c-.206 0-.399.01-.578.026a1.08 1.08 0 00-.865.572c-.177.327-.18.704-.007 1.034l8.988 17.212a6.364 6.364 0 002.13 2.361l16.138 10.648 4.48 27.412 24.714-.073c-.777-4.01-5.676-29.359-6.365-34.354-.736-5.336-6.233-19.74-6.289-19.885-1.72-5.052-5.707-6.538-5.747-6.552l-.19-.068-.075-.19c-2.41-6.141-8.821-5.927-8.886-5.923l-.309.013-.12-.286c-1.715-4.062-5.888-5.809-12.402-5.195-5.456.516-11.16 2.582-14.074 3.767-.45.183-.766.58-.848 1.062-.08.477.086.951.445 1.269 2.788 2.466 7.304 1.521 7.35 1.512l.059-.01 8.006-.631.115.321c4.84 13.558 2.147 17.097 2.03 17.241l-.163.2-4.589-.756-.106-.174c-5.482-8.983-10.21-10.553-12.843-10.553zm29.53 60.164l-4.54-27.777-15.81-10.432a7.258 7.258 0 01-2.43-2.694l-8.988-17.212a2.002 2.002 0 01.013-1.88 1.983 1.983 0 011.566-1.036c2.665-.241 8.035.84 14.152 10.776l3.632.598c.423-.897 1.678-4.892-2.179-15.84l-7.299.577c-.461.096-5.06.964-8.094-1.72a2.271 2.271 0 01-.735-2.094 2.276 2.276 0 011.391-1.742c2.96-1.204 8.756-3.302 14.324-3.828 6.803-.643 11.237 1.196 13.185 5.465 1.382.04 7.004.561 9.372 6.309.875.373 4.44 2.158 6.093 7.013.222.58 5.583 14.629 6.333 20.068.742 5.376 6.404 34.543 6.46 34.837l.104.533-26.55.079z"
            fill="#2E2E76"
            mask="url(#diy-illustration-b)"
          />
          <path
            d="M119.386 81.043s7.3 2.642 17.439 1.06c10.673-1.665 15.935-10.825 15.935-10.825l9.572 30.204h-39.764l-3.182-20.439z"
            fill="#2563F6"
            mask="url(#diy-illustration-b)"
          />
        </g>
      </g>
    </svg>
  );
};

export default DIYIllustration;
