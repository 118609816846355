import { IQuestionReference, IVariableReference } from '../../util/questions';
import { map } from 'lodash-es';

import { useModal } from '../../hooks/modals';
import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';

const DeleteQuestion = ({
  isDeleting,
  onConfirmDelete,
  questionId,
  questionReferences,
  variableReferences,
}: {
  isDeleting: boolean;
  onConfirmDelete(questionId: number): void;
  questionId: number;
  questionReferences: IQuestionReference[];
  variableReferences: IVariableReference[];
}): JSX.Element => {
  const {
    isOpen: isConfirmDelete,
    onCloseModal,
    setIsOpen: setIsConfirmDelete,
  } = useModal();

  return (
    <>
      <IconBackground
        onClick={() => {
          setIsConfirmDelete(true);
        }}
        title="Delete"
      >
        <div className="w-4 h-4">
          <Icon id="trash" />
        </div>
      </IconBackground>
      {isConfirmDelete && (
        <DeleteQuestionModal
          isDeleting={isDeleting}
          onCloseModal={onCloseModal}
          onConfirmDelete={() => {
            onConfirmDelete(questionId);
          }}
          questionReferences={questionReferences}
          variableReferences={variableReferences}
        />
      )}
    </>
  );
};

export default DeleteQuestion;

export const DeleteQuestionModal = ({
  isDeleting,
  onCloseModal,
  onConfirmDelete,
  questionReferences,
  variableReferences,
}: {
  isDeleting: boolean;
  onCloseModal(): void;
  onConfirmDelete?(): void;
  questionReferences: IQuestionReference[];
  variableReferences: IVariableReference[];
}): JSX.Element => {
  const hasOtherReferences =
    questionReferences.length > 0 || variableReferences.length > 0;

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Remove Question</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      {hasOtherReferences && (
        <div>
          <p>
            This question cannot be deleted or disabled because it is used in
            configuration for other questions. Please adjust the following
            questions before continuing.
          </p>
          <div className="mt-4 pl-4 space-y-4">
            {questionReferences.length > 0 && (
              <h2 className="mb-1 font-semibold">QUESTIONS</h2>
            )}
            {questionReferences.map(({ question, references }) => {
              return (
                <div key={question}>
                  <h2 className="mb-1 font-semibold">{question}</h2>
                  <ul className="list-disc pl-8">
                    {map(references, (reference, feature) => {
                      if (typeof reference === 'boolean') {
                        if (!reference) {
                          return null;
                        }

                        if (feature === 'carryForward') {
                          return <li key={feature}>Carry Forward</li>;
                        } else if (feature === 'displayLogic') {
                          return <li key={feature}>Display Logic</li>;
                        } else if (feature === 'pipeConcept') {
                          return <li key={feature}>Pipe Concept</li>;
                        } else if (feature === 'piping') {
                          return <li key={feature}>Answer Piping</li>;
                        }
                      } else if (
                        feature === 'displayLogicOptions' &&
                        reference.length > 0
                      ) {
                        return (
                          <li key={feature}>
                            Display Logic for Options
                            <ul className="list-circle pl-8">
                              {reference.map((option) => {
                                return <li key={option}>{option}</li>;
                              })}
                            </ul>
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                </div>
              );
            })}
            {variableReferences.length > 0 && (
              <h2 className="mb-1 font-semibold">HIDDEN VARIABLES</h2>
            )}
            {variableReferences.map(({ title, references }) => {
              return (
                <div key={title}>
                  <h2 className="mb-1 font-semibold">{title}</h2>
                  <ul className="list-disc pl-8">
                    {map(references, (reference, feature) => {
                      console.log(typeof reference);
                      if (reference.length > 0) {
                        return (
                          <li key={feature}>
                            Variable Segment
                            <ul className="list-circle pl-8">
                              {reference.map((segment) => {
                                return <li key={segment}>{segment}</li>;
                              })}
                            </ul>
                          </li>
                        );
                      } else if (typeof reference === 'boolean') {
                        if (!reference) {
                          return null;
                        }

                        return <li key={feature}>Survey Variable Segments</li>;
                      }

                      return null;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-8">
            <Button
              hierarchy="primary"
              onClick={onCloseModal}
              size="lg"
              type="button"
            >
              Done
            </Button>
          </div>
        </div>
      )}
      {!hasOtherReferences && (
        <>
          <p>Are you sure you want to delete this question?</p>

          <div className="mt-8 flex gap-3 flex-row-reverse">
            <ButtonLoading
              grow
              hierarchy="destructive"
              isLoading={isDeleting}
              onClick={onConfirmDelete}
              size="lg"
              type="button"
            >
              Delete
            </ButtonLoading>
            <Button
              grow
              hierarchy="secondary-gray"
              onClick={onCloseModal}
              size="lg"
              type="button"
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
