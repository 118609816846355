import { DashboardFilters } from '../types/internal';

export function getEmptyFilters(): DashboardFilters {
  return {
    createdAt: {
      enabled: false,
      end: '',
      start: '',
    },
    tag: {
      enabled: false,
      value: null,
    },
    userId: {
      enabled: false,
      value: null,
    },
  };
}
