export const SCALE_TYPES = [
  {
    value: 1,
    label: 'Radio',
  },
  {
    value: 2,
    label: 'Slider',
  },
];

export const SCALE_UNITS = [
  {
    value: 1,
    label: 'No Units',
  },
  {
    value: 2,
    label: 'Dollars ($)',
  },
  {
    value: 3,
    label: 'Percentage (%)',
  },
];
