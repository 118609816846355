const TemplateLibIllustration = (): JSX.Element => {
  return (
    <svg viewBox="0 0 99 131" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M28 50h70V1H28z" fill="#FFF" />
        <path
          d="M27 49h72V0H27v49zm1.102-1.1h69.795V1.1H28.102v46.8z"
          fill="#F5B3A5"
        />
        <path d="M28 9h70V1H28z" fill="#FFF" />
        <path
          d="M27 9h72V0H27v9zm1.102-1.099h69.795V1.098H28.102v6.803z"
          fill="#F5B3A5"
        />
        <path
          d="M76 46h1V12h-1zM41 42h18v-1H41zM68 38h-.895V16.919h-29.21v3.003H37V16h31z"
          fill="#2E2E76"
        />
        <path d="M84 21h6v-6h-6z" fill="#2A69EA" />
        <path
          d="M84.867 20.153h5.266v-4.306h-5.266v4.306zM84 21h7v-6h-7v6z"
          fill="#2E2E76"
        />
        <path d="M84 32h6v-6h-6z" fill="#89EFD7" />
        <path
          d="M84.867 30.153h5.266v-4.306h-5.266v4.306zM84 31h7v-6h-7v6z"
          fill="#2E2E76"
        />
        <path d="M84 43h6v-6h-6z" fill="#F5B3A5" />
        <path
          d="M84.867 41.153h5.266v-4.306h-5.266v4.306zM84 42h7v-6h-7v6z"
          fill="#2E2E76"
        />
        <path
          d="M0 113.98s4.182 6.402 20 6.95c0 0 18.727 0 20.727-6.95l-2.545-7.317H2l-2 7.316z"
          fill="#2563F6"
        />
        <path
          d="M43.786 26.43l-1.765-1.29.534-.74 1.765 1.29-.534.74zm3.53 2.581l-1.765-1.29.534-.74 1.765 1.29-.535.74zm3.53 2.581l-1.766-1.29.535-.74 1.765 1.29-.535.74zm3.529 2.582l-1.765-1.291.535-.74 1.764 1.29-.534.74zm3.53 2.58l-1.765-1.29.534-.74 1.765 1.29-.534.74zm3.53 2.582l-1.765-1.29.534-.74 1.765 1.29-.534.74zm3.53 2.58l-1.766-1.29.535-.74 1.765 1.291-.535.74zM27.636 31.666s3.091-1.28 8.364 2.927c0 0 1.818 2.926 1.818 4.938l-2.182 2.195L26 34.593l1.636-2.927z"
          fill="#2E2E76"
        />
        <path
          d="M32.182 40.263l5.988-6.871 3.603-1.95 2.74-6.054s-2.612-.476-3.919 3.083l-1.968.79.182-2.022 5.32-3.017s-3.423-3.783-8.053-2.411c0 0-2.534 1.647-4.218 3.864l-1.073 4.189L26 35.507l6.182 4.756z"
          fill="#FFF"
        />
        <path
          d="M39.239 27.52l-.094 1.04 1.096-.44c.29-.733.637-1.302 1.007-1.74l-2.01 1.14zm-12.582 7.917l5.463 4.203 5.708-6.55a.454.454 0 01.127-.101l3.467-1.877 2.38-5.26c-.75.12-2.002.654-2.782 2.778a.456.456 0 01-.258.266l-1.968.79a.452.452 0 01-.44-.058.458.458 0 01-.181-.407l.182-2.023c.013-.15.1-.283.23-.357l4.784-2.714c-1.011-.887-3.735-2.858-7.1-1.896-.367.246-2.515 1.733-3.995 3.649l-1.05 4.098a.457.457 0 01-.094.183l-4.473 5.276zm5.525 5.283a.45.45 0 01-.276-.094l-6.182-4.756a.458.458 0 01-.07-.66l4.716-5.562 1.047-4.087a.458.458 0 01.079-.164c1.712-2.254 4.227-3.901 4.333-3.97a.448.448 0 01.118-.055c4.847-1.436 8.37 2.379 8.517 2.542a.46.46 0 01-.113.706l-.574.326c.482-.064.807-.01.817-.008a.456.456 0 01.332.64l-2.74 6.053a.457.457 0 01-.198.213l-3.53 1.911-5.934 6.81a.452.452 0 01-.342.155zM58.545 53.25s2.182-.548 6.546 1.28c0 0 3.273 2.379 3.636 6.403l-2.545 3.292-9.637-5.67 2-5.305z"
          fill="#2E2E76"
        />
        <path
          d="M37.818 39.531s-4.182.366-7.273-2.378c-3.002-2.665-2.909-5.487-2.909-5.487S-.966 63.602 2.736 72.425l6.821 3.529L37.818 39.53z"
          fill="#89EFD7"
        />
        <path
          d="M13.664 66.525l.765-10.614 1.327-10.93 10.817.434s6.417 15.85-3.317 14.675l.15 6.26-9.742.175z"
          fill="#FFF"
        />
        <path
          d="M14.881 55.955l-.728 10.104 8.787-.158-.138-5.8a.458.458 0 01.508-.465c1.834.221 3.162-.19 3.945-1.223 2.38-3.138-.406-10.99-.997-12.553l-10.102-.405-1.275 10.5zm-1.217 11.028a.454.454 0 01-.453-.49l.764-10.616.003-.022 1.326-10.93a.457.457 0 01.47-.4l10.817.433a.455.455 0 01.403.284c.162.4 3.932 9.839.984 13.726-.911 1.202-2.34 1.747-4.255 1.625l.137 5.746a.459.459 0 01-.446.468l-9.742.176h-.008z"
          fill="#2E2E76"
        />
        <path
          d="M30.847 44.876c-.094-2.152-3.216-2.21-3.216-2.21 1.026-.308 1.306-.906 1.24-1.363-.374-2.598-5.482-.542-5.482-.542.992-1.09-.576-1.282-.576-1.282-1.908-.075-5.198 1.471-7.471 2.687-.792.076-10.72 1.328-1.61 15.496 0 0 5.505 1.064 5.525-4.428 0 0-1.724-2.62.677-3.238.457-.118.946.057 1.239.43.398.507.628.952.754 1.25.099.232.327.38.578.368a.6.6 0 00.57-.627l-.094-2.164c4.363-.447 7.932-2.856 7.866-4.377M3.906 106.733l-.042 23.526h35.703l-2.728-22.742z"
          fill="#2E2E76"
        />
        <path
          d="M17.102 130.385l-3.305-11.566.874-.253 3.305 11.567zM58 57.64l2.683-6.27-.568-4.49 3.634-6.338s2.093 2.047-.238 5.5l.554 2.277.904-1.849.217-4.885c-.08-.758.613-1.374 1.347-1.185 1.813.47 4.402 1.16 4.837 5.754.39 4.117-4.093 9.41-4.093 9.41l-2.338 5.882L58 57.64z"
          fill="#FFF"
        />
        <path
          d="M64 46.128l.21.86.31-.634.057-1.29c-.15.34-.34.694-.578 1.064zm-5.419 11.31l6.126 3.36 2.148-5.404a.462.462 0 01.076-.126c.044-.052 4.353-5.196 3.986-9.07-.406-4.297-2.77-4.908-4.497-5.355a.615.615 0 00-.563.135.64.64 0 00-.22.558.488.488 0 01.003.07l-.217 4.884a.466.466 0 01-.046.182l-.904 1.85a.454.454 0 01-.849-.094l-.555-2.277a.459.459 0 01.066-.366c1.443-2.137 1.046-3.655.654-4.398l-3.204 5.587.549 4.338a.46.46 0 01-.034.238l-2.519 5.888zm6.358 4.465a.45.45 0 01-.217-.056l-6.94-3.805a.459.459 0 01-.2-.582l2.634-6.156-.552-4.367a.46.46 0 01.057-.286l3.634-6.337a.454.454 0 01.71-.1c.01.01.384.387.67 1.074.045-.384.23-.743.527-1.002a1.528 1.528 0 011.385-.328c1.76.455 4.708 1.218 5.175 6.153.386 4.072-3.63 9.064-4.15 9.692l-2.31 5.813a.456.456 0 01-.423.287z"
          fill="#2E2E76"
        />
        <path
          d="M40.727 113.98l-4.182-26.707 10.375 1.982a6.01 6.01 0 006.188-2.672L68.85 60.858s-4.123.075-6.85-2.12c-3.327-2.677-3.41-5.604-3.41-5.604L45.272 72.64l-14.046-7.91a17.703 17.703 0 00-8.028-2.268l-.975-.036-7.697.146c-2.159.041-4.26.704-6.054 1.91-2.007 1.35-4.481 3.495-6.219 6.621L0 113.979s4.364-6.036 18.91-5.304c13.457.677 21.817 5.304 21.817 5.304"
          fill="#89EFD7"
        />
        <path
          d="M2.613 71.242a.459.459 0 01-.397-.68c1.977-3.58 6.406-6.713 6.594-6.845a.453.453 0 01.633.114.46.46 0 01-.113.637c-.044.03-4.446 3.145-6.32 6.539a.454.454 0 01-.397.235"
          fill="#2E2E76"
        />
        <path d="M38.34 24.294s2.772-1.68 4.114-1.428" fill="#FFF" />
        <path
          d="M38.34 24.752a.459.459 0 01-.235-.849c.299-.181 2.962-1.762 4.432-1.487a.457.457 0 01-.167.9c-.851-.16-2.78.755-3.796 1.37a.453.453 0 01-.234.066"
          fill="#2E2E76"
        />
        <path d="M36.878 23.216s1.007-1.073 2.745-1.419" fill="#FFF" />
        <path
          d="M36.878 23.673a.46.46 0 01-.33-.771c.045-.048 1.128-1.184 2.986-1.553a.457.457 0 01.176.897c-1.576.313-2.493 1.275-2.502 1.284a.452.452 0 01-.33.143"
          fill="#2E2E76"
        />
        <path d="M68.057 44.978s.195-2.157-1.103-4.4" fill="#FFF" />
        <path
          d="M68.058 45.435c-.014 0-.028 0-.042-.002a.456.456 0 01-.412-.496c.002-.026.159-2.052-1.043-4.13a.459.459 0 01.164-.625.453.453 0 01.621.165c1.356 2.343 1.172 4.578 1.164 4.672a.456.456 0 01-.452.416"
          fill="#2E2E76"
        />
        <path d="M70.006 45.762s.195-1.96-.78-4.117" fill="#FFF" />
        <path
          d="M70.006 46.22l-.045-.003a.456.456 0 01-.408-.5c.003-.023.166-1.876-.74-3.883a.458.458 0 01.225-.605.454.454 0 01.602.227c1.014 2.243.826 4.266.818 4.351a.456.456 0 01-.452.412M40.728 88.53a.466.466 0 01-.09-.01l-7.636-1.53a.457.457 0 01.178-.897l7.636 1.53a.457.457 0 01-.088.906"
          fill="#2E2E76"
        />
      </g>
    </svg>
  );
};

export default TemplateLibIllustration;
