import axios, { AxiosError } from 'axios';

import { dispatchUnauthorizedEvent } from 'contexts/auth';
import { removeQueryParamsEmptyKeys } from 'util/general';

axios.interceptors.request.use((config) => {
  // If we have request parameters, we want to strip out any empty ones.
  if (config.params) {
    config.params = removeQueryParamsEmptyKeys(config.params);
  }

  // "randomUUID" is not supported in all older browsers.
  // See https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID#browser_compatibility
  config.headers['Request-Id'] = window.crypto.randomUUID?.();

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof AxiosError) {
      // If we ever get back an Unauthorized API response code, we should log the user out.
      if (error.response?.status === 401) {
        dispatchUnauthorizedEvent();
      }
    }

    return Promise.reject(error);
  },
);
