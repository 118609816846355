import { NestedErrors } from 'types/internal';

import Alert from '../Alert';

const FormErrorsAlert = ({
  actionWord = 'saving',
  errors,
}: {
  actionWord?: string;
  errors: NestedErrors;
}) => {
  return (
    <Alert
      supportingText={
        <ul className="pl-4 list-disc">
          {errors.map((error) => {
            if (typeof error === 'string') {
              return <li key={error}>{error}</li>;
            }

            return (
              <li key={error.title}>
                {error.title}
                <ul className="pl-4 list-disc">
                  {error.messages.map((message) => {
                    return <li key={message}>{message}</li>;
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      }
    >
      You must fix the following errors before {actionWord}:
    </Alert>
  );
};

export default FormErrorsAlert;
