import UnauthenticatedLayout from 'components/layout/UnauthenticatedLayout';

export const PulleyRedirectSuccess = (): JSX.Element => {
  return (
    <UnauthenticatedLayout
      introText="Thanks for taking part in our partnered campaign! You will be redirected
    shortly."
    />
  );
};

export const PulleyRedirectFailure = (): JSX.Element => {
  return (
    <UnauthenticatedLayout introText="We weren't able to match you up with a partnered campaign at the moment, but please try back again later for another chance to get more points!" />
  );
};
