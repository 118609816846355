import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { getJwtFromHeaders } from '../../util/auth';
import { showErrorMessage } from '../../util/notifications';
import { useAuth } from '../../contexts/auth';
import { useLogin } from 'hooks/backend/users';

import ButtonLoading from '../common/forms/ButtonLoading';
import Card from '../common/Card';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import Input from 'components/common/forms/Input';
import UnauthenticatedLayout from '../layout/UnauthenticatedLayout';

interface LoginFormData {
  email: string;
  password: string;
}

const LoginSchema = object().shape({
  email: string()
    .email('Please enter a valid email.')
    .required('Please enter your email.'),
  password: string()
    .required('Please enter your password.')
    .min(6, 'Your password is too short.')
    .max(50, 'Your password is too long.'),
});

const LoginPage = (): JSX.Element => {
  const { isLoggedIn, onAuthChanged } = useAuth();

  const { isPending, mutate: login } = useLogin({
    onError: () => {
      showErrorMessage('Error authenticating your email and password.');
      onAuthChanged({ jwt: null });
    },
    onSuccess: (data) => {
      onAuthChanged({ jwt: getJwtFromHeaders(data.headers) });
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LoginFormData>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<LoginFormData> = (data) => {
    return login({ data });
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard/launched" />;
  }

  return (
    <UnauthenticatedLayout introText="Glad you're here! Please enter your info below to login.">
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <FormGroup>
              <FormLabel labelFor="email">Email</FormLabel>
              <Input {...register('email')} id="email" size="lg" type="email" />
              {errors.email?.message && (
                <FormFieldError error={errors.email.message} />
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel labelFor="password">Password</FormLabel>
              <Input
                {...register('password')}
                id="password"
                size="lg"
                type="password"
              />
              {errors.password?.message && (
                <FormFieldError error={errors.password.message} />
              )}
            </FormGroup>
          </div>
          <div className="flex justify-end mt-6">
            <ButtonLoading
              grow
              hierarchy="primary"
              isLoading={isPending}
              size="lg"
              type="submit"
            >
              Login
            </ButtonLoading>
          </div>
          <div className="mt-2 text-center text-primary-d-600">
            <Link className="text-sm hover:underline" to="/forgotpassword">
              Forgot password?
            </Link>
          </div>
        </form>
      </Card>
    </UnauthenticatedLayout>
  );
};

export default LoginPage;
