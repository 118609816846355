import axios from 'axios';

import { getAPIUrl } from './baseAPI';

interface CreateInvoiceBody {
  cost: string;
  purchaseOrder: string;
  surveyId: number;
}

export const CREATE_INVOICE = () => ({
  path: '/invoices',
  version: 'v1' as const,
});

export async function createInvoice({ data }: { data: CreateInvoiceBody }) {
  return (await axios.post(getAPIUrl(CREATE_INVOICE()), data)).data;
}
