import { queryOptions, useMutation } from '@tanstack/react-query';

import {
  cloneQuestionBlock,
  createQuestionBlock,
  deleteQuestionBlock,
  fetchQuestionBlocks,
  updateQuestionBlock,
} from 'services/backend/questionBlocks';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const questionBlockQueries = {
  list: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchQuestionBlocks({ surveyId: opts.surveyId }),
      queryKey: ['surveys', opts.surveyId, 'question-blocks'],
    }),
};

export function useCloneQuestionBlock(
  opts: ReactQueryFunctionCallbacks<typeof cloneQuestionBlock>,
) {
  return useMutation({ ...opts, mutationFn: cloneQuestionBlock });
}

export function useDeleteQuestionBlock(
  opts: ReactQueryFunctionCallbacks<typeof deleteQuestionBlock>,
) {
  return useMutation({ ...opts, mutationFn: deleteQuestionBlock });
}

export function useSaveQuestionBlock(
  opts?: ReactQueryFunctionCallbacks<
    typeof createQuestionBlock | typeof updateQuestionBlock
  >,
) {
  return useMutation({
    ...opts,
    mutationFn: async (
      data: Parameters<
        typeof createQuestionBlock | typeof updateQuestionBlock
      >[0],
    ) => {
      return 'blockId' in data
        ? updateQuestionBlock(data)
        : createQuestionBlock(data);
    },
  });
}
