import { Link, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useResetPassword } from 'hooks/backend/users';

import ButtonLoading from '../common/forms/ButtonLoading';
import Card from '../common/Card';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import Input from 'components/common/forms/Input';
import UnauthenticatedLayout from '../layout/UnauthenticatedLayout';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPasswordSchema = object().shape({
  email: string()
    .required('Please enter your email.')
    .email('Please enter a valid email.'),
});

const ForgotPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { isPending, mutate: resetPassword } = useResetPassword({
    onError: () => {
      showErrorMessage('Error resetting password.');
    },
    onSuccess: () => {
      showSuccessMessage(
        'An email with instructions to reset your password has successfully been sent.',
      );
      navigate('/login');
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ForgotPasswordFormData>({
    defaultValues: { email: '' },
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = (data) => {
    return resetPassword({ email: data.email });
  };

  return (
    <UnauthenticatedLayout introText="Enter your email below to reset your password.">
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <FormGroup>
              <FormLabel labelFor="email">Email</FormLabel>
              <Input {...register('email')} id="email" size="lg" type="email" />
              {errors.email?.message && (
                <FormFieldError error={errors.email.message} />
              )}
            </FormGroup>
          </div>
          <div className="flex justify-end mt-6">
            <ButtonLoading
              grow
              hierarchy="primary"
              isLoading={isPending}
              size="lg"
              type="submit"
            >
              Reset Password
            </ButtonLoading>
          </div>
          <div className="mt-2 text-center text-primary-d-600">
            <Link className="text-sm hover:underline" to="/login">
              Login
            </Link>
          </div>
        </form>
      </Card>
    </UnauthenticatedLayout>
  );
};

export default ForgotPasswordPage;
