import { IncidenceTypeWithAverage } from '../types/domainModels';

export const INCIDENCE_TYPES: IncidenceTypeWithAverage[] = [
  {
    average: 87.5,
    id: 1,
    lucidEstimate: '1.0',
    name: '100-75%',
  },
  {
    average: 62,
    id: 8,
    lucidEstimate: '0.7',
    name: '74-50%',
  },
  {
    average: 39.5,
    id: 2,
    lucidEstimate: '0.5',
    name: '49-30%',
  },
  {
    average: 24.5,
    id: 3,
    lucidEstimate: '0.3',
    name: '29-20%',
  },
  {
    average: 14.5,
    id: 4,
    lucidEstimate: '0.2',
    name: '19-10%',
  },
  {
    average: 7,
    id: 5,
    lucidEstimate: '0.1',
    name: '9-5%',
  },
  {
    average: 3.5,
    id: 6,
    lucidEstimate: '0.05',
    name: '4-3%',
  },
  {
    average: 1.5,
    id: 7,
    lucidEstimate: '0.02',
    name: '2-1%',
  },
];
