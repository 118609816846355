export function downloadFile({
  contentType,
  data,
  filename,
}: {
  contentType: string;
  data: BlobPart;
  filename: string;
}) {
  const blob = new Blob([data], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
