import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useState } from 'react';

import {
  addTagToSurvey,
  createTag,
  fetchTags,
  removeTagFromSurvey,
} from 'services/backend/tags';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const tagQueries = {
  all: ['tags'],
  list: (opts: { nameFilter?: string; organizationId?: number } = {}) =>
    queryOptions({
      queryFn: () =>
        fetchTags({
          nameFilter: opts.nameFilter,
          organizationId: opts.organizationId,
        }),
      queryKey: [
        ...tagQueries.all,
        { nameFilter: opts.nameFilter, organizationId: opts.organizationId },
      ],
      staleTime: minutesToMilliseconds(1),
    }),
};

export function useAddTagToSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof addTagToSurvey>,
) {
  return useMutation({ ...opts, mutationFn: addTagToSurvey });
}

export function useCreateTag(
  opts?: ReactQueryFunctionCallbacks<typeof createTag>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createTag,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: tagQueries.all });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useCreateAndAssociateTag(
  opts?: ReactQueryFunctionCallbacks<typeof createTag>,
) {
  const { mutateAsync: addTagToSurvey } = useAddTagToSurvey();
  const { mutateAsync: createTag } = useCreateTag();

  return useMutation({
    ...opts,
    mutationFn: async ({
      data,
      surveyId,
    }: Parameters<typeof createTag>[0] & { surveyId: number }) => {
      const newTag = await createTag({ data });

      await addTagToSurvey({ data: { projectId: newTag.id }, surveyId });

      return newTag;
    },
  });
}

export function useRemoveTagToSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof removeTagFromSurvey>,
) {
  return useMutation({ ...opts, mutationFn: removeTagFromSurvey });
}

export function useTags({
  organizationId,
}: {
  organizationId: number | undefined;
}) {
  const queryClient = useQueryClient();

  const [nameFilter, setNameFilter] = useState('');

  const {
    data,
    error: loadTagsError,
    isError: hasLoadTagsError,
    isLoading: isLoadingTags,
  } = useQuery({
    ...tagQueries.list({ nameFilter, organizationId }),
    enabled: !!organizationId,
  });
  const tags = data?.results ?? [];

  return {
    changeTagFilter: setNameFilter,
    hasLoadTagsError,
    isLoadingTags,
    loadTagsError,
    onTagCreated: async () => {
      await queryClient.invalidateQueries(
        tagQueries.list({ nameFilter, organizationId }),
      );

      setNameFilter('');
    },
    tagFilter: nameFilter,
    tags,
  };
}
