import { ChangeEvent, ReactNode } from 'react';
import { useField } from 'formik';

import Checkbox, { Props as CheckboxProps } from './Checkbox';
import CheckboxWithTooltip from './CheckboxWithTooltip';
import FormGroup from './FormGroup';

const FormCheckbox = ({
  checkboxLabel,
  label,
  labelFor,
  onChange,
  radio,
  tooltip,
  ...props
}: Omit<CheckboxProps, 'checked' | 'label' | 'onChange'> & {
  checkboxLabel: ReactNode;
  label?: string;
  labelFor?: string;
  onChange?: (value: boolean) => void;
  radio?: boolean;
  tooltip?: ReactNode;
}): JSX.Element => {
  const [{ value, ...rest }, meta] = useField<boolean>({
    name: props.name,
    type: 'checkbox',
  });

  const commonProps = {
    ...rest,
    ...props,
    checked: value,
    label: checkboxLabel,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      rest.onChange(event);
      onChange?.(event.currentTarget.checked);
    },
  };

  return (
    <FormGroup
      error={meta.touched && meta.error ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      {tooltip ? (
        <CheckboxWithTooltip {...commonProps} tooltip={tooltip} />
      ) : (
        <Checkbox radio={radio} {...commonProps} />
      )}
    </FormGroup>
  );
};

export default FormCheckbox;
