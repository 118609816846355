import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeStatements = (): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel="Randomize Statements"
        name="features.randomizeStatements"
      />
    </>
  );
};

export default RandomizeStatements;
