import BorderedButton from './BorderedButton';
import Icon from './Icon';

const VerticalDotsButton = ({ ariaLabel }: { ariaLabel: string }) => {
  return (
    <BorderedButton aria-label={ariaLabel} type="button">
      <div aria-hidden="true" className="w-4 h-4">
        <Icon id="dots-vertical" />
      </div>
    </BorderedButton>
  );
};

export default VerticalDotsButton;
