import { ComponentProps } from 'react';
import { useField } from 'formik';

import Textarea from './Textarea';

const TextareaFormik = ({
  name,
  ...rest
}: {
  name: string;
} & Omit<
  ComponentProps<typeof Textarea>,
  'onChange' | 'value'
>): JSX.Element => {
  const [field] = useField({ name });

  return <Textarea {...field} {...rest} />;
};

export default TextareaFormik;
