import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { TeamMember } from '../../types/domainModels';
import { useAuth } from 'contexts/auth';
import {
  useInviteTeamMember,
  useOrderedTeamMembers,
  useRemoveTeamMember,
} from 'hooks/backend/team';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import DefaultLayout from '../layout/DefaultLayout';
import ErrorDisplay from '../common/ErrorDisplay';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import Icon from 'components/common/Icon';
import IndexCard from 'components/common/IndexCard';
import Input from 'components/common/forms/Input';
import Modal, { ModalHeader } from 'components/common/Modal';
import UserBubble from '../common/UserBubble';

const TeamPage = () => {
  const [isInviting, setIsInviting] = useState(false);

  const {
    hasLoadTeamMembersError,
    isLoadingTeamMembers,
    loadTeamMembersError,
    orderedTeamMembers,
  } = useOrderedTeamMembers();

  return (
    <DefaultLayout>
      <div className="min-h-full pt-6 pb-32 px-8 bg-gray-50">
        <div className="flex items-center justify-between mb-10">
          <span className="text-xl text-primary-d-600 font-medium">
            Your Team
          </span>

          <Button
            hierarchy="primary"
            icon={<Icon id="plus" />}
            iconPlacement="leading"
            onClick={() => {
              setIsInviting(true);
            }}
            size="md"
            type="button"
          >
            Invite Team Member
          </Button>
        </div>
        <IndexCard>
          {isLoadingTeamMembers && <TeamMemberLoadingRows />}
          {hasLoadTeamMembersError && loadTeamMembersError instanceof Error && (
            <ErrorDisplay
              message={`Failed to load team members. (${loadTeamMembersError.message})`}
            />
          )}
          {orderedTeamMembers.map((teamMember) => {
            return (
              <div key={teamMember.id} className="border-b border-light-grey">
                <TeamMemberRow user={teamMember} />
              </div>
            );
          })}
        </IndexCard>
      </div>

      {isInviting && (
        <TeamMemberInvite
          onCloseModal={() => {
            setIsInviting(false);
          }}
          onMemberInvited={() => {
            setIsInviting(false);
          }}
        />
      )}
    </DefaultLayout>
  );
};

export default TeamPage;

const TeamMemberLoadingRows = () => {
  const loadingArr = [1, 2, 3, 4];

  return loadingArr.map((num) => {
    return (
      <div
        key={num}
        className="p-4 border-b border-light-grey flex justify-between items-center animate-pulse"
      >
        <div className="flex items-center space-x-2 grow">
          <div className="w-8 h-8 rounded-full bg-light-grey" />
          <div className="flex-grow space-y-2">
            <div className="w-1/4 h-4 bg-light-grey" />
            <div className="w-1/6 h-4 bg-light-grey" />
          </div>
        </div>
        <div className="w-32 h-6 bg-light-grey" />
      </div>
    );
  });
};

const TeamMemberRow = ({ user }: { user: TeamMember }): JSX.Element => {
  const { data: authData } = useAuth();
  const organizationId = authData?.organizationId;

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center space-x-2">
        <UserBubble user={user} />
        <div>
          <div>
            {user.firstName} {user.lastName}
          </div>
          <div className="text-sm">{user.email}</div>
        </div>
      </div>

      {organizationId && (
        <>
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="trash" />}
            iconPlacement="leading"
            onClick={() => {
              setIsDeleteOpen(true);
            }}
            size="sm"
            type="button"
          >
            Remove
          </Button>

          {isDeleteOpen && (
            <TeamMemberDelete
              onCloseModal={() => {
                setIsDeleteOpen(false);
              }}
              organizationId={organizationId}
              teamMember={user}
            />
          )}
        </>
      )}
    </div>
  );
};

const InviteSchema = z.object({
  email: z
    .string()
    .min(1, 'Please enter an email.')
    .email('Please enter a valid email.'),
  firstName: z.string().min(1, 'Please enter a first name.'),
  lastName: z.string().min(1, 'Please enter a last name.'),
});
type InviteFormData = z.infer<typeof InviteSchema>;

const TeamMemberInvite = ({
  onCloseModal,
  onMemberInvited,
}: {
  onCloseModal(): void;
  onMemberInvited(): void;
}): JSX.Element => {
  const { isPending: isInviting, mutate: inviteTeamMember } =
    useInviteTeamMember({
      onError: (err) => {
        showErrorMessage(
          `There was an error inviting your team member. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('Team member invited successfully.');
        onMemberInvited();
      },
    });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<InviteFormData>({
    defaultValues: { email: '', firstName: '', lastName: '' },
    resolver: zodResolver(InviteSchema),
  });

  const onSubmit: SubmitHandler<InviteFormData> = (data) => {
    return inviteTeamMember({ data });
  };

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Invite Team Member
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="mb-4 text-sm">
          Use the form below to invite a co-worker to your Glass organization.
        </p>
        <div className="space-y-4">
          <FormGroup>
            <FormLabel labelFor="firstName">First Name</FormLabel>
            <Input
              {...register('firstName')}
              id="firstName"
              size="lg"
              type="text"
            />
            {errors.firstName?.message && (
              <FormFieldError error={errors.firstName.message} />
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel labelFor="lastName">Last Name</FormLabel>
            <Input
              {...register('lastName')}
              id="lastName"
              size="lg"
              type="text"
            />
            {errors.lastName?.message && (
              <FormFieldError error={errors.lastName.message} />
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel labelFor="email">Email</FormLabel>
            <Input {...register('email')} id="email" size="lg" type="email" />
            {errors.email?.message && (
              <FormFieldError error={errors.email.message} />
            )}
          </FormGroup>
        </div>

        <div className="mt-8 flex gap-3 flex-row-reverse">
          <ButtonLoading
            grow
            hierarchy="primary"
            isLoading={isInviting}
            size="lg"
            type="submit"
          >
            Invite
          </ButtonLoading>
          <Button
            grow
            hierarchy="secondary-gray"
            onClick={onCloseModal}
            size="lg"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const TeamMemberDelete = ({
  onCloseModal,
  organizationId,
  teamMember,
}: {
  onCloseModal(): void;
  organizationId: number;
  teamMember: TeamMember;
}): JSX.Element => {
  const { isPending: isRemoving, mutate: removeTeamMember } =
    useRemoveTeamMember({
      onError: (err) => {
        showErrorMessage(
          `There was an error removing your team member. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('Team member removed successfully.');
      },
    });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Remove Team Member
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <p className="mb-4">
        Are you sure you want to remove{' '}
        <span className="font-bold">
          {teamMember.firstName} {teamMember.lastName}
        </span>{' '}
        from the team?
      </p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="destructive"
          isLoading={isRemoving}
          onClick={() => {
            removeTeamMember({ organizationId, userId: teamMember.id });
          }}
          size="lg"
          type="button"
        >
          Remove
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
