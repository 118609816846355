import { useField } from 'formik';

import { isOptionPopulated } from '../../../util/questions';
import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';

function getOptionsWithRequireView({
  options,
  requireView,
}: {
  options: QuestionFormData['options'];
  requireView:
    | ((option: QuestionFormData['options'][number]) => boolean)
    | boolean;
}) {
  return options.map((option) => {
    return {
      ...option,
      features: {
        ...option.features,
        requireView:
          typeof requireView === 'function' ? requireView(option) : requireView,
      },
    };
  });
}

const ViewAllImages = (): JSX.Element | null => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: options }, , optionsHelpers] =
    useField<QuestionFormData['options']>('options');

  if (optionType?.value !== 'image') {
    return null;
  }

  return (
    <>
      <FormCheckbox
        checkboxLabel="Require Viewing of Images"
        name="features.viewAllImages"
        onChange={(value) => {
          if (value) {
            optionsHelpers.setValue(
              getOptionsWithRequireView({
                options,
                requireView: (option) => isOptionPopulated(option),
              }),
            );
          } else {
            optionsHelpers.setValue(
              getOptionsWithRequireView({
                options,
                requireView: false,
              }),
            );
          }
        }}
        tooltip="By default, respondents do not have to view uploaded option images. Enable this feature to require the respondent to view each image before making a selection. After enabling this feature you can disable a required view on a per-option basis by using the cog icon for each option."
      />
    </>
  );
};

export default ViewAllImages;
