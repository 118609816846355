import { FieldArray } from 'formik';
import { isNull, isUndefined } from 'lodash-es';

import { apiOptionToFormOption } from '../../util/questions';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { QuestionFormOption } from '../../types/forms';

import AddButton from 'components/common/forms/AddButton';
import Button from '../common/forms/Button';
import FileUploadWithCrop from '../common/forms/FileUploadWithCrop';
import FormGroup from '../common/forms/FormGroup';
import QuestionConcept from './QuestionConcept';

const ConceptField = ({
  concepts,
  question,
  questions,
  survey,
  variables,
  disabled = false,
  lowerLimit,
  upperLimit,
}: {
  concepts: QuestionFormOption[];
  question: Question | undefined;
  questions: Question[];
  survey: Survey;
  variables?: SurveyVariable[];
  disabled?: boolean;
  lowerLimit?: number;
  upperLimit?: number;
}): JSX.Element => {
  if (disabled) {
    return (
      <div className="flex">
        <Button disabled={disabled} grow hierarchy="secondary-gray" size="md">
          Upload
        </Button>
      </div>
    );
  }

  return (
    <FormGroup
      tooltip={
        <div>
          <p>
            This optional concept will be displayed to the survey respondent
            above the question options.
          </p>
          {disabled && (
            <p className="mt-4 font-bold">
              Disabled: Question pipes concept from previous question.
            </p>
          )}
        </div>
      }
    >
      <FieldArray
        name="concepts"
        render={(arrayHelpers) => {
          return (
            <>
              {concepts.map((_option, index) => {
                return (
                  <QuestionConcept
                    key={index}
                    index={index}
                    onClickRemove={() => {
                      if (!lowerLimit || concepts.length > lowerLimit) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.replace(
                          index,
                          apiOptionToFormOption({ survey }),
                        );
                      }
                    }}
                    question={question}
                    questions={questions}
                    survey={survey}
                    variables={variables ?? []}
                  />
                );
              })}

              {(isUndefined(upperLimit) ||
                isNull(upperLimit) ||
                concepts.length < upperLimit) && (
                <>
                  {concepts.length === 0 ? (
                    <FileUploadWithCrop
                      onDone={(image) => {
                        const newConcept = apiOptionToFormOption({ survey });
                        newConcept.image = image;

                        arrayHelpers.push(newConcept);
                      }}
                    >
                      <a className="flex items-center justify-center border border-green text-green bg-white cursor-pointer font-normal px-3 py-1 rounded h-11">
                        <span className="select-none text-sm">
                          Upload New Concept
                        </span>
                      </a>
                    </FileUploadWithCrop>
                  ) : (
                    <div className="flex mt-2">
                      <AddButton
                        label="Add Concept"
                        onClick={() => {
                          arrayHelpers.push(apiOptionToFormOption({ survey }));
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          );
        }}
      />
    </FormGroup>
  );
};

export default ConceptField;
