const Logo = (): JSX.Element => {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 109 40"
      width="109"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.2059 29.1308C56.3906 29.1308 58.3443 28.1801 59.1155 26.8687V28.9252H62.3545V19.337H53.7171V22.0873H59.1155C58.9359 24.4776 57.1104 26.1489 54.3855 26.1489C51.0951 26.1489 48.8843 23.7325 48.8843 19.9279C48.8843 16.1234 51.069 13.707 54.308 13.707C56.7244 13.707 58.3436 15.0437 58.6521 17.1002H62.1735C61.7622 13.2957 58.8063 10.725 54.3073 10.725C48.9604 10.725 45.3615 14.3753 45.3615 19.9279C45.3615 25.8658 49.3717 29.1308 54.2045 29.1308H54.2059Z"
        fill="#00372D"
      />
      <path
        d="M68.3214 10.7258H64.9281V28.9259H68.3214V10.7258Z"
        fill="#00372D"
      />
      <path
        d="M74.0014 24.8896C74.0014 23.7846 74.8493 23.0387 76.6748 22.8331L79.811 22.4993C79.811 25.0185 78.2947 26.5095 76.1093 26.5095C74.85 26.5095 74.0014 25.8411 74.0014 24.8903V24.8896ZM75.5177 29.1308C77.4453 29.1308 79.1419 28.3082 79.8103 27.0489V28.9252H83.2035V20.0821C83.2035 16.4832 80.5815 14.4781 76.9058 14.4781C73.4872 14.4781 70.9419 16.6628 70.582 19.6448H73.8725C74.052 18.051 75.2345 17.1255 76.9058 17.1255C78.5771 17.1255 79.8103 18.0763 79.8103 19.6962V19.9786L76.6227 20.3385C72.4329 20.7751 70.582 22.3689 70.582 25.017C70.582 27.5102 72.4582 29.1301 75.5177 29.1301V29.1308Z"
        fill="#00372D"
      />
      <path
        d="M90.8395 29.1308C94.0785 29.1308 96.3146 27.3575 96.3146 24.6579C96.3146 18.9511 88.4745 21.0076 88.4745 18.4116C88.4745 17.589 89.2457 17.0749 90.3507 17.0749C91.7389 17.0749 92.6382 17.7686 92.7925 18.9257H96.0829C95.8519 16.1755 93.6926 14.4789 90.3247 14.4789C87.2399 14.4789 85.2609 16.2523 85.2609 18.5658C85.2609 23.81 93.0242 21.9844 93.0242 24.7354C93.0242 25.7376 92.1762 26.3806 90.8395 26.3806C89.2971 26.3806 88.2689 25.5841 88.166 24.2473H84.8756C85.0298 27.2032 87.3688 29.1316 90.8395 29.1316V29.1308Z"
        fill="#00372D"
      />
      <path
        d="M103.511 29.1308C106.75 29.1308 108.986 27.3575 108.986 24.6579C108.986 18.9511 101.146 21.0076 101.146 18.4116C101.146 17.589 101.917 17.0749 103.022 17.0749C104.41 17.0749 105.31 17.7686 105.464 18.9257H108.754C108.523 16.1755 106.364 14.4789 102.996 14.4789C99.9113 14.4789 97.9322 16.2523 97.9322 18.5658C97.9322 23.81 105.696 21.9844 105.696 24.7354C105.696 25.7376 104.848 26.3806 103.511 26.3806C101.969 26.3806 100.94 25.5841 100.837 24.2473H97.547C97.7012 27.2032 100.04 29.1316 103.511 29.1316V29.1308Z"
        fill="#00372D"
      />
      <path
        d="M25.8288 28.7985L28.482 36.913C29.9483 36.1599 31.3198 35.2186 32.5595 34.0998L30.0149 26.3191C28.8824 27.4864 27.445 28.3553 25.828 28.7985H25.8295H25.8288ZM32.0874 16.8743L38.5763 16.8707C38.3366 15.4413 37.9361 14.048 37.3822 12.7164C37.3011 12.5223 37.2171 12.3311 37.1302 12.1407L28.2973 12.1458C30.041 13.2428 31.3908 14.9047 32.0874 16.8736V16.875V16.8743ZM14.1196 19.9287C14.1196 14.846 18.2587 10.725 23.366 10.725C24.4616 10.725 25.5116 10.9155 26.4855 11.2631L33.1395 6.44544C31.9454 5.27453 30.6138 4.27451 29.1807 3.46204L19.6179 10.3847L22.6144 1.18974C21.6614 1.04419 20.6933 0.969604 19.7179 0.969604C19.0256 0.969604 18.3362 1.00871 17.6541 1.08184L14.0103 12.2645L11.0008 3.06378C9.53375 3.81252 8.16008 4.75026 6.91893 5.86252L10.583 17.0655L2.68351 11.3601C2.45542 11.8047 2.24542 12.2573 2.05353 12.7178C1.61181 13.7787 1.26857 14.8793 1.02599 16.0061L10.6453 22.9541L0.833374 22.9591C1.06871 24.4479 1.48002 25.8991 2.05497 27.2822C2.11218 27.4183 2.17011 27.553 2.22949 27.6877L14.1725 27.6819L6.19698 33.4546C7.37875 34.632 8.701 35.6386 10.1246 36.459L19.8178 29.4422L16.7678 38.8008C17.7374 38.9507 18.7229 39.0304 19.7179 39.0304C20.3913 39.0304 21.0604 38.9935 21.7244 38.9239L24.962 28.9911C24.4428 29.0809 23.9105 29.1309 23.3653 29.1309C18.2595 29.1309 14.1189 25.0113 14.1189 19.9272L14.1175 19.9287H14.1196ZM38.443 23.8476C38.1983 25.0265 37.8442 26.1757 37.3836 27.2836C37.2098 27.7007 37.0201 28.1127 36.8166 28.5168L31.3495 24.5674C32.1504 23.2053 32.6109 21.6209 32.6109 19.9287C32.6109 19.828 32.6058 19.7288 32.6037 19.6296L38.443 23.8476Z"
        fill="#FF6700"
      />
    </svg>
  );
};

export default Logo;
