import { AnchorHTMLAttributes, ReactNode } from 'react';

const Hyperlink = ({
  children,
  ...rest
}: {
  children: ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement>): JSX.Element => {
  const linkProps: Partial<AnchorHTMLAttributes<HTMLAnchorElement>> = rest.href
    ? {
        rel: 'noopener',
        target: '_blank',
      }
    : {};

  return (
    <a
      className="text-green cursor-pointer hover:underline"
      {...linkProps}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Hyperlink;
