/**
 * Note: The provided percentage is expected to be a number between 0 and 1.
 */
const ProgressBar = ({ percentage }: { percentage: number }): JSX.Element => {
  return (
    <div className="rounded-full relative bg-gray-300 w-32 h-2 mr-2">
      <div
        className="rounded-full relative h-2 bg-green max-w-full"
        style={{ width: `${percentage * 100}%` }}
      />
    </div>
  );
};

export default ProgressBar;
