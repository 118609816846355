import Badge from './Badge';
import Icon from './Icon';

const CompleteBadge = () => {
  return (
    <Badge
      color="primary"
      icon={<Icon id="check" />}
      iconPlacement="leading"
      size="md"
    >
      Complete
    </Badge>
  );
};

export default CompleteBadge;
