import axios from 'axios';

import { getAPIUrl } from './baseAPI';

interface CreateChargeBody {
  amount: string;
  campaignId: number;
  cardEmail: string;
  cardName: string;
  cardPhone: string;
  stripeToken: string;
  termsAccepted: boolean;
}

export const CREATE_CHARGE = () => ({
  path: '/billing/charge',
  version: 'v1' as const,
});

export async function createCharge({ data }: { data: CreateChargeBody }) {
  return (
    await axios.post<{
      history: {
        createdAt: string;
        ocAmount: string;
        ocHistory: string;
      }[];
      summary: string;
    }>(getAPIUrl(CREATE_CHARGE()), data)
  ).data;
}
