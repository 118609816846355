import {
  clamp,
  forEach,
  isArray,
  isPlainObject,
  size,
  startCase,
} from 'lodash-es';
import { FieldHelperProps, FormikErrors, FormikTouched } from 'formik';

export function clampNumberField({
  formHelpers,
  lowerBound,
  upperBound,
  value,
}: {
  formHelpers: FieldHelperProps<number | ''>;
  lowerBound: number;
  upperBound: number;
  value: string;
}): void {
  if (value) {
    formHelpers.setValue(clamp(Number(value), lowerBound, upperBound));
  } else {
    formHelpers.setValue('');
  }
}

export function errorsToTouched(
  errors: FormikErrors<unknown>,
): FormikTouched<unknown> {
  if (isArray(errors)) {
    return errors.map((currentErrors) => {
      return errorsToTouched(currentErrors);
    });
  }

  const touched: FormikTouched<unknown> = {};
  forEach(errors, (value, key) => {
    if (isArray(value) || isPlainObject(value)) {
      // TODO: Address compilation error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      touched[key] = errorsToTouched(value);
    } else {
      // TODO: Address compilation error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      touched[key] = true;
    }
  });

  return touched;
}

export function getNestedErrorMessages<FormData>(
  errors: FormikErrors<FormData>,
) {
  const errorMessages: ({ messages: string[]; title: string } | string)[] = [];

  forEach(errors, (value, key) => {
    if (typeof value === 'string') {
      if (value) {
        errorMessages.push(value);
      }
    } else if (isArray(value) && value.length > 0) {
      errorMessages.push(startCase(key));
    } else if (isPlainObject(value) && size(value) > 0) {
      const messages: string[] = [];

      forEach(value, (subValue, subField) => {
        if (typeof subValue === 'string' && subValue) {
          messages.push(subValue);
        } else if (subValue) {
          messages.push(startCase(subField));
        }
      });

      errorMessages.push({ messages, title: startCase(key) });
    }
  });

  return errorMessages;
}
