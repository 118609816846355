import axios from 'axios';

import { Comment } from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';

interface CreateCommentBody {
  comment: string;
  mentionedUserIds?: [];
  parentId: number | null;
  questionId?: number;
  resolved: boolean;
  surveyId: number;
}

function getCommentApiPath(appliesToSurvey: boolean) {
  return appliesToSurvey ? '/surveys/comment' : '/questions/comment';
}

export const CREATE_COMMENT = ({
  appliesToSurvey,
}: {
  appliesToSurvey: boolean;
}) => ({
  path: getCommentApiPath(appliesToSurvey),
  version: 'v1' as const,
});

export async function createComment({
  appliesToSurvey = false,
  data,
}: {
  appliesToSurvey?: boolean;
  data: CreateCommentBody;
}) {
  return (
    await axios.post<Comment[]>(
      getAPIUrl(CREATE_COMMENT({ appliesToSurvey })),
      data,
    )
  ).data;
}

export const DELETE_COMMENT = ({
  appliesToSurvey,
  commentId,
}: {
  appliesToSurvey: boolean;
  commentId: number;
}) => ({
  path: `${getCommentApiPath(appliesToSurvey)}/${commentId}`,
  version: 'v1' as const,
});

export async function deleteComment({
  appliesToSurvey,
  commentId,
}: {
  appliesToSurvey: boolean;
  commentId: number;
}) {
  return (
    await axios.delete(
      getAPIUrl(DELETE_COMMENT({ appliesToSurvey, commentId })),
    )
  ).data;
}

export const FETCH_QUESTION_COMMENTS = ({
  surveyId,
}: {
  surveyId: number;
}) => ({
  path: `/questions/comment/${surveyId}`,
  version: 'v1' as const,
});

export async function fetchQuestionComments({
  surveyId,
}: {
  surveyId: number;
}) {
  return (
    await axios.get<Comment[]>(getAPIUrl(FETCH_QUESTION_COMMENTS({ surveyId })))
  ).data;
}

export const FETCH_SURVEY_COMMENTS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/comment/${surveyId}`,
  version: 'v1' as const,
});

export async function fetchSurveyComments({ surveyId }: { surveyId: number }) {
  return (
    await axios.get<Comment[]>(getAPIUrl(FETCH_SURVEY_COMMENTS({ surveyId })))
  ).data;
}

export const UPDATE_COMMENT = ({
  appliesToSurvey,
  commentId,
}: {
  appliesToSurvey: boolean;
  commentId: number;
}) => ({
  path: `${getCommentApiPath(appliesToSurvey)}/${commentId}`,
  version: 'v1' as const,
});

export async function updateComment({
  appliesToSurvey,
  commentId,
  data,
}: {
  appliesToSurvey: boolean;
  commentId: number;
  data: Partial<{ comment: string; resolved: boolean }>;
}) {
  return (
    await axios.patch<Comment[]>(
      getAPIUrl(UPDATE_COMMENT({ appliesToSurvey, commentId })),
      data,
    )
  ).data;
}
