import { clsx } from 'clsx';
import { ReactNode } from 'react';

type BadgeColor = 'gray' | 'orange' | 'primary';
type BadgeSize = 'sm' | 'md' | 'lg';
type IconPlacement = 'leading' | 'trailing';

const COLOR_CLASS_MAP: Record<BadgeColor, string> = {
  gray: 'bg-gray-d-50 text-gray-d-700 border-gray-d-200',
  orange: 'bg-orange-d-50 text-orange-d-700 border-orange-d-200',
  primary: 'bg-primary-d-50 text-primary-d-700 border-primary-d-200',
};

const SIZE_MAP: Record<BadgeSize, string> = {
  sm: 'py-0.5 px-1.5 text-xs',
  md: 'py-0.5 px-2.5 text-sm',
  lg: 'py-1 px-3 text-sm',
};

const Badge = ({
  children,
  color,
  icon = undefined,
  iconPlacement = undefined,
  size,
}: {
  children: string;
  color: BadgeColor;
  icon?: ReactNode;
  iconPlacement?: IconPlacement;
  size: BadgeSize;
}) => {
  const iconToRender = icon ? (
    <div className="flex items-center justify-center w-3 h-3 shrink-0">
      {icon}
    </div>
  ) : null;

  return (
    <div
      className={clsx(
        'inline-flex items-center gap-1.5 rounded-md border',
        COLOR_CLASS_MAP[color],
        SIZE_MAP[size],
      )}
    >
      {iconPlacement === 'leading' && iconToRender}
      {children}
      {iconPlacement === 'trailing' && iconToRender}
    </div>
  );
};

export default Badge;
