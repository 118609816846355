import { ReactNode } from 'react';

const FixedHeaderAndCollapsedSidebar = ({
  children,
  header,
  sidebar,
}: {
  children: ReactNode;
  header: ReactNode;
  sidebar: ReactNode;
}): JSX.Element => {
  return (
    <div className="font-inter text-button text-gray-900 font-normal h-full">
      <div className="fixed top-0 left-0 bg-gray-50 w-main-sidebar-collapsed">
        {sidebar}
      </div>

      <div className="fixed top-0 left-0 right-0 h-16 xl:h-32 border-b border-gray-300 z-30 ml-main-sidebar-collapsed">
        {header}
      </div>

      <div className="ml-main-sidebar-collapsed pt-16 xl:pt-32 h-full">
        {children}
      </div>
    </div>
  );
};

export default FixedHeaderAndCollapsedSidebar;
