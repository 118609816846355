import { useEffect, useState } from 'react';

/**
 * Returns a boolean indicating if the loading cycle has started and finished. The returned boolean
 * will only be "true" for one render and then is reset back to "false" so it can be changed again.
 */
export function useHasProcessed(isLoading: boolean): boolean {
  const [hasProcessed, setHasProcessed] = useState(false);
  const [wasLoading, setWasLoading] = useState(false);

  useEffect(() => {
    if (isLoading && !wasLoading) {
      setWasLoading(true);
    } else if (!isLoading && wasLoading && !hasProcessed) {
      setHasProcessed(true);
    } else if (hasProcessed) {
      // We reset these flags since the hasProcessed flag should only be true for one render
      // so this effect can be triggered again.
      setHasProcessed(false);
      setWasLoading(false);
    }
  }, [hasProcessed, isLoading, wasLoading]);

  return hasProcessed;
}
