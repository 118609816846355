import { Role } from '../types/domainModels';
import { useAuth } from '../contexts/auth';

/**
 * Custom hook for determining if the user has the specified role.
 */
export function useHasRole(role: Role): boolean {
  const { data } = useAuth();

  return data?.role ? role === data?.role : false;
}
