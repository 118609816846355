const WordSeparator = ({ word }: { word?: string }): JSX.Element => {
  return (
    <div className="flex items-center my-4">
      {word && (
        <span className="mr-2 px-2 border border-dark-grey rounded text-dark-grey text-tiny uppercase">
          {word}
        </span>
      )}
      <div className="w-full h-px bg-light-grey" />
    </div>
  );
};

export default WordSeparator;
