import { useField } from 'formik';

import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';

/**
 * Required sum is a feature that allows the user to specify a value that the option values
 * must sum to when a respondent is answering the question. For example, if you have 5 scales
 * and you want the values of the scales to sum to 100, you could enable this feature and set
 * the value to 100.
 */
const RequiredSum = ({
  isMultChoice,
}: {
  isMultChoice?: boolean;
}): JSX.Element => {
  const [{ value: requiredSum }] = useField<
    QuestionFormData['features']['requiredSum']
  >('features.requiredSum.enabled');

  let tooltip =
    'This feature will require survey respondents to ensure the values they enter for the scales sum to a specified value.';

  if (isMultChoice) {
    tooltip +=
      ' On a multiplce choice question, this will enforce a constraint on all free text options that responses be whole numbers within 0 - 100.';
  }
  return (
    <div className="flex items-center">
      <FormCheckbox
        checkboxLabel="Require Sum for Scale Responses"
        name="features.requiredSum.enabled"
        tooltip={tooltip}
      />
      {requiredSum && (
        <div className="w-20 ml-2">
          <FormInput
            name="features.requiredSum.value"
            size="md"
            type="number"
          />
        </div>
      )}
    </div>
  );
};

export default RequiredSum;
