import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';

/**
 * Note: This component is absolutely positioned so there should be a relatively positioned parent.
 */
const InlineDeleteOverlay = ({
  isDeleting,
  onClickCancel,
  onClickDelete,
}: {
  isDeleting: boolean;
  onClickCancel(): void;
  onClickDelete(): void;
}): JSX.Element => {
  return (
    <div className="flex items-center justify-end absolute top-0 right-0 left-0 px-2 space-x-4 shadow-lg bg-white opacity-95 text-xs">
      <Button
        hierarchy="secondary-gray"
        onClick={onClickCancel}
        size="sm"
        type="button"
      >
        Cancel
      </Button>
      <ButtonLoading
        hierarchy="destructive"
        isLoading={isDeleting}
        onClick={onClickDelete}
        size="sm"
        type="button"
      >
        Delete
      </ButtonLoading>
    </div>
  );
};

export default InlineDeleteOverlay;
