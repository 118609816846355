import { clsx } from 'clsx';
import CreateableSelect from 'react-select/creatable';
import ReactSelect, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  Props,
  StylesConfig,
} from 'react-select';
import { useEffect, useRef, useState } from 'react';

import CircleLoader from '../CircleLoader';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
    /* eslint-enable @typescript-eslint/no-unused-vars */
  > {
    borderColor?: string;
    hasError?: boolean;
    height?: number;
    isCreatable?: boolean;
    onCreateOption?(newOption: string): void;
  }
}

function generateSelectStyles<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(hasError: boolean): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (provided) => {
      return {
        ...provided,
        borderRadius: '0.25rem',

        backgroundColor: '#fff',
      };
    },
    control: () => {
      return {
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',

        minHeight: '2rem',
        border: hasError ? '1px solid #bf0808' : '1px solid #eee',
        borderRadius: '0.25rem',

        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      };
    },
    menu: (provided) => {
      return {
        ...provided,

        fontSize: '0.875rem',
        lineHeight: '1.25rem',

        zIndex: 20,
      };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        margin: '0 2px 2px 0',
      };
    },
  };
}

function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  borderColor,
  height,
  hasError = false,
  isCreatable = false,
  placeholder,
  ...rest
}: Props<Option, IsMulti, Group>): JSX.Element {
  const commonProps = {
    ...rest,
    closeMenuOnSelect: !rest.isMulti,
    components: {
      ClearIndicator: SelectClearIndicator,
      DropdownIndicator: SelectDropdownIndicator,
      IndicatorSeparator: null,
      LoadingIndicator: SelectLoadingIndicator,
      MultiValueLabel: SelectMultiValueLabel,
      MultiValueRemove: SelectMultiValueRemove,
    },
    styles: generateSelectStyles(hasError),
  } satisfies Partial<Props<Option, IsMulti, Group>>;

  if (isCreatable) {
    return (
      <CreateableSelect
        {...commonProps}
        formatCreateLabel={(inputValue) => {
          return (
            <div className="flex items-center text-green text-sm">
              <div className="w-4 h-4">
                <Icon id="plus" />
              </div>
              <span>Create tag for '{inputValue}'</span>
            </div>
          );
        }}
        placeholder={placeholder}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: '14px',
            borderColor: state.isFocused
              ? 'green'
              : `${borderColor ?? baseStyles.borderColor}`,
            height: `${height ?? baseStyles.height}px`,
          }),
        }}
      />
    );
  }

  return (
    <ReactSelect
      {...commonProps}
      placeholder={placeholder}
      styles={{
        control: (baseStyles, state) => {
          let bc = borderColor ?? baseStyles.borderColor;
          if (state.isFocused) {
            bc = '#006451';
          }

          return {
            ...baseStyles,
            fontSize: '14px',
            borderColor: bc,
            height: `${height ?? baseStyles.height}px`,
            outline: 'none',
          };
        },
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#006451',
          primary25: '#E1FAF3',
          primary50: '#23B07E',
        },
      })}
    />
  );
}

export default Select;

function SelectClearIndicator<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ clearValue }: ClearIndicatorProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div className="pl-2">
      <div
        className="w-3 h-3 cursor-pointer"
        onClick={() => {
          clearValue();
        }}
      >
        <Icon id="x" />
      </div>
    </div>
  );
}

function SelectDropdownIndicator<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ isDisabled }: DropdownIndicatorProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div className="px-2">
      <div
        className={clsx('w-3 h-3', {
          'text-dark-grey': isDisabled,
        })}
      >
        <Icon id="chevron-down" />
      </div>
    </div>
  );
}

const SelectLoadingIndicator = (): JSX.Element => {
  return (
    <div className="pl-2">
      <CircleLoader isColored />
    </div>
  );
};

function SelectMultiValueLabel<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueGenericProps<Option, IsMulti, Group>): JSX.Element {
  const labelRef = useRef<HTMLDivElement>(null);
  const [needsTooltip, setNeedsTooltip] = useState(false);

  useEffect(() => {
    // For tooltips that display their entire label contents, we don't need a redundant tooltip.
    if (
      labelRef.current &&
      labelRef.current.offsetWidth < labelRef.current.scrollWidth
    ) {
      setNeedsTooltip(true);
    }
  }, []);

  let label = (
    <div {...props.innerProps} ref={labelRef} className="px-1">
      {props.children}
    </div>
  );
  if (needsTooltip) {
    label = <Tooltip trigger={label}>{props.children}</Tooltip>;
  }

  return label;
}

function SelectMultiValueRemove<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueRemoveProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div {...props.innerProps} className="pt-1 pr-1">
      <div className="w-3 h-3 cursor-pointer">
        <Icon id="x" />
      </div>
    </div>
  );
}
