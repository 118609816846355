import { getItem, LOCALSTORAGE_KEYS } from './localStorage';
import { stringToObject } from './general';

export function getLastDashboardRoute(): string {
  const lastActiveTab = getItem(LOCALSTORAGE_KEYS.DASHBOARD_TAB);

  return `/dashboard/${lastActiveTab || 'launched'}`;
}

export function getSurveyFlowRoute({ surveyId }: { surveyId: number }): string {
  return `/campaign/edit/${surveyId}/overview`;
}

/**
 * "Un-stringifies" the query part of a provided string.
 */
export function unStringifyQuery(
  search: string
): Record<string, string | undefined> {
  if (!search) {
    return {};
  }

  // Get rid of the "?" at the beginning.
  return stringToObject({
    propDelimiter: '&',
    value: search.substring(1),
    valueDelimiter: '=',
    valueProcessorFn: (value) => decodeURIComponent(value),
  });
}
