import { assign, setup } from 'xstate';

import { QuestionFormData } from '../../../types/forms';
import { SurveyFlowStep } from '../../../types/internal';

export interface Context {
  formDataToDuplicate: QuestionFormData | null;
}

export type Events =
  | { formData: QuestionFormData; type: 'duplicateQuestion' }
  | { type: 'enterNewQuestion' }
  | { type: 'enterNewVariable' }
  | { type: 'enterNewBlock' }
  // "surveyId" might be provided if we just created a new survey and are going to
  // the "audience" step.
  | { step: SurveyFlowStep; surveyId?: number; type: 'goToStep' }
  | { type: 'launched' }
  | { type: 'BLOCKED_NAVIGATION' }
  | { type: 'DISCARD_CHANGES' }
  | { type: 'DISMISS_UNSAVED_CHANGES' }
  | { type: 'FORM_DIRTY' }
  | { type: 'FORM_NOT_DIRTY' }
  | { type: 'HAS_FORM_ERROR' }
  | { newResourceId: number | undefined; type: 'RESOURCE_DELETED' }
  | {
      existingResourceId?: number;
      newResourceId?: number;
      type: 'RESOURCE_SAVED';
    };

export const surveyFlowMachine = setup({
  types: {} as {
    context: Context;
    events: Events;
  },

  actions: {
    cancelBlock: () => {
      throw new Error('Unimplemented');
    },
    clearFormDataToDuplicate: assign({
      formDataToDuplicate: null,
    }),
    navigateToBlocked: () => {
      throw new Error('Unimplemented');
    },
    navigateToNewQuestion: () => {
      throw new Error('Unimplemented');
    },
    navigateToSpecificResource: (
      _,
      params: { newResourceId: number | undefined },
    ) => {
      throw new Error(`Unimplemented: ${params.newResourceId}`);
    },
    setFormDataToDuplicate: assign({
      formDataToDuplicate: (_, params: { formData: QuestionFormData }) => {
        return params.formData;
      },
    }),
    shouldBlock: () => {
      throw new Error('Unimplemented');
    },
    updateRouteForLaunched: () => {
      throw new Error('Unimplemented');
    },
    updateRouteForNewVariable: () => {
      throw new Error('Unimplemented');
    },
    updateRouteForNewBlock: () => {
      throw new Error('Unimplemented');
    },
    updateRouteForStep: (
      _,
      params: { step: SurveyFlowStep; surveyId?: number },
    ) => {
      throw new Error(`Unimplemented: ${params.step}`);
    },
  },
  guards: {
    shouldNavigateAfterSave: (_, params: { existingResourceId?: number }) => {
      throw new Error(`Unimplemented: ${params.existingResourceId}`);
    },
  },
}).createMachine({
  context: {
    formDataToDuplicate: null,
  },
  id: 'surveyEdit',
  initial: 'idle',
  states: {
    idle: {
      on: {
        launched: { target: 'launched' },
        FORM_DIRTY: {
          actions: [{ type: 'shouldBlock' }],
          target: 'formDirty',
        },
        // A new resource may be saved in our idle state if the user chose to duplicate
        // a resource and then made no modifications (so we never entered the formDirty state).
        RESOURCE_SAVED: {
          actions: [
            {
              type: 'navigateToSpecificResource',
              params: ({ event }) => ({ newResourceId: event.newResourceId }),
            },
          ],
        },
      },
    },
    formDirty: {
      on: {
        BLOCKED_NAVIGATION: { target: 'unsavedChanges' },
        FORM_NOT_DIRTY: {
          actions: [{ type: 'cancelBlock' }],
          target: 'idle',
        },
        RESOURCE_SAVED: [
          {
            actions: [
              { type: 'cancelBlock' },
              {
                type: 'navigateToSpecificResource',
                params: ({ event }) => ({ newResourceId: event.newResourceId }),
              },
            ],
            guard: {
              type: 'shouldNavigateAfterSave',
              params: ({ event }) => ({
                existingResourceId: event.existingResourceId,
              }),
            },
            target: 'idle',
          },
          {
            actions: [{ type: 'cancelBlock' }],
            target: 'idle',
          },
        ],
      },
    },
    unsavedChanges: {
      on: {
        DISMISS_UNSAVED_CHANGES: {
          actions: [{ type: 'shouldBlock' }],
          target: 'formDirty',
        },
        DISCARD_CHANGES: {
          actions: [{ type: 'navigateToBlocked' }],
          target: 'idle',
        },
        HAS_FORM_ERROR: {
          actions: [{ type: 'shouldBlock' }],
          target: 'formDirty',
        },
        RESOURCE_SAVED: {
          actions: [{ type: 'navigateToBlocked' }],
          target: 'idle',
        },
      },
    },
    launched: {
      entry: [{ type: 'updateRouteForLaunched' }],
      type: 'final',
    },
  },

  on: {
    duplicateQuestion: {
      actions: [
        {
          type: 'setFormDataToDuplicate',
          params: ({ event }) => ({ formData: event.formData }),
        },
        { type: 'navigateToNewQuestion' },
      ],
    },
    enterNewQuestion: {
      actions: [
        { type: 'clearFormDataToDuplicate' },
        { type: 'navigateToNewQuestion' },
      ],
    },
    enterNewVariable: {
      actions: [{ type: 'updateRouteForNewVariable' }],
    },
    enterNewBlock: {
      actions: [{ type: 'updateRouteForNewBlock' }],
    },
    goToStep: {
      actions: [
        {
          type: 'updateRouteForStep',
          params: ({ event }) => ({
            step: event.step,
            surveyId: event.surveyId,
          }),
        },
      ],
    },
    RESOURCE_DELETED: {
      // We call the "cancelBlock" action here because it's possible the user made changes
      // to a resource and then decided to just delete it. If they made changes, we'd be in
      // the dirty state and would block navigation so we need to cancel it before we try to
      // navigate to the new resource.
      actions: [
        { type: 'cancelBlock' },
        {
          type: 'navigateToSpecificResource',
          params: ({ event }) => ({ newResourceId: event.newResourceId }),
        },
      ],
      target: '.idle',
    },
  },
});
