import Icon from 'components/common/Icon';

const OptionFeatureLabel = ({
  label,
  onClickRemove,
}: {
  label: string;
  onClickRemove?: () => void;
}): JSX.Element => {
  let labelColorString = 'border-green text-green';
  if (label === 'Inactive') {
    labelColorString = 'border-red text-red';
  }
  return (
    <div
      className={`inline-flex items-center py-0.5 px-1 rounded border ${labelColorString} text-tiny`}
    >
      <span className="uppercase">{label}</span>
      {onClickRemove && (
        <div className="w-3 h-3 ml-1 cursor-pointer" onClick={onClickRemove}>
          <Icon id="x" />
        </div>
      )}
    </div>
  );
};

export default OptionFeatureLabel;
