import { clsx } from 'clsx';

import { getOptionTitle } from '../../util/options';
import { isIdeaPresenterQuestion } from '../../util/questions';
import {
  QuestionConcept,
  QuestionOptionWithResults,
  QuestionWithResults,
  QUESTION_TYPE,
} from '../../types/domainModels';
import { ResultBreakdown } from '../../types/internal';

import IndexCard from '../common/IndexCard';
import Tooltip from '../common/Tooltip';
import { sortBy } from 'lodash-es';

const CHART_COLORS = [
  '#00372D', // forest
  '#006451',
  '#0E8765',
  '#23B07E',
  '#3DCB90',
  '#57D99E',
  '#71E3AD',
  '#8CEABE',
  '#A8F0D1',
  '#C4F5E3',
  '#E1FAF3',
];

const CHART_VERTICAL_LINES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function processFloat(value: string) {
  return Math.round(parseFloat(value) * 100) / 100;
}

const HorizontalBarChart = ({
  cohorts,
  monadicConcepts,
  question,
}: {
  cohorts: string[];
  monadicConcepts: QuestionConcept[];
  question: QuestionWithResults;
}): JSX.Element => {
  const { conceptTestMedia, options, questionTypeId } = question;

  const concepts = isIdeaPresenterQuestion(question)
    ? conceptTestMedia ?? []
    : [];

  let labels: string[] = [];
  if (questionTypeId === QUESTION_TYPE.MATRIX) {
    labels = sortBy(options[0].labels, 'id').map(
      ({ optionLabel }) => optionLabel,
    );
  } else if (questionTypeId === QUESTION_TYPE.RANKING) {
    labels = options.map((_option, optionIdx) => {
      return `${optionIdx + 1}`;
    });
  } else if (questionTypeId === QUESTION_TYPE.SCALE) {
    const { rangeMax, rangeMin, rangeStep } = options[0];

    if (rangeMax !== null && rangeMin !== null && rangeStep !== null) {
      const minValue = processFloat(rangeMin.toString());
      const maxValue = processFloat(rangeMax.toString());
      const stepValue = processFloat(rangeStep.toString());
      for (let step = minValue; step <= maxValue; step += stepValue) {
        labels.push(step.toString());
      }
    }

    console.log(labels);
  }

  if (monadicConcepts.length > 0) {
    return (
      <>
        {monadicConcepts.map((monadicConcept) => (
          <>
            <IndexCard>
              {renderChartComponent(
                question,
                labels,
                cohorts,
                concepts,
                monadicConcept,
              )}
            </IndexCard>
          </>
        ))}
      </>
    );
  }

  return (
    <IndexCard>
      {renderChartComponent(question, labels, cohorts, concepts)}
    </IndexCard>
  );
};

function renderChartComponent(
  question: QuestionWithResults,
  labels: string[],
  cohorts: string[],
  concepts: QuestionConcept[],
  monadicConcept?: QuestionConcept,
) {
  return (
    <>
      <ChartHeader
        labels={labels.length > 0 ? labels : cohorts}
        sort={question.sort}
        title={
          monadicConcept
            ? question.title + ', ' + monadicConcept.description
            : question.title
        }
      />
      <div className="relative pb-8 m-4 mt-0">
        <div
          className="relative grid gap-4 pb-4 items-center overflow-auto z-10"
          style={{
            height: '315px',
          }}
        >
          {concepts.length > 0 ? (
            concepts.map((concept, i) => {
              return (
                <HorizontalBars
                  key={i}
                  cohorts={cohorts}
                  concept={concept}
                  labels={labels}
                  monadicConceptId={monadicConcept?.id ?? null}
                  question={question}
                />
              );
            })
          ) : (
            <HorizontalBars
              cohorts={cohorts}
              labels={labels}
              monadicConceptId={monadicConcept?.id ?? null}
              question={question}
            />
          )}
        </div>
        <HorizontalChartPercentageLabels />
        <HorizontalChartGridLines />
      </div>
    </>
  );
}

export default HorizontalBarChart;

export const ChartHeader = ({
  labels,
  sort,
  title,
}: {
  labels?: string[];
  sort: number;
  title: string;
}): JSX.Element => {
  return (
    <>
      <div className="font-medium text-gray-600 ml-4 mt-4 text-xs">{`Question ${sort}`}</div>
      <div className="font-medium text-gray-900 ml-4 mt-2 text-base">
        {title}
      </div>

      {labels && labels.length > 0 && (
        <div className="flex justify-between ml-4 mt-10 border-b border-light-grey">
          <div
            className="flex-shrink-0 flex flex-wrap justify-end max-w-xs space-x-2 text-xs overflow-auto"
            style={{ maxHeight: 100 }}
          >
            {labels.map((label, labelIdx) => (
              <div
                key={`cohort-legend-${labelIdx}`}
                className="flex items-center"
              >
                <div
                  className="flex-shrink-0 w-3 h-3 mr-2 rounded-full"
                  style={{
                    backgroundColor:
                      CHART_COLORS[labelIdx % CHART_COLORS.length],
                  }}
                />
                <div>{label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const HorizontalChartGridLines = (): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full h-full">
      <div className="border-r-2 border-light-grey" />
      {CHART_VERTICAL_LINES.map((_stretch, stretchIdx) => {
        if (stretchIdx > 0) {
          return (
            <div
              key={stretchIdx}
              className="h-full border-r border-light-grey"
            />
          );
        }
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};

const HorizontalBars = ({
  cohorts,
  concept,
  labels,
  question,
  monadicConceptId,
}: {
  cohorts: string[];
  concept?: QuestionConcept;
  labels: string[];
  question: QuestionWithResults;
  monadicConceptId: number | null;
}): JSX.Element => {
  const { options, questionTypeId } = question;
  const hasMultipleBarPortions = [
    QUESTION_TYPE.MATRIX,
    QUESTION_TYPE.RANKING,
    QUESTION_TYPE.SCALE,
  ].includes(questionTypeId);

  return (
    <>
      {options.map((option, optionIdx) => {
        let resultsBreakdown: ResultBreakdown[] = option.resultsBreakdown
          ? option.resultsBreakdown
          : [];

        if (option.rankBreakdown && option.rankBreakdown.length > 0) {
          resultsBreakdown = option.rankBreakdown;
        } else if (option.scaleBreakdown && option.scaleBreakdown.length > 0) {
          resultsBreakdown = option.scaleBreakdown;
        }

        const optionName = `${getOptionTitle({ index: option.sort, option })}`;

        return (
          <div key={`horizontal-bar-${optionIdx}`}>
            <div className="my-2 text-green text-sm">{optionName}</div>
            {cohorts.map((cohort, cohortIdx) => {
              const barPortions: ResultBreakdown[] = [];

              const colorIdx = 0;
              let resultBreakdown: ResultBreakdown | undefined = undefined;

              if (hasMultipleBarPortions) {
                const resultsForCohort = resultsBreakdown.filter(
                  (breakdown) =>
                    breakdown.title === cohort &&
                    breakdown.monadicConceptId === monadicConceptId,
                );

                labels.forEach((label) => {
                  const barPortion = resultsForCohort.find((result) => {
                    return (
                      result.label === label ||
                      `${result.rank}` === label ||
                      `${result.step}` === label
                    );
                  });

                  if (barPortion) {
                    barPortions.push(barPortion);
                  }
                });
              } else {
                let resultForCohort = resultsBreakdown.find(
                  (breakdown) =>
                    breakdown.title === cohort &&
                    breakdown.monadicConceptId === monadicConceptId,
                );
                if (concept) {
                  // Currently assumes 1 option on concept question
                  resultForCohort = resultsBreakdown.find(
                    (result) =>
                      result.title === cohort &&
                      result.concept === concept.description &&
                      result.monadicConceptId === monadicConceptId,
                  );
                }

                resultBreakdown = resultForCohort;

                if (resultBreakdown) {
                  resultBreakdown.label = option.title;
                }
              }

              return (
                <div
                  key={`horizontal-bar-${optionIdx}`}
                  className="flex items-center justify-center"
                >
                  <div className="flex items-center w-full">
                    <div
                      className="flex items-center"
                      style={{
                        // This matches the width of the first column in the horizontal bars grid
                        // (see Tailwind configuration for "horizontal-bar-chart-bg").
                        width: '85px',
                        color: hasMultipleBarPortions
                          ? '#121212'
                          : CHART_COLORS[cohortIdx % CHART_COLORS.length],
                        fontSize: '10px',
                      }}
                    >
                      {cohort}
                    </div>

                    <div className="flex-grow flex rounded-full overflow-hidden">
                      {hasMultipleBarPortions ? (
                        <>
                          {barPortions.map((result, resultIdx) => (
                            <HorizontalBarPortion
                              key={resultIdx}
                              colorIndex={resultIdx % CHART_COLORS.length}
                              index={resultIdx}
                              option={option}
                              result={result}
                              resultsLength={barPortions.length}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {resultBreakdown && (
                            <HorizontalBarPortion
                              colorIndex={colorIdx}
                              concept={concept}
                              index={0}
                              isSingleBar={true}
                              option={option}
                              result={resultBreakdown}
                              resultsLength={1}
                            />
                          )}
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        // This matches the width of the last column in the horizontal bars grid
                        // (see Tailwind configuration for "horizontal-bar-chart-bg").
                        width: '60px',
                        paddingLeft: '3px',
                        color: CHART_COLORS[colorIdx % CHART_COLORS.length],
                        fontSize: '10px',
                      }}
                    >
                      {!hasMultipleBarPortions &&
                        resultBreakdown &&
                        `${resultBreakdown.count}, ${Number(
                          resultBreakdown.percentage * 100,
                        ).toFixed(0)}%`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

const HorizontalBarPortion = ({
  colorIndex,
  concept,
  index,
  isSingleBar,
  option,
  result,
  resultsLength,
}: {
  colorIndex: number;
  concept?: QuestionConcept;
  index: number;
  isSingleBar?: boolean;
  option: QuestionOptionWithResults;
  result: ResultBreakdown;
  resultsLength: number;
}): JSX.Element => {
  let title = `${
    result.label
      ? result.label
      : option.description
        ? option.description
        : option.title
  }`;
  if (result.step && option?.scaleUnit) {
    title = `${option.scaleUnit.unitValue}${result.step}`;

    if (result.step === option.rangeMin) {
      title = `${title}, "${option.scaleLowLabel}"`;
    }

    if (result.step === option.rangeMax) {
      title = `${title}, "${option.scaleHighLabel}"`;
    }
  }

  if (result.rank) {
    title = `Rank ${result.rank}`;
  }

  if (concept) {
    title = `${concept.description}, ${title}`;
  }

  let count = result.count;
  if (isSingleBar) {
    count = result.percentage;
  }

  const imageUrl = option.dataUrl?.url ?? concept?.media.secure_url ?? '';

  return (
    <Tooltip
      trigger={
        <div
          key={index}
          className={clsx('relative h-5', {
            'rounded-l-full': index === 0,
            'rounded-r-full': index === resultsLength - 1,
          })}
          style={{
            flex: count,
            backgroundColor: CHART_COLORS[colorIndex % CHART_COLORS.length],
          }}
        />
      }
    >
      <div className="flex flex-col justify-center">
        {imageUrl && (
          <div className="flex items-center justify-center w-56 h-56">
            <img className="max-w-full max-h-full" src={imageUrl} />
          </div>
        )}

        <div className="mb-2 text-sm text-center">{title}</div>

        <div className="flex w-full">
          <div className="w-1/2 mr-2 pr-2 border-r border-light-grey text-center">
            <span className="text-primary-d-600 text-sm">
              {result ? Number(result.count) : 0}
            </span>
            <div className="text-xs text-dark-grey">Responses</div>
          </div>
          <div className="w-1/2 text-center">
            <span className="text-primary-d-600 text-sm">
              {result ? `${Number(result.percentage * 100).toFixed(0)}%` : 0}
            </span>
            <div className="text-xs text-dark-grey">Percentage</div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const HorizontalChartPercentageLabels = (): JSX.Element => {
  return (
    <div
      className="absolute bottom-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full border-t border-light-grey bg-white"
      style={{
        zIndex: 1,
      }}
    >
      <div
        className="border-r-2 border-light-grey"
        style={{
          height: '30px',
        }}
      />
      {CHART_VERTICAL_LINES.map((_option, optionIdx) => {
        if (optionIdx === 0) {
          return '';
        }

        return (
          <div
            key={optionIdx}
            className="flex items-center justify-end border-r border-light-grey text-dark-grey text-tiny"
          >
            {`${optionIdx * 10}%`}
          </div>
        );
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};
