import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import ErrorDisplay from '../common/ErrorDisplay';
import Hyperlink from '../common/Hyperlink';
import Logo from '../common/Logo';

const ErrorBoundary = ({ children }: { children?: ReactNode }): JSX.Element => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;

export const ErrorFallback = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen space-y-8">
      <div className="w-56">
        <Logo />
      </div>

      <ErrorDisplay
        message={
          'Something went wrong! We have been alerted and are looking into the issue.'
        }
      />

      <Hyperlink
        onClick={() => {
          window.location.href = '/dashboard/launched';
        }}
      >
        Return to your surveys
      </Hyperlink>
    </div>
  );
};
