import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { Organization, OrganizationV2, User } from '../../types/domainModels';

interface CreateOrganizationBody {
  name: string;
  useInvoice: boolean;
  users: {
    email: string;
    firstName: string;
    lastName: string;
  }[];
}

export interface FetchOrganizationsResponse {
  organizations: OrganizationV2[];
  totalCount: number;
}

export const CHANGE_ORGANIZATION = () => ({
  path: '/users/change-organization',
  version: 'v1' as const,
});

export async function changeOrganization({
  data,
}: {
  data: { organizationId: number };
}) {
  return await axios.post<User>(getAPIUrl(CHANGE_ORGANIZATION()), data);
}

export const CREATE_ORGANIZATION = () => ({
  path: '/organizations',
  version: 'v1' as const,
});

export async function createOrganization({
  data,
}: {
  data: CreateOrganizationBody;
}) {
  return (
    await axios.post<Organization>(getAPIUrl(CREATE_ORGANIZATION()), data)
  ).data;
}

export const FETCH_ORGANIZATIONS = () => ({
  path: '/organizations',
  version: 'v2' as const,
});

export async function fetchOrganizations() {
  return (
    await axios.get<FetchOrganizationsResponse>(
      getAPIUrl(FETCH_ORGANIZATIONS()),
      {
        // We need to be able to fetch all organizations currently. 1000 is just an arbitrary large number
        // to try to make that happen.
        params: { limit: 1000 },
      },
    )
  ).data;
}
