import { FormikErrors } from 'formik';

import { CreateVariableBody } from '../services/backend/surveyVariables';
import {
  getConceptOption,
  getOptionOption,
  getQuestionOption,
  SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS,
} from './formOptions';
import { isIdeaPresenterQuestion } from './questions';
import {
  MatrixOption,
  QUESTION_TYPE,
  Question,
  QuestionConcept,
  QuestionLabel,
  QuestionOption,
  Survey,
  SurveyVariable,
  SurveyVariableModifier,
  SurveyVariableQuotaType,
  SurveyVariableSegment as SurveyVariableSegmentAPI,
  SurveyVariableSegmentConstraint as SurveyVariableSegmentConstraintAPI,
} from '../types/domainModels';
import { ReactSelectValue } from '../types/forms';
import {
  flatten,
  castArray,
  groupBy,
  map,
  compact,
  find,
  filter,
  some,
  difference,
  isEmpty,
} from 'lodash-es';

export type ConstraintConcepts = {
  concepts:
    | ReactSelectValue<QuestionConcept>
    | ReactSelectValue<QuestionConcept>[];
};
export type ConstraintOptions = {
  options:
    | ReactSelectValue<QuestionOption>
    | ReactSelectValue<QuestionOption>[];
};
export type ConstraintWithNumber = {
  range: { end: number | ''; start: number | '' };
};
export type ConstraintWithNumberValidated = {
  range: { end: number; start: number };
};
export type ConstraintWithRange = {
  option: ReactSelectValue<QuestionOption> | null;
  range: { end: number | ''; start: number | '' };
};
export type ConstraintWithDateRange = {
  dateRange: { end: Date | null; start: Date | null };
};
export type ConstraintWithRangeValidated = {
  option: ReactSelectValue<QuestionOption>;
  range: { end: number; start: number };
};
export type ConstraintWithStatement = {
  statement: ReactSelectValue<QuestionOption> | null;
  options:
    | ReactSelectValue<QuestionLabel>
    | ReactSelectValue<QuestionLabel>[]
    | ReactSelectValue<MatrixOption>
    | ReactSelectValue<MatrixOption>[];
};
export type ConstraintWithStatementValidated = {
  statement: ReactSelectValue<QuestionOption>;
  options: ReactSelectValue<QuestionLabel> | ReactSelectValue<QuestionLabel>[];
};

type SurveyVariableSegmentConstraint =
  | ConstraintConcepts
  | ConstraintOptions
  | ConstraintWithDateRange
  | ConstraintWithNumber
  | ConstraintWithRange
  | ConstraintWithStatement;

type SurveyVariableSegmentConstraintValidated =
  | ConstraintConcepts
  | ConstraintOptions
  | ConstraintWithNumberValidated
  | ConstraintWithRangeValidated
  | ConstraintWithStatementValidated;

export interface SurveyVariableSegment {
  id?: number;
  isUserSpecified: boolean;
  questions: SurveyVariableSegmentQuestion[][];
  tempId: string;
  title: string;
  userIds?: string[];
}

export interface SurveyVariableSegmentValidated {
  id?: number;
  isUserSpecified: boolean;
  questions: SurveyVariableSegmentQuestionValidated[][];
  tempId: string;
  title: string;
  userIds?: string[];
}

export interface SurveyVariableSegmentQuestion {
  constraints: SurveyVariableSegmentConstraint[];
  modifier: ReactSelectValue<SurveyVariableModifier> | null;
  question: ReactSelectValue<Question> | null;
}

export interface SurveyVariableSegmentQuestionValidated {
  constraints: SurveyVariableSegmentConstraintValidated[];
  modifier: ReactSelectValue<SurveyVariableModifier>;
  question: ReactSelectValue<Question>;
}

interface SurveyVariableQuota {
  segments: ReactSelectValue<SurveyVariableSegment>[];
  type: ReactSelectValue<SurveyVariableQuotaType> | null;
  value: number | '';
}

interface SurveyVariableQuotaValidated {
  segments: ReactSelectValue<SurveyVariableSegment>[];
  type: ReactSelectValue<SurveyVariableQuotaType>;
  value: number | '';
}

export interface SurveyVariableFormData {
  quotas: SurveyVariableQuota[];
  segments: SurveyVariableSegment[];
  title: string;
}

export interface SurveyVariableFormDataValidated {
  quotas: SurveyVariableQuotaValidated[];
  segments: SurveyVariableSegmentValidated[];
  title: string;
}

export const MODIFIER_OPTION_EITHER: ReactSelectValue<SurveyVariableModifier> =
  {
    label: 'either',
    value: 'should',
  };
const MODIFIER_OPTION_EQUAL_TO: ReactSelectValue<SurveyVariableModifier> = {
  label: 'equal to',
  value: 'is',
};
export const MODIFIER_OPTION_WITHIN: ReactSelectValue<SurveyVariableModifier> =
  {
    label: 'within',
    value: 'should',
  };
export const MODIFIER_OPTIONS_DEFAULT: ReactSelectValue<SurveyVariableModifier>[] =
  [
    MODIFIER_OPTION_EQUAL_TO,
    MODIFIER_OPTION_EITHER,
    { label: 'not', value: 'isnt' },
  ];

export function formDataToApiData({
  formData,
  survey,
}: {
  formData: SurveyVariableFormDataValidated;
  survey: Survey;
}): CreateVariableBody {
  const segmentIdsToQuotaIds: { [segmentTempId: string]: string } = {};

  return {
    quotas: flatten(
      formData.quotas.map((quota, quotaIdx) => {
        const quotaTempId = `${quotaIdx}`;

        quota.segments.forEach((segment) => {
          segmentIdsToQuotaIds[segment.value.tempId] = quotaTempId;
        });

        return {
          numberNeeded:
            quota.type.value === 'at_most'
              ? quota.value || undefined
              : undefined,
          tempId: quotaTempId,
          type: quota.type.value,
        };
      }),
    ),
    segments: formData.segments.map((segment) => {
      return {
        id: segment.id,
        isUserSpecified: segment.isUserSpecified,
        questions: flatten(
          segment.questions.map((segmentOrGroup, segmentOrGroupIdx) => {
            return segmentOrGroup.map((segmentQuestion) => {
              return {
                andGrouping: segmentOrGroupIdx,
                constraints: flatten<
                  CreateVariableBody['segments'][number]['questions'][number]['constraints'][number]
                >(
                  segmentQuestion.constraints.map((constraint) => {
                    if (isConstraintWithStatement(constraint)) {
                      const { statement, options } = constraint;

                      if (survey.useNewMatrixOptions) {
                        return {
                          optionId: statement.value.id,
                          matrixOptionIds: castArray(options).map((option) => {
                            return option.value.id;
                          }),
                        };
                      }

                      return {
                        optionId: statement.value.id,
                        matrixOptions: castArray(options).map((option) => {
                          return option.value.optionLabel;
                        }),
                      };
                    }

                    if (isConstraintWithRanges(constraint)) {
                      const { option, range } = constraint;

                      return {
                        numberRange: { end: range.end, start: range.start },
                        optionId: option.value.id,
                      };
                    }

                    if (isConstraintWithNumber(constraint)) {
                      return {
                        numberRange: {
                          end: constraint.range.end,
                          start: constraint.range.start,
                        },
                      };
                    }

                    if (isConstraintWithDateRange(constraint)) {
                      return {
                        dateRange: {
                          end: constraint.dateRange.end,
                          start: constraint.dateRange.start,
                        },
                      };
                    }

                    if (isConstraintWithConcepts(constraint)) {
                      return castArray(constraint.concepts).map(({ value }) => {
                        return { conceptId: value.id };
                      });
                    }

                    return castArray(constraint.options).map(({ value }) => {
                      return { optionId: value.id };
                    });
                  }),
                ),
                logicalModifier: segmentQuestion.modifier.value,
                questionId: segmentQuestion.question.value.id,
              };
            });
          }),
        ),
        tempQuotaId: segmentIdsToQuotaIds[segment.tempId],
        title: segment.title,
        userIds: segment.userIds,
      };
    }),
    title: formData.title,
  };
}

export function getEmptyConstraintWithConcepts(): ConstraintConcepts {
  return { concepts: [] };
}

export function getEmptyConstraintWithNumber(): ConstraintWithNumber {
  return {
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithOptions(): ConstraintOptions {
  return { options: [] };
}

export function getEmptyConstraintWithDateRange(): ConstraintWithDateRange {
  return {
    dateRange: { end: null, start: null },
  };
}

export function getEmptyConstraintWithRange(): ConstraintWithRange {
  return {
    option: null,
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithStatement(): ConstraintWithStatement {
  return {
    options: [],
    statement: null,
  };
}

export function getEmptySegment({
  numExistingSegments,
}: {
  numExistingSegments: number;
}): SurveyVariableSegment {
  return {
    isUserSpecified: false,
    questions: [[getEmptySegmentQuestion()]],
    tempId: `${numExistingSegments + 1}`,
    title: '',
  };
}

export function getEmptySegmentQuestion(): SurveyVariableSegmentQuestion {
  return {
    constraints: [],
    modifier: MODIFIER_OPTION_EQUAL_TO,
    question: null,
  };
}

export function getEmptyVariableQuota(): SurveyVariableQuota {
  return {
    segments: [],
    type: SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS[0],
    value: '',
  };
}

export function getInitialSurveyVariableFormData({
  questions,
  survey,
  variable,
}: {
  questions: Question[];
  survey: Survey;
  variable: SurveyVariable | undefined;
}): SurveyVariableFormData {
  const segments = variable
    ? variable.segments.map((segment, segmentIdx) => {
        const groupedSegmentQuestions = groupBy(
          segment.questions,
          'andGrouping',
        );

        return {
          id: segment.id,
          isUserSpecified: segment.isUserSpecified,
          questions: map(groupedSegmentQuestions, (segmentOrGroup) => {
            return segmentOrGroup.map((segmentQuestion) => {
              const question = questions.find(
                ({ id }) => id === segmentQuestion.questionId,
              );
              const logicalModifier = MODIFIER_OPTIONS_DEFAULT.find(
                ({ value }) => value === segmentQuestion.logicalModifier,
              );

              return {
                constraints: question
                  ? getSegmentQuestionConstraints({
                      apiConstraints: segmentQuestion.constraints,
                      fullQuestion: question,
                      survey,
                    })
                  : [],
                modifier: logicalModifier ?? null,
                question: question ? getQuestionOption({ question }) : null,
              };
            });
          }),
          tempId: `${segmentIdx + 1}`,
          title: segment.title,
          userIds: segment.userIds,
        };
      })
    : [getEmptySegment({ numExistingSegments: 0 })];

  return {
    quotas: variable
      ? variable.quotas.map((quota) => {
          return {
            segments: compact(
              variable.segments.map(({ quotaId }, segmentIdx) => {
                return quotaId === quota.id ? segments[segmentIdx] : undefined;
              }),
            ).map((segment) => {
              return {
                label: segment.title,
                value: segment,
              };
            }),
            type:
              find(
                SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS,
                (type) => type.value === quota.type,
              ) ?? null,
            value: quota.numberNeeded ?? '',
          };
        })
      : [],
    segments,
    title: variable?.title ?? '',
  };
}

function getSegmentQuestionConstraints({
  apiConstraints,
  fullQuestion,
  survey,
}: {
  apiConstraints: SurveyVariableSegmentConstraintAPI[];
  fullQuestion: Question;
  survey: Survey;
}): SurveyVariableSegmentConstraint[] {
  if (isIdeaPresenterQuestion(fullQuestion)) {
    return [
      {
        concepts: compact(
          apiConstraints.map((constraint) => {
            const concept = find(
              fullQuestion?.concepts,
              ({ id }) => id === constraint.conceptId,
            );

            return concept ? getConceptOption({ concept }) : undefined;
          }),
        ),
      },
    ];
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return [
      {
        options: compact(
          apiConstraints.map((constraint) => {
            const option = find(
              fullQuestion.options,
              ({ id }) => id === constraint.optionId,
            );

            return option ? getOptionOption({ option }) : undefined;
          }),
        ),
      },
    ];
  } else if (
    fullQuestion.questionTypeId === QUESTION_TYPE.RANKING ||
    fullQuestion.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return compact(
      apiConstraints.map((constraint) => {
        const option = find(
          fullQuestion.options,
          ({ id }) => id === constraint.optionId,
        );

        return option && constraint.numberRange
          ? {
              option: getOptionOption({ option }),
              range: {
                end: constraint.numberRange.end,
                start: constraint.numberRange.start,
              },
            }
          : undefined;
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MATRIX) {
    return compact(
      apiConstraints.map((constraint) => {
        const statement = find(
          fullQuestion.options,
          ({ id }) => id === constraint.optionId,
        );
        let options: ConstraintWithStatement['options'];

        if (survey.useNewMatrixOptions) {
          options = fullQuestion.matrixOptions
            .filter((option) => {
              return constraint.matrixOptionIds
                ? constraint.matrixOptionIds.includes(option.id)
                : false;
            })
            .map((option) => {
              return {
                label: option.title,
                value: option,
              };
            });
        } else {
          options = filter(statement?.labels, ({ optionLabel }) => {
            return constraint.matrixOptions
              ? constraint.matrixOptions.includes(optionLabel)
              : false;
          }).map((option) => {
            return {
              label: option.optionLabel,
              value: option,
            };
          });
        }

        return {
          statement: statement ? getOptionOption({ option: statement }) : null,
          options,
        };
      }),
    );
  } else if (
    fullQuestion.questionTypeId === QUESTION_TYPE.NUMBER ||
    (fullQuestion.questionTypeId === QUESTION_TYPE.OPEN_ENDED &&
      fullQuestion.features?.find((f) => f.code === 'Number'))
  ) {
    return compact(
      apiConstraints.map((constraint) => {
        if (!constraint.numberRange) {
          return;
        }

        return {
          range: {
            end: constraint.numberRange.end,
            start: constraint.numberRange.start,
          },
        };
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.DATE) {
    return compact(
      apiConstraints.map((constraint) => {
        if (!constraint.dateRange) {
          return;
        }

        return {
          dateRange: {
            end: constraint.dateRange.end,
            start: constraint.dateRange.start,
          },
        };
      }),
    );
  }

  return [];
}

export function getSurveyVariableOption(
  variable: SurveyVariable,
): ReactSelectValue<SurveyVariable> {
  return {
    label: variable.title,
    value: variable,
  };
}

export function getSurveyVariableSegmentOption(
  segment: SurveyVariableSegmentAPI,
): ReactSelectValue<SurveyVariableSegmentAPI> {
  return {
    label: segment.title,
    value: segment,
  };
}

export function hasConfiguredVariableQuotas(
  quotas: SurveyVariableFormData['quotas'],
): boolean {
  return some(quotas, (quota) => {
    const hasNeededQuotaValue =
      quota.type?.value === 'at_most' ? !!quota.value : true;

    return quota.type && hasNeededQuotaValue && quota.segments.length > 0;
  });
}

export function isConstraintWithConcepts(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintConcepts {
  return (constraint as ConstraintConcepts).concepts !== undefined;
}

export function isConstraintWithNumber(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintWithNumber {
  return (
    (constraint as ConstraintWithRange).option === undefined &&
    (constraint as ConstraintWithNumber).range !== undefined
  );
}

export function isConstraintWithDateRange(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintWithDateRange {
  return (
    (constraint as ConstraintWithRange).option === undefined &&
    (constraint as ConstraintWithDateRange).dateRange !== undefined
  );
}

export function isConstraintWithOptions(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintOptions {
  return (constraint as ConstraintOptions).options !== undefined;
}

export function isConstraintWithRanges(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintWithRange {
  // We need to check both "option" and "range" because number constraints have "range" but not "option".
  return (
    (constraint as ConstraintWithRange).option !== undefined &&
    (constraint as ConstraintWithRange).range !== undefined
  );
}

export function isConstraintWithStatement(
  constraint: SurveyVariableSegmentConstraint,
): constraint is ConstraintWithStatement {
  return (constraint as ConstraintWithStatement).statement !== undefined;
}

export function validateVariableQuotas({
  quotas,
  segmentIds,
}: {
  quotas: SurveyVariableFormData['quotas'];
  segmentIds: string[];
}): FormikErrors<SurveyVariableQuota>[] | undefined {
  const quotasErrors: FormikErrors<SurveyVariableQuota>[] = [];
  let usedSegmentIds: string[] = [];

  quotas.forEach((quota) => {
    const quotaErrors: FormikErrors<SurveyVariableQuota> = {};

    if (!quota.type) {
      quotaErrors.type = 'Required';
    }

    if (quota.type?.value === 'at_most' && quota.value === '') {
      quotaErrors.value = 'Please provide a number.';
    }

    if (quota.segments.length === 0) {
      quotaErrors.segments = 'Please select segments.';
    }

    const configuredSegmentIds = quota.segments.map(
      ({ value }) => value.tempId,
    );
    if (difference(configuredSegmentIds, segmentIds).length > 0) {
      quotaErrors.segments = 'A quota segment no longer exists.';
    }

    if (some(configuredSegmentIds, (id) => usedSegmentIds.includes(id))) {
      quotaErrors.segments = 'A segment can only be in one quota.';
    }

    quotasErrors.push(quotaErrors);

    usedSegmentIds = [...usedSegmentIds, ...configuredSegmentIds];
  });

  const hasQuotasErrors = some(quotasErrors, (error) => !isEmpty(error));

  return hasQuotasErrors ? quotasErrors : undefined;
}

function validateSurveyVariableSegments(
  segments: SurveyVariableSegment[],
): FormikErrors<SurveyVariableSegment>[] | undefined {
  const segmentsErrors: FormikErrors<SurveyVariableSegment>[] = [];

  segments.forEach((segment) => {
    const segmentErrors: FormikErrors<SurveyVariableSegment> = {};
    const segmentOrGroupErrors: FormikErrors<SurveyVariableSegmentQuestion>[][] =
      [];

    if (!segment.title) {
      segmentErrors.title = 'Please provide a title.';
    }

    segment.questions.forEach((segmentOrGroup) => {
      const questionsErrors: FormikErrors<SurveyVariableSegmentQuestion>[] = [];

      segmentOrGroup.forEach((segmentQuestion) => {
        const constraintErrors: FormikErrors<SurveyVariableSegmentConstraint>[] =
          [];
        const questionErrors: FormikErrors<SurveyVariableSegmentQuestion> = {};

        segmentQuestion.constraints.forEach((constraint) => {
          if (isConstraintWithStatement(constraint)) {
            const errors: FormikErrors<ConstraintWithStatement> = {};

            if (!constraint.statement) {
              errors.statement = 'Please select a statement.';
            }

            if (castArray(constraint.options).length === 0) {
              // Formik doesn't like this specification for the error but it's perfectly acceptable.
              errors.options = 'Please choose options.' as FormikErrors<
                ReactSelectValue<QuestionLabel>
              >;
            }

            constraintErrors.push(errors);
          } else if (isConstraintWithRanges(constraint)) {
            const errors: FormikErrors<ConstraintWithRange> = {};

            if (!constraint.option) {
              errors.option = 'Please select an option.';
            }

            if (constraint.range.end === '' || constraint.range.start === '') {
              const rangeErrors: FormikErrors<ConstraintWithRange['range']> =
                {};

              if (constraint.range.end === '') {
                rangeErrors.end = 'Required';
              }

              if (constraint.range.start === '') {
                rangeErrors.start = 'Required';
              }

              errors.range = rangeErrors;
            }

            constraintErrors.push(errors);
          } else if (isConstraintWithNumber(constraint)) {
            const errors: FormikErrors<ConstraintWithNumber> = {};

            if (constraint.range.end === '' || constraint.range.start === '') {
              const rangeErrors: FormikErrors<ConstraintWithRange['range']> =
                {};

              if (constraint.range.end === '') {
                rangeErrors.end = 'Required';
              }

              if (constraint.range.start === '') {
                rangeErrors.start = 'Required';
              }

              errors.range = rangeErrors;
            }

            constraintErrors.push(errors);
          } else if (isConstraintWithConcepts(constraint)) {
            const errors: FormikErrors<ConstraintConcepts> = {};

            if (castArray(constraint.concepts).length === 0) {
              // Formik doesn't like this specification for the error but it's perfectly acceptable.
              errors.concepts = 'Required' as FormikErrors<
                ReactSelectValue<QuestionLabel>
              >;
            }

            constraintErrors.push(errors);
          } else if (isConstraintWithDateRange(constraint)) {
            const errors: FormikErrors<ConstraintWithDateRange> = {};

            if (!constraint.dateRange.end || !constraint.dateRange.start) {
              const dateRangeErrors: FormikErrors<
                ConstraintWithDateRange['dateRange']
              > = {};

              if (!constraint.dateRange.end) {
                dateRangeErrors.end = 'Required';
              }

              if (!constraint.dateRange.start) {
                dateRangeErrors.start = 'Required';
              }

              errors.dateRange = dateRangeErrors;
            }

            constraintErrors.push(errors);
          } else {
            const errors: FormikErrors<ConstraintOptions> = {};

            if (castArray(constraint.options).length === 0) {
              // Formik doesn't like this specification for the error but it's perfectly acceptable.
              errors.options = 'Required' as FormikErrors<
                ReactSelectValue<QuestionLabel>
              >;
            }

            constraintErrors.push(errors);
          }
        });

        if (some(constraintErrors, (errors) => !isEmpty(errors))) {
          questionErrors.constraints = constraintErrors;
        }

        if (!segmentQuestion.modifier) {
          questionErrors.modifier = 'Please choose a modifier.';
        }

        if (!segmentQuestion.question) {
          questionErrors.question = 'Please choose a question.';
        }

        questionsErrors.push(questionErrors);
      });

      segmentOrGroupErrors.push(questionsErrors);
    });

    if (
      some(segmentOrGroupErrors, (orGroupErrors) => {
        return some(orGroupErrors, (errors) => !isEmpty(errors));
      })
    ) {
      segmentErrors.questions = segmentOrGroupErrors;
    }

    segmentsErrors.push(segmentErrors);
  });

  return some(segmentsErrors, (errors) => !isEmpty(errors))
    ? segmentsErrors
    : undefined;
}

export function validateSurveyVariableData(
  formData: SurveyVariableFormData,
): FormikErrors<SurveyVariableFormData> {
  const errors: FormikErrors<SurveyVariableFormData> = {};

  errors.quotas = validateVariableQuotas({
    quotas: formData.quotas,
    segmentIds: formData.segments.map(({ tempId }) => tempId),
  });
  errors.segments = validateSurveyVariableSegments(formData.segments);

  if (!formData.title) {
    errors.title = 'Please provide a title.';
  }

  return some(errors) ? errors : {};
}
