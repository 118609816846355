import { useEffect, useState } from 'react';

/**
 * Accepts a value and returns a debounced value that is only changed every so often (customizable
 * by the duration parameter).
 *
 * @param opts.leading Set this flag to have the value initialized with the passed in value to debounce.
 */
export function useDebouncedValue(
  valueToDebounce: string,
  { duration = 450, leading = false } = {}
): string {
  const [value, setValue] = useState<string>(leading ? valueToDebounce : '');

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      setValue(valueToDebounce);
    }, duration);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [duration, valueToDebounce]);

  return value;
}
