import FormCheckbox from '../../common/forms/FormCheckbox';

const ReverseOptions = ({
  disabled = false,
  isScale = false,
}: {
  disabled?: boolean;
  isScale?: boolean;
}): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel={`Reverse ${isScale ? 'Scales' : 'Options'}`}
        disabled={disabled}
        name="features.reverseOptions"
      />
    </>
  );
};

export default ReverseOptions;
