import { DataUrl } from '../types/domainModels';

export async function getImageDataUrl(imageUrl: string): Promise<string> {
  const imageResponse = await fetch(imageUrl);
  const imageBlob = await imageResponse.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) {
        reject(new Error('Unknown error getting image data URL.'));

        return;
      }

      // This is a string because the readAsDataURL method was used.
      // See https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result.
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(imageBlob);
  });
}

export function getImageToSave(
  image: DataUrl | string,
): DataUrl | { toSave: { url: string } } | null {
  return image
    ? typeof image === 'string'
      ? { toSave: { url: image } }
      : image
    : null;
}

export function getImageUrl({
  format,
  public_id,
  resource_type,
  version,
}: {
  format: string;
  public_id: string;
  resource_type: 'image' | 'video';
  version: number | string;
}): string {
  let resolvedFormat = format;

  // For videos, we currently don't support displaying the video in our application. Instead, we want
  // to display a thumbnail image. Cloudinary will generate a thumbnail image if you just change the
  // format in the request URL to an image content type like "jpg".
  if (resource_type === 'video') {
    resolvedFormat = 'jpg';
  }

  return `https://res.cloudinary.com/${
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
  }/${resource_type}/upload/f_auto,q_auto/v${version}/${public_id}.${resolvedFormat}`;
}

export function isDataUrl(
  image: DataUrl | Record<string, unknown> | null | undefined,
): image is DataUrl {
  return (
    image?.version !== undefined &&
    image?.public_id !== undefined &&
    image?.format !== undefined
  );
}
