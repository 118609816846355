import { useFormikContext, useField, FieldArray } from 'formik';
import { sortBy } from 'lodash-es';

import {
  AudienceTypeOptions as AudienceTypeOptionsType,
  DEMOGRAPHIC_QUOTA_TYPE,
  DemographicConstraint,
  DemographicFormData,
  getEmptyDemographicQuota,
  getEmptyQuota,
  getEmptyQuotaRangeConstraint,
  getLogicalModifierOptions,
  getValuesForAudienceTypeChange,
  isMultiSelectLogicalModifier,
  Quota as QuotaType,
} from 'util/demographics';
import { getQuestionOption } from 'util/formOptions';
import { Question } from 'types/domainModels';
import { ReactSelectValue } from 'types/forms';

import AddButton from './forms/AddButton';
import Checkbox from './forms/Checkbox';
import FormCheckbox from './forms/FormCheckbox';
import FormFieldError from './forms/FormFieldError';
import FormGroup from './forms/FormGroup';
import FormInput from './forms/FormInput';
import FormSearchSelectInput from './forms/FormSearchSelectInput';
import Icon from './Icon';
import IconBackground from './icons/IconBackground';
import SurveyEditRow from 'components/surveyEdit/SurveyEditRow';
import XButton from './forms/XButton';

export const DefineAudienceCard = ({
  useInboundCensusOptions,
}: {
  useInboundCensusOptions: boolean;
}): JSX.Element => {
  const { setValues, values } = useFormikContext<DemographicFormData>();

  const [{ value: isBringYourOwnAudience }] = useField<
    DemographicFormData['audienceProfile']['isBringYourOwnAudience']
  >('audienceProfile.isBringYourOwnAudience');

  return (
    <>
      {useInboundCensusOptions && !isBringYourOwnAudience.value && (
        <SurveyEditRow title="Inbound Census Options">
          <FormGroup>
            <InboundCensusOptions />
          </FormGroup>
        </SurveyEditRow>
      )}

      <SurveyEditRow
        isLastRow={
          !values.audienceProfile.audienceType ||
          values.audienceProfile.audienceType === 'natural'
        }
        title="Who do you want to talk to?"
      >
        <div className="space-y-4">
          <FormGroup>
            <AudienceTypeOptions
              onChange={(value) => {
                setValues(
                  getValuesForAudienceTypeChange({
                    formData: values,
                    newAudienceType: value,
                  }),
                );
              }}
              value={values.audienceProfile.audienceType}
            />
          </FormGroup>
        </div>
      </SurveyEditRow>
    </>
  );
};

export const BuildYourAudienceCard = ({
  demographicQuestions,
}: {
  demographicQuestions: Question[];
}): JSX.Element | null => {
  const [{ value: audienceType }] = useField<AudienceTypeOptionsType>(
    'audienceProfile.audienceType',
  );

  if (audienceType !== 'custom') {
    return null;
  }

  return (
    <>
      <SurveyEditRow title="Audience Title">
        <FormInput
          id="title"
          labelFor="title"
          name="audienceProfile.title"
          placeholder="ex: Age Range"
          size="md"
          type="text"
        />
      </SurveyEditRow>
      <SurveyEditRow isLastRow={true} title="Demographic Quotas">
        <DemographicQuotas demographicQuestions={demographicQuestions} />
      </SurveyEditRow>
    </>
  );
};

const InboundCensusOptions = (): JSX.Element => {
  return (
    <div className="flex flex-col space-y-4">
      <FormCheckbox
        checkboxLabel="Region"
        name="audienceProfile.inboundCensus.useRegion"
      />
      <FormCheckbox
        checkboxLabel="Gender"
        name="audienceProfile.inboundCensus.useGender"
      />
      <FormCheckbox
        checkboxLabel="Hispanic"
        name="audienceProfile.inboundCensus.useEthnicity"
      />
      <FormCheckbox
        checkboxLabel="Race"
        name="audienceProfile.inboundCensus.useRace"
      />
      <FormCheckbox
        checkboxLabel="Age"
        name="audienceProfile.inboundCensus.useAge"
      />
    </div>
  );
};

export const AudienceTypeOptions = ({
  onChange,
  value,
}: {
  onChange(value: AudienceTypeOptionsType): void;
  value: AudienceTypeOptionsType;
}): JSX.Element => {
  return (
    <div className="space-y-2 text-sm">
      <AudienceTypeOption
        currentValue={value}
        label="Natural Fallout"
        onChange={onChange}
        optionValue="natural"
      />
      <AudienceTypeOption
        currentValue={value}
        label="Custom Audience"
        onChange={onChange}
        optionValue="custom"
      />
    </div>
  );
};

const AudienceTypeOption = ({
  currentValue,
  label,
  onChange,
  optionValue,
}: {
  currentValue: AudienceTypeOptionsType;
  label: string;
  onChange(value: AudienceTypeOptionsType): void;
  optionValue: AudienceTypeOptionsType;
}): JSX.Element => {
  return (
    <div className="flex">
      <Checkbox
        checked={currentValue === optionValue}
        label={label}
        name="audienceProfile.audienceType"
        onChange={(event) => {
          if (event.currentTarget.checked) {
            onChange(optionValue);
          }
        }}
        radio={true}
      />
    </div>
  );
};

export const DemographicQuotas = ({
  demographicQuestions,
}: {
  demographicQuestions: Question[];
}): JSX.Element => {
  const [demographicQuotas] = useField<
    DemographicFormData['audienceProfile']['demographicQuotas']
  >('audienceProfile.demographicQuotas');

  return (
    <FieldArray
      name="audienceProfile.demographicQuotas"
      render={(arrayHelpers) => {
        return (
          <>
            {demographicQuotas.value.map((_quota, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col rounded-lg space-y-2 bg-gray-100 p-4 mb-6"
                >
                  <div className="text-xs font-medium text-gray-900 mb-2">
                    Demographic Quota {index + 1}
                  </div>
                  <DemographicQuota
                    demographicQuestions={demographicQuestions}
                    index={index}
                    onRemoveCategory={() => {
                      if (demographicQuotas.value.length > 1) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.replace(index, getEmptyDemographicQuota());
                      }
                    }}
                  />
                </div>
              );
            })}

            <div className="flex">
              <div className="flex bg-gray-400 rounded-lg">
                <AddButton
                  label="Add Demographic Quota"
                  onClick={() => {
                    arrayHelpers.push(getEmptyDemographicQuota());
                  }}
                />
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

const DemographicQuota = ({
  demographicQuestions,
  index,
  onRemoveCategory,
}: {
  demographicQuestions: Question[];
  index: number;
  onRemoveCategory?: () => void;
}): JSX.Element => {
  const demographicFieldName = `audienceProfile.demographicQuotas.${index}.demographic`;
  const quotasFieldName = `audienceProfile.demographicQuotas.${index}.quotas`;
  const [{ value: demographic }] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['demographic']
    >(demographicFieldName);
  const [, , quotasHelpers] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['quotas']
    >(quotasFieldName);

  return (
    <>
      <div className="flex">
        <div className="w-80 mr-2">
          <FormSearchSelectInput
            borderColor="#D9D9D9"
            name={demographicFieldName}
            onChange={(newDemographic) => {
              if (
                !newDemographic ||
                newDemographic.value.id === demographic?.value.id
              ) {
                return;
              }

              quotasHelpers.setError('');
              quotasHelpers.setValue([getEmptyQuota(newDemographic.value)]);
            }}
            options={sortBy(demographicQuestions, 'sort').map((question) => {
              return getQuestionOption({ question });
            })}
            placeholder="Search for a demographic..."
          />
        </div>
        {onRemoveCategory && (
          <div className="mt-2">
            <IconBackground
              onClick={onRemoveCategory}
              size="small"
              title="Delete Quota"
            >
              <div className="w-3 h-3">
                <Icon id="trash" />
              </div>
            </IconBackground>
          </div>
        )}
      </div>
      {demographic && (
        <>
          <div className="my-2 text-sm italic">
            Your audience will be asked: "{demographic.value.title}"
          </div>
          <Quotas demographic={demographic} index={index} />
        </>
      )}
    </>
  );
};

const Quotas = ({
  demographic,
  index,
}: {
  demographic: ReactSelectValue<Question>;
  index: number;
}): JSX.Element => {
  const quotasFieldName = `audienceProfile.demographicQuotas.${index}.quotas`;
  const [{ value: quotas }, quotasMeta] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['quotas']
    >(quotasFieldName);

  return (
    <FieldArray
      name={quotasFieldName}
      render={(arrayHelpers) => {
        return (
          <div>
            <div>
              {quotas.map((_quota, index) => {
                return (
                  <Quota
                    key={index}
                    demographic={demographic}
                    index={index}
                    namePrefix={quotasFieldName}
                    onClickRemoveQuota={() => {
                      if (quotas.length > 1) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.replace(
                          index,
                          getEmptyQuota(demographic.value),
                        );
                      }
                    }}
                  />
                );
              })}
            </div>
            {/* This is a top-level error for all quotas like the percentages not adding to 100%. */}
            {typeof quotasMeta.error === 'string' && (
              <FormFieldError error={quotasMeta.error} />
            )}
            <div className="flex mt-2">
              <AddButton
                label="Add Another Quota"
                onClick={() => {
                  arrayHelpers.push(getEmptyQuota(demographic.value));
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const Quota = ({
  demographic,
  index,
  namePrefix,
  onClickRemoveQuota,
}: {
  demographic: ReactSelectValue<Question>;
  index: number;
  namePrefix: string;
  onClickRemoveQuota?: () => void;
}): JSX.Element => {
  const quotaFieldName = `${namePrefix}.${index}`;
  const options = getLogicalModifierOptions(demographic.value);

  return (
    <div className="flex flex-col p-2 space-y-4">
      <div className="text-xs font-medium text-gray-900 flex flex-row justify-between">
        <div>Quota {index + 1}</div>
        {onClickRemoveQuota && (
          <div
            className="font-normal text-red underline cursor-pointer"
            onClick={onClickRemoveQuota}
          >
            Delete this quota
          </div>
        )}
      </div>

      <div className="flex flex-row items-start w-full space-x-4">
        <div className="flex-1">
          <FormInput
            icon="%"
            iconPlacement="trailing"
            max={100}
            min={0}
            name={`${quotaFieldName}.percentage`}
            size="md"
            type="number"
          />
        </div>
        <div className="flex-1">
          <FormSearchSelectInput
            borderColor="#D9D9D9"
            name={`${quotaFieldName}.logicalModifier`}
            options={options}
            placeholder="Select condition"
          />
        </div>
      </div>

      <div className="flex-1">
        <QuotaConstraints
          demographic={demographic}
          namePrefix={quotaFieldName}
        />
      </div>
    </div>
  );
};

const QuotaConstraints = ({
  demographic,
  namePrefix,
}: {
  demographic: ReactSelectValue<Question>;
  namePrefix: string;
}): JSX.Element | null => {
  const constraintsFieldName = `${namePrefix}.constraints`;
  const [{ value: constraints }] =
    useField<DemographicConstraint[]>(constraintsFieldName);
  const [{ value: logicalModifier }] = useField<QuotaType['logicalModifier']>(
    `${namePrefix}.logicalModifier`,
  );

  if (demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.ENUM) {
    return (
      <FormSearchSelectInput
        borderColor="#D9D9D9"
        isMulti={
          logicalModifier
            ? isMultiSelectLogicalModifier(logicalModifier.value)
            : false
        }
        name={constraintsFieldName}
        options={sortBy(demographic.value.options, 'sort').map((constraint) => {
          return {
            label: constraint.title,
            value: constraint,
          };
        })}
      />
    );
  } else if (
    demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.RANGE ||
    demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.OPEN_END
  ) {
    return (
      <FieldArray
        name={constraintsFieldName}
        render={(arrayHelpers) => {
          return (
            <div>
              <div className="space-y-2">
                {constraints.map((_constraint, index) => {
                  return (
                    <div key={index} className="flex space-x-2">
                      <div>
                        <FormInput
                          name={`${constraintsFieldName}.${index}.start`}
                          size="md"
                          type="number"
                        />
                      </div>
                      <span className="flex-shrink-0 mt-1 mx-4 text-sm">
                        and
                      </span>
                      <div>
                        <FormInput
                          name={`${constraintsFieldName}.${index}.end`}
                          size="md"
                          type="number"
                        />
                      </div>
                      {constraints.length > 1 && (
                        <div className="mt-2">
                          <XButton
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            title="Remove"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="flex mt-1">
                <AddButton
                  label={
                    logicalModifier?.value === 'should' ||
                    logicalModifier?.value === 'shouldnt'
                      ? 'or'
                      : 'and'
                  }
                  onClick={() => {
                    arrayHelpers.push(getEmptyQuotaRangeConstraint());
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }

  return null;
};
