import IndexCard from '../common/IndexCard';
import Tooltip from '../common/Tooltip';

interface BarData {
  count: number;
  percent: number;
  title: string;
}

const CHART_COLOR = '#00372D';

const CHART_VERTICAL_LINES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const HorizontalBarChartSimple = ({
  bars,
}: {
  bars: BarData[];
}): JSX.Element => {
  return (
    <IndexCard>
      <ChartHeader />
      <div className="relative pb-8 m-4 mt-0">
        <div
          className="relative grid gap-4 pb-4 items-center overflow-auto z-10"
          style={{
            height: '315px',
          }}
        >
          <HorizontalBars bars={bars} />
        </div>
        <HorizontalChartPercentageLabels />
        <HorizontalChartGridLines />
      </div>
    </IndexCard>
  );
};

export default HorizontalBarChartSimple;

const ChartHeader = (): JSX.Element => {
  return (
    <div className="flex justify-end pb-10 border-b border-light-grey text-xs m-4">
      <div className="flex items-center">
        <div
          className="flex-shrink-0 w-3 h-3 mr-2 rounded-full"
          style={{
            backgroundColor: CHART_COLOR,
          }}
        />
        <div>TOTAL SAMPLE</div>
      </div>
    </div>
  );
};

const HorizontalChartGridLines = (): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full h-full">
      <div className="border-r-2 border-light-grey" />
      {CHART_VERTICAL_LINES.map((_stretch, stretchIdx) => {
        if (stretchIdx > 0) {
          return (
            <div
              key={stretchIdx}
              className="h-full border-r border-light-grey"
            />
          );
        }
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};

const HorizontalBars = ({ bars }: { bars: BarData[] }): JSX.Element => {
  return (
    <>
      {bars.map((bar) => {
        return (
          <div key={`horizontal-bar-${bar.title}`}>
            <div className="my-2 text-green text-sm">{bar.title}</div>
            <div className="flex items-center justify-center">
              <div className="flex items-center w-full">
                <div
                  className="flex items-center"
                  style={{
                    // This matches the width of the first column in the horizontal bars grid
                    // (see Tailwind configuration for "horizontal-bar-chart-bg").
                    width: '85px',
                    color: CHART_COLOR,
                    fontSize: '10px',
                  }}
                >
                  TOTAL SAMPLE
                </div>

                <div className="flex-grow flex rounded-full overflow-hidden">
                  <HorizontalBarPortion
                    count={bar.count}
                    percent={bar.percent}
                    title={bar.title}
                  />
                </div>

                <div
                  style={{
                    // This matches the width of the last column in the horizontal bars grid
                    // (see Tailwind configuration for "horizontal-bar-chart-bg").
                    width: '60px',
                    paddingLeft: '3px',
                    color: CHART_COLOR,
                    fontSize: '10px',
                  }}
                >
                  {`${bar.count}, ${bar.percent.toFixed(0)}%`}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const HorizontalBarPortion = ({
  count,
  percent,
  title,
}: {
  count: number;
  percent: number;
  title: string;
}): JSX.Element => {
  const barLength = percent / 100;

  return (
    <Tooltip
      trigger={
        <div
          className="relative h-5 rounded-l-full rounded-r-full"
          style={{ flex: barLength, backgroundColor: CHART_COLOR }}
        />
      }
    >
      <div className="flex flex-col justify-center">
        <div className="mb-2 text-sm text-center">{title}</div>

        <div className="flex w-full">
          <div className="w-1/2 mr-2 pr-2 border-r border-light-grey text-center">
            <span className="text-primary-d-600 text-sm">{count}</span>
            <div className="text-xs text-dark-grey">Responses</div>
          </div>
          <div className="w-1/2 text-center">
            <span className="text-primary-d-600 text-sm">{`${percent.toFixed(
              0,
            )}%`}</span>
            <div className="text-xs text-dark-grey">Percentage</div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const HorizontalChartPercentageLabels = (): JSX.Element => {
  return (
    <div
      className="absolute bottom-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full border-t border-light-grey bg-white"
      style={{
        zIndex: 1,
      }}
    >
      <div
        className="border-r-2 border-light-grey"
        style={{
          height: '30px',
        }}
      />
      {CHART_VERTICAL_LINES.map((_option, optionIdx) => {
        if (optionIdx === 0) {
          return '';
        }

        return (
          <div
            key={optionIdx}
            className="flex items-center justify-end border-r border-light-grey text-dark-grey text-tiny"
          >
            {`${optionIdx * 10}%`}
          </div>
        );
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};
