import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeOptions = (): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel="Randomize Options"
        name="features.randomizeOptions"
      />
    </>
  );
};

export default RandomizeOptions;
