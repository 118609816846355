import Button from './forms/Button';
import Icon from './Icon';
import Listbox, { ListboxButton, ListboxOption } from './Listbox';

type WaveOption = { label: string; value: number | null };

const WaveListbox = ({
  onChangeWave,
  selectedWave,
  waveOptions,
}: {
  onChangeWave(wave: WaveOption['value']): void;
  selectedWave: WaveOption | undefined;
  waveOptions: WaveOption[];
}) => {
  return (
    <Listbox
      button={
        <ListboxButton as="div">
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="chevron-down" />}
            iconPlacement="trailing"
            size="sm"
          >
            {selectedWave?.label ?? 'Select wave'}
          </Button>
        </ListboxButton>
      }
      onChange={onChangeWave}
      placement="bottom-start"
      value={selectedWave?.value}
    >
      {waveOptions.map((option) => {
        return (
          <ListboxOption key={option.value} value={option.value}>
            {option.label}
          </ListboxOption>
        );
      })}
    </Listbox>
  );
};

export default WaveListbox;
