import { ReactSelectValue } from '../types/forms';
import { TeamMember } from '../types/domainModels';

export function createTeamMemberOption(
  teamMember: TeamMember
): ReactSelectValue<TeamMember> {
  return {
    label: `${teamMember.firstName} ${teamMember.lastName}`,
    value: teamMember,
  };
}
