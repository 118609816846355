import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  skipToken,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchUser,
  login,
  resetPassword,
  setPassword,
  updateUser,
} from 'services/backend/users';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const userQueries = {
  get: (userId: number | undefined) =>
    queryOptions({
      queryFn: userId ? () => fetchUser({ userId }) : skipToken,
      queryKey: ['users', userId],
      staleTime: minutesToMilliseconds(10),
    }),
};

export function useLogin({
  onError,
  onSuccess,
}: {
  onError(): void;
  onSuccess(data: Awaited<ReturnType<typeof login>>): void;
}) {
  return useMutation({
    meta: { sentryIgnoreErrorCodes: [401] },
    mutationFn: login,
    onError,
    onSuccess,
  });
}

export function useResetPassword({
  onError,
  onSuccess,
}: {
  onError(): void;
  onSuccess(): void;
}) {
  return useMutation({ mutationFn: resetPassword, onError, onSuccess });
}

export function useSetPassword({
  onError,
  onSuccess,
}: {
  onError(): void;
  onSuccess(): void;
}) {
  return useMutation({ mutationFn: setPassword, onError, onSuccess });
}

export function useUpdateUser(
  opts?: ReactQueryFunctionCallbacks<typeof updateUser>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: updateUser,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: userQueries.get(args[0].id).queryKey,
      });

      opts?.onSuccess?.(...args);
    },
  });
}
