import { ErrorMessage } from '@hookform/error-message';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { MutableRefObject, useImperativeHandle, useRef, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { RemovalGroup } from 'services/backend/surveys';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useRemoveRespondents } from 'hooks/backend/surveys';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import Modal, { ModalHeader } from '../common/Modal';
import Textarea from '../common/forms/Textarea';

type RemovalFormData = {
  nonQualityRemovals: string;
  qualityRemovals: string;
};

const RemovalFormDataSchema = z
  .object({
    nonQualityRemovals: z
      .string()
      .refine((data) => checkRespondentIdsAreNumbers(data), {
        message: 'Please make sure all respondent IDs are numbers.',
      }),
    qualityRemovals: z
      .string()
      .refine((data) => checkRespondentIdsAreNumbers(data), {
        message: 'Please make sure all respondent IDs are numbers.',
      }),
  })
  .refine(
    (data) => {
      const removalGroups = getRemovalGroups(data);

      return (
        removalGroups.length > 0 &&
        removalGroups.every((group) => group.userIds.length > 0)
      );
    },
    {
      message: 'Please provide respondent IDs to remove in at least one field.',
      path: ['qualityRemovals'],
    },
  );

const RemoveRespondentsModal = ({
  onCloseModal,
  surveyId,
}: {
  onCloseModal(): void;
  surveyId: number;
}) => {
  const fieldToFocus = useRef<HTMLTextAreaElement | null>(null);
  const [removeScreen, setRemoveScreen] = useState<'confirm' | 'enter'>(
    'enter',
  );

  const { isPending: isRemoving, mutate: removeRespondents } =
    useRemoveRespondents({
      onError: (err: Error) => {
        showErrorMessage(`Failed to remove respondents. Error: ${err.message}`);
      },
      onSuccess: () => {
        showSuccessMessage(
          'The selected respondents were successfully removed.',
        );

        onCloseModal();
      },
    });

  const useFormReturn = useForm<RemovalFormData>({
    defaultValues: {
      nonQualityRemovals: '',
      qualityRemovals: '',
    },
    resolver: zodResolver(RemovalFormDataSchema),
  });

  const onSubmit: SubmitHandler<RemovalFormData> = (data) => {
    if (removeScreen === 'enter') {
      setRemoveScreen('confirm');
    } else {
      removeRespondents({
        data: {
          groups: getRemovalGroups({
            nonQualityRemovals: data.nonQualityRemovals,
            qualityRemovals: data.qualityRemovals,
          }),
        },
        surveyId,
      });
    }
  };

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          {removeScreen === 'enter' ? 'Remove Respondents' : 'Confirm Removal'}
        </ModalHeader>
      }
      initialFocus={fieldToFocus}
      onCloseModal={onCloseModal}
      position="top"
    >
      <FormProvider {...useFormReturn}>
        <form onSubmit={useFormReturn.handleSubmit(onSubmit)}>
          {removeScreen === 'enter' && (
            <RemoveRespondentsPasteIDs
              fieldToFocus={fieldToFocus}
              onClickCancel={onCloseModal}
            />
          )}
          {removeScreen === 'confirm' && (
            <RemoveConfirmScreen
              isRemoving={isRemoving}
              onClickBack={() => {
                setRemoveScreen('enter');
              }}
            />
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default RemoveRespondentsModal;

const RemoveRespondentsPasteIDs = ({
  fieldToFocus,
  onClickCancel,
}: {
  fieldToFocus: MutableRefObject<HTMLTextAreaElement | null>;
  onClickCancel(): void;
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<RemovalFormData>();

  const { ref, ...rest } = register('qualityRemovals');
  useImperativeHandle(ref, () => fieldToFocus.current);

  return (
    <div>
      <div className="space-y-2 text-gray-d-800">
        <p>
          Use the below fields to paste respondent IDs to remove. Each
          respondent ID should be on its own line.
        </p>

        <FormGroup>
          <label className="font-bold" htmlFor="qualityRemovals">
            Quality Issue
          </label>
          <p className="text-sm">
            Respondents entered here will be included in reconciliation requests
            to integrated panel providers. They can be re-fielded for no
            additional cost.
          </p>
          <Textarea
            {...rest}
            ref={fieldToFocus}
            id="qualityRemovals"
            rows={6}
            size="lg"
          />
          <ErrorMessage
            errors={errors}
            name="qualityRemovals"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>

        <FormGroup>
          <label className="font-bold" htmlFor="nonQualityRemovals">
            Not a Quality Issue
          </label>
          <p className="text-sm">
            Respondents entered here will be removed from the survey results,
            but will not be sent to integrated panel providers in reconciliation
            requests. Respondents can be re-fielded for an additional charge.
          </p>
          <Textarea
            {...register('nonQualityRemovals')}
            id="nonQualityRemovals"
            rows={6}
            size="lg"
          />
          <ErrorMessage
            errors={errors}
            name="nonQualityRemovals"
            render={({ message }) => <FormFieldError error={message} />}
          />
        </FormGroup>
      </div>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <Button grow hierarchy="primary" size="lg" type="submit">
          Remove Respondents
        </Button>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickCancel}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const RemoveConfirmScreen = ({
  isRemoving,
  onClickBack,
}: {
  isRemoving: boolean;
  onClickBack(): void;
}) => {
  const { watch } = useFormContext<RemovalFormData>();

  const nonQualityRemovalsStr = watch('nonQualityRemovals');
  const qualityRemovalsStr = watch('qualityRemovals');
  const removalGroups = getRemovalGroups({
    nonQualityRemovals: nonQualityRemovalsStr,
    qualityRemovals: qualityRemovalsStr,
  });

  const numRespondentsToRemove = removalGroups.reduce(
    (acc, group) => acc + group.userIds.length,
    0,
  );
  const qualityRemovals = removalGroups.find(
    (group) => group.reason === 'quality',
  );
  const nonQualityRemovals = removalGroups.find(
    (group) => group.reason === 'nonQuality',
  );

  return (
    <>
      <div className="space-y-4 text-gray-d-700">
        <p>
          Are you sure you want to remove{' '}
          <span className="font-semibold">
            {numRespondentsToRemove} respondents
          </span>
          ?
        </p>
        <div className="grid grid-cols-2 gap-y-3">
          {qualityRemovals && (
            <>
              <div>Quality Issue</div>
              <div>{qualityRemovals.userIds.length}</div>
            </>
          )}
          {nonQualityRemovals && (
            <>
              <div>Not a Quality Issue</div>
              <div>{nonQualityRemovals.userIds.length}</div>
            </>
          )}
        </div>
      </div>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isRemoving}
          size="lg"
          type="submit"
        >
          Remove Respondents
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickBack}
          size="lg"
          type="button"
        >
          Back
        </Button>
      </div>
    </>
  );
};

function checkRespondentIdsAreNumbers(data: string) {
  const respondentIds = splitAndTrimStr(data);

  return respondentIds.every((id) => !Number.isNaN(Number(id)));
}

function splitAndTrimStr(str: string) {
  return str
    .split('\n')
    .map((id) => id.trim())
    .filter((id) => !!id);
}

function getRemovalGroups({
  nonQualityRemovals,
  qualityRemovals,
}: RemovalFormData) {
  const groups: RemovalGroup[] = [
    {
      reason: 'nonQuality',
      userIds: splitAndTrimStr(nonQualityRemovals).map((id) => Number(id)),
    },
    {
      reason: 'quality',
      userIds: splitAndTrimStr(qualityRemovals).map((id) => Number(id)),
    },
  ];

  return groups.filter((group) => group.userIds.length > 0);
}
