import axios from 'axios';

import {
  Audience,
  AudienceLogicalModifier,
  Question,
} from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';

export interface CreateAudienceBody {
  audienceSlices: {
    audienceSliceCategories: {
      audienceSliceCategoryAttributes: (
        | number
        | { end: number; start: number }
      )[];
      logicalModifier: AudienceLogicalModifier;
      percentage: number;
      question: Question;
      questionId: number;
    }[];
    percentage: number;
  }[];
  dependentSlices: boolean;
  isPublic: boolean;
  surveyId?: number;
  title: string;
}

export const CREATE_AUDIENCE = () => ({
  path: '/audiences',
  version: 'v1' as const,
});

export async function createAudience({ data }: { data: CreateAudienceBody }) {
  return (await axios.post<Audience>(getAPIUrl(CREATE_AUDIENCE()), data)).data;
}
