import { DashboardFilters, DashboardTab } from '../types/internal';

/**
 * Keys for storing items in local storage.
 */
export enum LOCALSTORAGE_KEYS {
  DASHBOARD_FILTERS = 'gauge-dashboard-filters',
  DASHBOARD_TAB = 'gauge-dashboard-tab',
  TOKEN = 'gauge-token',
}

type LocalStorageTypes<T extends LOCALSTORAGE_KEYS> =
  T extends LOCALSTORAGE_KEYS.DASHBOARD_FILTERS
    ? DashboardFilters
    : T extends LOCALSTORAGE_KEYS.DASHBOARD_TAB
      ? DashboardTab
      : T extends LOCALSTORAGE_KEYS.TOKEN
        ? string
        : never;

/**
 * Returns a specified item from localStorage.
 */
export function getItem<T extends LOCALSTORAGE_KEYS>(
  key: T,
): LocalStorageTypes<T> | null {
  try {
    const item = JSON.parse(window.localStorage.getItem(key) || '');

    return item || null;
  } catch (err) {
    return null;
  }
}

/**
 * Persists the provided JWT into local storage.
 */
export function setItem<T extends LOCALSTORAGE_KEYS>(
  key: T,
  value: LocalStorageTypes<T> | null,
): void {
  if (key) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    window.localStorage.removeItem(key);
  }
}
