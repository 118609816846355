import axios from 'axios';

import { DashboardFilters, DashboardSorts } from '../../types/internal';
import { DashboardSurvey } from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';
import { queryStringify } from 'util/api';

export interface FetchDashboardSurveysResponse {
  draftAmount: number;
  launchedAmount: number;
  nextOffset: number;
  results: DashboardSurvey[];
}

export const FETCH_DASHBOARD_SURVEYS = () => ({
  path: '/dashboard',
  version: 'v1' as const,
});

export async function fetchDashboardSurveys({
  activeTab = 'launched',
  filters,
  limit,
  name = '',
  offset,
  organizationId,
  sorts,
}: {
  activeTab?: 'drafts' | 'launched';
  filters: DashboardFilters;
  limit: number;
  name?: string;
  offset?: number;
  organizationId?: number;
  sorts: DashboardSorts;
}) {
  return (
    await axios.get<FetchDashboardSurveysResponse>(
      getAPIUrl(FETCH_DASHBOARD_SURVEYS()),
      {
        params: {
          'filter[dateEnd]': filters.createdAt.enabled
            ? filters.createdAt.end
            : '',
          'filter[dateStart]': filters.createdAt.enabled
            ? filters.createdAt.start
            : '',
          'filter[projectId]': filters.tag.enabled ? filters.tag.value : '',
          'filter[status]':
            activeTab === 'drafts' ? 'draft' : ['approved', 'completed'],
          'filter[title]': name,
          'filter[userId]': filters.userId.enabled ? filters.userId.value : '',
          limit,
          offset,
          organizationId,
          'sort[createdAt]': sorts.createdAt,
          'sort[title]': sorts.name,
        },
        // Need custom paramsSerializer here to transform the "filter[status]" array into
        // "filter[status]=approved&filter[status]=completed" instead of "filter[status][]=approved&filter[status][]=completed".
        // We should probably update the API endpoint at some point to accept the latter format.
        paramsSerializer: (params) => {
          return queryStringify(params);
        },
      },
    )
  ).data;
}
