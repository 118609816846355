import { ReactNode } from 'react';

const IndexCard = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="flex bg-white flex-1 flex-col rounded-lg border border-gray-300">
      {children}
    </div>
  );
};

export default IndexCard;
