import { ReactNode, useRef } from 'react';

const FileUpload = ({
  disabled = false,
  onLoadFile,
  trigger,
}: {
  disabled?: boolean;
  onLoadFile(opts: { data: string; type: string }): void;
  trigger: ReactNode;
}): JSX.Element => {
  const fileUploadInput = useRef<HTMLInputElement | null>(null);

  return (
    <div
      onClick={() => {
        if (!disabled && fileUploadInput.current) {
          fileUploadInput.current.click();
        }
      }}
    >
      {trigger}
      <input
        ref={fileUploadInput}
        className="hidden"
        onChange={(event) => {
          const files = event.target.files;
          const file = files?.item(0);

          if (!file) {
            return;
          }

          const reader = new FileReader();
          reader.onload = () => {
            // reader.result is a "string" because we used the readAsDataURL method
            // to read the file's contents.
            onLoadFile({
              data: (reader.result as string | null) ?? '',
              type: file.type.substring(0, file.type.indexOf('/')),
            });

            // Once we've read the file, we clear the value so the user could choose
            // to re-upload the same file.
            if (fileUploadInput.current) {
              fileUploadInput.current.value = '';
            }
          };
          reader.readAsDataURL(file);
        }}
        type="file"
      />
    </div>
  );
};

export default FileUpload;
