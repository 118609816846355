import { omitBy } from 'lodash-es';

import { QueryParams } from 'types/internal';

export function removeQueryParamsEmptyKeys(obj: QueryParams) {
  return omitBy(obj, (value) => {
    return value === undefined || value === '';
  });
}

/**
 * Converts the provided string value into an object, split by the delimiters.
 */
export function stringToObject({
  propDelimiter,
  value,
  valueDelimiter,
  valueProcessorFn = null,
}: {
  propDelimiter: string;
  value?: string | null;
  valueDelimiter: string;
  valueProcessorFn?: ((value: string) => string) | null;
}): Record<string, string | undefined> {
  if (!value) {
    return {};
  }

  return value.split(propDelimiter).reduce((queryObj, term) => {
    const termArr = term.split(valueDelimiter);
    const termValue = termArr[1];

    return {
      ...queryObj,
      [termArr[0]]:
        typeof valueProcessorFn === 'function'
          ? valueProcessorFn(termValue)
          : termValue,
    };
  }, {});
}
