import { clsx } from 'clsx';
import { ReactNode, useState } from 'react';
import { Form, Formik, FormikErrors, useField } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { createTeamMemberOption } from '../../util/team';
import { DATE_FORMATS, formatDate } from '../../util/dates';
import { getOrgStatus } from '../../util/users';
import { questionQueries } from 'hooks/backend/questions';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage } from '../../util/notifications';
import {
  surveyQueries,
  useCreateSurveyFromTemplate,
} from 'hooks/backend/surveys';
import { SurveyTemplate, TeamMember } from 'types/domainModels';
import { useHasRole } from '../../hooks/users';
import { useOrderedOrganizations } from '../../hooks/backend/organizations';
import { useOrderedTeamMembers } from '../../hooks/backend/team';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import DIYIllustration from '../common/illustrations/DIYIllustration';
import DocumentAddIcon from '../common/icons/DocumentAddIcon';
import ErrorDisplay from '../common/ErrorDisplay';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import GlassTemplateIllustration from '../common/illustrations/GlassTemplateIllustration';
import Icon from 'components/common/Icon';
import Modal, { ModalHeader } from '../common/Modal';
import TemplateLibIllustration from '../common/illustrations/TemplateLibIllustration';
import UserBubble from '../common/UserBubble';

interface SelectTemplateFormData {
  organization: ReactSelectValue<number> | null;
  owner: ReactSelectValue<TeamMember> | null;
  title: string;
}

type NewSurveyStep = 'adminConfirm' | 'chooseCreationRoute' | 'chooseTemplate';

function validateSelectTemplateData(
  formData: SelectTemplateFormData,
  { isAdmin = false } = {},
): FormikErrors<SelectTemplateFormData> {
  const errors: FormikErrors<SelectTemplateFormData> = {};

  if (!formData.organization && isAdmin) {
    errors.organization = 'Please select an organization.';
  }

  if (!formData.owner && isAdmin) {
    errors.owner = 'Please select an owner.';
  }

  if (!formData.title) {
    errors.title = 'Please enter a title.';
  } else if (formData.title.length < 5) {
    errors.title = 'Please enter at least 5 letters for the title.';
  }

  return errors;
}

const NewSurveyModal = ({
  onCloseModal,
}: {
  onCloseModal(): void;
}): JSX.Element => {
  const [newSurveyStep, setNewSurveyStep] = useState<NewSurveyStep>(
    'chooseCreationRoute',
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          {newSurveyStep === 'chooseCreationRoute'
            ? 'Create a New Survey'
            : 'Choose Template'}
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <div className="w-template-modal max-w-full">
        {newSurveyStep === 'chooseCreationRoute' && (
          <ChoosingCreationRoute
            onClickChooseTemplate={() => {
              setNewSurveyStep('chooseTemplate');
            }}
          />
        )}
        {newSurveyStep === 'chooseTemplate' && <ChoosingTemplate />}
      </div>
    </Modal>
  );
};

export default NewSurveyModal;

const ChoosingCreationRoute = ({
  onClickChooseTemplate,
}: {
  onClickChooseTemplate(): void;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div>
      <p className="text-dark-grey text-sm">
        This is where you can create a new survey. Choose one of the options
        below to get started.
      </p>
      <div className="grid grid-cols-3 gap-8 mt-4 mx-4">
        <CreationRouteOption
          buttonText="Start from Scratch"
          description="Ready to start from scratch? Dive right in to the survey builder to select your audience, write your questions, and get insights quickly."
          illustration={
            <div className="absolute bottom-0 left-1/2 w-3/4 transform -translate-x-1/2">
              <DIYIllustration />
            </div>
          }
          onChooseRoute={() => {
            navigate('/campaign/create');
          }}
          title="Do It Yourself"
        />
        <CreationRouteOption
          buttonText="Start from My Template Library"
          description="Start a survey from your template library to get started even quicker. Collaborate with co-workers to create templates that work for your team."
          illustration={
            <div className="absolute bottom-0 left-1/2 w-1/2 transform -translate-x-1/2">
              <TemplateLibIllustration />
            </div>
          }
          onChooseRoute={onClickChooseTemplate}
          title="My Template Library"
        />
        <CreationRouteOption
          buttonText="Coming Soon!"
          description="Glass Templates will include customizable turnkey approaches to help you run concept tests, claims research, market sizing studies and more!"
          illustration={
            <div className="absolute -bottom-6 left-1/2 w-3/4 transform -translate-x-1/2">
              <GlassTemplateIllustration />
            </div>
          }
          isComingSoon={true}
          onChooseRoute={onClickChooseTemplate}
          title="Glass Template"
        />
      </div>
    </div>
  );
};

const CreationRouteOption = ({
  buttonText,
  description,
  illustration,
  isComingSoon = false,
  onChooseRoute,
  title,
}: {
  buttonText: string;
  description: string;
  illustration: ReactNode;
  isComingSoon?: boolean;
  onChooseRoute?: () => void;
  title: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <h1 className="font-semibold">{title}</h1>
      <div className="relative w-full h-56 rounded-xl bg-light-purple overflow-hidden">
        {illustration}
      </div>
      <p className="flex-grow text-dark-grey text-sm text-center">
        {description}
      </p>
      <div className="flex w-full">
        <Button
          disabled={isComingSoon}
          grow
          hierarchy={isComingSoon ? 'secondary-gray' : 'primary'}
          icon={<Icon id="arrow-circle-right" />}
          iconPlacement="trailing"
          onClick={onChooseRoute}
          size="md"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const ChoosingTemplate = (): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate | null>(null);

  const {
    data: templates = [],
    error: loadTemplatesError,
    isError: hasLoadTemplatesError,
    isLoading: isLoadingTemplates,
  } = useQuery(surveyQueries.surveyTemplates);

  return (
    <div className="pb-4">
      <p className="text-dark-grey text-sm">
        Select a template from your library to get started.
      </p>
      <div className="flex justify-between mt-4">
        <div className="w-3/5 mr-8">
          <h1 className="mb-2 text-sm font-semibold">Template Library</h1>
          {hasLoadTemplatesError && (
            <ErrorDisplay
              message={`Failed to load template library. (${loadTemplatesError?.message})`}
            />
          )}
          {isLoadingTemplates ? (
            <SurveyTemplatePlaceholderRows />
          ) : (
            <div className="h-96 overflow-auto">
              {templates.length === 0 && (
                <div className="text-sm">
                  <span>You have no templates. Use the</span>{' '}
                  <div className="inline-block w-4 h-4">
                    <DocumentAddIcon />
                  </div>{' '}
                  <span>
                    icon in the quick actions of "Your Surveys" to create a
                    template from an existing survey.
                  </span>
                </div>
              )}
              {templates.map((template, i) => {
                const isSelected = selectedTemplate?.id === template.id;

                return (
                  <ExistingSurveyTemplate
                    key={template.id}
                    isLastRow={i === templates.length - 1}
                    isSelected={isSelected}
                    onClickTemplate={() => {
                      setSelectedTemplate(template);
                    }}
                    template={template}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="w-2/5">
          <SelectedTemplateGetStarted selectedTemplate={selectedTemplate} />
        </div>
      </div>
    </div>
  );
};

const SurveyTemplatePlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="py-4 px-2 border-b border-light-grey">
            <div className="flex space-x-2 animate-pulse">
              <div className="w-5 h-5 mt-1 border border-light-grey rounded-full bg-light-grey" />
              <div className="w-full space-y-2">
                <div className="w-3/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-4 bg-light-grey" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const ExistingSurveyTemplate = ({
  isLastRow,
  isSelected,
  onClickTemplate,
  template,
}: {
  isLastRow: boolean;
  isSelected: boolean;
  onClickTemplate(): void;
  template: SurveyTemplate;
}): JSX.Element => {
  const { createdAt, description, title, user } = template;

  return (
    <div
      className={clsx('flex w-full py-4 px-2 space-x-2 cursor-pointer', {
        'bg-darker-white': isSelected,
        'hover:bg-dark-white': !isSelected,
        'border-b border-light-grey': !isLastRow,
      })}
      onClick={onClickTemplate}
    >
      <div
        className={clsx(
          'flex-shrink-0 flex items-center justify-center w-5 h-5 mt-1 border border-light-grey rounded-full',
          {
            'bg-gray-400': isSelected,
          },
        )}
      >
        {isSelected && (
          <div className="w-4 h-4 text-white">
            <Icon id="check" />
          </div>
        )}
      </div>
      <div className="flex-grow">
        <div className="flex justify-between mb-1">
          <div className="pr-2">
            <p>{title}</p>
            <p className="text-dark-grey text-xs">
              Created:{' '}
              {formatDate(createdAt, {
                format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
              })}
            </p>
          </div>
          <div className="flex-shrink-0">
            <UserBubble user={user} />
          </div>
        </div>
        <p className="text-dark-grey text-sm">{description}</p>
      </div>
    </div>
  );
};

const SelectedTemplateGetStarted = ({
  selectedTemplate,
}: {
  selectedTemplate: SurveyTemplate | null;
}): JSX.Element => {
  const navigate = useNavigate();

  const isAdmin = useHasRole('admin');

  const { currentOrganizationId, hasChangedOrg } = getOrgStatus();

  const { isLoadingOrgs, organizations: organizationOptions } =
    useOrderedOrganizations();
  const initialSurveyOrg = hasChangedOrg
    ? organizationOptions.find(({ value }) => {
        return value === currentOrganizationId;
      })
    : undefined;

  const { isPending: isCreatingSurvey, mutate: createSurveyFromTemplate } =
    useCreateSurveyFromTemplate({
      onError: (err) => {
        showErrorMessage(
          `Failed to create survey from template. Error: ${err.message}`,
        );
      },
      onSuccess: (survey) => {
        navigate(`/campaign/edit/${survey.id}/overview`);
      },
    });

  return (
    // The -mt-4 is to pull this div up so the "Questions in Template" header aligns with
    // the "Template Library" header.
    <div className="flex flex-col h-full -mt-4">
      {selectedTemplate ? (
        <Formik<SelectTemplateFormData>
          enableReinitialize={true}
          initialValues={{
            organization: initialSurveyOrg ?? null,
            owner: null,
            title: '',
          }}
          onSubmit={(formData) => {
            return createSurveyFromTemplate({
              data: {
                organizationId: isAdmin
                  ? formData.organization?.value
                  : undefined,
                ownerId: isAdmin ? formData.owner?.value.id : undefined,
                title: formData.title,
              },
              surveyId: selectedTemplate.id,
            });
          }}
          validate={(formData) => {
            return validateSelectTemplateData(formData, { isAdmin });
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <div className="flex flex-col h-full">
            <div className="flex-grow">
              <TemplateQuestions selectedTemplate={selectedTemplate} />
            </div>
            <div className="mt-4">
              <SelectedTemplateForm
                hasChangedOrg={hasChangedOrg}
                isAdmin={isAdmin}
                isCreatingSurvey={isCreatingSurvey}
                isLoadingOrgs={isLoadingOrgs}
                organizations={organizationOptions}
              />
            </div>
          </div>
        </Formik>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <div className="relative w-3/4 h-3/4 rounded-full bg-light-purple text-dark-grey text-sm">
            <div className="absolute bottom-0 left-1/2 w-1/2 transform -translate-x-1/2">
              <TemplateLibIllustration />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TemplateQuestions = ({
  selectedTemplate,
}: {
  selectedTemplate: SurveyTemplate;
}) => {
  const {
    data: questions = [],
    error: loadQuestionsError,
    isError: hasLoadTemplatesError,
    isLoading: isLoadingTemplateQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId: selectedTemplate.id }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="h-96 p-4 rounded bg-darker-white overflow-auto">
      <h1 className="mb-2 text-sm font-semibold">Questions in Template</h1>
      {hasLoadTemplatesError && loadQuestionsError instanceof Error && (
        <ErrorDisplay
          message={`Failed to load questions for template. (${loadQuestionsError?.message})`}
        />
      )}
      {isLoadingTemplateQuestions ? (
        <TemplateQuestionsPlaceholderRows />
      ) : (
        <ol className="pl-4 list-decimal">
          {questions.map((question) => {
            return (
              <li key={question.id} className="mb-1 text-sm">
                {question.title}
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};

const TemplateQuestionsPlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="mb-2">
            <div className="flex items-center space-x-2 animate-pulse">
              <div className="w-5 h-5 rounded-full bg-light-grey" />
              <div className="w-3/4 h-4 bg-light-grey" />
            </div>
          </div>
        );
      })}
    </>
  );
};

const SelectedTemplateForm = ({
  hasChangedOrg,
  isAdmin,
  isCreatingSurvey,
  isLoadingOrgs,
  organizations,
}: {
  hasChangedOrg: boolean;
  isAdmin: boolean;
  isCreatingSurvey: boolean;
  isLoadingOrgs: boolean;
  organizations: ReactSelectValue<number>[];
}): JSX.Element => {
  const [{ value: organization }] =
    useField<SelectTemplateFormData['organization']>('organization');
  const [, , setOwnerHelpers] =
    useField<SelectTemplateFormData['owner']>('owner');

  const organizationId = organization?.value;

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });

  // We only want to display survey owner options to the admin users if they have already selected the
  // organization to which the survey will belong.
  const teamMembers =
    isAdmin && !!organizationId
      ? orderedTeamMembers.map((teamMember) => {
          return createTeamMemberOption(teamMember);
        })
      : [];

  return (
    <Form>
      <div className="space-y-4">
        {isAdmin && (
          <>
            <FormSearchSelectInput
              inputId="organization"
              isDisabled={hasChangedOrg || isLoadingOrgs}
              isLoading={isLoadingOrgs}
              label="Which organization does the survey belong to?"
              labelFor="organization"
              name="organization"
              onChange={() => {
                // If an organization is changed, we load new options for the survey owner so we want to clear out
                // the existing one and make the user select an updated value.
                setOwnerHelpers.setValue(null);
              }}
              options={organizations}
            />
            <FormSearchSelectInput
              inputId="owner"
              isDisabled={isLoadingTeamMembers}
              isLoading={isLoadingTeamMembers}
              label="Who is the owner of the survey?"
              labelFor="owner"
              name="owner"
              options={teamMembers}
            />
            <hr className="text-light-grey" />
          </>
        )}
        <div className="flex items-start w-full space-x-4">
          <div className="flex-grow">
            <FormInput
              id="title"
              label="New Survey Title"
              labelFor="title"
              name="title"
              size="lg"
              type="text"
            />
          </div>
          <div className="mt-5">
            <ButtonLoading
              hierarchy="primary"
              isLoading={isCreatingSurvey}
              size="md"
              type="submit"
            >
              Create Survey
            </ButtonLoading>
          </div>
        </div>
      </div>
    </Form>
  );
};
