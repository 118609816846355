import { ElementRef, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { formatDollars } from '../../util/currency';
import { pricesQueries } from 'hooks/backend/prices';
import { ReactSelectValue } from 'types/forms.js';
import { useChatSupport } from 'contexts/chatSupport';

import CheckboxWithTooltip from './forms/CheckboxWithTooltip';
import FormGroup from './forms/FormGroup';
import Input from './forms/Input';
import Modal, { ModalHeader } from './Modal';
import Select from './forms/Select';

const INCIDENCE_OPTIONS = [
  { label: '100 - 75%', value: '75' },
  { label: '74 - 50%', value: '50' },
  { label: '49 - 30%', value: '30' },
  { label: '29 - 20%', value: '20' },
  { label: '19 - 10%', value: '10' },
  { label: '9 - 5%', value: '5' },
  { label: '4 - 3%', value: '3' },
  { label: '2 - 1%', value: '1' },
] satisfies ReactSelectValue<string>[];

const QUESTION_COUNT_OPTIONS = [
  { label: '1 - 20', value: '20' },
  { label: '21 - 40', value: '40' },
  { label: '41 - 60', value: '60' },
] satisfies ReactSelectValue<string>[];

const SurveyQuoteModal = ({
  onCloseModal,
}: {
  onCloseModal(): void;
}): JSX.Element => {
  const { showChat } = useChatSupport();

  const numRespondentsField = useRef<ElementRef<'input'>>(null);

  const [services, setServices] = useState<string[]>([]);
  const [incidence, setIncidence] = useState<ReactSelectValue<string> | null>(
    null,
  );
  const [respondentsCount, setRespondentsCount] = useState(0);
  const [questionCount, setQuestionCount] =
    useState<ReactSelectValue<string> | null>(null);
  const [total, setTotal] = useState(0);

  const { data: quote, isPending } = useQuery(
    pricesQueries.getQuote({
      incidence: incidence ? Number(incidence.value) : null,
      questionCount: questionCount ? Number(questionCount.value) : null,
      respondentsCount,
      services,
    }),
  );

  const quoteTotal = quote?.total ?? 0;

  // We use an effect and additional piece of state so that while the next quote is loading,
  // we show the previous quote.
  useEffect(() => {
    if (
      incidence === null ||
      questionCount === null ||
      respondentsCount === 0 ||
      Number.isNaN(respondentsCount)
    ) {
      setTotal(0);
    } else if (!isPending) {
      setTotal(quoteTotal);
    }
  }, [incidence, respondentsCount, questionCount, isPending, quoteTotal]);

  // @TODO add an endpoint to get the service types
  const serviceOptions = [
    {
      name: 'methodology_and_approach_consultation',
      label: 'Methodology & Approach Consultation',
      type: 'survey',
      tooltip: `Up to an hour of time from a Glass research expert, who will work with you to identify the survey approach, methodology, audience best suited to answer your research goals.  This can happen at the start of a project, or to help review a questionnaire you've started to design.`,
    },
    {
      name: 'questionnaire_design',
      label: 'Questionnaire Design',
      type: 'survey',
      tooltip: `A Glass research expert will work with you to understand your goals, then craft a custom questionnaire that utilizes best practice methodology and design approach to meet your needs.  Questionnaire will be created on paper (not yet programmed into the Glass platform) and will include all details necessary to program into a survey (wording, question options, survey logic).`,
    },
    {
      name: 'survey_programming',
      label: 'Survey Programming',
      type: 'survey',
      tooltip: `Glass will take your written-out questionnaire and program it into a survey on the Glass platform.  Includes quality checks to ensure survey logic is working properly.`,
    },
    {
      name: 'survey_review',
      label: 'Survey Review',
      type: 'survey',
      tooltip: `Want to program your own survey, but with some support?  A Glass researcher will review your programmed survey to ensure it will operate as intended and share recommendations for best outcome.`,
    },
    {
      name: 'survey_field_management',
      label: 'Survey Field Management',
      type: 'survey',
      tooltip: `Glass will design and execute a sample and field approach to best meet your sample needs.  Includes field management and a round of final data cleaning.`,
    },
    {
      name: 'table_creation',
      label: 'Table Creation',
      type: 'deliverable',
      tooltip: `Creation of 1-10 custom banners and resulting crosstabs, including stat testing.`,
    },
    {
      name: 'scorecard',
      label: 'Scorecard',
      type: 'deliverable',
      tooltip: `Roughly 3 summary slides or Excel worksheets summarizing key metrics and highest level takeaways.`,
    },
    {
      name: 'focused_report',
      label: 'Focused Report',
      type: 'deliverable',
      tooltip: `A focused deliverable sharing "what matters" in roughly 10 slides.  This deliverable will focus on 3-5 key learnings or objectives in an easy to understand format.`,
    },
    {
      name: 'signature_deck',
      label: 'Signature Deck',
      type: 'deliverable',
      tooltip: `Our signature deliverable is a story-driven deck that is generally about 20 slides.  It focuses on the objectives for the study, including call-outs and recommendations based on the findings.`,
    },
    {
      name: 'extended_deck',
      label: 'Extended Deck',
      type: 'deliverable',
      tooltip: `The extended deck is story-driven, with topical deep-dives into sub-groups, or sub-topics of interest.  Generally 30+ slides and a comprehensive appendix.  This report option is generally used for multi-category, or mixed-methods projects.`,
    },
  ];

  const removeFromServices = (service: string) => {
    const newServices = services.filter((s) => s !== service);
    setServices(newServices);
  };

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Quote a New Survey
        </ModalHeader>
      }
      initialFocus={numRespondentsField}
      onCloseModal={onCloseModal}
    >
      <div className="grid grid-cols-3 gap-4">
        <FormGroup
          label="Number of Respondents"
          labelFor="participants"
          tooltip="Enter how many people you'd like to complete your survey. This is the number you pay for. People who do not qualify for your survey do not count towards this number."
        >
          <Input
            ref={numRespondentsField}
            id="respondentsCount"
            min={0}
            name="respondentsCount"
            onChange={(event) => {
              setRespondentsCount(parseInt(event.target.value, 10));
            }}
            size="md"
            type="number"
          />
        </FormGroup>
        <FormGroup label="Estimated Incidence" labelFor="estimatedIncidence">
          <Select
            id="incidence"
            name="incidence"
            onChange={(option) => {
              setIncidence(option);
            }}
            options={INCIDENCE_OPTIONS}
            value={incidence}
          />
        </FormGroup>
        <FormGroup
          label="Number of Questions"
          labelFor="numQuestions"
          tooltip="The number of questions impacts the length of the survey and therefore how many people who qualify will complete it. Because of this, longer surveys will cost more than shorter ones. But remember, core demographics are free and not included in your question count, so focus on questions unique to you."
        >
          <Select
            id="questionCount"
            name="questionCount"
            onChange={(option) => {
              setQuestionCount(option);
            }}
            options={QUESTION_COUNT_OPTIONS}
            value={questionCount}
          />
        </FormGroup>
      </div>
      <div className="mt-4 space-y-2">
        <h2 className="mb-2 font-bold">Survey Design & Fielding</h2>
        <CheckboxWithTooltip
          checked={services.includes('full_service_strategy')}
          id="full_service_strategy"
          label="Full Service Support: Strategy, design, programming & fielding"
          name="full_service_strategy"
          onChange={(event) => {
            if (event.target.checked) {
              const surveyTypeServices = serviceOptions
                .filter((service) => service.type === 'survey')
                .map((service) => service.name);
              setServices(
                [...services, event.target.value].filter(
                  (service) => !surveyTypeServices.includes(service),
                ),
              );
            } else {
              removeFromServices('full_service_strategy');
            }
          }}
          tooltip="A Glass research expert will work with you to understand your goals
          and then take the lead on study approach, design, programming, sample
          strategy, and field management. You'll be as involved in feedback and
          approval on each stage as you want to be, with the Glass team managing
          the work."
          type="checkbox"
          value="full_service_strategy"
        />

        <div className="flex items-center mt-8">
          <div className="flex-grow h-1 bg-light-grey" />
          <div className="flex flex-col items-center m-4">
            <span className="text-grey text-sm">
              or choose only the design services you need
            </span>
          </div>
          <div className="flex-grow h-1 bg-light-grey" />
        </div>
        {serviceOptions
          .filter((service) => service.type === 'survey')
          .map((service) => (
            <CheckboxWithTooltip
              key={service.name}
              checked={services.includes(service.name)}
              disabled={services.includes('full-service-strategy')}
              id={service.name}
              label={service.label}
              name={service.name}
              onChange={(event) => {
                if (event.target.checked) {
                  setServices((prevServices) => [
                    ...prevServices,
                    service.name,
                  ]);
                } else {
                  removeFromServices(service.name);
                }
              }}
              tooltip={service.tooltip}
              type="checkbox"
              value={service.name}
            />
          ))}
        <h2 className="pt-4 font-bold mb-2">Reporting & Analysis</h2>
        {serviceOptions
          .filter((service) => service.type === 'deliverable')
          .map((service) => (
            <CheckboxWithTooltip
              key={service.name}
              checked={services.includes(service.name)}
              id={service.name}
              label={service.label}
              name={service.name}
              onChange={(event) => {
                if (event.target.checked) {
                  setServices((prevServices) => [
                    ...prevServices,
                    service.name,
                  ]);
                } else {
                  removeFromServices(service.name);
                }
              }}
              tooltip={service.tooltip}
              type="checkbox"
              value={service.name}
            />
          ))}
      </div>
      <div className="flex items-center mt-8">
        <div className="flex-grow h-1 bg-light-grey" />
        <div className="flex flex-col items-center mx-4">
          <span className="text-green text-4xl">
            <span>{formatDollars(total / 100)}</span>
          </span>
          <span className="text-dark-grey text-xs">Estimated Cost</span>
        </div>
        <div className="flex-grow h-1 bg-light-grey" />
      </div>
      <p className="text-sm mt-2">
        Research services costs are an estimate and can vary based on project
        complexity/needs. Reach out to your Research Director or{' '}
        <a
          className="text-green cursor-pointer hover:underline"
          onClick={() => {
            showChat();
          }}
        >
          send us a chat
        </a>{' '}
        for serviced projects.
      </p>
    </Modal>
  );
};

export default SurveyQuoteModal;
