import DefaultLayout from '../layout/DefaultLayout';

const NotFoundPage = (): JSX.Element => {
  return (
    <DefaultLayout>
      <div className="m-6">
        <p>Sorry, we couldn't find the page you were looking for!</p>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
