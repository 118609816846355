import { InputHTMLAttributes } from 'react';

const SliderToggle = (
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'type'>,
) => {
  return (
    <input
      {...props}
      className="relative appearance-none w-8 h-5 bg-gray-d-300 rounded-full before:absolute before:content-[''] before:w-4 before:h-4 before:bg-white before:rounded-full before:top-0.5 before:left-0.5 checked:before:translate-x-3 checked:bg-primary-d-600 before:transition-all"
      type="checkbox"
    />
  );
};

export default SliderToggle;
