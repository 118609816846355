import { clsx } from 'clsx';
import { forwardRef, TextareaHTMLAttributes } from 'react';

type TextareaSize = 'md' | 'lg';

const SIZE_MAP: Record<TextareaSize, string> = {
  md: 'py-2 px-3 text-sm',
  lg: 'py-2.5 px-3.5 text-base',
};

type Props = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'className' | 'size'
> & {
  size: TextareaSize;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(function Textarea(
  { size, ...rest },
  ref,
) {
  return (
    <textarea
      rows={3}
      {...rest}
      ref={ref}
      className={clsx(
        'w-full rounded-lg border border-gray-d-300 shadow-sm text-gray-d-900 outline-primary-d-600',
        SIZE_MAP[size],
      )}
    />
  );
});

export default Textarea;
