import { useNavigate } from 'react-router-dom';

import OverviewStep from './OverviewStep';

const SurveyCreatePage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <OverviewStep
      isLoadingSurvey={false}
      isShowingUnsavedChanges={false}
      onDiscardChanges={() => {
        // Pass
      }}
      onDismissUnsavedChanges={() => {
        // Pass
      }}
      onHasError={() => {
        // Pass
      }}
      onOverviewDirtyChanged={() => {
        // Pass
      }}
      onOverviewSaved={() => {
        // Pass
      }}
      onStepCompleted={(surveyId) => {
        navigate(`/campaign/edit/${surveyId}/audience`);
      }}
      questions={[]}
      survey={undefined}
    />
  );
};

export default SurveyCreatePage;
