import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createComment,
  deleteComment,
  fetchQuestionComments,
  fetchSurveyComments,
  updateComment,
} from 'services/backend/comments';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const commentQueries = {
  all: () => ['comments'],
  questions: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchQuestionComments({ surveyId: opts.surveyId }),
      queryKey: [...commentQueries.all(), 'questions', opts.surveyId],
    }),
  survey: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchSurveyComments({ surveyId: opts.surveyId }),
      queryKey: [...commentQueries.all(), 'survey', opts.surveyId],
    }),
};

export function useCreateComment(
  opts?: ReactQueryFunctionCallbacks<typeof createComment>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createComment,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: commentQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useDeleteComment(
  opts?: ReactQueryFunctionCallbacks<typeof deleteComment>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: deleteComment,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: commentQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useUpdateComment(
  opts?: ReactQueryFunctionCallbacks<typeof updateComment>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: updateComment,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: commentQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}
