import { FormikErrors } from 'formik';

import {
  Audience as CreateQuestionAudience,
  AudienceSliceCategoryAttribute,
} from '../services/backend/questions';
import {
  Audience,
  DisplayLogicConstraint as DisplayLogicConstraintAPI,
  DisplayLogicLogicalModifier,
  Question,
  QuestionAudienceSliceCategoryAttribute,
  QUESTION_TYPE,
  SurveyVariableSegment,
  Survey,
} from '../types/domainModels';
import {
  ConstraintConcepts,
  ConstraintOptions,
  ConstraintWithNumber,
  ConstraintWithRange,
  ConstraintWithStatement,
  DisplayLogicAndGroup,
  DisplayLogicConstraint,
  DisplayLogicConstraintValidated,
  DisplayLogicOrGroups,
  DisplayLogicOrGroupsValidated,
  QuestionFormDataValidated,
  QuestionFormOption,
  ReactSelectValue,
} from '../types/forms';
import { CreateDisplayLogicBody } from '../types/requestBodies';
import {
  DISPLAY_LOGIC_MODIFIER_OPTIONS,
  getConceptOption,
  getConceptOptions,
  getOptionOption,
  getOptionOptions,
  getQuestionOption,
} from './formOptions';
import { getOptionTitleIndexFromSort } from './options';
import { isIdeaPresenterQuestion } from './questions';
import {
  flatten,
  castArray,
  groupBy,
  map,
  compact,
  find,
  filter,
  some,
  isEmpty,
} from 'lodash-es';

export const MODIFIER_OPTION_EITHER: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'either',
    value: 'should',
  };
export const MODIFIER_OPTION_EQUAL_TO: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'equal to',
    value: 'is',
  };
export const MODIFIER_OPTION_WITHIN: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'within',
    value: 'should',
  };
export const MODIFIER_OPTIONS_DEFAULT: ReactSelectValue<DisplayLogicLogicalModifier>[] =
  [
    MODIFIER_OPTION_EQUAL_TO,
    MODIFIER_OPTION_EITHER,
    { label: 'not', value: 'isnt' },
  ];

/**
 * Transforms display logic entered into a form into a format that the API accepts.
 */
export function getApiDataForDisplayLogic({
  displayLogic,
  survey,
}: {
  displayLogic: QuestionFormDataValidated['features']['displayLogic']['values'];
  survey: Survey;
}): CreateQuestionAudience[] {
  return displayLogic.map((orGroup, i) => {
    return {
      categories: {
        audienceSlices: [
          {
            audienceSliceCategories: orGroup.map((andGroup) => {
              return {
                audienceSliceCategoryAttributes:
                  getApiConstraintsForDisplayLogic({
                    constraints: andGroup.constraints,
                    survey,
                  }),
                conceptId: andGroup.concept?.value?.id ?? null,
                logicalModifier: andGroup.modifier.value,
                questionId: andGroup.question.value.id,
              };
            }),
            percentage: 1,
          },
        ],
        // TODO is dependentSlices always true?
        dependentSlices: true,
        // TODO is isPublic always false?
        isPublic: false,
      },
      sort: i + 1,
      unionModifier: i === 0 ? 'and' : 'or',
    };
  });
}

export function getApiDataForDisplayLogicV2({
  displayLogic,
  survey,
}: {
  displayLogic: DisplayLogicOrGroupsValidated;
  survey: Survey;
}): CreateDisplayLogicBody {
  return flatten(
    displayLogic.map((orGroup, orGroupIdx) => {
      return orGroup.map((andGroup) => {
        return {
          andGrouping: orGroupIdx,
          constraints: getApiConstraintsForDisplayLogicV2({
            constraints: andGroup.constraints,
            survey,
          }),
          logicalModifier: andGroup.modifier.value,
          questionId: andGroup.question.value.id,
        };
      });
    }),
  );
}

/**
 * Transforms a display logic form constraint into a format that the API accepts.
 */
export function getApiConstraintsForDisplayLogic({
  constraints,
  survey,
}: {
  constraints: DisplayLogicConstraintValidated[];
  survey: Survey;
}): AudienceSliceCategoryAttribute[] {
  return flatten<AudienceSliceCategoryAttribute>(
    constraints.map((constraint) => {
      if (isConstraintWithStatement(constraint)) {
        const { statement, options } = constraint;

        return castArray(options).map((option) => {
          return {
            enumStringMap: survey.useNewMatrixOptions ? null : option.label,
            enumValue: statement.value.id,
            id: statement.value.id,
            matrixOptionId: survey.useNewMatrixOptions ? option.value.id : null,
          };
        });
      }

      if (isConstraintWithRanges(constraint)) {
        const { option, range } = constraint;

        return {
          end: range.end,
          enumValue: option.value.id,
          start: range.start,
        };
      }

      if (isConstraintWithNumber(constraint)) {
        const { range } = constraint;

        return {
          end: range.end,
          start: range.start,
        };
      }

      if (isConstraintWithConcepts(constraint)) {
        return castArray(constraint.concepts).map(({ value }) => ({
          id: value.id,
        }));
      }

      return castArray(constraint.options).map(({ value }) => value.id);
    }),
  );
}

export function getApiConstraintsForDisplayLogicV2({
  constraints,
  survey,
}: {
  constraints: DisplayLogicConstraintValidated[];
  survey: Survey;
}): CreateDisplayLogicBody[number]['constraints'] {
  return flatten<CreateDisplayLogicBody[number]['constraints'][number]>(
    constraints.map((constraint) => {
      if (isConstraintWithStatement(constraint)) {
        const { statement, options } = constraint;

        return {
          optionId: statement.value.id,
          matrixOptions: survey.useNewMatrixOptions
            ? []
            : castArray(options).map((option) => {
                return option.label;
              }),
          matrixOptionIds: survey.useNewMatrixOptions
            ? castArray(options).map((option) => {
                return option.value.id;
              })
            : [],
        };
      }

      if (isConstraintWithRanges(constraint)) {
        const { option, range } = constraint;

        return {
          numberRange: { end: range.end, start: range.start },
          optionId: option.value.id,
        };
      }

      if (isConstraintWithNumber(constraint)) {
        return {
          numberRange: {
            end: constraint.range.end,
            start: constraint.range.start,
          },
        };
      }

      if (isConstraintWithConcepts(constraint)) {
        return castArray(constraint.concepts).map(({ value }) => {
          return { conceptId: value.id };
        });
      }

      return castArray(constraint.options).map(({ value }) => {
        return { optionId: value.id };
      });
    }),
  );
}

export function getEligibleQuestions({
  questions,
}: {
  questions: Question[];
}): Question[] {
  return questions.filter((question) => {
    return ![QUESTION_TYPE.GABOR_GRANGER, QUESTION_TYPE.OPEN_ENDED].includes(
      question.questionTypeId,
    );
  });
}

export function getEmptyAndGroup(): DisplayLogicAndGroup {
  return {
    concept: null,
    constraints: [],
    modifier: MODIFIER_OPTION_EQUAL_TO,
    question: null,
  };
}

export function getEmptyConstraintWithConcepts(): ConstraintConcepts {
  return { concepts: [] };
}

export function getEmptyConstraintWithNumber(): ConstraintWithNumber {
  return {
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithOptions(): ConstraintOptions {
  return { options: [] };
}

export function getEmptyConstraintWithRange(): ConstraintWithRange {
  return {
    option: null,
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithStatement(): ConstraintWithStatement {
  return {
    statement: null,
    options: [],
  };
}

/**
 * Transforms audiences from the API into a format usable for display logic in a form.
 */
export function getFormDisplayLogic({
  audiences,
  questions,
  survey,
}: {
  audiences: Audience[];
  questions: Question[];
  survey: Survey;
}): DisplayLogicOrGroups {
  const monadicConcepts = questions
    .filter((q) => !!q.monadicId)
    .flatMap((q) => q.concepts ?? []);

  const apiDisplayLogic =
    audiences
      .filter((audience) => !!audience)
      .map((audience) => {
        const andGroupData = audience.audienceSlices[0].audienceSliceCategories;

        return andGroupData.map(
          ({
            audienceSliceCategoryAttributes,
            conceptId,
            logicalModifier,
            question,
            questionId,
          }) => {
            const modifier =
              DISPLAY_LOGIC_MODIFIER_OPTIONS.find(
                ({ value }) => value === logicalModifier,
              ) || null;

            // We could potentially revisit looking at the questions array first instead of always just
            // using the "question" variable. Not doing that now because I'm not confident the "question"
            // here has the exact same structure as the questions in the "questions" array.
            const questionToUse =
              questions.find(({ id }) => {
                return id === questionId;
              }) || question;
            const conceptToUse = monadicConcepts.find(
              (c) => c.id === conceptId,
            );

            return {
              concept: questionToUse.monadicId
                ? conceptToUse
                  ? getConceptOption({ concept: conceptToUse })
                  : { label: 'Any concept', value: null }
                : null,
              constraints: getFormDisplayLogicConstraints({
                audienceSliceCategoryAttributes,
                question: questionToUse,
                survey,
              }),
              modifier,
              question: questionToUse
                ? getQuestionOption({ question: questionToUse })
                : null,
            };
          },
        );
      }) ?? [];

  return apiDisplayLogic.length > 0 ? apiDisplayLogic : [[getEmptyAndGroup()]];
}

/**
 * Transforms audience constraints from the API into a format usable for display logic
 * constraints in a form.
 */
function getFormDisplayLogicConstraints({
  audienceSliceCategoryAttributes,
  question,
  survey,
}: {
  audienceSliceCategoryAttributes: QuestionAudienceSliceCategoryAttribute[];
  question: Question | undefined;
  survey: Survey;
}): DisplayLogicConstraint[] {
  if (!question) {
    return [];
  }

  if (isIdeaPresenterQuestion(question)) {
    const conceptIds = audienceSliceCategoryAttributes.map(
      ({ audienceAttribute }) => {
        return audienceAttribute.enumNumberRange?.start;
      },
    );

    return [
      {
        concepts: getConceptOptions({
          concepts: question.concepts ?? [],
          filterFn: ({ id }) => {
            return conceptIds.includes(id);
          },
        }),
      },
    ];
  } else if (question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    const optionIds = audienceSliceCategoryAttributes.map(
      ({ audienceAttribute }) => {
        return audienceAttribute.enumValueId;
      },
    );

    return [
      {
        options: getOptionOptions({
          filterFn: ({ id }) => {
            return optionIds.includes(id);
          },
          options: question.options,
        }),
      },
    ];
  } else if (
    question.questionTypeId === QUESTION_TYPE.RANKING ||
    question.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return audienceSliceCategoryAttributes.map(({ audienceAttribute }) => {
      const option = question.options.find(({ id }) => {
        return id === audienceAttribute.enumValueId;
      });

      return {
        option: option
          ? getOptionOption({
              index: getOptionTitleIndexFromSort(option.sort),
              option,
            })
          : null,
        range: {
          end: audienceAttribute.enumNumberRange?.end ?? '',
          start: audienceAttribute.enumNumberRange?.start ?? '',
        },
      };
    });
  } else if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
    const groupedByStatement = groupBy(
      audienceSliceCategoryAttributes,
      'audienceAttribute.enumValueId',
    );

    return map(groupedByStatement, (group, statementId) => {
      const statement = question.options.find(({ id }) => {
        return `${id}` === statementId;
      });

      let options: ConstraintWithStatement['options'];

      if (survey.useNewMatrixOptions) {
        options = question.matrixOptions
          .filter((option) => {
            return group.find(({ audienceAttribute }) => {
              return audienceAttribute.matrixOptionId === option.id;
            });
          })
          .map((option) => {
            return {
              label: option.title,
              value: option,
            };
          });
      } else {
        const matrixOptions = group.map(({ audienceAttribute }) => {
          return audienceAttribute.enumRegex;
        });

        options =
          question.labels
            ?.filter(({ optionLabel }) => {
              return matrixOptions.includes(optionLabel);
            })
            .map((option) => {
              return {
                label: option.optionLabel,
                value: option,
              };
            }) ?? [];
      }

      return {
        statement: statement
          ? getOptionOption({
              index: getOptionTitleIndexFromSort(statement.sort),
              option: statement,
            })
          : null,
        options,
      };
    });
  }

  return [];
}

export function getFormDisplayLogicConstraintsV2({
  apiConstraints,
  fullQuestion,
  survey,
}: {
  apiConstraints: DisplayLogicConstraintAPI[];
  fullQuestion: Question;
  survey: Survey;
}): DisplayLogicConstraint[] {
  if (isIdeaPresenterQuestion(fullQuestion)) {
    return [
      {
        concepts: compact(
          apiConstraints.map((constraint) => {
            const concept = find(
              fullQuestion?.concepts,
              ({ id }) => id === constraint.conceptId,
            );

            return concept ? getConceptOption({ concept }) : undefined;
          }),
        ),
      },
    ];
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return [
      {
        options: compact(
          apiConstraints.map((constraint) => {
            const option = find(
              fullQuestion.options,
              ({ id }) => id === constraint.optionId,
            );

            return option ? getOptionOption({ option }) : undefined;
          }),
        ),
      },
    ];
  } else if (
    fullQuestion.questionTypeId === QUESTION_TYPE.RANKING ||
    fullQuestion.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return compact(
      apiConstraints.map((constraint) => {
        const option = find(
          fullQuestion.options,
          ({ id }) => id === constraint.optionId,
        );

        return option && constraint.numberRange
          ? {
              option: getOptionOption({ option }),
              range: {
                end: constraint.numberRange.end,
                start: constraint.numberRange.start,
              },
            }
          : undefined;
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MATRIX) {
    return compact(
      apiConstraints.map((constraint) => {
        const statement = find(
          fullQuestion.options,
          ({ id }) => id === constraint.optionId,
        );
        let options: ConstraintWithStatement['options'] = [];

        if (survey.useNewMatrixOptions) {
          options = fullQuestion.matrixOptions
            .filter((option) => {
              return constraint.matrixOptionIds
                ? constraint.matrixOptionIds.includes(option.id)
                : false;
            })
            .map((option) => {
              return {
                label: option.title,
                value: option,
              };
            });
        } else {
          options = filter(statement?.labels, ({ optionLabel }) => {
            return constraint.matrixOptions
              ? constraint.matrixOptions.includes(optionLabel)
              : false;
          }).map((option) => {
            return {
              label: option.optionLabel,
              value: option,
            };
          });
        }

        if (!statement || options.length === 0) {
          return;
        }

        return {
          statement: getOptionOption({ option: statement }),
          options,
        };
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.NUMBER) {
    return compact(
      apiConstraints.map((constraint) => {
        if (!constraint.numberRange) {
          return;
        }

        return {
          range: {
            end: constraint.numberRange.end,
            start: constraint.numberRange.start,
          },
        };
      }),
    );
  }

  return [];
}

/**
 * It's possible a display logic expectation may reference a matrix option that doesn't
 * exist since we currently reference the matrix option's title, instead of its ID. So
 * if a user updates a matrix option title, it can break display logic later in the survey.
 */
export function getMissingMatrixOption({
  expectation,
  question,
}: {
  expectation: QuestionAudienceSliceCategoryAttribute['audienceAttribute'];
  question: Question | undefined;
}) {
  if (
    question?.questionTypeId === QUESTION_TYPE.MATRIX &&
    !expectation.matrixOptionId
  ) {
    const matrixOptionTitle = expectation.enumRegex;
    if (matrixOptionTitle) {
      const matrixOption = question.labels?.find(
        ({ optionLabel }) => optionLabel === matrixOptionTitle,
      );

      return matrixOption ? undefined : { title: matrixOptionTitle };
    }
  }

  return undefined;
}

export function hasConfiguredDisplayLogic(
  displayLogic: DisplayLogicOrGroups,
): boolean {
  return (
    filter(displayLogic, (group) => {
      return some(group, (andGroup) => {
        return isFullyConfiguredGroup(andGroup);
      });
    }).length > 0
  );
}

export function hasDisplayLogicReferencesForMatrixOption({
  displayLogic,
  matrixOption,
  survey,
}: {
  displayLogic: DisplayLogicOrGroups;
  matrixOption: QuestionFormOption;
  survey: Survey;
}): boolean {
  return some(displayLogic, (orGroup) => {
    return some(orGroup, (andGroup) => {
      return some(andGroup.constraints, (constraint) => {
        if (isConstraintWithStatement(constraint)) {
          return some(castArray(constraint.options), (option) => {
            return survey.useNewMatrixOptions
              ? option.value.id === matrixOption.id
              : option.label === matrixOption.value;
          });
        }
      });
    });
  });
}

export function hasSurveyVariableReferencesForOption({
  segment,
  optionId,
}: {
  segment: SurveyVariableSegment;
  optionId: number;
}): boolean {
  return some(segment.questions, (question) => {
    return some(question.constraints, (constraint) => {
      return constraint.optionId === optionId;
    });
  });
}

export function hasSurveyVariableReferencesForConcept({
  segment,
  conceptId,
}: {
  segment: SurveyVariableSegment;
  conceptId: number;
}): boolean {
  return some(segment.questions, (question) => {
    return some(question.constraints, (constraint) => {
      return constraint.conceptId === conceptId;
    });
  });
}

export function hasSurveyVariableReferencesForMatrixOption({
  matrixOption,
  segment,
  survey,
}: {
  matrixOption: QuestionFormOption;
  segment: SurveyVariableSegment;
  survey: Survey;
}): boolean {
  return some(segment.questions, (question) => {
    return some(question.constraints, (constraint) => {
      if (survey.useNewMatrixOptions) {
        return constraint.matrixOptionIds && matrixOption.id
          ? constraint.matrixOptionIds.includes(matrixOption.id)
          : false;
      }

      return some(constraint.matrixOptions, (matrixOptionTitle) => {
        return matrixOptionTitle === matrixOption.value;
      });
    });
  });
}

export function hasSurveyVariableReferencesForQuestion({
  segment,
  questionId,
}: {
  segment: SurveyVariableSegment;
  questionId: number;
}): boolean {
  return some(
    segment.questions,
    (question) => question.questionId === questionId,
  );
}

export function hasDisplayLogicReferencesForOption({
  displayLogic,
  optionId,
}: {
  displayLogic: DisplayLogicOrGroups;
  optionId: number;
}): boolean {
  return some(displayLogic, (orGroup) => {
    return some(orGroup, (andGroup) => {
      return some(andGroup.constraints, (constraint) => {
        if (isConstraintWithConcepts(constraint)) {
          return some(
            castArray(constraint.concepts),
            (concept) => concept.value.id === optionId,
          );
        } else if (isConstraintWithStatement(constraint)) {
          return (
            constraint.statement?.value.id === optionId ||
            some(
              castArray(constraint.options),
              ({ value }) => value.id === optionId,
            )
          );
        } else if (isConstraintWithRanges(constraint)) {
          return constraint.option?.value.id === optionId;
        } else if (isConstraintWithOptions(constraint)) {
          return some(
            castArray(constraint.options),
            (option) => option.value.id === optionId,
          );
        }

        return false;
      });
    });
  });
}

export function hasPipingReferencesForQuestion({
  title,
  questionId,
}: {
  title: string;
  questionId: number;
}): boolean {
  const colonIdx = title.indexOf('::');
  // const brackIdx = title.slice(colonIdx).indexOf(')') + colonIdx;
  const colonslice = title.slice(colonIdx);
  const firstslashidx = colonslice.indexOf('/');
  const slashIdx = firstslashidx + colonIdx;

  if (colonIdx > -1) {
    const questionIdSegment = title.substring(colonIdx + 4, slashIdx);
    const pipedQuestionId = parseInt(questionIdSegment, 10);
    if (questionId === pipedQuestionId) {
      return true;
    }
  }

  return false;
}

export function hasPipingReferencesForOption({
  title,
  optionId,
}: {
  title: string;
  optionId?: number;
}): boolean {
  const colonIdx = title.indexOf('::');
  const brackIdx = title.slice(colonIdx).indexOf(')') + colonIdx;
  const colonslice = title.slice(colonIdx);
  const firstslashidx = colonslice.indexOf('/');
  let slashIdx = firstslashidx + colonIdx;

  if (colonIdx > -1) {
    const questionIdSegment = title.substring(colonIdx + 4, slashIdx);
    const pipedQuestionId = parseInt(questionIdSegment, 10);
    if (pipedQuestionId) {
      let optionIdSegment = title.substring(slashIdx + 1, brackIdx);
      slashIdx = optionIdSegment.indexOf('/');
      optionIdSegment = optionIdSegment.substring(0, slashIdx);

      const optionIdIdx = optionIdSegment.substring(1, optionIdSegment.length);
      const pipedOptionId = parseInt(optionIdIdx, 10);

      if (optionId === pipedOptionId) {
        return true;
      }
    }
  }

  return false;
}

export function hasDisplayLogicReferencesForQuestion({
  displayLogic,
  questionId,
}: {
  displayLogic: DisplayLogicOrGroups;
  questionId: number;
}): boolean {
  return some(displayLogic, (orGroup) => {
    return some(orGroup, (andGroup) => {
      return andGroup.question?.value.id === questionId;
    });
  });
}

export function isConstraintWithConcepts(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintConcepts {
  return (constraint as ConstraintConcepts).concepts !== undefined;
}

export function isConstraintWithNumber(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithNumber {
  return (
    (constraint as ConstraintWithRange).option === undefined &&
    (constraint as ConstraintWithNumber).range !== undefined
  );
}

export function isConstraintWithOptions(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintOptions {
  return (constraint as ConstraintOptions).options !== undefined;
}

export function isConstraintWithRanges(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithRange {
  // We need to check both "option" and "range" because number constraints have "range" but not "option".
  return (
    (constraint as ConstraintWithRange).option !== undefined &&
    (constraint as ConstraintWithRange).range !== undefined
  );
}

export function isConstraintWithStatement(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithStatement {
  return (constraint as ConstraintWithStatement).statement !== undefined;
}

export function isFullyConfiguredGroup(group: DisplayLogicAndGroup): boolean {
  const { constraints, modifier, question } = group;

  let hasMisconfiguredConstraint = false;
  for (let i = 0; i < constraints.length; i++) {
    const constraint = constraints[i];
    if (isConstraintWithStatement(constraint)) {
      if (!constraint.statement || castArray(constraint.options).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithRanges(constraint)) {
      if (
        !constraint.option ||
        constraint.range.start === '' ||
        constraint.range.end === ''
      ) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithNumber(constraint)) {
      if (constraint.range.start === '' || constraint.range.end === '') {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithConcepts(constraint)) {
      if (compact(castArray(constraint.concepts)).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else {
      if (compact(castArray(constraint.options)).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    }
  }

  return !!(question?.value && modifier?.value && !hasMisconfiguredConstraint);
}

export function validateDisplayLogicConstraints(
  constraints: DisplayLogicConstraint[],
): FormikErrors<DisplayLogicConstraint>[] | undefined {
  const constraintErrors: FormikErrors<DisplayLogicConstraint>[] = [];

  constraints.forEach((constraint) => {
    if (isConstraintWithStatement(constraint)) {
      const errors: FormikErrors<ConstraintWithStatement> = {};

      if (!constraint.statement) {
        errors.statement = 'Please select a statement.';
      }

      if (castArray(constraint.options).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.options = 'Please choose options.' as any;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithRanges(constraint)) {
      const errors: FormikErrors<ConstraintWithRange> = {};

      if (!constraint.option) {
        errors.option = 'Please select an option.';
      }

      if (constraint.range.end === '' || constraint.range.start === '') {
        const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

        if (constraint.range.end === '') {
          rangeErrors.end = 'Required';
        }

        if (constraint.range.start === '') {
          rangeErrors.start = 'Required';
        }

        errors.range = rangeErrors;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithNumber(constraint)) {
      const errors: FormikErrors<ConstraintWithNumber> = {};

      if (constraint.range.end === '' || constraint.range.start === '') {
        const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

        if (constraint.range.end === '') {
          rangeErrors.end = 'Required';
        }

        if (constraint.range.start === '') {
          rangeErrors.start = 'Required';
        }

        errors.range = rangeErrors;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithConcepts(constraint)) {
      const errors: FormikErrors<ConstraintConcepts> = {};

      if (castArray(constraint.concepts).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.concepts = 'Required' as any;
      }

      constraintErrors.push(errors);
    } else {
      const errors: FormikErrors<ConstraintOptions> = {};

      if (castArray(constraint.options).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.options = 'Required' as any;
      }

      constraintErrors.push(errors);
    }
  });

  const hasErrors = some(constraintErrors, (errors) => !isEmpty(errors));

  return hasErrors ? constraintErrors : undefined;
}

export function validateDisplayLogic(
  displayLogic: DisplayLogicOrGroups,
): FormikErrors<DisplayLogicAndGroup>[][] | undefined {
  const displayLogicErrors: FormikErrors<DisplayLogicAndGroup>[][] = [];

  displayLogic.forEach((orGroup) => {
    const orGroupErrors: FormikErrors<DisplayLogicAndGroup>[] = [];

    orGroup.forEach((andGroup) => {
      const andGroupErrors: FormikErrors<DisplayLogicAndGroup> = {};

      const constraintErrors = validateDisplayLogicConstraints(
        andGroup.constraints,
      );
      if (constraintErrors) {
        andGroupErrors.constraints = constraintErrors;
      }

      if (!andGroup.modifier) {
        andGroupErrors.modifier = 'Please select a modifier.';
      }

      if (!andGroup.question) {
        andGroupErrors.question = 'Please select a question.';
      } else if (
        !isIdeaPresenterQuestion(andGroup.question.value) &&
        !!andGroup.question.value.monadicId &&
        !andGroup.concept
      ) {
        andGroupErrors.concept = 'Please select a concept.';
      }

      orGroupErrors.push(andGroupErrors);
    });

    displayLogicErrors.push(orGroupErrors);
  });

  const hasErrors = some(displayLogicErrors, (orGroupErrors) => {
    return some(orGroupErrors, (andGroupErrors) => {
      return !isEmpty(andGroupErrors);
    });
  });

  return hasErrors ? displayLogicErrors : undefined;
}
