import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { IncidenceType } from '../../types/domainModels';

export const FETCH_INCIDENCE_TYPES = () => ({
  path: '/projects/incidenceTypes',
  version: 'v1' as const,
});

export async function fetchIncidenceTypes() {
  return (await axios.get<IncidenceType[]>(getAPIUrl(FETCH_INCIDENCE_TYPES())))
    .data;
}
