import { ReactNode } from 'react';

import Checkbox, { Props as CheckboxProps } from './Checkbox';
import Tooltip from '../Tooltip';

export type Props = Omit<CheckboxProps, 'label'> & {
  label: ReactNode;
  tooltip: ReactNode;
};

const CheckboxWithTooltip = ({
  label,
  tooltip,
  ...rest
}: Props): JSX.Element => {
  return (
    <Checkbox
      {...rest}
      label={
        <div className="flex items-center">
          <span className="mr-2">{label}</span>
          <Tooltip>{tooltip}</Tooltip>
        </div>
      }
    />
  );
};

export default CheckboxWithTooltip;
