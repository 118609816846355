import { clsx } from 'clsx';

const CircleLoader = ({
  isColored = false,
}: {
  isColored?: boolean;
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'flex justify-center w-3.5 h-3.5 rounded-full border-2 animate-spin-pulse',
        {
          'border-black-d': isColored,
          'border-white': !isColored,
        },
      )}
      style={{ borderBottomColor: 'transparent' }}
    />
  );
};

export default CircleLoader;
