import { ReactNode } from 'react';

import FormFieldError from './FormFieldError';
import FormFieldLabel from './FormFieldLabel';

const FormGroup = ({
  children,
  error,
  label,
  labelFor,
  tooltip,
}: {
  children: ReactNode;
  error?: string;
  label?: ReactNode;
  labelFor?: string;
  tooltip?: string | ReactNode;
}): JSX.Element => {
  return (
    <div>
      {label && (
        <div className="mb-1">
          <FormFieldLabel label={label} labelFor={labelFor} tooltip={tooltip} />
        </div>
      )}

      {children}

      {error ? <FormFieldError error={error} /> : null}
    </div>
  );
};

export default FormGroup;
