import { map } from 'lodash-es';

import {
  getOtherQuestionReferencesForMatrixOption,
  getVariableReferencesForMatrixOption,
} from '../../util/questions';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { QuestionFormOption } from 'types/forms';

import Button from '../common/forms/Button';
import Modal, { ModalHeader } from '../common/Modal';

const PreventDeleteMatrixOptionModal = ({
  matrixOption,
  onCloseModal,
  questions,
  survey,
  variables,
}: {
  matrixOption: QuestionFormOption;
  onCloseModal(): void;
  questions: Question[];
  survey: Survey;
  variables: SurveyVariable[];
}): JSX.Element => {
  const questionReferences = getOtherQuestionReferencesForMatrixOption({
    matrixOption,
    questions,
    survey,
  });
  const variableReferences = getVariableReferencesForMatrixOption({
    matrixOption,
    survey,
    variables,
  });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Remove Matrix Option
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <div className="text-sm">
        <p>
          This matrix option cannot be deleted or disabled because it is used in
          configuration for other questions. Please adjust the following
          questions before continuing.
        </p>
        <div className="mt-4 space-y-4">
          <div>
            {questionReferences.length > 0 && (
              <h2 className="mb-1 font-semibold">QUESTIONS</h2>
            )}
            {questionReferences.map(({ title, references }) => {
              return (
                <div key={title}>
                  <h2 className="mb-1 font-semibold">{title}</h2>
                  <ul className="list-disc pl-8">
                    {map(references, (reference, feature) => {
                      if (typeof reference === 'boolean') {
                        if (!reference) {
                          return null;
                        }

                        if (feature === 'carryForward') {
                          return <li key={feature}>Carry Forward</li>;
                        } else if (feature === 'displayLogic') {
                          return <li key={feature}>Display Logic</li>;
                        }
                      } else if (
                        feature === 'displayLogicOptions' &&
                        reference &&
                        reference.length > 0
                      ) {
                        return (
                          <li key={feature}>
                            Display Logic for Options
                            <ul className="list-circle pl-8">
                              {reference.map((option) => {
                                return <li key={option}>{option}</li>;
                              })}
                            </ul>
                          </li>
                        );
                      } else if (
                        feature === 'displayLogicConcepts' &&
                        reference &&
                        reference.length > 0
                      ) {
                        return (
                          <li key={feature}>
                            Display Logic for Concepts
                            <ul className="list-circle pl-8">
                              {reference.map((concept) => {
                                return <li key={concept}>{concept}</li>;
                              })}
                            </ul>
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div>
            {variableReferences.length > 0 && (
              <h2 className="mb-1 font-semibold">HIDDEN VARIABLES</h2>
            )}
            {variableReferences.map(({ title, references }) => {
              return (
                <div key={title}>
                  <h2 className="mb-1 font-semibold">{title}</h2>
                  <ul className="list-disc pl-8">
                    {map(references, (reference) => {
                      return reference.map((segment) => {
                        return <li key={segment}>{segment}</li>;
                      });
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end mt-8">
          <Button
            hierarchy="primary"
            onClick={onCloseModal}
            size="md"
            type="button"
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreventDeleteMatrixOptionModal;
