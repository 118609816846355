import { clsx } from 'clsx';
import { createPortal } from 'react-dom';
import { ReactNode, useEffect, useRef, useState } from 'react';

import Icon from './Icon';

let sidebarRoot = document.getElementById('sidebar-root');
if (!sidebarRoot) {
  sidebarRoot = document.createElement('div');
  sidebarRoot.setAttribute('id', 'sidebar-root');
  document.body.appendChild(sidebarRoot);
}

const Sidebar = ({
  children,
  isOpen,
}: {
  children: ReactNode;
  isOpen: boolean;
}): JSX.Element => {
  const [showChildren, setShowChildren] = useState(isOpen);
  const showChildrenTimeout = useRef<number | null>(null);

  useEffect(() => {
    if (showChildrenTimeout.current) {
      window.clearTimeout(showChildrenTimeout.current);
    }

    if (isOpen) {
      setShowChildren(true);
    } else {
      showChildrenTimeout.current = window.setTimeout(() => {
        setShowChildren(false);
      }, 300); // This timeout needs to match the duration of the transform transition below.
    }

    return () => {
      if (showChildrenTimeout.current) {
        window.clearTimeout(showChildrenTimeout.current);
      }
    };
  }, [isOpen]);

  return createPortal(
    <div
      className={clsx(
        'fixed top-0 right-0 h-screen border-l border-light-grey transform transition-transform duration-300 bg-white shadow-lg overflow-auto z-50',
        {
          'translate-x-0': isOpen,
          'translate-x-full': !isOpen,
        },
      )}
    >
      {showChildren && children}
    </div>,
    sidebarRoot as HTMLDivElement,
  );
};

export default Sidebar;

export const SidebarBody = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <div className="p-4">{children}</div>;
};

export const SidebarHeader = ({
  children,
  onClickClose,
}: {
  children: ReactNode;
  onClickClose(): void;
}): JSX.Element => {
  return (
    <div className="flex items-center justify-between mb-4">
      <h1 className="text-xl">{children}</h1>
      <div
        className="w-5 h-5 text-dark-grey cursor-pointer"
        onClick={onClickClose}
      >
        <Icon id="x" />
      </div>
    </div>
  );
};
