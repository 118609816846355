import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import Modal, { ModalHeader } from './Modal';

const UnsavedChangesModal = ({
  isSaving,
  onClickDiscardChanges,
  onClickSaveChanges,
  onCloseModal,
}: {
  isSaving: boolean;
  onClickDiscardChanges(): void;
  onClickSaveChanges(): void;
  onCloseModal(): void;
}): JSX.Element | null => {
  return (
    <Modal
      header={
        <ModalHeader
          onClickClose={() => {
            onCloseModal();
          }}
        >
          Unsaved Changes
        </ModalHeader>
      }
      onCloseModal={() => {
        onCloseModal();
      }}
    >
      <p>
        You have unsaved changes. Do you want to save them before you leave?
      </p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          hierarchy="primary"
          isLoading={isSaving}
          onClick={() => {
            onClickSaveChanges();
          }}
          size="sm"
          type="button"
        >
          Save Changes
        </ButtonLoading>
        <Button
          hierarchy="secondary-gray"
          onClick={() => {
            onClickDiscardChanges();
          }}
          size="sm"
          type="button"
        >
          Discard Changes
        </Button>
      </div>
    </Modal>
  );
};

export default UnsavedChangesModal;
