import { useMutation } from '@tanstack/react-query';

import {
  onboardingSetPassword,
  onboardingWelcome,
} from 'services/backend/onboarding';

export function useOnboardingSetPassword({
  onError,
  onSuccess,
}: {
  onError(): void;
  onSuccess(): void;
}) {
  return useMutation({ mutationFn: onboardingSetPassword, onError, onSuccess });
}

export function useOnboardingWelcome({
  onError,
  onSuccess,
}: {
  onError(): void;
  onSuccess(): void;
}) {
  return useMutation({ mutationFn: onboardingWelcome, onError, onSuccess });
}
