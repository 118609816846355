import { Combobox as HeadlessUICombobox } from '@headlessui/react';
import {
  ComponentProps,
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';

import Input from './forms/Input';

type InputProps = ComponentProps<typeof Input>;
type Option = { label: string; value: number };

const ComboboxContext = createContext<
  | {
      onChangeQuery(query: string): void;
      options: Option[];
      query: string;
    }
  | undefined
>(undefined);

const Combobox = ({
  children,
  onChange,
  options,
  value,
}: {
  children: ReactNode;
  onChange(option: Option | null): void;
  options: Option[];
  value: Option | null;
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <ComboboxContext.Provider
      value={{ onChangeQuery: setQuery, options: filteredOptions, query }}
    >
      <HeadlessUICombobox onChange={onChange} value={value}>
        {children}
      </HeadlessUICombobox>
    </ComboboxContext.Provider>
  );
};

export default Combobox;

export const ComboboxInput = (
  props: Pick<InputProps, 'icon' | 'iconPlacement'>,
) => {
  const context = useContext(ComboboxContext);
  const { query, onChangeQuery } = context ?? {};

  return (
    <HeadlessUICombobox.Input
      {...props}
      as={Input}
      onChange={(event) => {
        onChangeQuery?.(event.target.value);
      }}
      size="md"
      value={query}
    />
  );
};

export const ComboboxOptions = ({
  alwaysOpen = false,
}: {
  alwaysOpen?: boolean;
}) => {
  const context = useContext(ComboboxContext);
  const { options } = context ?? {};

  return (
    <HeadlessUICombobox.Options
      className="max-h-60 overflow-auto"
      static={alwaysOpen}
    >
      {options?.map((option) => (
        <HeadlessUICombobox.Option
          key={option.value}
          className="w-full flex items-center gap-2 py-3 px-2.5 text-gray-d-700 text-sm ui-active:bg-gray-d-50 whitespace-nowrap rounded-md select-none"
          value={option}
        >
          {option.label}
        </HeadlessUICombobox.Option>
      ))}
    </HeadlessUICombobox.Options>
  );
};

export const ComboboxOptionsShadow = () => {
  return (
    <div className="rounded bg-white shadow-lg">
      <ComboboxOptions />
    </div>
  );
};
