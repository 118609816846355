import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react';
import { clsx } from 'clsx';
import { ComponentProps, ElementType, ReactNode } from 'react';
import { Menu } from '@headlessui/react';

const Dropdown = ({
  button,
  children,
  placement = 'bottom-end',
}: {
  button: ReactNode;
  children: ReactNode;
  placement?: Placement;
}) => {
  const { refs, strategy, x, y } = useFloating({
    middleware: [offset({ mainAxis: 8 }), flip(), shift({ padding: 8 })],
    placement,
    whileElementsMounted: autoUpdate,
  });

  return (
    <Menu as="div" className="relative flex">
      <div ref={refs.setReference}>{button}</div>

      <FloatingPortal>
        <Menu.Items
          ref={refs.setFloating}
          className="rounded-lg bg-white border border-gray-d-200 shadow-lg py-1 px-1.5 min-w-[240px] z-30"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
        >
          {children}
        </Menu.Items>
      </FloatingPortal>
    </Menu>
  );
};

export default Dropdown;

export const DropdownButton = (props: ComponentProps<typeof Menu.Button>) => {
  return <Menu.Button {...props} />;
};

export function DropdownItem<TTag extends ElementType>({
  children,
  icon,
  ...rest
}: Omit<ComponentProps<typeof Menu.Item<TTag>>, 'className'> & {
  icon?: ReactNode;
}) {
  const iconToRender = icon ? (
    <div className="flex items-center justify-center w-4 h-4">{icon}</div>
  ) : null;

  return (
    <Menu.Item
      {...rest}
      className={clsx(
        'w-full flex items-center gap-2 py-3 px-2.5 text-gray-d-700 text-sm ui-active:bg-gray-d-50 whitespace-nowrap rounded-md select-none',
        {
          'text-left': rest.as === 'button',
        },
      )}
    >
      {iconToRender}
      {children}
    </Menu.Item>
  );
}
