import { HardcodedWavesQuestion } from 'util/exports';
import { Question, SurveyVariable } from './domainModels';
import { ReactSelectValue } from './forms';

export interface APIError extends Error {
  data?: Record<string, unknown>;
  statusCode?: number;
}

export interface DashboardFilters {
  createdAt: {
    enabled: boolean;
    end: string;
    start: string;
  };
  tag: {
    enabled: boolean;
    value: number | null;
  };
  userId: {
    enabled: boolean;
    value: number | null;
  };
}

export type DashboardSortValue = 'ASC' | 'DESC' | '';

export interface DashboardSorts {
  createdAt: DashboardSortValue;
  name: DashboardSortValue;
}

export type DashboardTab = 'drafts' | 'launched';

export type NestedErrors = ({ messages: string[]; title: string } | string)[];

export type OptionType =
  | 'audio-video'
  | 'email'
  | 'image'
  | 'number'
  | 'text'
  | 'zip';

export type QueryParams = Record<
  string,
  boolean | number[] | string | string[] | null | undefined | ''
>;

export type QuestionGroup<
  QuestionType extends
    | Question
    | SurveyVariable
    | HardcodedWavesQuestion
    | null,
> = {
  label: string;
  options: ReactSelectValue<QuestionType>[];
};

type ReactQueryVariables<
  // We allow "any" for the function args because we don't want to restrict the type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FunctionType extends (...args: any[]) => Promise<unknown>,
  AdditionalVariables extends Record<string, unknown>,
> =
  AdditionalVariables extends Record<string, never>
    ? Parameters<FunctionType>[0]
    : Parameters<FunctionType>[0] & AdditionalVariables;

export type ReactQueryFunctionCallbacks<
  // We allow "any" for the function args because we don't want to restrict the type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FunctionType extends (...args: any[]) => Promise<unknown>,
  AdditionalVariables extends Record<string, unknown> = Record<string, never>,
> = {
  onError?(
    error: Error,
    variables: ReactQueryVariables<FunctionType, AdditionalVariables>,
    context: unknown,
  ): void;
  onSuccess?(
    data: Awaited<ReturnType<FunctionType>>,
    variables: ReactQueryVariables<FunctionType, AdditionalVariables>,
    context: unknown,
  ): void;
  onSettled?(): void;
};

export interface RechartsXTickProps {
  payload: {
    coordinate: number;
    index: number;
    isShow: boolean;
    offset: number;
    tickCoord: number;
    value: string;
  };
  x: number;
  y: number;
}

export interface ResultBreakdown {
  base: number;
  concept?: string;
  count: number;
  label?: string;
  percentage: number;
  rank?: number;
  step?: number;
  title: string;
  monadicConceptId?: number;
}

export type SurveyFlowStep =
  | 'audience'
  | 'overview'
  | 'questions'
  | 'question-blocks'
  | 'review'
  | 'variables';

export const SurveyFlowSteps: { [step: string]: SurveyFlowStep } = {
  audience: 'audience',
  overview: 'overview',
  questions: 'questions',
  questionBlocks: 'question-blocks',
  review: 'review',
  variables: 'variables',
};
