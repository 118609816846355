import { clsx } from 'clsx';
import { InputHTMLAttributes, MouseEventHandler, ReactNode } from 'react';

export type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'onClick'
> & {
  label?: ReactNode;
  name: string;
  onClick?: MouseEventHandler<HTMLElement>;
  partial?: boolean;
  radio?: boolean;
  tag?: 'label' | 'div';
};

const Checkbox = ({
  checked,
  disabled = false,
  label,
  name,
  onClick,
  partial = false,
  radio,
  tag = 'label',
  ...rest
}: Props): JSX.Element => {
  const Component = tag;

  return (
    <div className="flex">
      <Component
        className={clsx(
          'flex items-center relative text-sm font-normal text-gray-900 select-none',
          {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          },
        )}
        onClick={onClick}
      >
        {/* Hide the browser's default checkbox. */}
        <input
          {...rest}
          checked={checked}
          className="absolute w-0 h-0 cursor-pointer opacity-0"
          disabled={disabled}
          name={name}
          type="checkbox"
        />
        {/* Create a custom checkbox. */}
        <div
          className={clsx(
            'relative w-4 h-4 border rounded flex justify-center items-center shrink-0',
            {
              'rounded-full': radio,
              'border-green bg-green': checked && !disabled && !radio,
              'border-green bg-white': checked && !disabled && radio,
              'border-gray-400 bg-gray-400 opacity-70': checked && disabled,
              'border-gray-400 bg-white': !checked && !disabled,
              'border-gray-400 bg-white opacity-70': !checked && disabled,
            },
          )}
        >
          {checked && !radio && (
            <>
              {partial ? (
                <div className="absolute top-1/2 -translate-y-1/2 w-3/5 h-0.5 bg-white" />
              ) : (
                <div
                  className="absolute transform rotate-45 border border-white border-t-0 border-r-2 border-b-2 border-l-0"
                  style={{
                    top: 2,
                    left: 5,
                    width: 5,
                    height: 9,
                  }}
                />
              )}
            </>
          )}
          {checked && radio && (
            <div className="relative w-1.5 h-1.5 bg-green rounded-full" />
          )}
        </div>

        {label && <span className="pl-2">{label}</span>}
      </Component>
    </div>
  );
};
export default Checkbox;
