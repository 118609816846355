const AddButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <button
      className="flex items-center px-3 py-1 select-none text-sm"
      onClick={onClick}
      type="button"
    >
      + {label}
    </button>
  );
};

export default AddButton;
