import { useModal } from '../../hooks/modals';
import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';

const DeleteVariable = ({
  isDeleting,
  onConfirmDelete,
  variableId,
}: {
  isDeleting: boolean;
  onConfirmDelete(variableId: number): void;
  variableId: number;
}): JSX.Element => {
  const {
    isOpen: isConfirmDelete,
    onCloseModal,
    setIsOpen: setIsConfirmDelete,
  } = useModal();

  return (
    <>
      <IconBackground
        onClick={() => {
          setIsConfirmDelete(true);
        }}
        title="Delete"
      >
        <div className="w-3 h-3">
          <Icon id="trash" />
        </div>
      </IconBackground>
      {isConfirmDelete && (
        <DeleteVariableModal
          isDeleting={isDeleting}
          onCloseModal={onCloseModal}
          onConfirmDelete={() => {
            onConfirmDelete(variableId);
          }}
        />
      )}
    </>
  );
};

export default DeleteVariable;

const DeleteVariableModal = ({
  isDeleting,
  onCloseModal,
  onConfirmDelete,
}: {
  isDeleting: boolean;
  onCloseModal(): void;
  onConfirmDelete(): void;
}): JSX.Element => {
  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Delete Variable</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <p>Are you sure you want to delete this variable?</p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="destructive"
          isLoading={isDeleting}
          onClick={onConfirmDelete}
          size="lg"
          type="button"
        >
          Delete
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
