import { ReactNode } from 'react';
import { useField } from 'formik';

import DateInput, { Props as DateInputProps } from './DateInput';
import FormGroup from './FormGroup';

const FormDateInput = ({
  label,
  name,
  tooltip,
}: Pick<DateInputProps, 'name'> & {
  label?: ReactNode;
  tooltip?: string;
}): JSX.Element => {
  const [field, meta, fieldHelpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      tooltip={tooltip}
    >
      <DateInput
        {...field}
        onChange={(value) => {
          fieldHelpers.setValue(value);
        }}
      />
    </FormGroup>
  );
};

export default FormDateInput;
