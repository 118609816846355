import { ComponentProps, forwardRef } from 'react';

import Button from './Button';
import CircleLoader from '../CircleLoader';

export type Props = ComponentProps<typeof Button> & {
  isLoading: boolean;
};

const ButtonLoading = forwardRef<HTMLButtonElement, Props>(
  function ButtonLoading(
    { icon, iconPlacement = 'leading', isLoading, ...rest },
    ref,
  ) {
    return (
      <Button
        type="submit"
        {...rest}
        ref={ref}
        disabled={rest.disabled || isLoading}
        icon={isLoading ? <CircleLoader /> : icon}
        iconPlacement={iconPlacement}
      />
    );
  },
);

export default ButtonLoading;
