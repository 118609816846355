import IndexCard from '../common/IndexCard';

const SkeletonSurveyCard = (): JSX.Element => {
  return (
    <div className="h-80 animate-pulse">
      <div className="w-1/2 h-6 mb-4 bg-light-grey" />
      <IndexCard>
        <div className="grid grid-cols-2 gap-4 p-6">
          <div className="w-full h-4 bg-light-grey" />
          <div className="w-full h-4 bg-light-grey" />
          <div className="w-full h-4 bg-light-grey" />
          <div className="w-full h-4 bg-light-grey" />
        </div>
      </IndexCard>
    </div>
  );
};

export default SkeletonSurveyCard;
