import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import Icon from './Icon';
import IconBackground from './icons/IconBackground';
import Popover from './Popover';

const TrashIconWithConfirmation = ({
  disabled = false,
  isConfirmOpen,
  isDeleting,
  name,
  onConfirmDelete,
  prompt,
  setIsConfirmOpen,
}: {
  disabled?: boolean;
  isConfirmOpen: boolean;
  isDeleting: boolean;
  name: string;
  onConfirmDelete(): void;
  prompt: string;
  setIsConfirmOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((isOpen: boolean) => void);
}): JSX.Element => {
  return (
    <Popover
      disabled={disabled}
      isOpen={isConfirmOpen}
      name={name}
      setIsOpen={setIsConfirmOpen}
      trigger={(triggerProps) => (
        <div
          {...triggerProps}
          className="flex gap-x-1 items-center cursor-pointer text-red"
        >
          <IconBackground disabled={disabled} title="Delete" visible={false}>
            <div className="w-3.5 h-3.5">
              <Icon id="trash" />
            </div>
          </IconBackground>
        </div>
      )}
    >
      <div className="p-4 text-sm">
        <p>{prompt}</p>
        <div className="flex flex-row-reverse mt-4 gap-3">
          <ButtonLoading
            hierarchy="destructive"
            isLoading={isDeleting}
            onClick={onConfirmDelete}
            size="md"
            type="button"
          >
            Delete
          </ButtonLoading>
          <Button
            hierarchy="secondary-gray"
            onClick={() => {
              setIsConfirmOpen(false);
            }}
            size="md"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default TrashIconWithConfirmation;
