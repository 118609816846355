import { ComponentProps } from 'react';
import { useField } from 'formik';

import Input from './Input';

const InputFormik = ({
  name,
  ...rest
}: {
  name: string;
} & Omit<ComponentProps<typeof Input>, 'onChange' | 'value'>): JSX.Element => {
  const [field] = useField({ name });

  return <Input {...field} {...rest} />;
};

export default InputFormik;
