import { clsx } from 'clsx';
import { useField, useFormikContext } from 'formik';

import { QuestionFormData } from '../../../types/forms';
import { supportsRangeConstraint } from 'util/questions';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';

const RangeConstraint = ({ constraintLabel }: { constraintLabel: string }) => {
  const { values: formData } = useFormikContext<QuestionFormData>();
  const [{ value: range }] =
    useField<QuestionFormData['constraint']['range']>('constraint.range');

  if (!supportsRangeConstraint({ formData })) {
    return null;
  }

  return (
    <div className="flex">
      <div
        className={clsx({
          'mt-2': range.enabled,
        })}
      >
        <FormCheckbox
          checkboxLabel={constraintLabel}
          name="constraint.range.enabled"
          tooltip={
            'Allows entering a min and max value the response must be between.'
          }
        />
      </div>
      {range.enabled && (
        <div className="flex ml-4 space-x-4">
          <div className="flex">
            <span className="text-sm mt-2">Min:</span>
            <div className="w-20 ml-2">
              <FormInput
                name="constraint.range.start"
                size="md"
                type="number"
              />
            </div>
          </div>
          <div className="flex">
            <span className="text-sm mt-2">Max:</span>
            <div className="w-20 ml-2">
              <FormInput name="constraint.range.end" size="md" type="number" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeConstraint;
