import axios, { isAxiosError } from 'axios';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { getEnvVar } from 'util/env';
import { getItem, LOCALSTORAGE_KEYS } from 'util/localStorage';
import { StatusCode } from 'util/api';

import './index.css';
import 'apiRequests';
import { AuthProvider } from './contexts/auth';
import ErrorBoundary from './components/errors/ErrorBoundary';
import { router } from './components/routes/Routes';

interface ReactQueryMeta extends Record<string, unknown> {
  // This property allows us to specify API error codes that we'll ignore when
  // capturing API errors in Sentry. For instance, a 401 response when logging in
  // is not an actionable error.
  sentryIgnoreErrorCodes: StatusCode[];
}

declare module '@tanstack/react-query' {
  interface Register {
    mutationMeta: ReactQueryMeta;
    queryMeta: ReactQueryMeta;
  }
}

Sentry.init({
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    // See the Sentry documentation on integrating with react-router:
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
  ],

  // We configure Replays here but we only add the integration once the user is authenticated
  // to not waste sessions on unauthenticated users. See Routes.tsx for initialization code.
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,

  tracePropagationTargets: [getEnvVar('API_BASE_URL')],
  // This is somewhat arbitrary and can be adjusted when we get data to get more or less.
  tracesSampleRate: 0.3,
});

const stripePromise = loadStripe(getEnvVar('STRIPE_APP_ID'));

function captureErrorIfNotIgnored(
  error: Error,
  meta: ReactQueryMeta | undefined,
) {
  if (isAxiosError(error) && error.response) {
    // We allow ignoring specific error codes for API requests. For example, we don't
    // need to log 401 errors when a user is logging in.
    if (
      meta?.sentryIgnoreErrorCodes.includes(error.response.status as StatusCode)
    ) {
      return;
    }

    Sentry.addBreadcrumb({
      category: 'api',
      data: error.response.data,
      message: `API error message: ${error.response.data.message}`,
      level: 'error',
    });
  }

  Sentry.captureException(error);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const statusCode = isAxiosError(error)
          ? error.response?.status
          : undefined;

        return statusCode !== 401 && statusCode !== 403 && failureCount < 2;
      },
    },
  },
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      captureErrorIfNotIgnored(error, mutation.meta);
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      captureErrorIfNotIgnored(error, query.meta);
    },
  }),
});

const token = getItem(LOCALSTORAGE_KEYS.TOKEN);
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
  <Elements stripe={stripePromise}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ErrorBoundary>

      <ReactQueryDevtools />
    </QueryClientProvider>
  </Elements>,
);
