const GlassTemplateIllustration = (): JSX.Element => {
  return (
    <svg
      viewBox="0 0 164 149"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M10.215 0C3.558.041.72 2.154.72 2.154L0 6.596 19.999 7l-.143-4.846C17.578.556 12.398 0 10.286 0h-.071z"
          id="glass-template-illustration-a"
        />
        <path
          d="M10.349 0a2.979 2.979 0 00-2.957 2.515L.035 53.596C-.24 55.387 1.161 57 2.993 57h88.658a2.984 2.984 0 002.958-2.515l7.356-51.081C102.24 1.613 100.839 0 99.007 0H10.349z"
          id="glass-template-illustration-c"
        />
        <path
          d="M10.766 0C9.075 0 7.663 1.222 7.41 2.91L.039 55.065c-.15.987.136 1.987.781 2.744A3.38 3.38 0 003.394 59h88.84c1.691 0 3.102-1.223 3.357-2.911l7.371-52.154a3.441 3.441 0 00-.782-2.744A3.383 3.383 0 0099.605 0H10.766zM1.42 57.289a2.64 2.64 0 01-.598-2.107L8.193 3.027A2.592 2.592 0 0110.766.799h88.839c.76 0 1.478.332 1.973.913.495.58.713 1.346.6 2.107l-7.372 52.154a2.592 2.592 0 01-2.572 2.228H3.394a2.598 2.598 0 01-1.974-.912z"
          id="glass-template-illustration-e"
        />
        <path
          d="M10.792 0a3.375 3.375 0 00-3.364 2.868L.039 51.117c-.15.973.136 1.961.783 2.708A3.412 3.412 0 003.402 55h84.807a3.374 3.374 0 003.363-2.869l7.389-48.247a3.351 3.351 0 00-.784-2.708A3.406 3.406 0 0095.597 0H10.792zM1.425 53.311a2.575 2.575 0 01-.601-2.076L8.212 2.987a2.588 2.588 0 012.58-2.199h84.805c.761 0 1.483.328 1.979.901a2.57 2.57 0 01.6 2.076l-7.39 48.248a2.585 2.585 0 01-2.577 2.199H3.402a2.61 2.61 0 01-1.977-.901z"
          id="glass-template-illustration-g"
        />
        <path
          d="M7.073 1.05L5.58 12.699s-3.571 2.635-2.231 7.138c0 0 3.459 11.859 2.009 18.009L0 53h16.519s1.116-11.97 2.791-16.472c1.674-4.502.67-12.189-7.144-24.378 0 0 1.784-9.521-3.351-12.026a1.214 1.214 0 00-1.742.926z"
          id="glass-template-illustration-i"
        />
        <path
          d="M7.615.192a1.606 1.606 0 00-.81 1.209L5.39 12.94c-.642.569-3.352 3.277-2.155 7.479.033.119 3.277 11.851 1.932 17.862L.021 53.474a.404.404 0 00.05.359c.073.105.19.167.314.167h15.888a.39.39 0 00.383-.359c.011-.12 1.087-12.018 2.662-16.438 1.392-3.906 1.33-11.359-6.828-24.691.21-1.305 1.392-9.877-3.456-12.345A1.523 1.523 0 008.347 0c-.25 0-.5.064-.732.192zM5.901 38.521a.317.317 0 00.012-.041c1.401-6.202-1.801-17.788-1.938-18.28-1.195-4.193 1.877-6.637 2.008-6.739a.392.392 0 00.151-.266L7.57 1.499a.798.798 0 01.409-.611.751.751 0 01.713-.012c4.621 2.351 3.031 11.553 3.015 11.646a.406.406 0 00.054.283c8.07 13.142 8.17 20.374 6.834 24.127-1.471 4.131-2.478 14.207-2.673 16.277H.927l4.974-14.688z"
          id="glass-template-illustration-k"
        />
        <path
          d="M.633 0C.266.011-.017.19.001.4c.37 4.245 1.676 7.281 1.688 7.311.075.174.347.289.645.289.054 0 .107-.004.162-.012.356-.05.573-.258.484-.462-.014-.029-1.288-2.998-1.651-7.164C1.312.157 1.022 0 .662 0H.633z"
          id="glass-template-illustration-m"
        />
        <path
          d="M46.549 0l.43.007c.718.025 1.437.112 2.154.268l.373.085c5.447 1.307 10.013 5.264 13.577 11.766l.105.184c1.024 1.727 3.213 3.622 5.774 3.862l.26.02c2.518.13 5.007-1.277 7.414-4.189l.16-.194c1.703-2.055 4.745-5.605 8.861-6.513L86 6.825l-.369.09c-3.532.95-6.175 4.146-7.775 6.082l-.241.286c-3.453 4.026-6.682 4.646-8.802 4.448l-.312-.036c-3.104-.42-5.639-2.694-6.805-4.821l-.24-.43c-3.378-5.975-7.635-9.554-12.66-10.64l-.326-.065C39.433.085 29.775 10.71 23.36 17.769l-.399.439c-1.056 1.16-2.051 2.248-2.923 3.134l-.312.312C16.503 24.81 13.316 26 10.552 26l-.368-.007a9.241 9.241 0 01-5.032-1.712l-.25-.182C1.938 21.904.08 19.166 0 19.047l1.313-.869.007.01c.13.19 1.976 2.836 4.756 4.823l.15.105c2.891 1.96 7.69 2.215 12.682-2.867l.325-.335c.884-.916 1.894-2.026 2.954-3.194C28.346 9.944 37.383.001 46.549 0z"
          id="glass-template-illustration-o"
        />
        <path
          d="M10.3 3.966C4.952 3.682 2.689.059 2.689.059L.084 11h21.803L21.065.059S19.344 4.446 10.3 3.966z"
          id="glass-template-illustration-q"
        />
        <path
          d="M4.139 7.356a6.972 6.972 0 01-3.421-.903l-.719 1.455A8.454 8.454 0 004.139 9C8.823 9 12.715 5.091 13 .1L11.464 0c-.235 4.125-3.453 7.356-7.325 7.356z"
          id="glass-template-illustration-s"
        />
        <path
          d="M0 8.786c0 2.873 1.366 5.57 3.654 7.214l.441-.653C2.014 13.851.771 11.399.771 8.786c0-4.406 3.475-7.99 7.745-7.99 3.99 0 7.38 3.211 7.715 7.309L17 8.038a8.868 8.868 0 00-2.708-5.707A8.348 8.348 0 008.516 0C3.82 0 0 3.941 0 8.786z"
          id="glass-template-illustration-u"
        />
        <path
          d="M-.001 1.5A1.5 1.5 0 003 1.5a1.501 1.501 0 00-3.001 0z"
          id="glass-template-illustration-w"
        />
        <path
          d="M0 1.5a1.5 1.5 0 003 0 1.5 1.5 0 10-3 0z"
          id="glass-template-illustration-y"
        />
        <path
          d="M8.857 0C4.178 0 .287 3.908 0 8.899L1.535 9c.238-4.125 3.453-7.355 7.322-7.355h.004a6.97 6.97 0 013.42.905L13 1.096A8.416 8.416 0 008.862 0h-.005z"
          id="glass-template-illustration-A"
        />
        <path
          d="M12.909.653c2.08 1.497 3.32 3.951 3.319 6.564-.002 4.406-3.476 7.988-7.743 7.987h-.004c-3.992-.002-7.38-3.215-7.713-7.315l-.769.067a8.875 8.875 0 002.707 5.711A8.35 8.35 0 008.48 16h.005c4.692-.001 8.513-3.939 8.514-8.783.003-2.873-1.362-5.572-3.65-7.217l-.44.653z"
          id="glass-template-illustration-C"
        />
        <path
          d="M0 1.5a1.5 1.5 0 003 .001A1.5 1.5 0 100 1.5z"
          id="glass-template-illustration-E"
        />
        <path
          d="M-.001 1.499C-.001 2.328.671 3 1.5 3a1.5 1.5 0 00.001-3A1.501 1.501 0 00-.001 1.499z"
          id="glass-template-illustration-G"
        />
        <path
          d="M0 7.5c0 1.011.201 1.974.565 2.853a7.55 7.55 0 003.689 3.907A7.46 7.46 0 007.5 15c4.135 0 7.499-3.365 7.499-7.5A7.51 7.51 0 007.743.004C7.662.002 7.581 0 7.5 0 3.365 0 0 3.365 0 7.5zm5.043 4.89a5.516 5.516 0 01-2.547-2.672A5.418 5.418 0 012.027 7.5 5.479 5.479 0 017.5 2.027a5.482 5.482 0 014.999 3.245c.304.68.474 1.434.474 2.228A5.48 5.48 0 017.5 12.974a5.439 5.439 0 01-2.457-.584z"
          id="glass-template-illustration-I"
        />
        <path
          d="M0 7.765C0 8.919.221 10.015.618 11l1.639-.94a6.323 6.323 0 01-.425-2.295c0-3.124 2.22-5.666 4.948-5.666.074 0 .147.001.22.005v-2.1C6.926.002 6.853 0 6.78 0 3.042 0 0 3.483 0 7.765z"
          id="glass-template-illustration-K"
        />
        <path
          d="M0 1.923c2.331 0 4.342 1.265 5.238 3.077l1.761-1.119C5.696 1.579 3.047 0 0 0v1.923z"
          id="glass-template-illustration-M"
        />
        <path
          d="M0 1.5a1.5 1.5 0 102.999 0A1.5 1.5 0 000 1.5z"
          id="glass-template-illustration-O"
        />
        <path
          d="M0 1.5a1.5 1.5 0 102.999 0A1.5 1.5 0 100 1.5z"
          id="glass-template-illustration-Q"
        />
        <path
          d="M0 1.5a1.5 1.5 0 102.999 0A1.5 1.5 0 000 1.5z"
          id="glass-template-illustration-S"
        />
        <path
          d="M9.079.063C1.836-.07.061 3.394.061 3.394l.683 4.664H22.06l-.956-4.797C18.498.258 11.376.059 9.524.059c-.285 0-.445.004-.445.004z"
          id="glass-template-illustration-U"
        />
        <path
          d="M5.121.601c-.946 1.442-1.658 4.157.082 9.01 0 0 .024 4.845 1.93 11.095l.86 9.783-2.523-4.507s.343-5.244-3.295-6.229a1.364 1.364 0 00-1.722 1.234l-.385 6.466c-.055.923.17 1.841.647 2.633 1.83 3.034 6.961 11.007 13.75 17.111l2.083 12.861H37.06l-7.678-19.236s.988-18.467-6.58-19.017c0 0-1.585-1.539-4.437-.88 0 0-2.364-2.197-4.778-1.098L7.294.9A1.223 1.223 0 006.123.058c-.38 0-.761.175-1.002.543z"
          id="glass-template-illustration-W"
        />
        <path
          d="M5.215.781c-.979 1.489-1.767 4.317.017 9.33.014.622.17 5.306 1.946 11.139l.7 7.941-1.581-2.818c.032-.839.041-5.551-3.61-6.537a1.771 1.771 0 00-1.503.271 1.754 1.754 0 00-.729 1.324L.069 27.92a4.953 4.953 0 00.707 2.87c1.892 3.13 7.012 11.044 13.798 17.168l2.073 12.767a.396.396 0 00.391.333h20.627a.4.4 0 00.328-.173.397.397 0 00.039-.369l-7.689-19.223c.074-1.556.716-18.652-6.855-19.4-.422-.347-1.991-1.437-4.516-.928-.566-.466-2.531-1.891-4.668-1.193L8.105 1.173A1.621 1.621 0 006.767.072a1.596 1.596 0 00-1.552.709zm3.336 30.587a.397.397 0 00.277-.412l-.865-9.818a.366.366 0 00-.016-.08c-1.875-6.137-1.924-10.972-1.924-11.021a.371.371 0 00-.025-.131c-1.701-4.745-1.006-7.345-.122-8.69a.804.804 0 01.788-.36.825.825 0 01.69.567l6.33 18.992a.397.397 0 00.539.236c2.153-.98 4.35 1.013 4.372 1.032.096.09.23.125.358.097 2.606-.602 4.037.725 4.097.78a.394.394 0 00.247.111c7.138.517 6.262 18.486 6.252 18.667a.372.372 0 00.027.168l7.505 18.76H17.374l-2.043-12.573a.378.378 0 00-.126-.23C8.448 41.397 3.336 33.496 1.453 30.381a4.161 4.161 0 01-.594-2.414l.386-6.488a.979.979 0 011.235-.88c3.3.892 3.024 5.794 3.022 5.843a.395.395 0 00.049.219l2.537 4.523a.396.396 0 00.463.184z"
          id="glass-template-illustration-Y"
        />
        <path
          d="M10.778 5.415C1.642 4.835.061.058.061.058l.276 11H26.06l-4.563-11s-1.729 5.929-10.719 5.357z"
          id="glass-template-illustration-aa"
        />
        <path
          d="M.417.077a.508.508 0 00-.339.622l1.033 3.985a.487.487 0 00.466.374.433.433 0 00.127-.018.513.513 0 00.34-.624L1.01.432A.488.488 0 00.544.059a.428.428 0 00-.127.018z"
          id="glass-template-illustration-ac"
        />
        <path
          d="M.448.076C.167.146.001.408.08.661l.942 3.049c.065.21.277.348.509.348a.574.574 0 00.142-.017c.281-.071.446-.332.368-.585L1.099.407C1.034.196.821.059.59.059a.58.58 0 00-.142.017z"
          id="glass-template-illustration-ae"
        />
        <path
          d="M.441.079C.148.152-.009.387.09.604l1.885 4.172c.078.173.295.282.528.282a.723.723 0 00.178-.022c.291-.072.449-.307.35-.524L1.145.341C1.067.167.85.058.617.058a.75.75 0 00-.176.021z"
          id="glass-template-illustration-ag"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(27 114)">
          <mask fill="#fff" id="glass-template-illustration-b">
            <use xlinkHref="#glass-template-illustration-a" />
          </mask>
          <path
            d="M-5 12h30V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-b)"
          />
        </g>
        <g transform="translate(33 52)">
          <mask fill="#fff" id="glass-template-illustration-d">
            <use xlinkHref="#glass-template-illustration-c" />
          </mask>
          <path
            d="M-5 62h112V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-d)"
          />
        </g>
        <g transform="translate(32 51)">
          <mask fill="#fff" id="glass-template-illustration-f">
            <use xlinkHref="#glass-template-illustration-e" />
          </mask>
          <path
            d="M-5 64h113V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-f)"
          />
        </g>
        <g transform="translate(33 52)">
          <mask fill="#fff" id="glass-template-illustration-h">
            <use xlinkHref="#glass-template-illustration-g" />
          </mask>
          <path
            d="M-5 60h109V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-h)"
          />
        </g>
        <g transform="translate(27 72)">
          <mask fill="#fff" id="glass-template-illustration-j">
            <use xlinkHref="#glass-template-illustration-i" />
          </mask>
          <path
            d="M-5 58h30V-5H-5z"
            fill="#FFF"
            mask="url(#glass-template-illustration-j)"
          />
        </g>
        <g transform="translate(26 71)">
          <mask fill="#fff" id="glass-template-illustration-l">
            <use xlinkHref="#glass-template-illustration-k" />
          </mask>
          <path
            d="M-5 59h30V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-l)"
          />
        </g>
        <g transform="translate(31 84)">
          <mask fill="#fff" id="glass-template-illustration-n">
            <use xlinkHref="#glass-template-illustration-m" />
          </mask>
          <path
            d="M-5 13H8V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-n)"
          />
        </g>
        <g opacity=".165" transform="translate(41 62)">
          <mask fill="#fff" id="glass-template-illustration-p">
            <use xlinkHref="#glass-template-illustration-o" />
          </mask>
          <path
            d="M-5 33h96V-3H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-p)"
          />
        </g>
        <g transform="translate(24 116)">
          <mask fill="#fff" id="glass-template-illustration-r">
            <use xlinkHref="#glass-template-illustration-q" />
          </mask>
          <path
            d="M-4.916 16h31.804V-4.942H-4.916z"
            fill="#2563F6"
            mask="url(#glass-template-illustration-r)"
          />
        </g>
        <path d="M49 94h11v-1H49z" fill="#2E2E76" />
        <path d="M1 48h55V17.999H1z" fill="#FFF" />
        <path
          d="M0 49h56V18H0v31zm.946-.937h54.107V18.937H.946v29.126z"
          fill="#F5B3A5"
        />
        <g transform="translate(12 34)">
          <mask fill="#fff" id="glass-template-illustration-t">
            <use xlinkHref="#glass-template-illustration-s" />
          </mask>
          <path
            d="M-5 14h23V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-t)"
          />
        </g>
        <g transform="translate(7 25)">
          <mask fill="#fff" id="glass-template-illustration-v">
            <use xlinkHref="#glass-template-illustration-u" />
          </mask>
          <path
            d="M-5 21h27V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-v)"
          />
        </g>
        <g transform="translate(12 39)">
          <mask fill="#fff" id="glass-template-illustration-x">
            <use xlinkHref="#glass-template-illustration-w" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-x)"
          />
        </g>
        <g transform="translate(23 32)">
          <mask fill="#fff" id="glass-template-illustration-z">
            <use xlinkHref="#glass-template-illustration-y" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-z)"
          />
        </g>
        <g transform="translate(31 24)">
          <mask fill="#fff" id="glass-template-illustration-B">
            <use xlinkHref="#glass-template-illustration-A" />
          </mask>
          <path
            d="M-5 14h23V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-B)"
          />
        </g>
        <g transform="translate(30 26)">
          <mask fill="#fff" id="glass-template-illustration-D">
            <use xlinkHref="#glass-template-illustration-C" />
          </mask>
          <path
            d="M-5 21h27V-5H-5z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-D)"
          />
        </g>
        <g transform="translate(42 25)">
          <mask fill="#fff" id="glass-template-illustration-F">
            <use xlinkHref="#glass-template-illustration-E" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-F)"
          />
        </g>
        <g transform="translate(29 32)">
          <mask fill="#fff" id="glass-template-illustration-H">
            <use xlinkHref="#glass-template-illustration-G" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-H)"
          />
        </g>
        <path
          d="M13 32h4.999v-1H13zM11.999 34h9v-1h-9zM35 32h5v-1h-5zM36 34h9v-1h-9z"
          fill="#2E2E76"
        />
        <path
          d="M59.638 32.961h1.82v-.96h-1.82v.96zm-3.639 0h1.82v-.96h-1.82v.96zm6.092 2.214H63v-1.921h-.909v1.921zm0 3.843H63v-1.922h-.909v1.922zm0 3.842H63v-1.922h-.909v1.922zm0 3.843H63v-1.922h-.909v1.922zm0 3.842H63v-1.922h-.909v1.922zm0 3.842H63v-1.921h-.909v1.921zm0 3.843H63v-1.922h-.909v1.922zm0 3.842H63v-1.921h-.909v1.921zm0 1.928H63v-.006l-.909.006z"
          fill="#F5B3A5"
        />
        <path d="M121 46h41.999V23H121z" fill="#FFF" />
        <path
          d="M121 46h42.999V23H121v23zm.952-.901h41.095V23.901h-41.095v21.198z"
          fill="#F5B3A5"
        />
        <path d="M128.999 44h5V28h-5z" fill="#2A69EA" />
        <path d="M144 44h5V28h-5z" fill="#89EFD7" />
        <path d="M136 44h5V33h-5z" fill="#F5B3A5" />
        <path d="M151 43h5v-5h-5zM127 44h32v-1h-32z" fill="#2E2E76" />
        <path
          d="M120.042 35.973H122V35h-1.958v.973zm-3.917 0h1.958V35h-1.958v.973zM113 35v1.76h.979v-.787h.187V35H113zm0 5.651h.979v-1.946H113v1.946zm0 3.892h.979v-1.946H113v1.946zm0 3.891h.979v-1.946H113v1.946zm0 3.892h.979V50.38H113v1.946zm0 3.891h.979v-1.945H113v1.945zm0 3.891h.979v-1.946H113v1.946zM113 64h.979v-1.946H113V64z"
          fill="#F5B3A5"
        />
        <path d="M64 24h44V1H64z" fill="#FFF" />
        <path
          d="M63 25h46V0H63v25zm.981-.938h44.038V.94H63.981v23.122z"
          fill="#F5B3A5"
        />
        <g transform="translate(69 6)">
          <mask fill="#fff" id="glass-template-illustration-J">
            <use xlinkHref="#glass-template-illustration-I" />
          </mask>
          <path
            d="M-5 20h25V-5H-5z"
            fill="#F5B3A5"
            mask="url(#glass-template-illustration-J)"
          />
        </g>
        <g transform="translate(69 6)">
          <mask fill="#fff" id="glass-template-illustration-L">
            <use xlinkHref="#glass-template-illustration-K" />
          </mask>
          <path
            d="M-5 16h17V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-L)"
          />
        </g>
        <g transform="translate(76 6)">
          <mask fill="#fff" id="glass-template-illustration-N">
            <use xlinkHref="#glass-template-illustration-M" />
          </mask>
          <path
            d="M-5 10h17V-5H-5z"
            fill="#2A69EA"
            mask="url(#glass-template-illustration-N)"
          />
        </g>
        <g transform="translate(89 7)">
          <mask fill="#fff" id="glass-template-illustration-P">
            <use xlinkHref="#glass-template-illustration-O" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#89EFD7"
            mask="url(#glass-template-illustration-P)"
          />
        </g>
        <path d="M94 9h10V8H94z" fill="#89EFD7" />
        <path
          d="M86 25.886h1v-1.885h-1v1.885zm0 3.772h1v-1.886h-1v1.886zm0 3.771h1v-1.885h-1v1.885zm0 3.771h1v-1.886h-1V37.2zm0 3.771h1v-1.885h-1v1.885zm0 3.771h1v-1.885h-1v1.885zm0 3.772h1v-1.885h-1v1.885zm0 3.771h1V50.4h-1v1.885zm0 3.772h1v-1.885h-1v1.885zm0 3.771h1v-1.885h-1v1.885zm0 3.772h1v-1.885h-1V63.6zm0 3.771h1v-1.885h-1v1.885zm0 3.772h1v-1.886h-1v1.886z"
          fill="#F5B3A5"
        />
        <g transform="translate(89 11)">
          <mask fill="#fff" id="glass-template-illustration-R">
            <use xlinkHref="#glass-template-illustration-Q" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#2A69EA"
            mask="url(#glass-template-illustration-R)"
          />
        </g>
        <path d="M94 13h10v-1H94z" fill="#2A69EA" />
        <g transform="translate(89 14)">
          <mask fill="#fff" id="glass-template-illustration-T">
            <use xlinkHref="#glass-template-illustration-S" />
          </mask>
          <path
            d="M-5 8H8V-5H-5z"
            fill="#F5B3A5"
            mask="url(#glass-template-illustration-T)"
          />
        </g>
        <path d="M94 16h10v-1H94z" fill="#F5B3A5" />
        <g transform="translate(118 133)">
          <mask fill="#fff" id="glass-template-illustration-V">
            <use xlinkHref="#glass-template-illustration-U" />
          </mask>
          <path
            d="M-4.94 13.058h32v-18h-32z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-V)"
          />
        </g>
        <g transform="translate(104 85)">
          <mask fill="#fff" id="glass-template-illustration-X">
            <use xlinkHref="#glass-template-illustration-W" />
          </mask>
          <path
            d="M-4.94 65.058h47v-70h-47z"
            fill="#FFF"
            mask="url(#glass-template-illustration-X)"
          />
        </g>
        <g transform="translate(104 84)">
          <mask fill="#fff" id="glass-template-illustration-Z">
            <use xlinkHref="#glass-template-illustration-Y" />
          </mask>
          <path
            d="M-4.94 66.058h48v-71h-48z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-Z)"
          />
        </g>
        <g transform="translate(118 137)">
          <mask fill="#fff" id="glass-template-illustration-ab">
            <use xlinkHref="#glass-template-illustration-aa" />
          </mask>
          <path
            d="M-4.94 16.058h36v-21h-36z"
            fill="#2563F6"
            mask="url(#glass-template-illustration-ab)"
          />
        </g>
        <g transform="translate(122 106)">
          <mask fill="#fff" id="glass-template-illustration-ad">
            <use xlinkHref="#glass-template-illustration-ac" />
          </mask>
          <path
            d="M-4.94 10.058h12v-15h-12z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-ad)"
          />
        </g>
        <g transform="translate(125 107)">
          <mask fill="#fff" id="glass-template-illustration-af">
            <use xlinkHref="#glass-template-illustration-ae" />
          </mask>
          <path
            d="M-4.94 9.058h12v-14h-12z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-af)"
          />
        </g>
        <g transform="translate(117 104)">
          <mask fill="#fff" id="glass-template-illustration-ah">
            <use xlinkHref="#glass-template-illustration-ag" />
          </mask>
          <path
            d="M-4.94 10.058h13v-15h-13z"
            fill="#2E2E76"
            mask="url(#glass-template-illustration-ah)"
          />
        </g>
        <text
          fill="#2E2E76"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="11.284"
          fontWeight="bold"
        >
          <tspan x="45.236" y="79.676">
            COM
          </tspan>{' '}
          <tspan letterSpacing="-.006" x="70.295" y="79.676">
            I
          </tspan>{' '}
          <tspan x="73.578" y="79.676">
            NG SOON
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default GlassTemplateIllustration;
