import { cloneDeep, flatMap, reject, some } from 'lodash-es';
import { clsx } from 'clsx';
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  APIError,
  DashboardFilters,
  DashboardSortValue,
  DashboardSorts,
  DashboardTab,
} from '../../types/internal';
import { DATE_FORMATS, formatDate } from '../../util/dates';
import { dashboardQueries } from 'hooks/backend/dashboard';
import { DashboardSurvey, Tag } from '../../types/domainModels';
import { FetchDashboardSurveysResponse } from '../../services/backend/dashboard';
import { getEmptyFilters } from '../../util/dashboard';
import { getItem, LOCALSTORAGE_KEYS, setItem } from '../../util/localStorage';
import { getOrgStatus } from 'util/users';
import { getSurveyFlowRoute } from '../../util/routes';
import { showErrorMessage } from '../../util/notifications';
import {
  useAddTagToSurvey,
  useCreateAndAssociateTag,
  useRemoveTagToSurvey,
  useTags,
} from 'hooks/backend/tags';
import { useAuth } from '../../contexts/auth';
import { useCloneSurvey } from 'hooks/backend/surveys';
import { useDebouncedValue } from '../../hooks/general';
import { useHasRole } from 'hooks/users';
import { useModal } from '../../hooks/modals';

import Badge from 'components/common/Badge';
import Button from '../common/forms/Button';
import ChangeOrganization from '../dashboard/ChangeOrganization';
import CircleLoader from '../common/CircleLoader';
import CreateSurveyTemplateModal from './CreateSurveyTemplateModal';
import DashboardFiltersPopover from './DashboardFiltersPopover';
import DefaultLayout from '../layout/DefaultLayout';
import DeleteSurveyVerificationModal from './DeleteSurveyVerificationModal';
import DocumentAddIcon from '../common/icons/DocumentAddIcon';
import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import ErrorDisplay from '../common/ErrorDisplay';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IndexCard from '../common/IndexCard';
import Input from '../common/forms/Input';
import NewSurveyModal from './NewSurveyModal';
import noSurveysPlaceholderBg from './noSurveysPlaceholderBg.svg';
import Popover from '../common/Popover';
import ProgressBar from '../common/ProgressBar';
import SurveyQuoteModal from '../common/SurveyQuoteModal';
import SurveyStatusBadge from '../common/SurveyStatusBadge';
import Tab from '../common/Tab';
import UserBubble from 'components/common/UserBubble';
import VerticalDotsButton from 'components/common/VerticalDotsButton';

interface SortProps {
  onChangeSort(sortName: keyof DashboardSorts, value: DashboardSortValue): void;
  sorts: DashboardSorts;
}

interface SurveyActionFns {
  onSurveyCloned(): void;
  onSurveyDeleted(surveyId: number): void;
  onSurveyStatusChanged(surveyId: number): void;
}

interface TagFns {
  onTagAdded(opts: { survey: DashboardSurvey; tag: Tag }): void;
  onTagRemoved(survey: DashboardSurvey): void;
}

type SurveyTableProps = {
  hasNextPage: boolean;
  hasSurveysPlaceholder: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  loadError: APIError | null;
  onLoadNextPage(): void;
  surveys: DashboardSurvey[];
} & SortProps &
  SurveyActionFns &
  TagFns;

function getEmptySorts(): DashboardSorts {
  return {
    createdAt: '',
    name: '',
  };
}

function getSurveysFromInfiniteData({
  data,
}: {
  data: InfiniteData<FetchDashboardSurveysResponse>;
}): DashboardSurvey[] {
  return flatMap(data.pages, (page) => {
    return page.results;
  });
}

function modifySurveyTag({
  data,
  survey,
  tag,
}: {
  data: InfiniteData<FetchDashboardSurveysResponse>;
  survey: DashboardSurvey;
  tag: DashboardSurvey['project'];
}): InfiniteData<FetchDashboardSurveysResponse> {
  const newData = cloneDeep(data);

  const surveys = getSurveysFromInfiniteData({ data: newData });
  const newSurvey = surveys.find((oldSurvey) => oldSurvey.id === survey.id);
  if (newSurvey) {
    newSurvey.project = tag;
  }

  return newData;
}

function toggleNameSort(
  value: DashboardSortValue,
  {
    defaultSortDirection = 'ASC',
  }: { defaultSortDirection?: DashboardSortValue } = {},
): DashboardSortValue {
  if (value === 'ASC') {
    return 'DESC';
  }
  if (value === 'DESC') {
    return '';
  }

  return defaultSortDirection;
}

function toggleCreatedAtSort(
  value: DashboardSortValue,
  {
    defaultSortDirection = 'DESC',
  }: { defaultSortDirection?: DashboardSortValue } = {},
): DashboardSortValue {
  if (value === 'ASC') {
    return '';
  }
  if (value === 'DESC') {
    return 'ASC';
  }

  return defaultSortDirection;
}

const DashboardPage = (): JSX.Element => {
  const isAdmin = useHasRole('admin');
  const { tab: activeTab } = useParams<{ tab: DashboardTab }>();

  const { data: authData } = useAuth();
  const queryClient = useQueryClient();

  const { hasChangedOrg } = getOrgStatus();
  let organizationId = authData?.organizationId;
  if (isAdmin && !hasChangedOrg) {
    organizationId = undefined;
  }

  const [sorts, _setSorts] = useState<DashboardSorts>(() => {
    return getEmptySorts();
  });
  const hasAppliedSort = some(sorts);

  const [filters, _setFilters] = useState<DashboardFilters>(() => {
    return getItem(LOCALSTORAGE_KEYS.DASHBOARD_FILTERS) || getEmptyFilters();
  });
  const hasAppliedFilter = some(filters, 'enabled');

  const [nameFilter, setNameFilter] = useState('');
  const debouncedNameFilter = useDebouncedValue(nameFilter, { duration: 250 });

  const dashboardQueryOptions = useMemo(() => {
    return {
      activeTab,
      filters,
      name: debouncedNameFilter,
      organizationId,
      sorts,
    };
  }, [activeTab, filters, debouncedNameFilter, sorts, organizationId]);
  const {
    data,
    error: loadSurveysError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadSurveysError,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    dashboardQueries.get({
      activeTab,
      filters,
      name: debouncedNameFilter,
      organizationId,
      sorts,
    }),
  );
  const surveys = useMemo(() => {
    return data ? getSurveysFromInfiniteData({ data }) : [];
  }, [data]);
  const hasSurveysPlaceholder =
    !isLoading &&
    surveys.length === 0 &&
    !debouncedNameFilter &&
    !hasAppliedFilter &&
    !hasAppliedSort;

  const {
    isOpen: isQuoteModalOpen,
    onCloseModal: onCloseQuoteModal,
    setIsOpen: setIsQuoteModalOpen,
  } = useModal();

  const {
    isOpen: isNewSurveyModalOpen,
    onCloseModal: onCloseNewSurveyModal,
    setIsOpen: setIsNewSurveyModalOpen,
  } = useModal();

  const invalidateDashboardResults = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: dashboardQueries.get(dashboardQueryOptions).queryKey,
    });
  }, [dashboardQueryOptions, queryClient]);

  const onChangeSort = useCallback(
    (sortName: keyof DashboardSorts, value: DashboardSortValue) => {
      // We only currently support one sort at a time. So when a sort is changed, we clear out
      // any existing sorts.
      setSorts({
        ...getEmptySorts(),
        [sortName]: value,
      });
    },
    [],
  );

  const removeSurveyFromPage = useCallback(
    (surveyId: number) => {
      queryClient.setQueryData(
        dashboardQueries.get(dashboardQueryOptions).queryKey,
        (currentData) => {
          if (!currentData) {
            return;
          }

          const newData = cloneDeep(currentData);

          newData.pages = newData.pages.map((page) => {
            return { ...page, results: reject(page.results, { id: surveyId }) };
          });

          return newData;
        },
      );

      invalidateDashboardResults();
    },
    [dashboardQueryOptions, queryClient, invalidateDashboardResults],
  );

  const onTagAdded = useCallback(
    ({ survey, tag }: { survey: DashboardSurvey; tag: Tag }) => {
      queryClient.setQueryData(
        dashboardQueries.get(dashboardQueryOptions).queryKey,
        (currentData) => {
          if (!currentData) {
            return;
          }

          return modifySurveyTag({
            data: currentData,
            survey,
            tag,
          });
        },
      );
    },
    [dashboardQueryOptions, queryClient],
  );

  const onTagRemoved = useCallback(
    (survey: DashboardSurvey) => {
      queryClient.setQueryData(
        dashboardQueries.get(dashboardQueryOptions).queryKey,
        (currentData) => {
          if (!currentData) {
            return;
          }

          return modifySurveyTag({
            data: currentData,
            survey,
            tag: null,
          });
        },
      );
    },
    [dashboardQueryOptions, queryClient],
  );

  function setFilters(filters: DashboardFilters) {
    setItem(LOCALSTORAGE_KEYS.DASHBOARD_FILTERS, filters);
    _setFilters(filters);
  }

  function setSorts(sorts: DashboardSorts) {
    _setSorts(sorts);
  }

  const surveysTableProps: SurveyTableProps = {
    hasNextPage: !!hasNextPage,
    hasSurveysPlaceholder,
    isFetchingNextPage,
    isLoading,
    loadError: hasLoadSurveysError ? loadSurveysError : null,
    onChangeSort,
    onLoadNextPage: fetchNextPage,
    onSurveyCloned: invalidateDashboardResults,
    onSurveyDeleted: removeSurveyFromPage,
    onSurveyStatusChanged: removeSurveyFromPage,
    onTagAdded,
    onTagRemoved,
    sorts,
    surveys,
  };

  // We sync the dashboard tab in local storage so we can adjust the path of the "Your Surveys"
  // link on the sidebar using the previously visited tab.
  useEffect(() => {
    if (activeTab) {
      setItem(LOCALSTORAGE_KEYS.DASHBOARD_TAB, activeTab);
    }
  }, [activeTab]);

  // The filters sometimes depend on the selected organization (like a "Tag" filter). When an
  // org is changed, we want to clear the filters so unapplicable ones aren't applied.
  useEffect(() => {
    setFilters(getEmptyFilters());
  }, [organizationId]);

  return (
    <DefaultLayout>
      <div className="min-h-full pt-6 pb-32 px-8 bg-gray-50">
        <div className="flex items-start justify-between mb-10">
          <div className="space-y-1">
            <div className="text-xl text-primary-d-600 font-medium">
              Your Surveys
            </div>
            <ChangeOrganization />
          </div>
          <div className="flex space-x-3">
            <Button
              hierarchy="secondary-gray"
              onClick={() => {
                setIsQuoteModalOpen(true);
              }}
              size="md"
            >
              Get a Quote
            </Button>

            {isQuoteModalOpen && (
              <SurveyQuoteModal onCloseModal={onCloseQuoteModal} />
            )}

            <Button
              hierarchy="primary"
              icon={<Icon id="plus" />}
              iconPlacement="leading"
              onClick={() => {
                setIsNewSurveyModalOpen(true);
              }}
              size="md"
            >
              New Survey
            </Button>

            {isNewSurveyModalOpen && (
              <NewSurveyModal onCloseModal={onCloseNewSurveyModal} />
            )}
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center pb-2 space-x-4">
            <IndexCard>
              <div className="flex justify-between pt-4 border-b border-light-grey px-4">
                <div className="flex items-end space-x-4">
                  <Tab
                    href="/dashboard/launched"
                    isActive={activeTab === 'launched'}
                  >
                    Launched
                  </Tab>
                  <Tab
                    href="/dashboard/drafts"
                    isActive={activeTab === 'drafts'}
                  >
                    Drafts
                  </Tab>
                </div>
              </div>
              <div className="flex flex-row mt-4 mx-4 space-x-2">
                <div className="w-full">
                  <Input
                    icon={
                      <div className="text-gray-d-400 w-full h-full">
                        <Icon id="search-lg" />
                      </div>
                    }
                    iconPlacement="leading"
                    onChange={(event) => {
                      setNameFilter(event.target.value);
                    }}
                    placeholder="Search"
                    size="md"
                    value={nameFilter}
                  />
                </div>
                <DashboardFiltersPopover
                  filters={filters}
                  onFiltersChanged={setFilters}
                />
              </div>
              <div className="mt-4">
                {activeTab === 'drafts' && (
                  <DraftSurveysTable {...surveysTableProps} />
                )}
                {activeTab === 'launched' && (
                  <LaunchedSurveysTable {...surveysTableProps} />
                )}
              </div>
            </IndexCard>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DashboardPage;

const LaunchedSurveysTable = memo(function LaunchedSurveysTable({
  hasNextPage,
  hasSurveysPlaceholder,
  isFetchingNextPage,
  isLoading,
  loadError,
  onChangeSort,
  onLoadNextPage,
  onSurveyCloned,
  onSurveyDeleted,
  onTagAdded,
  onTagRemoved,
  sorts,
  surveys,
}: SurveyTableProps) {
  return (
    <div>
      <div className="grid grid-cols-dashboard-launched gap-2 justify-items-center py-2 px-4 border-b border-gray-300 text-gray-600 font-normal bg-white sticky top-0 z-10">
        <SortableColumnHeader
          isFirstColumn={true}
          label="Name"
          onChangeSort={onChangeSort}
          sortKey="name"
          sorts={sorts}
        />
        <SortableColumnHeader
          defaultSortDirection="DESC"
          label="Date Created"
          onChangeSort={onChangeSort}
          sortKey="createdAt"
          sorts={sorts}
        />
        <div className="justify-self-start">Contact</div>
        <div className="justify-self-start">Status</div>
        <div className="justify-self-start">Progress</div>
      </div>
      <div className="space-y-2">
        {isLoading ? (
          <LaunchedTablePlaceholderRows withPulse={true} />
        ) : (
          <>
            {loadError && (
              <div className="mt-2">
                <ErrorDisplay
                  message={`Failed
                  to load surveys. (${loadError.message})`}
                />
              </div>
            )}
            {!loadError && (
              <>
                {hasSurveysPlaceholder && (
                  <div className="mt-2">
                    <NoSurveysPlaceholder type="launched" />
                  </div>
                )}
                {!hasSurveysPlaceholder && surveys.length === 0 && (
                  <p className="py-4 text-center text-sm">No surveys found</p>
                )}
              </>
            )}
            {surveys.map((survey) => {
              return (
                <LaunchedSurveyRow
                  key={survey.id}
                  onSurveyCloned={onSurveyCloned}
                  onSurveyDeleted={onSurveyDeleted}
                  onSurveyStatusChanged={onSurveyDeleted}
                  onTagAdded={onTagAdded}
                  onTagRemoved={onTagRemoved}
                  survey={survey}
                />
              );
            })}
            {isFetchingNextPage && (
              <LaunchedTablePlaceholderRows withPulse={true} />
            )}
            {hasNextPage && (
              <SurveysTableInfiniteScrollTrigger onTriggered={onLoadNextPage} />
            )}
          </>
        )}
      </div>
    </div>
  );
});

const LaunchedTablePlaceholderRows = ({
  withPulse,
}: {
  withPulse: boolean;
}): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="p-4 rounded border-b border-gray-300">
            <div
              className={clsx(
                'grid grid-cols-dashboard-launched items-center gap-2',
                {
                  'animate-pulse': withPulse,
                },
              )}
            >
              {/* Name */}
              <div className="justify-self-start w-full space-y-2">
                <div className="w-3/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-4 bg-light-grey" />
              </div>
              {/* Date Created */}
              <div className="w-3/4 h-4 bg-light-grey" />
              {/* Contact */}
              <div className="w-8 h-8 rounded-full bg-light-grey" />
              {/* Status */}
              <div className="w-1/2 h-4 bg-light-grey" />
              {/* Progress */}
              <div className="flex items-center space-x-2">
                <div className="w-32 h-2 rounded bg-light-grey" />
                <div className="w-px grow h-4 bg-light-grey" />
              </div>
              {/* Quick Actions */}
              <div className="w-8 h-8 rounded-full bg-light-grey justify-self-center" />
            </div>
          </div>
        );
      })}
    </>
  );
};

const LaunchedSurveyRow = ({
  onSurveyCloned,
  onSurveyDeleted,
  onSurveyStatusChanged,
  onTagAdded,
  onTagRemoved,
  survey,
}: {
  survey: DashboardSurvey;
} & SurveyActionFns &
  TagFns): JSX.Element => {
  let percentage = 0;
  if (survey.status.name === 'approved') {
    percentage = survey.numberOfCompletes / survey.participants;
  }

  return (
    <div
      className="grid grid-cols-dashboard-launched items-center justify-items-center gap-2 p-4 border-b border-gray-300"
      data-testid="dashboard-survey"
    >
      <div className="justify-self-start max-w-full">
        <Link
          className="block mb-1 cursor-pointer"
          to={`/campaign/${survey.id}`}
        >
          {survey.title}
        </Link>
        <SurveyTagging
          onTagAdded={onTagAdded}
          onTagRemoved={onTagRemoved}
          survey={survey}
        />
      </div>
      <div className="justify-self-start">
        {formatDate(survey.createdAt, {
          format: DATE_FORMATS.DASHBOARD_CREATED,
        })}
      </div>
      <div className="justify-self-start">
        <UserBubble user={survey.user} />
      </div>
      <div className="justify-self-start">
        <SurveyStatusBadge
          onSurveyStatusChanged={onSurveyStatusChanged}
          survey={survey}
        />
      </div>
      <div className="flex items-center max-w-full justify-self-start">
        {survey.status.name === 'approved' && (
          <ProgressBar percentage={percentage} />
        )}
        <div className="flex-row mt-1 text-sm">
          {survey.numberOfCompletes?.toLocaleString()} /{' '}
          {survey.participants?.toLocaleString()}
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="flex center mt-2 text-gray-900">
          <OptionsButton
            onSurveyCloned={onSurveyCloned}
            onSurveyDeleted={onSurveyDeleted}
            survey={survey}
          />
        </div>
      </div>
    </div>
  );
};

const OptionsButton = ({
  survey,
  onSurveyCloned,
  onSurveyDeleted,
}: {
  survey: DashboardSurvey;
  onSurveyCloned(): void;
  onSurveyDeleted(id: number): void;
}): JSX.Element => {
  const navigate = useNavigate();
  const [isVerifyingDelete, setIsVerifyingDelete] = useState(false);

  const {
    isOpen: isCreateTemplateModalOpen,
    onCloseModal: onCloseCreateTemplateModal,
    setIsOpen: setIsCreateTemplateModalOpen,
  } = useModal();

  const { mutate: cloneSurvey } = useCloneSurvey({
    onError: (err) => {
      showErrorMessage(`Failed to clone survey. Error: ${err.message}`);
    },
    onSuccess: (data) => {
      onSurveyCloned();

      navigate(getSurveyFlowRoute({ surveyId: data.id }));
    },
  });

  return (
    <>
      <Dropdown
        button={
          <DropdownButton as="div">
            <VerticalDotsButton ariaLabel="Survey Actions" />
          </DropdownButton>
        }
      >
        <DropdownItem
          as="button"
          icon={<Icon id="copy-02" />}
          onClick={() => {
            cloneSurvey({ surveyId: survey.id });
          }}
          type="button"
        >
          Duplicate Survey
        </DropdownItem>
        <DropdownItem
          as="button"
          icon={<DocumentAddIcon />}
          onClick={() => {
            setIsCreateTemplateModalOpen(true);
          }}
          type="button"
        >
          Create Survey Template
        </DropdownItem>
        <DropdownItem
          as="button"
          icon={<Icon id="trash" />}
          onClick={() => {
            setIsVerifyingDelete(true);
          }}
          type="button"
        >
          Delete Survey
        </DropdownItem>
      </Dropdown>

      {isCreateTemplateModalOpen && (
        <CreateSurveyTemplateModal
          onCloseModal={onCloseCreateTemplateModal}
          survey={survey}
        />
      )}

      {isVerifyingDelete && (
        <DeleteSurveyVerificationModal
          onCloseModal={() => setIsVerifyingDelete(false)}
          onSurveyDeleted={() => {
            onSurveyDeleted(survey.id);
            setIsVerifyingDelete(false);
          }}
          surveyID={survey.id}
          title={survey.title}
        />
      )}
    </>
  );
};

const DraftSurveysTable = memo(function DraftSurveysTable({
  hasNextPage,
  hasSurveysPlaceholder,
  isFetchingNextPage,
  isLoading,
  loadError,
  onChangeSort,
  onLoadNextPage,
  onSurveyCloned,
  onSurveyDeleted,
  onTagAdded,
  onTagRemoved,
  sorts,
  surveys,
}: SurveyTableProps) {
  return (
    <div>
      <div className="sticky z-10 top-0 grid grid-cols-dashboard-draft gap-2 justify-items-center py-2 px-4 border-b border-gray-300 text-gray-600 bg-white">
        <SortableColumnHeader
          isFirstColumn={true}
          label="Name"
          onChangeSort={onChangeSort}
          sortKey="name"
          sorts={sorts}
        />
        <SortableColumnHeader
          defaultSortDirection="DESC"
          label="Date Created"
          onChangeSort={onChangeSort}
          sortKey="createdAt"
          sorts={sorts}
        />
        <div className="justify-self-start">Contact</div>
        <div className="justify-self-start">Status</div>
        <div className="justify-self-start">Participants</div>
        <div className="justify-self-start">Questions</div>
      </div>
      <div className="space-y-2">
        {isLoading ? (
          <DraftsTablePlaceholderRows withPulse={true} />
        ) : (
          <>
            {loadError && (
              <div className="mt-2">
                <ErrorDisplay
                  message={`Failed
                  to load surveys. (${loadError.message})`}
                />
              </div>
            )}
            {!loadError && (
              <>
                {hasSurveysPlaceholder && (
                  <div className="mt-2">
                    <NoSurveysPlaceholder type="draft" />
                  </div>
                )}
                {!hasSurveysPlaceholder && surveys.length === 0 && (
                  <p className="py-4 text-center text-sm">No surveys found</p>
                )}
              </>
            )}
            {surveys.map((survey) => {
              return (
                <DraftSurveyRow
                  key={survey.id}
                  onSurveyCloned={onSurveyCloned}
                  onSurveyDeleted={onSurveyDeleted}
                  onSurveyStatusChanged={onSurveyDeleted}
                  onTagAdded={onTagAdded}
                  onTagRemoved={onTagRemoved}
                  survey={survey}
                />
              );
            })}
            {isFetchingNextPage && (
              <DraftsTablePlaceholderRows withPulse={true} />
            )}
            {hasNextPage && (
              <SurveysTableInfiniteScrollTrigger onTriggered={onLoadNextPage} />
            )}
          </>
        )}
      </div>
    </div>
  );
});

const DraftsTablePlaceholderRows = ({
  withPulse,
}: {
  withPulse: boolean;
}): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="p-4 rounded border-b border-gray-300">
            <div
              className={clsx(
                'grid grid-cols-dashboard-draft items-center gap-2',
                {
                  'animate-pulse': withPulse,
                },
              )}
            >
              {/* Name */}
              <div className="justify-self-start w-full space-y-2">
                <div className="w-3/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-4 bg-light-grey" />
              </div>
              {/* Date Created */}
              <div className="w-3/4 h-4 bg-light-grey" />
              {/* Contact */}
              <div className="w-8 h-8 rounded-full bg-light-grey" />
              {/* Status */}
              <div className="w-1/2 h-4 bg-light-grey" />
              {/* Participants */}
              <div className="w-1/4 h-4 bg-light-grey" />
              {/* Questions */}
              <div className="w-1/4 h-4 bg-light-grey" />
              {/* Quick Actions */}
              <div className="justify-self-center w-8 h-8 rounded-full bg-light-grey" />
            </div>
          </div>
        );
      })}
    </>
  );
};

const DraftSurveyRow = ({
  onSurveyCloned,
  onSurveyDeleted,
  onSurveyStatusChanged,
  onTagAdded,
  onTagRemoved,
  survey,
}: {
  survey: DashboardSurvey;
} & SurveyActionFns &
  TagFns): JSX.Element => {
  return (
    <div
      className="grid grid-cols-dashboard-draft items-center justify-items-center gap-2 p-4 border-b border-gray-300"
      data-testid="dashboard-survey"
    >
      <div className="justify-self-start">
        <Link
          className="block mb-1 cursor-pointer"
          to={`/campaign/edit/${survey.id}/overview`}
        >
          {survey.title}
        </Link>
        <SurveyTagging
          onTagAdded={onTagAdded}
          onTagRemoved={onTagRemoved}
          survey={survey}
        />
      </div>
      <div className="justify-self-start">
        {formatDate(survey.createdAt, {
          format: DATE_FORMATS.DASHBOARD_CREATED,
        })}
      </div>
      <div className="justify-self-start">
        <UserBubble user={survey.user} />
      </div>
      <div className="justify-self-start">
        <SurveyStatusBadge
          onSurveyStatusChanged={onSurveyStatusChanged}
          survey={survey}
        />
      </div>
      <div className="justify-self-start">
        {survey.participants?.toLocaleString()}
      </div>
      <div className="justify-self-start">{survey.surveyLength?.name}</div>

      <div className="flex space-x-2">
        <div className="flex center mt-2 text-gray-900">
          <OptionsButton
            onSurveyCloned={onSurveyCloned}
            onSurveyDeleted={onSurveyDeleted}
            survey={survey}
          />
        </div>
      </div>
    </div>
  );
};

const SurveysTableInfiniteScrollTrigger = ({
  onTriggered,
}: {
  onTriggered(): void;
}): JSX.Element => {
  const target = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0] && entries[0].isIntersecting) {
          onTriggered();
        }
      },
      { rootMargin: '50px 0px 0px' },
    );

    if (target.current) {
      observer.observe(target.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onTriggered]);

  return <div ref={target} />;
};

const NoSurveysPlaceholder = ({
  type,
}: {
  type: 'draft' | 'launched';
}): JSX.Element => {
  const prompt =
    type === 'draft'
      ? 'This area is everything related to your draft surveys!'
      : 'This area is everything related to your live and completed surveys!';

  return (
    <div className="space-y-2">
      <div
        className="relative flex items-center justify-between py-8 px-4 space-x-4 rounded bg-cover bg-no-repeat bg-center text-white text-center"
        style={{
          backgroundImage: `url(${noSurveysPlaceholderBg})`,
        }}
      >
        <span>This is your survey view</span>
        <p>{prompt}</p>
        <Link to="/campaign/create">
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="arrow-circle-right" />}
            iconPlacement="trailing"
            size="md"
          >
            Create a New Survey
          </Button>
        </Link>
      </div>
      {type === 'draft' ? (
        <DraftsTablePlaceholderRows withPulse={false} />
      ) : (
        <LaunchedTablePlaceholderRows withPulse={false} />
      )}
    </div>
  );
};

const SortableColumnHeader = ({
  defaultSortDirection = 'ASC',
  isFirstColumn = true,
  label,
  onChangeSort,
  sortKey,
  sorts,
}: {
  defaultSortDirection?: DashboardSortValue;
  isFirstColumn?: boolean;
  label: string;
  sortKey: keyof DashboardSorts;
} & Pick<SurveyTableProps, 'onChangeSort' | 'sorts'>): JSX.Element => {
  const sortValue = sorts[sortKey];

  return (
    <div
      className={clsx(
        'group relative flex items-center cursor-pointer text-gray-600',
        {
          'justify-self-start': isFirstColumn,
        },
      )}
      onClick={() => {
        onChangeSort(
          sortKey,
          sortKey === 'name'
            ? toggleNameSort(sortValue, { defaultSortDirection })
            : toggleCreatedAtSort(sortValue, { defaultSortDirection }),
        );
      }}
    >
      <span>{label}</span>
      <div
        className={clsx('absolute -right-4 w-3 h-3', {
          // group-hover:visible', {
          invisible: sortValue === '',
        })}
      >
        {defaultSortDirection === 'ASC' ? (
          <>
            {sortValue === 'DESC' ? (
              <Icon id="chevron-up" />
            ) : (
              <Icon id="chevron-down" />
            )}
          </>
        ) : (
          <>
            {sortValue === 'ASC' ? (
              <Icon id="chevron-up" />
            ) : (
              <Icon id="chevron-down" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const SurveyTagging = ({
  onTagAdded,
  onTagRemoved,
  survey,
}: {
  survey: DashboardSurvey;
} & TagFns): JSX.Element => {
  const [isTagPopoverOpen, setIsTagPopoverOpen] = useState(false);

  return (
    <div className="flex">
      <Popover
        isOpen={isTagPopoverOpen}
        name={`survey-tag-${survey.id}`}
        setIsOpen={setIsTagPopoverOpen}
        trigger={(triggerProps) => {
          return survey.project ? (
            <div
              {...triggerProps}
              className={`${triggerProps.className} max-w-full cursor-pointer`}
              onClick={(event) => {
                // The user may have been clicking the remove the tag from the survey in which
                // case we don't want to open the tag adding popover.
                if (event.defaultPrevented) {
                  return;
                }

                triggerProps.onClick();
              }}
            >
              <SurveyTag
                onTagRemoved={() => {
                  onTagRemoved(survey);
                }}
                surveyId={survey.id}
                tag={survey.project}
              />
            </div>
          ) : (
            <div {...triggerProps}>
              <div className="flex items-center space-x-1 text-green">
                <div className="w-3.5 h-3.5">
                  <Icon id="tag-01" />
                </div>
                <Hyperlink>Add Tag</Hyperlink>
              </div>
            </div>
          );
        }}
      >
        <AddTagPopoverBody
          onTagAdded={(...args) => {
            setIsTagPopoverOpen(false);

            onTagAdded(...args);
          }}
          survey={survey}
        />
      </Popover>
    </div>
  );
};

const SurveyTag = ({
  onTagRemoved,
  surveyId,
  tag,
}: {
  onTagRemoved(): void;
  surveyId: number;
  tag: Tag;
}): JSX.Element => {
  const { mutate: removeTagFromSurvey } = useRemoveTagToSurvey({
    onError: (err) => {
      showErrorMessage(
        `Failed to remove tag from survey. Error: ${err.message}`,
      );
    },
    onSuccess: () => {
      onTagRemoved();
    },
  });

  return (
    <Badge
      color="primary"
      icon={
        <button
          className="w-full h-full"
          onClick={(event) => {
            // The default action when clicking on a survey tag is to open a popover so the user
            // can add / edit the tag for a survey. If we're removing the tag, we don't want this
            // default action.
            event.preventDefault();

            removeTagFromSurvey({ surveyId });
          }}
        >
          <Icon id="x" />
        </button>
      }
      iconPlacement="trailing"
      size="sm"
    >
      {tag.title}
    </Badge>
  );
};

const AddTagPopoverBody = ({
  onTagAdded,
  survey,
}: { survey: DashboardSurvey } & Pick<TagFns, 'onTagAdded'>): JSX.Element => {
  const organizationId = survey.organizationId;

  const {
    changeTagFilter,
    hasLoadTagsError,
    isLoadingTags,
    loadTagsError,
    onTagCreated,
    tagFilter,
    tags,
  } = useTags({ organizationId });

  function onTagAddSuccess(tag: Tag) {
    onTagAdded({ survey, tag });
  }

  const {
    isPending: isCreatingAndAssociatingTag,
    mutate: createAndAssociateTag,
  } = useCreateAndAssociateTag({
    onError: (err) => {
      showErrorMessage(`Failed to add tag to survey. Error: ${err.message}`);
    },
    onSuccess: (newTag) => {
      onTagCreated();

      onTagAddSuccess(newTag);
    },
  });

  return (
    <div className="w-72 text-base">
      <div className="p-2 border-b border-light-grey">
        <Input
          name="tagFilter"
          onChange={(event) => {
            changeTagFilter(event.target.value);
          }}
          placeholder="Filter"
          size="md"
          value={tagFilter}
        />
      </div>
      <div className="max-h-72 py-1 overflow-auto">
        {isLoadingTags && tags.length === 0 && (
          <div className="h-20 pt-1 animate-pulse">
            <div className="w-3/4 h-4 mx-2 bg-light-grey" />
            <div className="w-1/2 h-4 mt-2 mx-2 bg-light-grey" />
          </div>
        )}
        {hasLoadTagsError && loadTagsError instanceof Error && (
          <ErrorDisplay
            message={`Failed to load tags. (${loadTagsError.message})`}
          />
        )}
        {!isLoadingTags &&
          !loadTagsError &&
          tags.length === 0 &&
          !tagFilter && (
            <p className="px-2 text-dark-grey text-sm">No tags found</p>
          )}
        {tags.map((existingTag) => {
          return (
            <ExistingTag
              key={existingTag.id}
              onTagAssociated={onTagAddSuccess}
              surveyId={survey.id}
              tag={existingTag}
            />
          );
        })}
        {tagFilter && !isLoadingTags && !hasLoadTagsError && (
          <div
            className="py-1 px-2 hover:bg-light-grey cursor-pointer"
            onClick={() => {
              createAndAssociateTag({
                data: { organizationId, title: tagFilter },
                surveyId: survey.id,
              });
            }}
          >
            <div className="flex items-center text-blue text-sm">
              <div className="w-4 h-4">
                <Icon id="plus" />
              </div>
              <span>Create tag for '{tagFilter}'</span>
              {isCreatingAndAssociatingTag && (
                <div className="ml-2">
                  <CircleLoader isColored={true} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExistingTag = ({
  onTagAssociated,
  surveyId,
  tag,
}: {
  onTagAssociated(tag: Tag): void;
  surveyId: number;
  tag: Tag;
}): JSX.Element => {
  const { isPending: isAddingTagToSurvey, mutate: addTagToSurvey } =
    useAddTagToSurvey({
      onError: (err) => {
        showErrorMessage(`Failed to add tag to survey. Error: ${err.message}`);
      },
      onSuccess: () => {
        onTagAssociated(tag);
      },
    });

  return (
    <div
      className="flex items-center justify-between py-1 px-2 hover:bg-light-grey text-sm cursor-pointer"
      onClick={() => {
        addTagToSurvey({ data: { projectId: tag.id }, surveyId });
      }}
    >
      <span>{tag.title}</span>
      {isAddingTagToSurvey && <CircleLoader isColored={true} />}
    </div>
  );
};
