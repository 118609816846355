import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Fragment } from 'react';
import { groupBy, orderBy, sortBy } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

import { isIdeaPresenterQuestion } from '../../util/questions';
import {
  orderQuotasByOptions,
  questionSupportsQuotas,
} from 'util/questionQuotas';
import {
  QUESTION_TYPE,
  QuestionConcept,
  QuestionWithResults,
  Survey,
} from '../../types/domainModels';
import { questionQueries } from 'hooks/backend/questions';
import { questionQuotaQueries } from 'hooks/backend/questionQuotas';
import { useHasRole } from 'hooks/users';

import ErrorDisplay from '../common/ErrorDisplay';
import HorizontalBarChart, { ChartHeader } from './HorizontalBarChart';
import IndexCard from '../common/IndexCard';
import {
  QuotaProgress,
  QuotasProgressHeader,
  QuestionQuotasDropdown,
  Requirements,
} from './Quotas';
import TitledCard, { TitledCardHeader } from './TitledCard';
import WordSeparator from 'components/common/WordSeparator';

function hasHorizontalBarChart(question: QuestionWithResults) {
  return (
    isIdeaPresenterQuestion(question) ||
    [
      QUESTION_TYPE.MATRIX,
      QUESTION_TYPE.MULTIPLE_CHOICE,
      QUESTION_TYPE.RANKING,
      QUESTION_TYPE.SCALE,
    ].includes(question.questionTypeId)
  );
}

const QuestionAnalytics = ({
  cohorts,
  monadicConcepts,
  questionId,
  survey,
  waveIds,
}: {
  cohorts: string[];
  monadicConcepts: QuestionConcept[];
  questionId: number;
  survey: Survey;
  waveIds: number[];
}): JSX.Element => {
  const {
    data: question = null,
    isError: hasLoadQuestionResultsError,
    isLoading: isLoadingQuestionResults,
  } = useQuery(questionQueries.getResults({ questionId, waveIds }));

  if (isLoadingQuestionResults) {
    return (
      <div className="w-210">
        <SkeletonQuestionAnalytics />
      </div>
    );
  }

  if (hasLoadQuestionResultsError || !question) {
    return <ErrorDisplay message="Failed to load results for question." />;
  }

  if (
    question.questionTypeId === QUESTION_TYPE.OPEN_ENDED ||
    question.questionTypeId === QUESTION_TYPE.NUMBER
  ) {
    return (
      <div className="w-210">
        <OpenEndedAnalysis question={question} />
      </div>
    );
  }

  return (
    <div className="w-210">
      <div className="relative space-y-4">
        <p className="absolute -top-6 right-0 text-dark-grey text-xs text-right">
          {question.totalRespondentsCount} respondents
        </p>

        {question.questionTypeId === QUESTION_TYPE.GABOR_GRANGER && (
          <GaborGrangerChartCard question={question} />
        )}
        {hasHorizontalBarChart(question) && (
          <HorizontalBarChart
            cohorts={cohorts}
            monadicConcepts={monadicConcepts}
            question={question}
          />
        )}

        <QuestionQuotas
          question={question}
          questions={[question]}
          survey={survey}
          waveIds={waveIds}
        />
      </div>
    </div>
  );
};

export default QuestionAnalytics;

const SkeletonQuestionAnalytics = (): JSX.Element => {
  const tableRows = [1, 2];

  return (
    <div className="space-y-4">
      {/* Mocks out the shape of a bar chart (poorly). */}
      <IndexCard>
        <div className="h-96 animate-pulse p-4">
          <div className="space-y-2">
            <div className="w-20 h-2 bg-light-grey" />
            <div className="w-1/4 h-4 bg-light-grey" />
            <div className="w-1/3 h-2 bg-light-grey" />
          </div>
          <div className="mt-16 space-y-6">
            <div className="w-4/5 h-4 bg-light-grey" />
            <div className="w-3/5 h-4 bg-light-grey" />
            <div className="w-2/5 h-4 bg-light-grey" />
            <div className="w-1/5 h-4 bg-light-grey" />
          </div>
        </div>
      </IndexCard>
      {/* Mocks out the shape of the Quotas Card. */}
      <IndexCard>
        <div className="p-4 space-y-4">
          <div className="w-1/4 h-4 bg-light-grey" />
          <div className="grid grid-cols-3 gap-4 animate-pulse">
            {tableRows.map((num) => {
              return (
                <Fragment key={num}>
                  <div className="w-3/4 h-4 bg-light-grey" />
                  <div className="w-3/4 h-4 bg-light-grey" />
                  <div className="w-3/4 h-4 bg-light-grey" />
                </Fragment>
              );
            })}
          </div>
        </div>
      </IndexCard>
    </div>
  );
};

const OpenEndedAnalysis = ({
  question,
}: {
  question: QuestionWithResults;
}): JSX.Element => {
  const results = question?.openEndedResponses;

  return (
    <IndexCard>
      <div className="space-y-2">
        <ChartHeader labels={[]} sort={question.sort} title={question.title} />
        <div>
          <div className="grid grid-cols-open-end-responses gap-2 items-center h-10 px-4 bg-dark-white text-dark-grey text-xs">
            <div>ID</div>
            <div>Response</div>
          </div>
          <div className="overflow-auto" style={{ maxHeight: '405px' }}>
            {results.length === 0 && (
              <p className="p-4 text-sm">No responses</p>
            )}
            {results.map(({ id, value }) => {
              return (
                <div
                  key={id}
                  className="grid grid-cols-open-end-responses gap-2 p-4 text-sm"
                >
                  <div>{id}</div>
                  <div>{value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </IndexCard>
  );
};

const GaborGrangerChartCard = ({
  question,
}: {
  question: QuestionWithResults;
}): JSX.Element => {
  const option = question.options[0];

  const data = sortBy(option.resultsBreakdown, 'step').map(
    ({ percentage, step }) => {
      return {
        name: `$${step}`,
        value: percentage * 100,
      };
    },
  );

  return (
    <IndexCard>
      <ChartHeader labels={[]} sort={question.sort} title={question.title} />
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={data}>
          <XAxis dataKey="name" type="category" />
          <YAxis domain={[0, 100]} ticks={[0, 20, 40, 60, 80, 100]} />
          <RechartTooltip cursor={{ fill: '#eee' }} />
          <Bar barSize={20} dataKey="value" fill="#3f93ea" />
        </BarChart>
      </ResponsiveContainer>
    </IndexCard>
  );
};

const QuestionQuotas = ({
  question,
  questions,
  survey,
  waveIds,
}: {
  question: QuestionWithResults;
  questions: QuestionWithResults[];
  survey: Survey;
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');
  const surveyId = survey.id;

  const { data: questionQuotas = [] } = useQuery(
    questionQuotaQueries.forSurvey({ surveyId, waveIds }),
  );
  const questionQuotasByQuestionId = groupBy(questionQuotas, 'question.id');

  const quotasForQuestion = orderQuotasByOptions(
    questionQuotasByQuestionId[question.id] ?? [],
  );

  // Non-admins can't add question quotas to a live survey.
  if (
    (!isAdmin && quotasForQuestion.length === 0) ||
    !questionSupportsQuotas(question)
  ) {
    return null;
  }

  return (
    <TitledCard
      header={
        <TitledCardHeader
          rightContent={
            isAdmin ? (
              <QuestionQuotasDropdown
                numQuotas={quotasForQuestion.length}
                question={question}
                questions={questions}
                survey={survey}
                waveIds={waveIds}
              />
            ) : undefined
          }
        >
          Question Quotas
        </TitledCardHeader>
      }
    >
      {/*
       * We only show this messaging for admins since non-admins can't edit the
       * question quotas for a live survey.
       */}
      {isAdmin && quotasForQuestion.length === 0 && (
        <p className="p-4">No quotas</p>
      )}

      {quotasForQuestion.length > 0 && (
        <div className="p-4">
          <div className="mb-2">
            <QuotasProgressHeader titleFirstColumn="Option(s)" />
          </div>

          {quotasForQuestion.map((quota, quotaIdx) => {
            return (
              <div key={quota.id}>
                <QuotaProgress
                  firstColumnContent={
                    <Requirements
                      logic="should"
                      requirements={orderBy(quota.options, 'sort').map(
                        (o) => o.title,
                      )}
                    />
                  }
                  numCompletes={quota.count}
                  numNeeded={quota.numberNeeded}
                  type={quota.logicalModifier}
                />
                {quotaIdx !== quotasForQuestion.length - 1 && (
                  <WordSeparator word="and" />
                )}
              </div>
            );
          })}
        </div>
      )}
    </TitledCard>
  );
};
