import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { useField } from 'formik';

import { DisplayLogicOrGroups, ReactSelectValue } from '../../types/forms';
import {
  getEmptyAndGroup,
  hasConfiguredDisplayLogic,
} from '../../util/displayLogic';
import { Question, Survey } from '../../types/domainModels';
import { QuestionGroup } from '../../types/internal';

import { useModal } from '../../hooks/modals';
import DisplayLogicEditModal, {
  Props as DisplayLogicEditModalProps,
} from './DisplayLogicEditModal';
import DisplayLogicSentence from './DisplayLogicSentence';
import ExclamationCircleIcon from '../common/icons/ExclamationCircleIcon';
import FormCheckbox from '../common/forms/FormCheckbox';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';

const DisplayLogicCheckbox = ({
  disabledTooltip = undefined,
  fieldPrefix,
  isDisabled = false,
  isWithinMonadicLoop,
  questionOptions,
  survey,
  type,
}: {
  disabledTooltip?: ReactNode;
  fieldPrefix: string;
  isDisabled?: boolean;
  isWithinMonadicLoop: boolean;
  questionOptions: QuestionGroup<Question>[] | ReactSelectValue<Question>[];
  survey: Survey;
  type?: DisplayLogicEditModalProps['type'];
}): JSX.Element => {
  const [{ value: displayLogicEnabled }] = useField<boolean>(
    `${fieldPrefix}.enabled`,
  );
  const [
    { value: displayLogicValues },
    displayLogicValuesMeta,
    displayLogicValuesHelpers,
  ] = useField<DisplayLogicOrGroups>(`${fieldPrefix}.values`);

  const {
    isOpen: isDisplayLogicModalOpen,
    onCloseModal,
    setIsOpen: setIsDisplayLogicModalOpen,
  } = useModal();

  const hasDisplayLogic = hasConfiguredDisplayLogic(displayLogicValues);
  const hasDisplayLogicError = !!displayLogicValuesMeta.error;
  const isCheckboxDisabled = !displayLogicEnabled && isDisabled;

  function openDisplayLogicModal() {
    if (displayLogicValues.length === 0) {
      displayLogicValuesHelpers.setValue([[getEmptyAndGroup()]]);
    }

    setIsDisplayLogicModalOpen(true);
  }

  return (
    <div>
      <FormCheckbox
        checkboxLabel={
          <div className="flex items-center">
            <span
              className={clsx({
                'text-red font-bold': hasDisplayLogicError,
              })}
            >
              Display Logic
            </span>
            {displayLogicEnabled &&
              (hasDisplayLogic || hasDisplayLogicError) && (
                <div className="flex items-center ml-2 space-x-2">
                  <div>
                    <IconBackground
                      onClick={(event) => {
                        // This event manipulation is to prevent the unchecking of the checkbox when this
                        // icon is clicked.
                        event.preventDefault();
                        event.stopPropagation();

                        openDisplayLogicModal();
                      }}
                      title="Edit"
                    >
                      <div className="w-3 h-3">
                        <Icon id="pencil" />
                      </div>
                    </IconBackground>
                  </div>
                  {hasDisplayLogicError && (
                    <div className="w-6 h-6 text-red">
                      <ExclamationCircleIcon />
                    </div>
                  )}
                </div>
              )}
          </div>
        }
        disabled={isCheckboxDisabled}
        name={`${fieldPrefix}.enabled`}
        onChange={(value) => {
          if (value) {
            openDisplayLogicModal();
          }
        }}
        tooltip={isCheckboxDisabled ? disabledTooltip : undefined}
      />
      {displayLogicEnabled && (
        <>
          {hasDisplayLogicError && (
            <p className="mt-2 text-sm">
              The display logic is misconfigured. Please adjust or remove.
            </p>
          )}
          {hasDisplayLogic && (
            <div className="mt-2 pl-6">
              <DisplayLogicSentence
                displayLogicValues={displayLogicValues}
                isWithinMonadicLoop={isWithinMonadicLoop}
              />
            </div>
          )}
          {isDisplayLogicModalOpen && (
            <DisplayLogicEditModal
              isWithinMonadicLoop={isWithinMonadicLoop}
              namePrefix={fieldPrefix}
              onCloseModal={onCloseModal}
              questionOptions={questionOptions}
              survey={survey}
              type={type}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DisplayLogicCheckbox;
