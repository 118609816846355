import ExclamationCircleIcon from './icons/ExclamationCircleIcon';

const ErrorDisplay = ({ message }: { message: string }): JSX.Element => {
  return (
    <div className="flex items-start p-4 space-x-2 bg-faded-red">
      <div className="flex-shrink-0 mt-0.5 w-4 h-4 text-red">
        <ExclamationCircleIcon />
      </div>
      <p className="text-sm">
        <span className="text-red font-semibold">Error:</span> {message}
      </p>
    </div>
  );
};

export default ErrorDisplay;
