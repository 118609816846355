const LogoCollapsed = (): JSX.Element => {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 39 40"
      width="39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8288 28.7986L28.482 36.9132C29.9483 36.1601 31.3198 35.2187 32.5595 34.0999L30.0149 26.3192C28.8824 27.4865 27.445 28.3554 25.828 28.7986H25.8295H25.8288ZM32.0874 16.8744L38.5763 16.8708C38.3366 15.4414 37.9361 14.0482 37.3822 12.7165C37.3011 12.5224 37.2171 12.3313 37.1302 12.1408L28.2973 12.1459C30.041 13.2429 31.3908 14.9048 32.0874 16.8737V16.8751V16.8744ZM14.1196 19.9288C14.1196 14.8461 18.2587 10.7251 23.366 10.7251C24.4616 10.7251 25.5116 10.9156 26.4855 11.2632L33.1395 6.44557C31.9454 5.27465 30.6138 4.27464 29.1807 3.46217L19.6179 10.3848L22.6144 1.18986C21.6614 1.04431 20.6933 0.969727 19.7179 0.969727C19.0256 0.969727 18.3362 1.00883 17.6541 1.08197L14.0103 12.2646L11.0008 3.0639C9.53375 3.81264 8.16008 4.75039 6.91893 5.86264L10.583 17.0656L2.68351 11.3602C2.45542 11.8048 2.24542 12.2574 2.05353 12.7179C1.61181 13.7788 1.26857 14.8795 1.02599 16.0062L10.6453 22.9542L0.833374 22.9593C1.06871 24.4481 1.48002 25.8992 2.05497 27.2823C2.11218 27.4184 2.17011 27.5531 2.22949 27.6878L14.1725 27.682L6.19698 33.4547C7.37875 34.6322 8.701 35.6387 10.1246 36.4591L19.8178 29.4424L16.7678 38.8009C17.7374 38.9508 18.7229 39.0305 19.7179 39.0305C20.3913 39.0305 21.0604 38.9936 21.7244 38.9241L24.962 28.9912C24.4428 29.081 23.9105 29.131 23.3653 29.131C18.2595 29.131 14.1189 25.0114 14.1189 19.9273L14.1175 19.9288H14.1196ZM38.443 23.8478C38.1983 25.0266 37.8442 26.1758 37.3836 27.2837C37.2098 27.7008 37.0201 28.1129 36.8166 28.5169L31.3495 24.5675C32.1504 23.2055 32.6109 21.6211 32.6109 19.9288C32.6109 19.8281 32.6058 19.7289 32.6037 19.6297L38.443 23.8478Z"
        fill="#FF6700"
      />
    </svg>
  );
};

export default LogoCollapsed;
