const backgroundColors = [
  '#DBF8D1', // mint
  '#FBDFC5', // peach
  '#FEE4E2', // error-100
  '#D1E9FF', // blue-100
  '#F7F7F7', // gray-100
];
const textColors = [
  '#027A48', // green-700
  '#B54708', // warning-700
  '#B42318', // error-700
  '#175CD3', // blue-700
  '#585858', // gray-700
];

const ColoredBubble = ({ id, value }: { id: number; value: string }) => {
  const text = value
    .split(' ')
    .slice(0, 2)
    .map((word) => word.trim()[0])
    .join('')
    .toUpperCase();

  const bgColor = backgroundColors[id % backgroundColors.length];
  const textColor = textColors[id % textColors.length];

  return (
    <div className="flex overflow-hidden items-center justify-center w-8 h-8 rounded-full">
      <div
        className="flex items-center justify-center w-full h-full text-xs cursor-default"
        style={{
          background: bgColor,
          color: textColor,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default ColoredBubble;
