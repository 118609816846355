import { infiniteQueryOptions } from '@tanstack/react-query';

import { DashboardFilters, DashboardSorts, DashboardTab } from 'types/internal';
import {
  FetchDashboardSurveysResponse,
  fetchDashboardSurveys,
} from 'services/backend/dashboard';

export const dashboardQueries = {
  get: (opts: {
    activeTab: DashboardTab | undefined;
    filters: DashboardFilters;
    name: string;
    organizationId: number | undefined;
    sorts: DashboardSorts;
  }) =>
    infiniteQueryOptions({
      getNextPageParam: (lastPage: FetchDashboardSurveysResponse) => {
        const { draftAmount, launchedAmount, nextOffset } = lastPage;
        const comparisonAmount =
          opts.activeTab === 'drafts' ? draftAmount : launchedAmount;

        return nextOffset >= comparisonAmount ? null : nextOffset;
      },
      initialPageParam: 0,
      queryFn: ({ pageParam: offset }) => {
        return fetchDashboardSurveys({
          activeTab: opts.activeTab,
          filters: opts.filters,
          limit: 20,
          name: opts.name,
          offset,
          organizationId: opts.organizationId,
          sorts: opts.sorts,
        });
      },
      queryKey: [
        'dashboard',
        {
          activeTab: opts.activeTab,
          filters: opts.filters,
          name: opts.name,
          organizationId: opts.organizationId,
          sorts: opts.sorts,
        },
      ],
    }),
};
