import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';

import { getFormDataToDuplicate } from '../../util/questions';
import { Question } from 'types/domainModels';
import { QuestionFormData } from '../../types/forms';

import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';

const DuplicateQuestion = ({
  onDuplicate,
  questions,
}: {
  onDuplicate(formData: QuestionFormData): void;
  questions: Question[];
}): JSX.Element => {
  const { values } = useFormikContext<QuestionFormData>();

  const delayTimeoutId = useRef<number | null>(null);
  const [isDuplicating, setIsDuplicating] = useState(false);

  // Clears any queued timeout if the component is no longer active.
  useEffect(() => {
    return () => {
      if (delayTimeoutId.current) {
        window.clearTimeout(delayTimeoutId.current);
        delayTimeoutId.current = null;
      }
    };
  }, []);

  return (
    <IconBackground
      isLoading={isDuplicating}
      onClick={() => {
        setIsDuplicating(true);
        delayTimeoutId.current = window.setTimeout(() => {
          onDuplicate(getFormDataToDuplicate({ formData: values, questions }));
          setIsDuplicating(false);
        }, 300);
      }}
      tooltip="Duplicate"
    >
      <div className="w-4 h-4">
        <Icon id="copy-02" />
      </div>
    </IconBackground>
  );
};

export default DuplicateQuestion;
