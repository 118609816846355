import { clsx } from 'clsx';
import { fill } from 'lodash-es';

const Dots = ({
  step,
  total,
}: {
  step: number;
  total: number;
}): JSX.Element => {
  return (
    <ul className="flex space-x-2">
      {fill(new Array(total), '').map((_i, idx) => {
        const isCurrentStep = idx === step - 1;

        return (
          <li
            key={idx}
            className={clsx('w-4 h-4 rounded-full', {
              'bg-primary-d-600': isCurrentStep,
              'bg-gray-d-300': !isCurrentStep,
            })}
          />
        );
      })}
    </ul>
  );
};

export default Dots;
