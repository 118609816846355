import { init as FullStoryInit } from '@fullstory/browser';
import * as Sentry from '@sentry/react';

import { getEnvVar } from 'util/env';

let hasInitReplay = false;

export function initReplay() {
  const isProduction = getEnvVar('APP_ENV') === 'production';
  if (!isProduction) {
    return;
  }

  if (!hasInitReplay) {
    hasInitReplay = true;

    if (!Sentry.getReplay()) {
      Sentry.addIntegration(
        Sentry.replayIntegration({
          blockAllMedia: false,
          maskAllText: false,
        }),
      );
    }

    FullStoryInit({ orgId: getEnvVar('FULLSTORY_ORG_ID') });
  }
}
