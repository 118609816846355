import { Form, Formik, useField } from 'formik';
import { flatten } from 'lodash-es';

import { apiResultDataToFormData, getEmptyQuota } from '../../util/questions';
import { formQuotaToApiQuotaElement } from 'util/questionQuotas';
import { QuestionFormOption, QuestionQuota } from '../../types/forms';
import { QuestionWithResults, Survey } from '../../types/domainModels';
import { Quota } from '../surveyEdit/QuotasEditModal';
import { showErrorMessage } from '../../util/notifications';
import { useCreateQuestionQuotas } from 'hooks/backend/questionQuotas';

import AddButton from './forms/AddButton';
import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import Modal, { ModalHeader } from './Modal';
import WordSeparator from './WordSeparator';

interface QuotaFormData {
  quotas: QuestionQuota[];
}

const ResultQuotaEditModal = ({
  onCloseModal,
  question,
  questions,
  survey,
}: {
  onCloseModal(): void;
  question: QuestionWithResults;
  questions: QuestionWithResults[];
  survey: Survey;
}) => {
  const questionValues = apiResultDataToFormData({
    question,
    questions,
    survey,
  });
  const initialQuotaValues = questionValues.features.quotas.values;
  const optionValues = questionValues.options;

  const { isPending: isCreatingQuotas, mutate: createQuestionQuotas } =
    useCreateQuestionQuotas({
      onError: (err: Error) => {
        showErrorMessage(`Failed to update quota(s). Error: ${err.message}`);
      },
      onSuccess: () => {
        onCloseModal();
      },
    });

  return (
    <Modal
      header={<ModalHeader onClickClose={onCloseModal}>Quotas</ModalHeader>}
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <Formik<QuotaFormData>
        enableReinitialize
        initialValues={{ quotas: initialQuotaValues }}
        onSubmit={(data) => {
          createQuestionQuotas({
            data: {
              quotaElements: data.quotas.map((quota) =>
                formQuotaToApiQuotaElement({ quota }),
              ),
            },
            questionID: question.id,
            surveyId: question.surveyId,
          });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form>
          <Quotas optionValues={optionValues} />

          <div className="mt-8 flex gap-3 flex-row-reverse">
            <ButtonLoading
              grow
              hierarchy="primary"
              isLoading={isCreatingQuotas}
              size="lg"
              type="submit"
            >
              Save Quotas
            </ButtonLoading>
            <Button
              grow
              hierarchy="secondary-gray"
              onClick={onCloseModal}
              size="lg"
              type="button"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ResultQuotaEditModal;

const Quotas = ({ optionValues }: { optionValues: QuestionFormOption[] }) => {
  const [{ value: quotas }, , quotasHelper] =
    useField<QuotaFormData['quotas']>('quotas');

  return (
    <div className="w-160">
      {quotas.map((_quota, idx) => {
        const currentlyUsedQuotaOptionIdxs = flatten(
          quotas.map((quota) =>
            quota.options ? quota.options.map((o) => o.value) : [],
          ),
        );

        return (
          <div key={idx}>
            <Quota
              index={idx}
              indexPrefix="quotas"
              onClickRemove={() => {
                const newQuotas = [...quotas];
                newQuotas.splice(idx, 1);

                quotasHelper.setValue(newQuotas);
              }}
              options={optionValues}
              usedOptionIndices={currentlyUsedQuotaOptionIdxs}
            />
            {idx !== quotas.length - 1 && <WordSeparator word="and" />}
          </div>
        );
      })}

      <AddButton
        label="Add Quota"
        onClick={() => {
          quotasHelper.setValue([...quotas, getEmptyQuota()]);
        }}
      />
    </div>
  );
};
