import { ComponentProps, useLayoutEffect, useRef } from 'react';

import Textarea from './Textarea';

const TextareaAutosize = (
  props: Omit<ComponentProps<typeof Textarea>, 'rows'>,
): JSX.Element => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (!textAreaRef.current) {
      return;
    }

    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    textAreaRef.current.style.height = '0px';
    const newHeight =
      textAreaRef.current.scrollHeight === 0
        ? 'auto'
        : // We add 2px here because our textareas have 1px border (one on the top and one on the bottom).
          `${textAreaRef.current.scrollHeight + 2}px`;

    // We then set the height directly, outside of the render loop
    // Trying to set this with state or a ref will product an incorrect value.
    textAreaRef.current.style.height = newHeight;

    if (newHeight !== 'auto') {
      textAreaRef.current.style.maxHeight = '200px';
    }
  });

  return <Textarea ref={textAreaRef} {...props} rows={1} />;
};

export default TextareaAutosize;
