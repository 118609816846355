import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { User } from '../../types/domainModels';

export const ONBOARDING_SET_PASSWORD = ({ token }: { token: string }) => ({
  path: `/onboarding/change-password/${token}`,
  version: 'v1' as const,
});

export async function onboardingSetPassword({
  data,
  token,
}: {
  data: {
    password: string;
    retypepassword: string;
  };
  token: string;
}) {
  return (
    await axios.post<User>(getAPIUrl(ONBOARDING_SET_PASSWORD({ token })), data)
  ).data;
}

export const ONBOARDING_WELCOME = ({ token }: { token: string }) => ({
  path: `/onboarding/welcome/${token}`,
  version: 'v1' as const,
});

export async function onboardingWelcome({ token }: { token: string }) {
  return (await axios.post<User>(getAPIUrl(ONBOARDING_WELCOME({ token }))))
    .data;
}
