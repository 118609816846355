import { ComponentProps } from 'react';
import { useField } from 'formik';

import SliderToggle from './SliderToggleNew';

const SliderToggleFormik = ({
  name,
  ...rest
}: {
  name: string;
} & Omit<ComponentProps<typeof SliderToggle>, 'onChange'>): JSX.Element => {
  const [field] = useField({ name });

  return <SliderToggle {...field} {...rest} checked={field.value} />;
};

export default SliderToggleFormik;
