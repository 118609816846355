import { clsx } from 'clsx';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

type IconPlacement = 'leading' | 'trailing';
type InputSize = 'md' | 'lg';

const SIZE_MAP: Record<InputSize, string> = {
  md: 'py-2 px-3 text-sm',
  lg: 'py-2.5 px-3.5 text-base',
};

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'className' | 'size'
> & {
  icon?: ReactNode;
  iconPlacement?: IconPlacement;
  size: InputSize;
};

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { icon = undefined, iconPlacement = undefined, size, ...rest },
  ref,
) {
  const iconToRender = icon ? (
    <div className="flex items-center justify-center w-5 h-5">{icon}</div>
  ) : null;
  const input = (
    <input
      {...rest}
      ref={ref}
      className={clsx(
        'w-full rounded-lg border border-gray-d-300 shadow-sm text-gray-d-900 outline-primary-d-600',
        SIZE_MAP[size],
        {
          'pl-8': iconToRender && iconPlacement === 'leading',
          'pr-8': iconToRender && iconPlacement === 'trailing',
        },
      )}
      // Scrolling on a number input will change the value of the input, which is typically undesirable
      // behavior because the user may not know the value changed. So we disable this functionality. We
      // experimented with an approach suggested by MDN to use inputmode="numeric" and a "text" input, but
      // this loses the up/down arrow key functionality to change the value.
      // See https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number.
      onWheelCapture={
        rest.type === 'number' ? (e) => e.currentTarget.blur() : undefined
      }
    />
  );

  if (iconToRender) {
    return (
      <div className="relative">
        {input}

        <div
          className={clsx('absolute top-1/2 -translate-y-1/2', {
            'left-2': iconPlacement === 'leading',
            'right-2': iconPlacement === 'trailing',
          })}
        >
          {iconToRender}
        </div>
      </div>
    );
  }

  return input;
});

export default Input;
