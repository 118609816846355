import { AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { object, string } from 'yup';

import { getItem, LOCALSTORAGE_KEYS } from './localStorage';
import { JwtPayload } from '../types/domainModels';

export const UpdatePasswordSchema = object().shape({
  password: string()
    .min(6, 'Your password must be at least 6 characters.')
    .max(50, 'Your password cannot be longer than 50 characters.')
    .required('Please provide your password.'),
  retypepassword: string()
    .required('Please confirm your password.')
    .test('match', 'Your passwords do not match.', function () {
      return this.parent.password === this.parent.retypepassword;
    }),
});

export function getDecodedJwt() {
  const jwt = getItem(LOCALSTORAGE_KEYS.TOKEN);

  return jwt ? jwtDecode<JwtPayload>(jwt) : null;
}

export function getJwtFromHeaders(headers: AxiosResponse['headers'] | Headers) {
  const authorizationHeader =
    typeof headers.get === 'function' ? headers.get('authorization') : '';

  return typeof authorizationHeader === 'string'
    ? authorizationHeader.replace('Bearer ', '')
    : '';
}
