import axios from 'axios';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getItem, LOCALSTORAGE_KEYS, setItem } from '../util/localStorage';
import { identifyUser } from '../util/analytics';
import { JwtPayload, Role, UserV2 } from '../types/domainModels';
import { userQueries } from 'hooks/backend/users';

interface Auth {
  data: { id: number; organizationId: number; role: Role } | null;
  isLoggedIn: boolean;
  onAuthChanged(opts: { jwt: string | null }): void;
  user: UserV2 | undefined;
}

const AuthContext = createContext<Auth | undefined>(undefined);

const UNAUTHORIZED_EVENT_NAME = 'glass:unauthorized';

export function dispatchUnauthorizedEvent() {
  const unauthorizedEvent = new Event(UNAUTHORIZED_EVENT_NAME);
  document.dispatchEvent(unauthorizedEvent);
}

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const queryClient = useQueryClient();

  const [data, setData] = useState<Auth['data'] | null>(null);
  const [jwt, setJwt] = useState(() => getItem(LOCALSTORAGE_KEYS.TOKEN));

  const { data: user } = useQuery(userQueries.get(data?.id));

  useEffect(() => {
    setItem(LOCALSTORAGE_KEYS.TOKEN, jwt);

    if (jwt) {
      const decodedJwt = jwtDecode<JwtPayload>(jwt);

      // We cache this data when the JWT is updated so we're not constantly
      // deconding the JWT.
      setData({
        id: decodedJwt.id,
        organizationId: decodedJwt.organizationId,
        role: decodedJwt.role,
      });
    } else {
      queryClient.clear();
    }
  }, [jwt, queryClient]);

  useEffect(() => {
    function handleUnauthorizedEvent() {
      setJwt(null);
    }

    document.addEventListener(UNAUTHORIZED_EVENT_NAME, handleUnauthorizedEvent);

    return () => {
      document.removeEventListener(
        UNAUTHORIZED_EVENT_NAME,
        handleUnauthorizedEvent,
      );
    };
  }, []);

  const organizationId = data?.organizationId;
  useEffect(() => {
    if (user) {
      identifyUser({ organizationId, user });
    }
  }, [organizationId, user]);

  return (
    <AuthContext.Provider
      value={{
        data,
        isLoggedIn: !!jwt,
        onAuthChanged: ({ jwt }: { jwt?: string | null }) => {
          if (jwt !== undefined) {
            axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
            setItem(LOCALSTORAGE_KEYS.TOKEN, jwt);
            setJwt(jwt);
          }
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used in an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
