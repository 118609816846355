import { ReactNode } from 'react';

const FormLabel = ({
  children,
  labelFor,
}: {
  children: ReactNode;
  labelFor?: string;
}): JSX.Element => {
  return (
    <label className="flex text-gray-d-700 text-sm" htmlFor={labelFor}>
      {children}
    </label>
  );
};

export default FormLabel;
