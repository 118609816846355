import { clsx } from 'clsx';

export interface Props {
  isFluidWidth?: boolean;
  onChange(value: boolean): void;
  labels?: [string] | [string, string];
  value: boolean;
}

/**
 * By default, the first label will be shown when the provided value is true and the second
 * label will be shown when the provided value is false. If only one label is provided, it
 * will be shown regardless of the value.
 */
const SliderToggle = ({
  isFluidWidth = true,
  onChange,
  labels,
  value,
}: Props): JSX.Element => {
  let label: string | undefined = undefined;
  if (labels) {
    if (labels.length === 2) {
      // When we have two labels, the first label is the "active" label and the second one is the "inactive" label.
      label = value ? labels[0] : labels[1];
    } else {
      label = labels[0];
    }
  }

  const labelWidth = isFluidWidth ? '' : 'w-8';

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={(e) => {
        e.preventDefault();

        onChange(!value);
      }}
    >
      <div
        className={clsx('relative w-10 h-4 rounded-full', {
          'bg-green': value,
          'bg-gray-500': !value,
        })}
      >
        <div
          className={clsx(
            'absolute top-1/2 w-3 h-3 transform -translate-y-1/2 transition-transform rounded-full bg-white shadow-md',
            {
              'translate-x-6': value,
              'translate-x-1': !value,
            },
          )}
        />
      </div>
      {label && <div className={`${labelWidth} ml-2 text-xs`}>{label}</div>}
    </div>
  );
};

export default SliderToggle;
