import { clsx } from 'clsx';
import { MouseEvent, ReactNode } from 'react';

import CircleLoader from '../CircleLoader';
import Tooltip from '../Tooltip';

const IconBackground = ({
  children,
  disabled = false,
  onClick,
  isLoading = false,
  size = 'normal',
  tooltip = '',
  visible = true,
  ...rest
}: {
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  visible?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  size?: 'normal' | 'small';
  title?: string;
  tooltip?: ReactNode;
}): JSX.Element => {
  const iconBackgroundContent = (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full cursor-pointer',
        {
          'w-6 h-6': size === 'normal',
          'w-4 h-4': size === 'small',
          'text-dark-grey cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
          'bg-light-grey': visible,
        },
      )}
      {...rest}
      onClick={
        onClick
          ? (event) => {
              if (!disabled) {
                onClick(event);
              }
            }
          : undefined
      }
    >
      {isLoading ? <CircleLoader isColored={true} /> : children}
    </div>
  );

  if (tooltip) {
    return <Tooltip trigger={iconBackgroundContent}>{tooltip}</Tooltip>;
  }

  return iconBackgroundContent;
};

export default IconBackground;
