import {
  cloneElement,
  CSSProperties,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import Icon from './Icon';

let tooltipRoot = document.getElementById('tooltip-root');
if (!tooltipRoot) {
  tooltipRoot = document.createElement('div');
  tooltipRoot.setAttribute('id', 'tooltip-root');
  document.body.appendChild(tooltipRoot);
}

function getArrowAdjustment(placement: string): CSSProperties {
  if (placement.startsWith('top')) {
    return { bottom: -4 };
  } else if (placement.startsWith('bottom')) {
    return { top: -4 };
  } else if (placement.startsWith('left')) {
    return { right: -4 };
  } else if (placement.startsWith('right')) {
    return { left: -4 };
  }

  return {};
}

const Tooltip = ({
  alwaysShow = false,
  children,
  initialShow = false,
  placement = 'top',
  showDelay = 200,
  trigger,
}: {
  alwaysShow?: boolean;
  children: ReactNode;
  initialShow?: boolean;
  placement?: 'right' | 'top';
  showDelay?: number;
  trigger?: ReactElement;
}): JSX.Element => {
  const isHovering = useRef(false);
  const showTooltipTimeout = useRef<number | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(alwaysShow || initialShow);

  /**
   * This delays showing the tooltip. This is useful so we don't immediately show the tooltip if the user
   * quickly moves their mouse over the trigger point on their way to some other element.
   */
  function triggerDelayedShow() {
    if (showTooltipTimeout.current) {
      window.clearTimeout(showTooltipTimeout.current);
    }

    showTooltipTimeout.current = window.setTimeout(() => {
      if (!isHovering.current) {
        return;
      }

      setShowTooltip(true);
    }, showDelay);
  }

  useEffect(() => {
    return () => {
      if (showTooltipTimeout.current) {
        window.clearTimeout(showTooltipTimeout.current);
      }
    };
  }, []);

  const triggerProps = {
    ref: setReferenceElement,
    onMouseEnter: () => {
      isHovering.current = true;
      triggerDelayedShow();
    },
    onMouseLeave: () => {
      if (alwaysShow) {
        return;
      }

      isHovering.current = false;
      setShowTooltip(false);
    },
  };
  const resolvedTrigger = trigger ? (
    cloneElement(trigger, triggerProps)
  ) : (
    <div className="w-4 h-4 text-green" {...triggerProps}>
      <Icon id="info-circle" />
    </div>
  );

  return (
    <>
      {resolvedTrigger}

      {showTooltip && (
        <TooltipBody placement={placement} referenceElement={referenceElement}>
          {children}
        </TooltipBody>
      )}
    </>
  );
};

export default Tooltip;

const TooltipBody = ({
  children,
  placement,
  referenceElement,
}: {
  children: ReactNode;
  placement: 'right' | 'top';
  referenceElement: HTMLElement | null;
}): JSX.Element => {
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement,
  });

  const sharedArrowClasses = 'absolute w-2 h-2 -z-10';

  return createPortal(
    <div
      ref={setPopperElement}
      className="max-w-xs z-50 rounded-lg shadow-[0_20px_25px_-5px_rgb(0_0_0_/_0.1),0_-1px_2px_-0_rgb(0_0_0_/_0.075),_0_8px_10px_-6px_rgb(0_0_0_/_0.1)]"
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="rounded-lg bg-white p-3 text-gray-d-700 text-xs leading-relaxed">
        {children}
      </div>
      <div
        ref={setArrowElement}
        className={sharedArrowClasses}
        style={{
          ...styles.arrow,
          ...getArrowAdjustment(
            attributes.popper ? attributes.popper['data-popper-placement'] : '',
          ),
        }}
      >
        <div
          className={`${sharedArrowClasses} top-0 left-0 transform rotate-45 bg-white`}
        />
      </div>
    </div>,
    tooltipRoot as HTMLDivElement,
  );
};
