import {
  queryOptions,
  useMutation,
  useQueryClient as useQueryClient,
} from '@tanstack/react-query';

import {
  createVariable,
  deleteVariable,
  fetchVariableResults,
  fetchVariables,
  updateVariable,
} from 'services/backend/surveyVariables';
import {
  formDataToApiData,
  SurveyVariableFormDataValidated,
} from 'util/surveyVariables';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { Survey, SurveyVariable } from 'types/domainModels';

export const variableQueries = {
  getResults: (opts: { variableId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchVariableResults({
          variableId: opts.variableId,
          waveIds: opts.waveIds,
        }),
      queryKey: ['variables', opts.variableId, 'results', opts.waveIds],
    }),
  list: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchVariables({ surveyId: opts.surveyId }),
      queryKey: ['surveys', opts.surveyId, 'variables'],
    }),
};

export function useDeleteVariable(
  opts?: ReactQueryFunctionCallbacks<typeof deleteVariable>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: async (opts) => {
      const deleteVariableResult = await deleteVariable(opts);

      await queryClient.invalidateQueries(
        variableQueries.list({ surveyId: opts.surveyId }),
      );

      return deleteVariableResult;
    },
  });
}

export function useSaveSurveyVariables({
  onError,
  onSuccess,
  survey,
  variable,
}: {
  onError(err: Error): void;
  onSuccess(
    data: Awaited<ReturnType<typeof createVariable | typeof updateVariable>>,
  ): void;
  survey: Survey;
  variable: SurveyVariable | undefined;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: SurveyVariableFormDataValidated) => {
      const data = formDataToApiData({ formData, survey });

      const saveQuestionResult = await (variable
        ? updateVariable({ data, surveyId: survey.id, variableId: variable.id })
        : createVariable({ data, surveyId: survey.id }));

      await queryClient.invalidateQueries(
        variableQueries.list({ surveyId: survey.id }),
      );

      return saveQuestionResult;
    },
    onError,
    onSuccess,
  });
}
