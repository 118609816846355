import { QuestionQuota } from 'types/forms';
import {
  QUESTION_TYPE,
  Question,
  QuestionQuotaElement,
  QuestionQuotaLogicalModifiers,
  QuestionQuotaResult,
} from 'types/domainModels';
import { orderBy } from 'lodash-es';

export function formQuotaToApiQuotaElement({
  quota,
}: {
  quota: QuestionQuota;
}): QuestionQuotaElement {
  return {
    type: 'enum', // currently hardcoded; other types will be created/adjusted manually
    numberNeeded: quota.optionQuota,
    optionIndices: quota.options.map((option) => option.value),
    logicalModifier: quota.logicalModifier
      ?.value as QuestionQuotaLogicalModifiers,
  };
}

export function orderQuotasByOptions(quotas: QuestionQuotaResult[]) {
  return orderBy(quotas, (quota) => {
    const orderedOptions = orderBy(quota.options, 'sort');

    return orderedOptions[0]?.sort;
  });
}

export function questionSupportsQuotas(question: Question) {
  return (
    question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE ||
    question.questionTypeId === QUESTION_TYPE.RANKING
  );
}
