import { clsx } from 'clsx';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

import ChatIcon from 'components/common/icons/ChatIcon';
import Icon from 'components/common/Icon';

let lastSidebarScroll: number | undefined;

const SurveyWithSidebar = ({
  children,
  sidebar,
  sidebarRight,
}: {
  children: ReactNode;
  sidebar: ReactNode;
  sidebarRight?: ReactNode;
}): JSX.Element => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const previousSidebar = sidebarRef.current;
    if (lastSidebarScroll) {
      sidebarRef.current?.scrollTo(0, lastSidebarScroll);
    }

    return () => {
      lastSidebarScroll = previousSidebar?.scrollTop;
    };
  }, []);

  return (
    <div className="w-full bg-gray-50 min-h-full h-survey-sidebar flex">
      <div
        ref={sidebarRef}
        className="ml-6 w-survey-page-sidebar overflow-auto scrollbar-hide h-full bg-gray-50 shrink-0"
      >
        <div className="py-6 pb-24">{sidebar}</div>
      </div>

      <div className="p-6 pb-0 grow overflow-auto h-full">
        <div className="max-w-survey-summary-card pb-24">{children}</div>
      </div>

      {sidebarRight && (
        <>
          <div className="xl:hidden h-full w-comments-tray bg-white border-r border-l border-gray-300">
            {sidebarRight}
          </div>

          <div
            className={clsx(
              'xl:block hidden fixed top-comments-tray right-0 w-comments-tray h-comments-tray transform transition-transform z-10 bg-white shadow-lg',
              {
                'translate-x-0': isRightSidebarOpen,
                'translate-x-full': !isRightSidebarOpen,
              },
            )}
          >
            <div
              className="absolute top-0 left-0 flex items-center p-4 space-x-2 transform -translate-x-full bg-green text-white cursor-pointer"
              onClick={() => {
                setIsRightSidebarOpen((isOpen) => !isOpen);
              }}
            >
              <div className="w-6 h-6">
                {isRightSidebarOpen ? <Icon id="x" /> : <ChatIcon />}
              </div>
            </div>

            {sidebarRight}
          </div>
        </>
      )}
    </div>
  );
};

export default SurveyWithSidebar;
