import { FormikErrors, useFormikContext } from 'formik';
import { some, throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { useHasProcessed } from './loading';

/**
 * Provides functionality for displaying errors and validating only after the submit button has been
 * clicked. The idea here is that it's annoying to be validating a form as a user goes on blur because
 * there might be dependent validations later on that will appear to the user even though they just
 * haven't gotten to that step yet.
 *
 * This functionality will only return the form errors after the user has attempted to submit and it
 * will throttle validation on change until the user fixes all errors in the form. Once the form is valid
 * and / or saved, the errors will not be returned until the user attempts to save again.
 */
export function useSubmitValidation<FormDataType>({
  isSaving,
  onHasError,
}: {
  isSaving: boolean;
  onHasError?(): void;
}): {
  errors: FormikErrors<FormDataType> | undefined;
  onClickSubmit(): Promise<unknown>;
  validateAndSubmit(): Promise<unknown>;
} {
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const { errors, isValid, submitCount, submitForm, validateForm, values } =
    useFormikContext<FormDataType>();

  const hasErrors = hasClickedSubmit && !isValid;

  const hasSaved = useHasProcessed(isSaving);
  useEffect(() => {
    // When the form becomes valid, we want to reset our flag for if we clicked submit so
    // that we give user visual feedback that they can try submitting again.
    if (isValid || hasSaved) {
      setHasClickedSubmit(false);
    }
  }, [isValid, hasSaved]);

  useEffect(() => {
    const throttledValidate = throttle(
      () => {
        validateForm();
      },
      400,
      {
        leading: false,
      },
    );
    if (hasErrors && submitCount > 0) {
      throttledValidate();
    }

    return () => {
      throttledValidate.cancel();
    };
  }, [hasErrors, submitCount, validateForm, values]);

  const onClickSubmit = useCallback(() => {
    const submitFormReturn = submitForm();
    setHasClickedSubmit(true);

    return submitFormReturn;
  }, [submitForm]);

  const validateAndSubmit = useCallback(async () => {
    const errors = await validateForm();
    if (some(errors, (error) => !!error)) {
      // This is somewhat confusing why we still call this even when there errors in the
      // form, but we do this because it will currently cause the form errors to be
      // shown. Without this we won't show errors since our logic will think the user
      // hasn't attempted to save the form yet, and we don't show errors if the user
      // hasn't saved to not be flooding them with validation errors if they're still working.
      onClickSubmit();
      onHasError?.();

      return;
    }

    return onClickSubmit();
  }, [onClickSubmit, onHasError, validateForm]);

  return {
    errors: hasErrors ? errors : undefined,
    onClickSubmit,
    validateAndSubmit,
  };
}
