import { useField } from 'formik';

import { Question, Survey } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import FormCheckbox from 'components/common/forms/FormCheckbox';
import FormInput from 'components/common/forms/FormInput';
import PipeConcept from '../questionFeatures/PipeConcept';
import QuestionConstraint from '../questionFeatures/RangeConstraint';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';
import ViewConcept from '../questionFeatures/ViewConcept';

const OpenEnded = ({
  concepts = [],
  pipeConcept,
  question,
  questions,
  survey,
}: {
  concepts?: QuestionFormOption[];
  pipeConcept?: boolean;
  question: Question | undefined;
  questions: Question[];
  survey: Survey;
}) => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');

  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          disabled={pipeConcept}
          question={question}
          questions={questions}
          survey={survey}
          upperLimit={1}
        />
      </SurveyEditRow>
      <div className="p-6 border-b border-gray-300">
        {optionType?.value === 'audio-video' ? (
          <VoxpopmeConfiguration />
        ) : (
          <p className="text-sm">Respondents will type an open ended answer.</p>
        )}
      </div>
      <div className="p-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <PipeConcept
            concepts={concepts}
            question={question}
            questions={questions}
          />
          <ViewConcept />
          <DisplayLogic
            question={question}
            questions={questions}
            survey={survey}
          />
          <QuestionConstraint constraintLabel="Within Range" />
        </div>
      </div>
    </>
  );
};

export default OpenEnded;

const VoxpopmeConfiguration = () => {
  return (
    <>
      <SurveyEditRowLeftSide title="Voxpopme Configuration" />
      <div className="grid grid-cols-2 gap-4">
        <FormInput
          id="voxpopme-project-id"
          label="Project ID"
          labelFor="voxpopme-project-id"
          name="voxpopmeConfig.projectId"
          size="md"
          type="text"
        />
        <div className="mt-6">
          <FormCheckbox
            checkboxLabel="Response Required"
            id="voxpopme-response-required"
            name="voxpopmeConfig.responseRequired"
            tooltip={
              <p>
                If selected, the respondent must submit either an audio or video
                response to progress in the survey.
              </p>
            }
          />
        </div>
      </div>
    </>
  );
};
